import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AppBar from "@mui/material/AppBar";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateSubAdminAction } from "../../../Actions/AdminActions";
const helperText = makeStyles(({theme}) => ({
  root: {
    "& .MuiTextField-root": {
      margin: 1,
      width: 200,
    },
    "& .MuiFormHelperText-root": {
      color: "red !important",
    },
  },
  bg: {
    backgroundColor: "#7986cb",
  },
  textfield: {
    backgroundColor: "#fff",
  },
}));
const EditSubAdmin = ({
  handleClose,
  open,
  subAdminId,
  emailForUpdate,
  firstNameForUpdate,
  lastNameForUpdate,
  passwordForUpdate,
}) => {
  const helperTxtClass = helperText();
  const dispatch = useDispatch();
  const [fistName, setFirstName] = useState(firstNameForUpdate);
  const [lastName, setLastName] = useState(lastNameForUpdate);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [email, setEmail] = useState(emailForUpdate);
  const [emailErr, setEmailErr] = useState(false);
  const [password, setPassword] = useState(passwordForUpdate);
  const [passwordErr, setPasswordErr] = useState(false);
  const [emtyFirstName, setEmtyFirstName] = useState(false);
  const [emtyLastName, setEmtyLastName] = useState(false);
  const [emtyEmail, setEmtyEmail] = useState(false);
  const [emtyPassword, setEmtyPassword] = useState(false);
  const [emailErrText, setEmailErrText] = useState("This Field is required");

  const inputHandle = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "firstName") {
      setFirstName(value);
      setFirstNameErr(true);
    }
    if (name === "lastName") {
      setLastName(value);
      setLastNameErr(true);
    } else if (name === "email") {
      setEmail(value);
      setEmailErr(true);
    } else if (name === "password") {
      setPassword(value);
      setPasswordErr(true);
    }
  };

  const handleSubmit = () => {
    if (!fistName) {
      setEmtyFirstName(true);
    } else {
      setEmtyFirstName(false);
    }
    if (!lastName) {
      setEmtyLastName(true);
    } else {
      setEmtyLastName(false);
    }
    if (!email) {
      setEmtyEmail(true);
    } else {
      setEmtyEmail(false);
    }
    if (!password) {
      setEmtyPassword(true);
    } else {
      setEmtyPassword(false);
    }
  
    if (
      fistName !== "" &&
      lastName !== "" &&
      email !== "" &&
      password !== "" &&
      subAdminId !== ""
    ) {
      dispatch(
        updateSubAdminAction({
          fistName,
          lastName,
          email,
          password,
          subAdminId,
        })
      );
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={handleClose}>
        <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
          <DialogTitle sx={{ color: "white" }}>
            <AddIcon /> Add sub admin
            <span style={{ marginLeft: "20px" }}></span>
          </DialogTitle>
        </AppBar>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            {/* {exist ? "This sub admin is duplicate please enter anthor name" :""} */}
            <TextField
              className={helperTxtClass.root}
              helperText={
                (!fistName && firstNameErr) || emtyFirstName
                  ? "Field is required"
                  : ""
              }
              label="First Name"
              name="firstName"
              value={fistName}
              fullWidth
              sx={{
                width: "344px",
              }}
              type="text"
              onChange={inputHandle}
            />
            <TextField
              className={helperTxtClass.root}
              helperText={
                (!lastName && lastNameErr) || emtyLastName
                  ? "Field is required"
                  : ""
              }
              label="Last Name"
              name="lastName"
              value={lastName}
              fullWidth
              sx={{
                width: "344px",
              }}
              type="text"
              onChange={inputHandle}
            />
            <TextField
              className={helperTxtClass.root}
              helperText={(!email && emailErr) || emtyEmail ? emailErrText : ""}
              label="Email"
              type="email"
              name="email"
              value={email}
              sx={{
                width: "344px",
              }}
              onChange={inputHandle}
            />
            <TextField
              className={helperTxtClass.root}
              helperText={
                (!password && passwordErr) || emtyPassword
                  ? "Field is required"
                  : ""
              }
              label="Password"
              type="password"
              name="password"
              value={password}
              sx={{
                width: "344px",
              }}
              onChange={inputHandle}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default EditSubAdmin;
