import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import { Table } from "react-bootstrap";
import "../../../src/App.css";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Upload from "@mui/icons-material/Upload";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  addUserBanner,
  listUserBanner,
  updateUserBanner,
  deleteUserBanner,
} from "../../Actions/AdminActions";
import Loader from "../../Components/Loader";
import PopUpImage from "../../Components/Admin/image_modal/PopUpImage";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: 2,
  },
  "& .MuiDialogActions-root": {
    padding: 1,
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const UserBanner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const [open, setOpen] = React.useState(false);
  const [bannerPrivew, setBannerprivew] = useState("");
  const [hedding, setHedding] = useState("");
  const [option, setOption] = useState("");
  const [updateDrive, setUpdatePrivew] = useState();
  const loading1 = useSelector((state) => state.adminAddUserBanner.loading);
  const bannersUser = useSelector((state) => state.userBanner);
  let { userBanners } = bannersUser;
  const { loading, success } = useSelector((state) => state.updateUserBanner);
  const { deleted } = useSelector((state) => state.adminDeleteUserBanner);
  const { addsuccess } = useSelector((state) => state.adminAddUserBanner);
  const [imgUrl, setImgUrl] = useState("");
  const [openImg, setOpenImg] = React.useState(false);

  const handleCloseimg = () => {
    setOpenImg(false);
  };

  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }
    dispatch(listUserBanner());
  }, [adminInfo, dispatch, navigate, success, addsuccess, deleted]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setBannerprivew("");
  };

  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }
  }, [adminInfo, navigate]);

  const inputHandle = (e) => {
    const file = e.target.files[0];
    previewProfile(file);
  };

  const previewProfile = (file) => {
    setImageErr("");
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBannerprivew(reader.result);
    };
  };

  const [imgErr, setImageErr] = useState("");

  // save function

  const SaveBanner = () => {
    if (option === "Add") {
      if (bannerPrivew === "") {
        setImageErr("Please choose an image ...");
      } else {
        setImageErr("");
        dispatch(addUserBanner(bannerPrivew));
        setOpen(false);
      }
    } else {
      if (bannerPrivew === "") {
        setImageErr("Please choose an image ...");
      } else {
        setImageErr("");
        dispatch(updateUserBanner(updateDrive._id, bannerPrivew));
        setOpen(false);
        setBannerprivew("");
      }
    }
  };

  const deleteBanner = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this banner?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it?",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteUserBanner(id));

        Swal.fire("Deleted!", "This banner successfully deleted.", "success");
      }
    });
  };

  return (
    <>
      {loading1 ? <Loader loading={loading1} /> : ""}
      {loading ? <Loader loading={loading} /> : ""}
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {hedding}
          </BootstrapDialogTitle>
          <DialogContent sx={{ width: "600px", height: "300px" }} dividers>
            <Button
              variant="contained"
              component="label"
              style={{ backgroundColor: "green" }}
              color="primary"
            >
              <AddIcon />
              <input
                name="addImage"
                onChange={inputHandle}
                type="file"
                accept="image/*"
                hidden
              />
              Add banner
            </Button>
            <h6
              style={{
                marginLeft: "20px",
                display: "inline-block",
                color: "red",
              }}
            >
              {imgErr}
            </h6>
            <div
              style={{
                padding: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {bannerPrivew && (
                <img height="180px" src={bannerPrivew} alt="chosen"></img>
              )}
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={SaveBanner}
              variant="contained"
              style={{ backgroundColor: "green" }}
              color="primary"
            >
              Save
            </Button>
            <Button
              variant="contained"
              sx={{ background: "red", marginLeft: "20px" }}
              autoFocus
              onClick={handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="User Banner"
          id={adminInfo && adminInfo._id}
          name={`${adminInfo.firstName} ${adminInfo.lastName}`}
          email={adminInfo && adminInfo.email}
          place={adminInfo && adminInfo.place}
          token={adminInfo && adminInfo.token}
        />

        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          <h5 style={{ display: "block", color: "green" }}>User Banner</h5>

          <div style={{ display: "flex", justifyContent: "end" }}>
            {" "}
            <div>
              <Button
                onClick={() => {
                  setHedding("Upload User Banner");
                  setOption("Add");
                  handleClickOpen();
                }}
                variant="contained"
                style={{ backgroundColor: "green" }}
                color="primary"
              >
                <AddIcon /> add Banner
              </Button>
            </div>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>No</th>
                <th>Image</th>
                <th>Update</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {userBanners &&
                userBanners.map((banner, index) => {
                  return (
                    <tr key={banner._id}>
                      <td>
                        <span>{index + 1}</span>
                      </td>
                      <td>
                        <img
                          onClick={() => {
                            setImgUrl(banner.userBanner);
                            setOpenImg(true);
                          }}
                          width="250px"
                          src={banner.userBanner}
                        ></img>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            width: "250px",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            color="primary"
                            style={{ marginTop: "20px" }}
                            onClick={() => {
                              setHedding("Update Driver Banner");
                              setOption("Edit");
                              setUpdatePrivew(banner);
                              setBannerprivew(banner.userBanner);
                              handleClickOpen();
                            }}
                          >
                            <Upload />
                            Update Banner
                          </Button>
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            width: "250px",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              deleteBanner(banner._id);
                            }}
                            variant="contained"
                            style={{ background: "red", marginTop: "20px" }}
                            startIcon={<DeleteIcon />}
                          >
                            Delete Banner
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {userBanners&&userBanners.length===0 ? <h6 align="center" style={{color:"green"}}>No Banners</h6> : ""}
        </Box>
      </Box>
      <PopUpImage
        handleCloseimg={handleCloseimg}
        open={openImg}
        imgUrl={imgUrl}
      />
    </>
  );
};

export default UserBanner;
