import {
  BOOKING_DATE_FILTER,
  BOOKING_SEARCH,
  BOOKING_STATUS_FILTER,
  BOOKING_VEHICLETYPE_FILTER,
  BOOKING_VEHICLE_SUBTYPE_FILTER,
  WITHDRAWAL_DATE_FILTER,
  WITHDRAWAL_SEARCH_FILTER,
  WITHDRAWAL_STATUS_FILTER,
  BOOKING_PLACES_FILTER,
  DRIVER_PLACES_FILTER
} from '../Constants/FilterConstants';

export const FilterBookingStatusAction = (values) => async (dispatch) => {
  dispatch({
    type: BOOKING_STATUS_FILTER,
    payload: values,
  });
};
export const FilterBookingPlacesAction = (values) => async (dispatch) => {

  dispatch({
    type: BOOKING_PLACES_FILTER,
    payload: values,
  });
};
export const filterDriverPlaceAction = (values) => async (dispatch) => {

  dispatch({
    type: DRIVER_PLACES_FILTER,
    payload: values,
  });
};

export const FilterBookingVehicleTypeAction = (values) => async (dispatch) => {
  dispatch({
    type: BOOKING_VEHICLETYPE_FILTER,
    payload: values,
  });
};

export const FilterBookingVehicleSubTypeAction =
  (values) => async (dispatch) => {
    dispatch({
      type: BOOKING_VEHICLE_SUBTYPE_FILTER,
      payload: values,
    });
  };

export const SearchBookingsAction = (values) => async (dispatch, getState) => {
  dispatch({
    type: BOOKING_SEARCH,
    payload: values,
  });
};

export const FilterWithDateAction = ({from, to}) => async (dispatch) => {
  
  dispatch({
    type: BOOKING_DATE_FILTER,
    payload: { from, to },
  });
};


export const FilterWithdrawalStatusAction = (values) => async (dispatch) => {
 
  dispatch({
    type: WITHDRAWAL_STATUS_FILTER,
    payload: values,
  });
};

export const FilterWithdrawDateAction = (from, to) => async (dispatch) => {
  dispatch({
    type: WITHDRAWAL_DATE_FILTER,
    payload: { from, to },
  });
};

export const SearchWithdrawRequestsAction = (values) => async (dispatch, getState) => {
  dispatch({
    type: WITHDRAWAL_SEARCH_FILTER,
    payload: values,
  });
};
