import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import Loader from "../../Components/Loader";
import Typography from "@mui/material/Typography";
import {StatusDrowpDown, FromToDateFilter, SearchFilter} from '../../Components/Admin/WithdrawalRequests/Filters'
// import {
//   SearchFilter,
// } from "../../Components/Admin/customerHelper/FilterCustomer";
import { EnhancedTableHead1 } from "../../Components/Admin/WithdrawalRequests/TableHeadings";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import io from "socket.io-client";
import { LOADRUNNR_SOCKET_API } from "../../socketConst/BaseApi";
import { Paper } from "@mui/material";
import exportFromJSON from "export-from-json";
import {
  approveWithdrawalRequestAction,
  getWithdrawalRequestsAction,
  updateWithdrowalAction
} from "../../Actions/AdminActions";
import ApprovalDetails from "../../Components/Admin/WithdrawalRequests/ApprovalDetails";
import moment from "moment";
import "moment-timezone";
import Swal from "sweetalert2";


var data = [];
let stop = 2;

const fileName = "download";
const exportType = "xls";

const handleDownload = () => {
  if (data.length > 0) {
    exportFromJSON({ data, fileName, exportType });
  } else {
    alert("No Data For Download!...");
  }
};


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(withdrawalRequests, comparator) {
  const stabilizedThis = withdrawalRequests.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

EnhancedTableHead1.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const WithdrawalRequest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  // menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // end menu
  // wallt modal
  const [isOpen, setisOpen] = React.useState(false);
  const [isOpenComment, setisCommentOpen] = React.useState(false);
  const handleOpen = () => {
    setisOpen(true);
  };
  const handleClose = () => {
    setisOpen(false);
  };
  const handleCommentClose = () => {
    setisCommentOpen(false);
  };

  // end wallet

  // Pagination
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [age, setAge] = React.useState("");
  const [online, setOnline] = React.useState(true);
  const [costomId, setCostomId] = React.useState("");
  const [id, setId] = React.useState("");
  const [walletLogs, setWalletLogs] = React.useState(false);
  const [addWalletModalShow, setAddWalletModalShow] = React.useState(false);
  const updateDriverWallet = useSelector((state) => state.updatedDriver);
  const { updateSuccess, error } = updateDriverWallet;
  const [csnotes, setCsnotes] = React.useState([]);
  const [showApprovalDetails, setShowApprovalDetails] = React.useState(false);
  const withdrawalRequest = useSelector((state) => state.withdrawalRequest);
  const { loading, withdrawalRequests } = withdrawalRequest;
  const [update, setUpdate] = React.useState(false);
  const [updateOrder, setUpdateOrder] = React.useState({})

  useEffect(() => {
    const socket = io(LOADRUNNR_SOCKET_API, {
      withCredentials: true,
      transports: ["websocket"] ,
      extraHeaders: {
        "my-custom-header": "abcd"
      }
    });
    // const socket = io(LOADRUNNR_SOCKET_API, { transports: ["websocket"] });
    socket.on("requestUpdations", (data) => {
      if(data.new){
        // setUpdateOrder(data.request)
       
        dispatch(updateWithdrowalAction(data.request))
        //setUpdate(true)
        
      }
      return ()=> socket.close()
    });
    return () => socket.close()
  }, []);
  // useEffect(() => {
  //   if(update, updateOrder){
  //     setUpdate(false)
  //     if(updateOrder !={}){
  //       withdrawalRequests && withdrawalRequests.unshift(updateOrder)
  //       setUpdateOrder({})
  //     }
  //   }
    
  // }, [update])
  

  // download data

  const dataPushing = (reqs) => {
    data.push({
      "Date & Time" : reqs.date + " " + reqs.time,
      "Request Id" : reqs.requestId,
      Status : reqs.status,
      "Driver Name" : reqs.driverName,
      "Phone Number" : reqs.driverPhoneNumber,
      "Bank Name" : reqs.bankName,
      "Account Number" : reqs.accountNumber,
      "IFSC Code" : reqs.ifscCode,
      Amount : reqs.amount,
      Comment : reqs.comment
    })
  }

  withdrawalRequests && withdrawalRequests.map(reqs => {
    if(withdrawalRequests.length === data.length){
      return data
    }else{
      data.push({
        "Date & Time" : reqs.date + " " + reqs.time,
        "Request Id" : reqs.requestId,
        Status : reqs.status,
        "Driver Name" : reqs.driverName,
        "Phone Number" : reqs.driverPhoneNumber,
        "Bank Name" : reqs.bankName,
        "Account Number" : reqs.accountNumber,
        "IFSC Code" : reqs.ifscCode,
        Amount : reqs.amount,
        Comment : reqs.comment
      })
    }
})

const {withdrowal} = useSelector(state=> state.approveWithdrawRequest)
const {reject}= useSelector(state=> state.rejectWithdrawRequest)


  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }
    dispatch(getWithdrawalRequestsAction());
  }, [adminInfo, dispatch, navigate,withdrowal,reject]);

// filters 

const withdrawalStatus = useSelector(state => state.withdrawalStatus)
const  { statusDateFilter, from, to } = useSelector(state => state.withdrawDateFilter)
const { content } = useSelector(state => state.withdrawSearch)

let allKey = withdrawalStatus.filters


  const handleDropdown = (action, id) => {
    if (action === "AddVerify") {
      navigate("/admin/verifydriver/" + id);
    } else if ("walletLogs") {
      setWalletLogs(true);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = withdrawalRequests.map(
        (n) => n.personalDetails.firstName
      );
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - withdrawalRequests.length)
      : 0;

  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }
  }, [adminInfo, navigate]);

  const handleApproval = (id) => { Swal.fire({
    title: 'Are you sure?',
    text: 'You want to approve this request ?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'approve',
  }).then((result) => {
    if (result.isConfirmed) {
     
      dispatch(approveWithdrawalRequestAction(id));
      Swal.fire(
        'Approved!',
        'This request is approved.',
        'success'
      )
    }
  });




   
  };

  // clear data for download
  statusDateFilter && (data = [])
content && (data =[])
withdrawalStatus && withdrawalStatus.statusFilter && (data =[])

  let userREconrd = (row) => {
    return (
      <>
        <TableRow hover key={row._id}>
          <TableCell>{row.date + " " + row.time}</TableCell>
          <TableCell>{row.requestId}</TableCell>
          <TableCell>
            {row.status === "Rejected" && (
              <p style={{ fontWeight: "bold", color: "red" }}>{row.status}</p>
            )}
            {row.status === "Pending" && (
              <p style={{ fontWeight: "bold", color: "yellowgreen" }}>
                {row.status}
              </p>
            )}
            {row.status === "Approved" && (
              <p style={{ fontWeight: "bold", color: "green" }}>{row.status}</p>
            )}
          </TableCell>
          <TableCell>{row.driverName}</TableCell>
          <TableCell>{row.driverPhoneNumber}</TableCell>

          <TableCell>{row.bankName}</TableCell>

          <TableCell>{row.accountNumber}</TableCell>
          <TableCell>{row.ifscCode}</TableCell>
          <TableCell>₹ {row.amount}</TableCell>
         

          <TableCell>
            {row.status === "Rejected" || row.status === "Approved" ? (
              <>
                {row.status === "Rejected" && (
                  <p style={{ color: "red" }}>{row.comment}</p>
                )}
                <Button
                  style={{ padding: "2px" }}
                  sx={{
                    width: "100px",
                  }}
                  variant="contained"
                >
                  No Action
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={{ display: "inline-block", padding: "2px" }}
                  sx={{
                    backgroundColor: "green",
                    width: "50px",
                    height: "20px",
                    fontSize: "10px",
                    marginLeft: "10px",
                  }}
                  variant="contained"
                  onClick={() => handleApproval(row._id)}
                >
                  Approve
                </Button>

                <Button
                  style={{ display: "inline-block", padding: "2px" }}
                  sx={{
                    backgroundColor: "red",
                    width: "50px",
                    height: "20px",
                    fontSize: "10px",
                    marginLeft: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    setId(row._id);
                    setShowApprovalDetails(true);
                  }}
                >
                  Reject
                </Button>
              </>
            )}
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="Withdrawal"
          id={adminInfo && adminInfo._id}
          email={adminInfo && adminInfo.email}
          name={`${adminInfo.firstName} ${adminInfo.lastName}`}
          place={adminInfo && adminInfo.place}
          token={adminInfo && adminInfo.token}
        />

        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <>
            <Toolbar />
         
            <h5 style={{ display: "block", color: "green" }}>Withdrawal Requests</h5>
            <Typography
                sx={{ fontSize: "15px", fontWeight: "bold" }}
                component="span"
              >
                Filter By:
              </Typography>
            <div style={{ paddingTop: 25, paddingBottom: 25 }}
              >
                <StatusDrowpDown style={{ marginTop: "30px" }} />
                <FromToDateFilter style={{ marginTop: "30px" }} />

                <div
                  style={{
                    display: "inline-flex",
                    marginLeft: "50px",
                  }}
                >
                  <SearchFilter />
                  <Button
                    sx={{ marginLeft: "20px", bgcolor: "red" }}
                    variant="contained"
                    endIcon={<DownloadIcon />}
                    onClick={() => handleDownload()}
                  >
                    Download 
                  </Button>
                </div>
              </div> 

            {/* {/* table */}
            {!loading && withdrawalRequests && (
              <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2 }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                    >
                      <EnhancedTableHead1
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={withdrawalRequests.length}
                      />

                      <TableBody>
                        {stableSort(
                          withdrawalRequests,
                          getComparator(order, orderBy)
                        )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )

                          .map((row) => {

                            if (statusDateFilter) {
                              let date = row.createdAt;
                              let start = moment(from).format("L");
                              let end = moment(to).format("L");
                              date = moment(date).format("L");
                              let datefltr = moment(date).isBetween(start, end);
                              if(datefltr){
                                if(withdrawalStatus.statusFilter){
                                  if(allKey.includes(row.status)){
                                    dataPushing(row)
                                    return userREconrd(row);
                                  }
                                }else{
                                  dataPushing(row)
                                  return userREconrd(row)
                                }
                              }
                            } else {
                              if(content){
                                function escapeRegExp(string) {
                                  return string.replace(
                                    /[.*+?^${}()|[\]\\]/g,
                                    "\\$&"
                                  );
                                }
                                let escpd = escapeRegExp(content);
                                var regex = new RegExp(escpd, "gi");
                                let amount = row.amount + ''
                                if(
                                  row.status.match(regex) || 
                                  row.requestId.match(regex)||
                                  row.date.match(regex) || 
                                  row.driverName.match(regex) || 
                                  row.driverPhoneNumber.match(regex)||
                                  row.bankName.match(regex)||
                                  row.accountNumber.match(regex)||
                                  row.ifscCode.match(regex) ||
                                  amount.match(regex)
                                  ){
                                    dataPushing(row)
                                  return userREconrd(row)
                                }
                              }else {
                                if(withdrawalStatus.statusFilter){
                                  if(allKey.includes(row.status)){
                                    dataPushing(row)
                                    return userREconrd(row);
                                  }
                                }
                                else{
                                  dataPushing(row)
                                  return userREconrd(row);
                                }
                              }
                            }
                          })}
                        {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (dense ? 33 : 53) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    {withdrawalRequests.length === 0 ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {" "}
                        <h1 style={{ marginTop: "50px", marginRight: "500px" }}>
                          No Data
                        </h1>{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={withdrawalRequests.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Box>
            )}
          </>
        </Box>
      </Box>
      <Loader loading={loading}/>
      <ApprovalDetails
        onHide={() => setShowApprovalDetails(false)}
        show={showApprovalDetails}
        id={id}
      />
    </>
  );
};

export default WithdrawalRequest;
