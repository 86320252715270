import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import ClearIcon from "@mui/icons-material/Clear";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { API } from "../../socketConst/BaseApi";
import { IconButton, InputBase } from "@mui/material";
import { useState } from "react";

const columns = [
  { id: "Id", label: "ID", minWidth: 170, align: "center" },
  { id: "dateAndTime", label: "date And Time", minWidth: 200, align: "center" },
  { id: "order_id", label: "Order id", minWidth: 370, align: "center" },
  { id: "user_id", label: "customer id", minWidth: 150, align: "center" },
  { id: "name", label: "NAME", minWidth: 200, align: "center" },
  { id: "phoneNumber", label: "PHONE NUMBER", minWidth: 170, align: "center" },

  { id: "txn_id", label: "txn_id", minWidth: 370, align: "center" },
  { id: "amount", label: "amount", minWidth: 100, align: "center" },
  { id: "status", label: "status", minWidth: 100, align: "center" },
];
const UserTransactions = () => {
  const naviagate = useNavigate();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;

  useEffect(() => {
    if (!adminInfo) {
      naviagate("/login");
    }
  }, [adminInfo, naviagate]);
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const config = {
    headers: {
      Authorization: `Bearer ${adminInfo.token}`,
    },
  };

  useEffect(() => {
    let getTransactions = async () => {
      let response = await axios.get(
        `${API}/api/admin/customer-transactions`,
        config
      );

      let transactions = response.data;
      setRows(transactions);
    };

    getTransactions();
  }, []);

  const [content, setContent] = useState("");
  useEffect(() => {
    setPage(0);
  }, [content]);

  const refresh = () => setContent("");
  const transactionRecord = (row, i) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={i}>
        <TableCell align="center">{row.Id}</TableCell>

        <TableCell align="center">{row.dateAndTime}</TableCell>

        <TableCell align="center">{row.order_id}</TableCell>
        <TableCell align="center">{row.user_id}</TableCell>
        <TableCell align="center">{row.name}</TableCell>
        <TableCell align="center">{row.phoneNumber}</TableCell>
        <TableCell align="center">{row.txn_id}</TableCell>
        <TableCell align="center">{`₹${row.amount}`}</TableCell>

        <TableCell align="center">
          {row.status === "Failed" ? (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {row.status}
            </span>
          ) : (
            ""
          )}
          {row.status === "Success" ? (
            <span style={{ color: "green", fontWeight: "bold" }}>
              {row.status}
            </span>
          ) : (
            ""
          )}
          {row.status === "Pending" ? (
            <span
              style={{
                color: "orange",
                fontWeight: "bold",
              }}
            >
              {row.status}
            </span>
          ) : (
            ""
          )}
        </TableCell>
      </TableRow>
    );
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="Customer Transactions"
          id={adminInfo && adminInfo._id}
          email={adminInfo && adminInfo.email}
          name={adminInfo && `${adminInfo.firstName} ${adminInfo.lastName}`}
          token={adminInfo && adminInfo.token}
        />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          <h5 style={{ display: "inline-block", color: "green" }}>
            customer paytm Transactions
          </h5>
          <div
            style={{ display: "inline-block", color: "green", marginLeft: 400 ,marginBottom:20 }}
          >
            <Paper
              variant="outlined"
              square
              sx={{
                display: "inline-block",
                width: 250,
                height: 40,
                marginTop: 1,
                borderRadius: 1,
              }} // width: 400
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search google maps" }}
                value={content}
                onChange={(e) => {
                  setContent(e.target.value.trimStart());
                }}
              />
              <IconButton onClick={refresh} aria-label="search">
                <ClearIcon />
              </IconButton>
            </Paper>
          </div>
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ top: 5, minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      if (content) {
                        function escapeRegExp(string) {
                          if (string.length != 0) {
                            return string.replace(
                              /[.*+?^${}()|[\]\\]/g,
                              "\\$&"
                            );
                          }
                        }
                        let escpd = escapeRegExp(content);
                        var regex = new RegExp(escpd, "i");
                        let isData1 = row.Id.match(regex);
                        let isData2 = row.dateAndTime.match(regex);
                        let isData3 = row.order_id.match(regex);
                        let isData4 = row.user_id.match(regex);
                        let isData5 = row.name.match(regex);
                        let isData6 = row.phoneNumber.match(regex);
                        let isData7 = row.txn_id.match(regex);
                        let isData8 = row.amount.match(regex);
                        let isData9 = row.status.match(regex);
                        if (
                          isData1 ||
                          isData2 ||
                          isData3 ||
                          isData4 ||
                          isData5 ||
                          isData6 ||
                          isData7 ||
                          isData8 ||
                          isData9
                        ) {
                          return transactionRecord(row, i);
                        }
                      } else {
                        return transactionRecord(row, i);
                      }
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default UserTransactions;
