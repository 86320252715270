import { Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import Sidebar from '../../Components/Admin/sidebar/SideBar';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Paper } from '@mui/material';
import { EnhancedTableHead } from '../../Components/Admin/driverHelper/PromoCodeTableHead';
import AddPromocode from '../../Components/Admin/AddPromocode';
import {
  activeInactivePromocodeAction,
  deletePromocodeAction,
  getAllPromocodesAction,
} from '../../Actions/AdminActions';
import Swal from 'sweetalert2';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: 2,
  },
  '& .MuiDialogActions-root': {
    padding: 1,
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',

            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function PromoCode(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [id, setId] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const getAllPromocodes = useSelector((state) => state.getAllPromocodes);
  const { promocodes } = getAllPromocodes;
  const deletePromocode = useSelector((state) => state.deletePromocode);
  const { deletesuccess } = deletePromocode;
  const updatePromocodes = useSelector((state) => state.updatePromocode);
  const { updatePromocode } = updatePromocodes;
  const {addpromo } = useSelector((state) => state.addPromoCode);
  if (promocodes) {
    var array = [...promocodes];
  } else {
    var array = [1, 2];
  }

  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }
    dispatch(getAllPromocodesAction());
  }, [adminInfo, dispatch, navigate, deletesuccess, updatePromocode,addpromo]);
  const handleClickOpen = () => {
    setId('');
    setOpen(true);
  };

  const handleClickOpen1 = (Id) => {
    setId(Id);
    setOpenUpdate(true);
  };
  const handleClose = () => {
    setId('');
    setOpen(false);
  };
  const handleClose1 = () => {
    setId('');
    setOpenUpdate(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = array.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeletePromcode = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this coupon?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => { 
  
    if (result.isConfirmed) { 
       
        
        dispatch(deletePromocodeAction(id));
        Swal.fire(
          'Deleted!',
          'This Coupon successfully deleted.',
          'success'
        )
      }
    })
    
  };

  const handleActiveInActive = (id, status) => {

    Swal.fire({
      title: 'Are you sure?',
      text: `You want to ${status} this coupon?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => { 
  
    if (result.isConfirmed) {   
      
      dispatch(activeInactivePromocodeAction(id, status));
      status=="Active" ? 
        Swal.fire(
          `Activated!`,
          `This Coupon successfully Activated.`,
          'success'
        ) :
        Swal.fire(
          `Inactivated!`,
          `This Coupon successfully Inactivated.`,
          'success'
        )
      }
    })
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - array.length) : 0;

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Sidebar
          ActiveTab='Promo Code'
          id={adminInfo && adminInfo._id}
          name={`${adminInfo.firstName} ${adminInfo.lastName}`}
          email={adminInfo && adminInfo.email}
          place={adminInfo && adminInfo.place}
          token={adminInfo && adminInfo.token}
        />

        <Box
          component='main'
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
        >
          <Toolbar />
        
          <h5 style={{ display: "block", color: "green" }}>Add Promo Code</h5>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <div>
              <Button
                onClick={() => {
                  handleClickOpen();
                }}
                variant='contained'
                style={{ backgroundColor: 'red' }}
                color='primary'
              >
                <AddIcon /> add Promo
              </Button>
            </div>
          </div>

          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby='tableTitle'
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={array.length}
                  />

                  <TableBody>
                    {stableSort(array, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => {
                        return (
                          <TableRow hover key={1}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{row.status}</TableCell>
                            <TableCell>{row.category}</TableCell>
                            <TableCell>{row.promocode}</TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', fontSize: '16px' }}
                            >
                              <Button variant='contained'>
                                {row.couponType}
                              </Button>
                            </TableCell>
                            <TableCell>{row.couponValue}</TableCell>
                            <TableCell>{row.minPrice}</TableCell>
                            <TableCell>{row.maxDiscount}</TableCell>

                            <TableCell sx={{ color: 'green' }}>
                              <div>
                                <FormControl
                                  sx={{ m: 1, minWidth: 120 }}
                                  size='small'
                                >
                                  <InputLabel id='demo-select-small'>
                                    Action
                                  </InputLabel>
                                  <Select
                                    labelId='demo-select-small'
                                    id='demo-select-small'
                                    label='Action'
                                    component='button'
                                  >
                                    <MenuItem
                                    
                                    >
                                      <Button   value=''
                                      sx={{width:"100px", backgroundColor:"blue",color:"#fff",':hover': {
                                        color: "whitesmoke",
                                        backgroundColor: '#d98302',}}}
                                      onClick={() => handleClickOpen1(row._id)}>Update</Button>
                                      
                                    </MenuItem>
                                    {row.status === 'Active' && (
                                      <MenuItem
                                      >
                                        <Button  sx={{width:"100px", backgroundColor:"#e75c05",color:"#fff",
                                        ':hover': {
                                          color: "whitesmoke",
                                          backgroundColor: '#d98302',
                                        }}}
                                        value=''
                                        onClick={() => {
                                          handleActiveInActive(
                                            row._id,
                                            'InActive'
                                          );
                                        }}>InActive</Button>
                                        
                                      </MenuItem>
                                    )}
                                    {row.status === 'InActive' && (
                                      <MenuItem
                                      
                                      >
                                        <Button sx={{width:"100px",  backgroundColor:"green",color:"white",':hover': {
                                        color: "whitesmoke",
                                        backgroundColor: 'darkgreen',
                                      }}}
                                        value=''
                                        onClick={() => {
                                          handleActiveInActive(
                                            row._id,
                                            'Active'
                                          );
                                        }}
                                        >Active</Button>
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>
                              </div>
                            </TableCell>
                            <TableCell>{row.expireDate}</TableCell>
                            <TableCell>{row.createdDate}</TableCell>
                            <TableCell
                              sx={{ fontWeight: 'bold', fontSize: '16px' }}
                            >
                              <Button
                                onClick={() => {
                                  handleDeletePromcode(row._id);
                                }}
                                sx={{ backgroundColor: 'red' }}
                                variant='contained'
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {array.length === 0 ? (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {' '}
                    <h1 style={{ marginTop: '50px', marginRight: '500px' }}>
                      No Data
                    </h1>{' '}
                  </div>
                ) : (
                  ''
                )}
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                count={array.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Box>
      </Box>
      <AddPromocode handleClose={handleClose} open={open} id={false} />
      <AddPromocode handleClose1={handleClose1} open={openUpdate} id={id} />
    </>
  );
}
