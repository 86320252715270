import * as React from "react";
import Box from "@mui/material/Box";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import Moment from "react-moment";
import "moment-timezone";
export default function Block({ Address, Show, DriverInfo }) {
  let updatedAt = "";
  if (DriverInfo) {
    updatedAt = moment(DriverInfo.updatedAt).format("LLL");
  }
  return (
    <div style={{ width: "100%" }}>
      <Box
        // component="span"
        sx={{
          display: "block",
          p: 2,
          mt: 2,
          width: "100%",
          height: "auto",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          borderRadius: 2,
          fontSize: "0.875rem",
          fontWeight: "700",
        }}
      >
        {Show === "driverDetails" ? (
          <div>
            {" "}
            <InfoIcon sx={{ color: "blue" }} />{" "}
            <span>Driver ID :{DriverInfo && DriverInfo.driverId} </span>
            <br />
            <span>
              Driver Name :
              {DriverInfo &&
                DriverInfo.personalDetails &&
                DriverInfo.personalDetails.firstName.concat(
                  " ",
                  DriverInfo.personalDetails.lastName
                )}{" "}
            </span>
            <br />
            <span>
              Vehicle Type :
              {DriverInfo &&
                DriverInfo.personalDetails &&
                DriverInfo.vehicleDetails.vehicleType}{" "}
            </span>
            <br />
            <span>
              Vehicle Subtype :
              {DriverInfo &&
                DriverInfo.personalDetails &&
                DriverInfo.vehicleDetails.subType}{" "}
            </span>
            <br />
            <span>
               Driver Mobile:
              {DriverInfo &&
                DriverInfo.personalDetails &&
                DriverInfo.personalDetails.defaultPhoneNumber}{" "}
            </span>
            <br />
            <span>Last Updated Time : {DriverInfo && updatedAt}</span>
          </div>
        ) : (
          <>
            {" "}
            <LocationOnIcon sx={{ color: "#c22415" }} /> <span>{Address}</span>
          </>
        )}
      </Box>
    </div>
  );
}
