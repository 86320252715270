import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateBookings } from '../../../Actions/AdminActions';

const UnAssignComment = (props) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState('');

  const handleAddComment = (e) => {
    e.preventDefault();
    if (comment) {
      setErrors('');
      dispatch(updateBookings(props.bookingId, comment));
      setComment('');
      props.setShowUnAssignedCommentForm(false);
    } else {
      setErrors('comment is required');
    }
  };
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='mainColor'>
        <Modal.Title id='contained-modal-title-vcenter'>
          {'Booking ID : ' + props.lrnrbid}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mt-4'>
          <Form onSubmit={handleAddComment}>
            <Form.Control
              className='mt-3'
              as='textarea'
              rows='3'
              cols='7'
              placeholder='Add Your Remarks'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            {errors && (
              <p className='text-danger font-weight-bold mt-2 mb-2'>
                comment is required.
              </p>
            )}
            <div className='d-flex justify-content-end'>
              <Button
                type='submit'
                className='m-3 w-25'
                style={{
                  backgroundColor: 'rgb(231, 92, 5)',
                  fontWeight: 'bold',
                }}
              >
                Add
              </Button>
              <Button onClick={props.onHide} className='m-3 w-25'>
                Close
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UnAssignComment;
