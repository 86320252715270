

  import TableCell from "@mui/material/TableCell";
import { Box } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

const headCells1 = [
  {
    id: "Dt&Tm",
    label: "Date & Time",

  },
  {
    id: "requestId",
    label: "ID",

  },
  {
    id: "status",
    label: "Status",
   
  },
    {
      id: "DriverName",
      label: "Name",
     
    },
    {
      id: "phoneNumber",
      label: "Phone Number",
     
    },
    {
      id: "bankName",
      label: "Bank Name",
     
    },
    {
      id: "account_no",
      label: "Account No",
     
    },
    {
      id: "ifsc",
      label: "IFSC Code",
     
    },
    {
        id : "amount",
        label : "Amount"
    },
    
    {
      id: "action",
      label: "Action",
     
    },
  ];
function EnhancedTableHead1(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells1.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{width : "149px"}}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
const headCells = [
  {
    id: "Dt&Tm",
    label: "Date & Time",

  },
  // {
  //   id: "requestId",
  //   label: "ID",

  // },
  {
    id: "status",
    label: "Status",
   
  },
    {
      id: "userName",
      label: "Name",
     
    },
    {
      id: "email",
      label: "Email",
     
    },
    {
      id: "phoneNumber",
      label: "Phone Number",
     
    },
    {
      id: "coin",
      label: "Coin",
     
    },
   
    {
      id: "action",
      label: "Action",
     
    },
  ];
function EnhancedTableHead2(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{width : "149px"}}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }


  export {
    EnhancedTableHead1,
    EnhancedTableHead2
  }