import React from "react";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { API } from "../../../socketConst/BaseApi";
import { adminChangeDetailsAction } from "../../../Actions/AdminActions";
import { Theme } from '@mui/material'
const helperText = makeStyles(() => ({
  root: {
    "& .MuiTextField-root": {
      margin: 1,
      width: 200,
    },
    "& .MuiFormHelperText-root": {
      color: "#ff4000 !important",
    },
  },
  root2: {
    "& .MuiTextField-root": {
      margin: 1,
      width: 200,
    },
    "& .MuiFormHelperText-root": {
      color: "green !important",
    },
  },
  bg: {
    backgroundColor: "#7986cb",
  },
  textfield: {
    backgroundColor: "#fff",
  },
}));
const EditAdmin = ({ handleClose, open, id }) => {
  const helperTxtClass = helperText();
  const dispatch = useDispatch();
  const { adminInfo, loading } = useSelector((state) => state.adminLogin);
  const [pwderr, setPwderr] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLasttName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(null);
  const [status, setStatus] = useState(false);
  const [click, setClick] = useState(false);
  const [errPass, setErrPass] = useState(false);
  const [pwdErr, setPwdErr] = useState(false);
  const [new_password, setNewPassword] = useState("");
  const [new_password_err, setNewPasswordErr] = useState(false);
  const [confirm_password, setConfirmPassword] = useState("");
  const [confirm_password_err, setConfirmPasswordErr] = useState(false);
  const [confirmed_pwd, setConfirmedPassword] = useState();
  const [change, setChange] = useState(false);
  const [mathMsg, setMathMsg] = useState("");
  const [passwordCheckErr, setPasswordCheckErr] = useState("");
  useEffect(() => {
    if (adminInfo) {
      setStatus("");
      setFirstName(adminInfo.firstName);
      setLasttName(adminInfo.lastName);
      setEmail(adminInfo.email);
    }
  }, [id, open, change, dispatch, loading]);

  useEffect(() => {
    handleClose();
  }, [change, dispatch, loading]);

  let oldPasswordCheck = (e) => {
    setPassword(e.target.value);
  };

  let submitPassword = async (pwd) => {
    setErrPass(true);
    if (pwd) {
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${API}/api/admin/check-password`,
        { password: pwd, id },
        config
      );
      setClick(true);
      if (!data.status) {
        setPasswordCheckErr("❌ Authentication Failed");
      }
      setStatus(data.status);
    }
  };

  const saveChanges = async ({
    firstName,
    lastName,
    email,
    confirmed_pwd,
    oldPwd,
    id,
  }) => {
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    await axios
      .put(
        `${API}/api/admin/update-admin-details`,
        { firstName, lastName, email, confirmed_pwd, oldPwd, id },
        config
      )
      .then((data) => {
        if (data.data.ok) {
          dispatch(adminChangeDetailsAction(data.data));
          setChange(!change);
        }
      });
  };

  const submit = () => {
    if (status) {
      if (firstName && lastName && email && confirmed_pwd && password && id) {
        saveChanges({
          firstName,
          lastName,
          email,
          confirmed_pwd,
          oldPwd: password,
          id,
        });
      }
      setPwderr("Please Enter New Password");
    } else {
      if (firstName && lastName && email) {
        saveChanges({
          firstName,
          lastName,
          email,
          confirmed_pwd: "",
          oldPwd: "",
          id,
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={handleClose}>
        <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
          <DialogTitle sx={{ color: "white" }}>
            <EditIcon /> Edit Details
            <span style={{ marginLeft: "20px" }}></span>
          </DialogTitle>
        </AppBar>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            <TextField
              label="First Name"
              className={helperTxtClass.root}
              helperText={!firstName ? "Field is required" : ""}
              value={firstName}
              fullWidth
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              sx={{
                width: "344px",
              }}
              type="text"
            />
            <TextField
              label="Last Name"
              className={helperTxtClass.root}
              helperText={!lastName ? "Field is required" : ""}
              value={lastName}
              fullWidth
              onChange={(e) => {
                setLasttName(e.target.value);
              }}
              sx={{
                width: "344px",
              }}
              type="text"
            />
            <TextField
              label="Email"
              type="email"
              className={helperTxtClass.root}
              helperText={!email ? "Field is required" : ""}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              sx={{
                width: "344px",
              }}
              inputProps={{
                autoComplete: "off",
              }}
            />
          </Box>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <span style={{ color: "green", marginRight: "5px" }}>
              <LockIcon />
            </span>
            <span>
              <h5
                style={{
                  display: "inline-block",
                  marginTop: "5px",
                  color: "green",
                }}
              >
                <span>Password Reset</span>
              </h5>
            </span>
          </div>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            <TextField
              className={helperTxtClass.root}
              helperText={!password && errPass ? "Field is required" : ""}
              label="Your Current Password "
              name="old_password"
              value={password}
              fullWidth
              sx={{
                width: "344px",
              }}
              type="password"
              onChange={oldPasswordCheck}
              inputProps={{
                type: "password",
                autoComplete: "new-password",
              }}
            />
            <IconButton
              onClick={() => {
                submitPassword(password);
              }}
              aria-label="edit"
              sx={{ border: "solid 1px #42a5f5" }}
              color="primary"
            >
              <VpnKeyIcon />
            </IconButton>
            {status ? (
              <>
                <TextField
                  className={helperTxtClass.root}
                  label="New Password"
                  name="newPassword"
                  helperText={!new_password ? pwderr : ""}
                  value={new_password}
                  fullWidth
                  sx={{
                    width: "344px",
                  }}
                  type="password"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    if (
                      confirm_password.trim() != "" &&
                      e.target.value.trim() != "" &&
                      confirm_password == e.target.value
                    ) {
                      setMathMsg("Password Is Successfully Confirmed");
                      setConfirmedPassword(e.target.value);
                    } else if (confirm_password !== e.target.value) {
                      setConfirmedPassword("");
                      if (confirm_password === "" || new_password == "") {
                        setMathMsg("");
                      } else {
                        setMathMsg("Password Is Not Matched");
                      }
                    }
                  }}
                  inputProps={{
                    type: "password",
                    autoComplete: "new-password",
                  }}
                />
                <TextField
                  className={
                    mathMsg === "Password Is Not Matched"
                      ? helperTxtClass.root
                      : helperTxtClass.root2
                  }
                  label="Confirm Password"
                  name="confirm_password"
                  helperText={mathMsg}
                  value={confirm_password}
                  fullWidth
                  sx={{
                    width: "344px",
                  }}
                  type="password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);

                    if (
                      new_password.trim() != "" &&
                      e.target.value.trim() != "" &&
                      new_password == e.target.value
                    ) {
                      setMathMsg("Password Is Successfully Confirmed");
                      setConfirmedPassword(e.target.value);
                    } else if (new_password !== e.target.value) {
                      setConfirmedPassword("");
                      if (confirm_password === "" || new_password == "") {
                        setMathMsg("");
                      } else {
                        setMathMsg("Password Is Not Matched");
                      }
                    }
                  }}
                  inputProps={{
                    type: "password",
                    autoComplete: "new-password",
                  }}
                />
              </>
            ) : (
              ""
            )}
            {!status && click ? (
              <span style={{ color: "red" }}>{passwordCheckErr}</span>
            ) : (
              ""
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              submit();
            }}
          >
            Submit
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default EditAdmin;
