import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import NotificationForm from "./NotificationForm";
import Swal from "sweetalert2";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SpinnerLoader from "../../SpinnerLoader";
import {
  ListAllForLoadRunnrViewDriverAction,
  FilterDriverStatusAction,
  FilterVehicleTypeAction,
  FilterVehicleSubTypeAction,
  searchAction,
  refreshAction,
} from "../../../Actions/AdminActions";
import RefreshIcon from "@mui/icons-material/Refresh";
import { filterDriverPlaceAction } from "../../../Actions/FilterAction";
import {
  PlaceDrowpDown,
  StatusDrowpDown,
  VehicleTypeDrowpDown,
  VehicleSubTypeDrowpDown,
  SearchFilter,
} from "../../Admin/driverHelper/Filter";
import { useState } from "react";

const RegistredDriver = () => {
  const dispatch = useDispatch();

  const [info, setInfo] = useState(false);

  let DRIVERS_TOKENS = [];
  let DRIVER_IDS = [];
  useEffect(() => {
    refresh();
    DRIVERS_TOKENS = [];
    DRIVER_IDS = [];
  }, [dispatch]);

  const refresh = async () => {
    dispatch(FilterDriverStatusAction([]));
    dispatch(FilterVehicleTypeAction([]));
    dispatch(FilterVehicleSubTypeAction([]));
    dispatch(filterDriverPlaceAction([]));
    dispatch(searchAction([]));
    dispatch(refreshAction());
    dispatch(ListAllForLoadRunnrViewDriverAction());
  };
  const filterPlaces = useSelector((state) => state.driverPlaceFilter);
  const filterStatus = useSelector((state) => state.driverStausFilter);
  const filterSubType = useSelector(
    (state) => state.driverVehicleSubTypeFilter
  );
  const filterVehicleType = useSelector(
    (state) => state.driverVehcleTypeFilter
  );
  const { content } = useSelector((state) => state.searchFilter);
  const { AllDriversList, loading } = useSelector(
    (state) => state.alldriverLdView
  );
  const { success, fail } = useSelector((state) => state.notification);

  let allKey = filterStatus.filters
    .concat(filterVehicleType.filters)
    .concat(filterSubType.filters)
    .concat(filterPlaces.filters);

  if (AllDriversList) {
    AllDriversList.map((row) => {
      if (content) {
        let online;
        row.isOnline ? (online = "Online") : (online = "Offline");
        function escapeRegExp(string) {
          if (string.length != 0) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
          }
        }
        let escpd = escapeRegExp(content);
        var regex = new RegExp(escpd, "i");
        let isdata = online.match(regex);
        let isData2 = row.status.match(regex);
        let isData3 = row.driverId.match(regex);
        let isData4;
        if (row.personalDetails) {
          isData4 = row.personalDetails.firstName.match(regex);
        }
        let isData5;
        if (row.personalDetails) {
          isData5 = row.personalDetails.defaultPhoneNumber.match(regex);
        }
        let isData6;
        let isData7;
        if (row.vehicleDetails) {
          isData6 = row.vehicleDetails.vehicleType.match(regex);
          isData7 = row.vehicleDetails.subType.match(regex);
        }
        let isData8;
        if (row.wallet) {
          isData8 = row.wallet.toString().match(regex);
        }

        let isData9;
        if (row.personalDetails.refferalNumber) {
          isData9 = row.personalDetails.refferalNumber.match(regex);
        }

        let isdata0 = row.dateAndTime.match(regex);

        let isdata11;
        if (row.personalDetails) {
          isdata11 = row.personalDetails.addCity.match(regex);
        }
        let isdata12;
        if (row.personalDetails) {
          isdata12 = row.personalDetails.addLocality.match(regex);
        }

        if (
          isdata ||
          isData2 ||
          isData3 ||
          isData4 ||
          isData5 ||
          isData6 ||
          isData7 ||
          isData8 ||
          isData9 ||
          isdata0 ||
          isdata11 ||
          isdata12
        ) {
          if (
            filterPlaces.placeFilter &&
            filterStatus.statusFilter &&
            filterVehicleType.VehcleTypeFilter &&
            filterSubType.subTypeFilter
          ) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.personalDetails.addCity) &&
                allKey.includes(row.status) &&
                allKey.includes(row.vehicleDetails.vehicleType) &&
                allKey.includes(row.vehicleDetails.subType)
              ) {
                DRIVER_IDS.push(row.driverId);
                DRIVERS_TOKENS.push(row.fcmToken);
              }
            }
          } else if (
            filterStatus.statusFilter &&
            filterVehicleType.VehcleTypeFilter &&
            filterSubType.subTypeFilter
          ) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.status) &&
                allKey.includes(row.vehicleDetails.vehicleType) &&
                allKey.includes(row.vehicleDetails.subType)
              ) {
                DRIVER_IDS.push(row.driverId);
                DRIVERS_TOKENS.push(row.fcmToken);
              }
            }
          } else if (
            filterPlaces.placeFilter &&
            filterVehicleType.VehcleTypeFilter &&
            filterSubType.subTypeFilter
          ) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.personalDetails.addCity) &&
                allKey.includes(row.vehicleDetails.vehicleType) &&
                allKey.includes(row.vehicleDetails.subType)
              ) {
                DRIVER_IDS.push(row.driverId);
                DRIVERS_TOKENS.push(row.fcmToken);
              }
            }
          } else if (
            filterPlaces.placeFilter &&
            filterStatus.statusFilter &&
            filterSubType.subTypeFilter
          ) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.personalDetails.addCity) &&
                allKey.includes(row.status) &&
                allKey.includes(row.vehicleDetails.subType)
              ) {
                DRIVER_IDS.push(row.driverId);
                DRIVERS_TOKENS.push(row.fcmToken);
              }
            }
          } else if (
            filterPlaces.placeFilter &&
            filterStatus.statusFilter &&
            filterVehicleType.VehcleTypeFilter
          ) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.personalDetails.addCity) &&
                allKey.includes(row.status) &&
                allKey.includes(row.vehicleDetails.vehicleType)
              ) {
                DRIVER_IDS.push(row.driverId);
                DRIVERS_TOKENS.push(row.fcmToken);
              }
            }
          } else if (
            filterStatus.statusFilter &&
            filterVehicleType.VehcleTypeFilter
          ) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.status) &&
                allKey.includes(row.vehicleDetails.vehicleType)
              ) {
                DRIVER_IDS.push(row.driverId);
                DRIVERS_TOKENS.push(row.fcmToken);
              }
            }
          } else if (
            filterVehicleType.VehcleTypeFilter &&
            filterSubType.subTypeFilter
          ) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.vehicleDetails.vehicleType) &&
                allKey.includes(row.vehicleDetails.subType)
              ) {
                DRIVER_IDS.push(row.driverId);
                DRIVERS_TOKENS.push(row.fcmToken);
              }
            }
          } else if (
            filterVehicleType.VehcleTypeFilter &&
            filterPlaces.placeFilter
          ) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.vehicleDetails.vehicleType) &&
                allKey.includes(row.personalDetails.addCity)
              ) {
                DRIVER_IDS.push(row.driverId);
                DRIVERS_TOKENS.push(row.fcmToken);
              }
            }
          } else if (filterStatus.statusFilter && filterSubType.subTypeFilter) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.status) &&
                allKey.includes(row.vehicleDetails.subType)
              ) {
                DRIVER_IDS.push(row.driverId);
                DRIVERS_TOKENS.push(row.fcmToken);
              }
            }
          } else if (filterPlaces.placeFilter && filterStatus.statusFilter) {
            if (row.personalDetails) {
              if (
                allKey.includes(row.personalDetails.addCity) &&
                allKey.includes(row.status)
              ) {
                DRIVER_IDS.push(row.driverId);
                DRIVERS_TOKENS.push(row.fcmToken);
              }
            }
          } else if (filterPlaces.placeFilter && filterSubType.subTypeFilter) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.personalDetails.addCity) &&
                allKey.includes(row.vehicleDetails.subType)
              ) {
                DRIVER_IDS.push(row.driverId);
                DRIVERS_TOKENS.push(row.fcmToken);
              }
            }
          } else if (filterSubType.subTypeFilter) {
            if (row.vehicleDetails) {
              if (allKey.includes(row.vehicleDetails.subType)) {
                DRIVER_IDS.push(row.driverId);
                DRIVERS_TOKENS.push(row.fcmToken);
              }
            }
          } else if (filterVehicleType.VehcleTypeFilter) {
            if (row.vehicleDetails) {
              if (allKey.includes(row.vehicleDetails.vehicleType)) {
                DRIVER_IDS.push(row.driverId);
                DRIVERS_TOKENS.push(row.fcmToken);
              }
            }
          } else if (filterStatus.statusFilter) {
            if (allKey.includes(row.status)) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          } else if (filterPlaces.placeFilter) {
            if (allKey.includes(row.personalDetails.addCity)) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          } else {
            DRIVER_IDS.push(row.driverId);
            DRIVERS_TOKENS.push(row.fcmToken);
          }
        }
      } else {
        if (
          filterPlaces.placeFilter &&
          filterStatus.statusFilter &&
          filterVehicleType.VehcleTypeFilter &&
          filterSubType.subTypeFilter
        ) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.personalDetails.addCity) &&
              allKey.includes(row.status) &&
              allKey.includes(row.vehicleDetails.vehicleType) &&
              allKey.includes(row.vehicleDetails.subType)
            ) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          }
        } else if (
          filterStatus.statusFilter &&
          filterVehicleType.VehcleTypeFilter &&
          filterSubType.subTypeFilter
        ) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.status) &&
              allKey.includes(row.vehicleDetails.vehicleType) &&
              allKey.includes(row.vehicleDetails.subType)
            ) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          }
        } else if (
          filterPlaces.placeFilter &&
          filterVehicleType.VehcleTypeFilter &&
          filterSubType.subTypeFilter
        ) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.personalDetails.addCity) &&
              allKey.includes(row.vehicleDetails.vehicleType) &&
              allKey.includes(row.vehicleDetails.subType)
            ) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          }
        } else if (
          filterPlaces.placeFilter &&
          filterStatus.statusFilter &&
          filterSubType.subTypeFilter
        ) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.personalDetails.addCity) &&
              allKey.includes(row.status) &&
              allKey.includes(row.vehicleDetails.subType)
            ) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          }
        } else if (
          filterPlaces.placeFilter &&
          filterStatus.statusFilter &&
          filterVehicleType.VehcleTypeFilter
        ) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.personalDetails.addCity) &&
              allKey.includes(row.status) &&
              allKey.includes(row.vehicleDetails.vehicleType)
            ) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          }
        } else if (
          filterStatus.statusFilter &&
          filterVehicleType.VehcleTypeFilter
        ) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.status) &&
              allKey.includes(row.vehicleDetails.vehicleType)
            ) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          }
        } else if (
          filterVehicleType.VehcleTypeFilter &&
          filterSubType.subTypeFilter
        ) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.vehicleDetails.vehicleType) &&
              allKey.includes(row.vehicleDetails.subType)
            ) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          }
        } else if (
          filterVehicleType.VehcleTypeFilter &&
          filterPlaces.placeFilter
        ) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.vehicleDetails.vehicleType) &&
              allKey.includes(row.personalDetails.addCity)
            ) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          }
        } else if (filterStatus.statusFilter && filterSubType.subTypeFilter) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.status) &&
              allKey.includes(row.vehicleDetails.subType)
            ) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          }
        } else if (filterPlaces.placeFilter && filterStatus.statusFilter) {
          if (row.personalDetails) {
            if (
              allKey.includes(row.personalDetails.addCity) &&
              allKey.includes(row.status)
            ) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          }
        } else if (filterPlaces.placeFilter && filterSubType.subTypeFilter) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.personalDetails.addCity) &&
              allKey.includes(row.vehicleDetails.subType)
            ) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          }
        } else if (filterSubType.subTypeFilter) {
          if (row.vehicleDetails) {
            if (allKey.includes(row.vehicleDetails.subType)) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          }
        } else if (filterVehicleType.VehcleTypeFilter) {
          if (row.vehicleDetails) {
            if (allKey.includes(row.vehicleDetails.vehicleType)) {
              DRIVER_IDS.push(row.driverId);
              DRIVERS_TOKENS.push(row.fcmToken);
            }
          }
        } else if (filterStatus.statusFilter) {
          if (allKey.includes(row.status)) {
            DRIVER_IDS.push(row.driverId);
            DRIVERS_TOKENS.push(row.fcmToken);
          }
        } else if (filterPlaces.placeFilter) {
          if (allKey.includes(row.personalDetails.addCity)) {
            DRIVER_IDS.push(row.driverId);
            DRIVERS_TOKENS.push(row.fcmToken);
          }
        } else {
          DRIVER_IDS.push(row.driverId);
          DRIVERS_TOKENS.push(row.fcmToken);
        }
      }
    });
  }

  useEffect(() => {
    if (allKey.length == 0 && DRIVERS_TOKENS.length != 0) {
      setInfo(true);
    } else {
      setInfo(false);
    }
  }, [dispatch, loading]);

  return (
    <>
      <div>
        <PlaceDrowpDown style={{ marginTop: "30px" }} />
        <StatusDrowpDown style={{ marginTop: "30px" }} />
        <VehicleTypeDrowpDown style={{ marginTop: "30px" }} />
        <VehicleSubTypeDrowpDown style={{ marginTop: "30px" }} />
        <span style={{ marginLeft: "20px" }}>
          <SearchFilter />
        </span>

        <Button
          variant="contained"
          sx={{ bgcolor: "green", height: "39px", marginLeft: "10px" }}
          onClick={refresh}
        >
          <RefreshIcon />
        </Button>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "50vh",
            alignItems: "center",
          }}
        >
          {" "}
          <SpinnerLoader />
        </div>
      ) : (
        <>
          <div style={{ marginTop: 10 }}>
            <NotificationForm
              tokens={DRIVERS_TOKENS}
              NotificationFor="Driver"
              type="registered"
            />

            {allKey.length != 0 ? (
              <div>
                <strong style={{ display: "inline-block", color: "blue" }}>
                  Who can see This notification
                </strong>
              </div>
            ) : (
              ""
            )}

            <Typography
              sx={{ fontSize: "10px", fontWeight: "bold" }}
              component="span"
            >
              {allKey.length != 0
                ? allKey.map((el, i) => {
                    let dr = "Drivers";
                    if (
                      el === "Open" ||
                      (el === "Close") | (el === "Tarpaulin")
                    ) {
                      dr = "Vehicle Drivers";
                    }

                    return (
                      // " hai"
                      <Chip
                        avatar={
                          <Avatar
                            alt="driverAvatar"
                            src={
                              "https://img.freepik.com/premium-vector/man-with-steering-wheel-driver-avatar-chauffeur-character_176411-3059.jpg"
                            }
                          />
                        }
                        sx={{ margin: 0.5 }}
                        size="small"
                        label={`${el} ${dr}`}
                        variant="outlined"
                        color="success"
                      />
                    );
                  })
                : ""}
            </Typography>
          </div>
          <div style={{ marginTop: 2 }}>
            <div align="center">
              {DRIVERS_TOKENS.length !== 0 ? (
                <strong style={{ display: "inline-block", color: "#ff0051" }}>
                  This notification will be sent to {DRIVERS_TOKENS.length}{" "}
                  {DRIVERS_TOKENS.length === 1 ? "driver" : "drivers"}
                </strong>
              ) : (
                ""
              )}
            </div>

            <Typography
              sx={{ fontSize: "10px", fontWeight: "bold" }}
              component="span"
            >
              {/* {
              
              DRIVER_IDS.length != 0

                ? DRIVER_IDS.map((el, i) => {
                    return (
                      <Chip
                        avatar={
                          <Avatar
                            alt="driverAvatar"
                            src="https://img.freepik.com/premium-vector/man-with-steering-wheel-driver-avatar-chauffeur-character_176411-3059.jpg"
                          />
                        }
                        // loading="lazy"
                        sx={{ margin: 0.5 }}
                        size="small"
                        label={el}
                        variant="outlined"
                        color="success"
                      />
                    );
                  })
                : ""} */}
            </Typography>
          </div>
        </>
      )}
    </>
  );
};

export default RegistredDriver;
