import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { statusTypes } from './Values';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { InputAdornment } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { makeStyles } from '@material-ui/core/styles';
// import {
//   FilterBookingStatusAction,
//   FilterBookingVehicleSubTypeAction,
//   FilterBookingVehicleTypeAction,
//   FilterWithDateAction,
//   SearchBookingsAction,
// } from '../../../Actions/FilterAction';

import moment from 'moment';
import 'moment-timezone';
import { FilterWithdrawalStatusAction, FilterWithdrawDateAction, SearchWithdrawRequestsAction } from '../../../Actions/FilterAction';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles(({theme}) => ({
  formControl: {
    margin: 1,
    width: 300,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

let filters = [];

const StatusDrowpDown = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [withdrawalStatus, setWithdrawalStatus] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === 'All') {
      setWithdrawalStatus(
        withdrawalStatus.length === statusTypes.length ? [] : statusTypes
      );
      withdrawalStatus.length === statusTypes.length
        ? dispatch(FilterWithdrawalStatusAction([]))
        : dispatch(FilterWithdrawalStatusAction(statusTypes));

      return;
    }
    setWithdrawalStatus(typeof value === 'string' ? value.split(',') : value);
    dispatch(FilterWithdrawalStatusAction(value));
  };

  const isAllSelected =
    statusTypes.length > 0 && withdrawalStatus.length === statusTypes.length;

  return (
    <FormControl sx={{ m: 1, width: 200, minWidth: 150 }} size='small'>
      <InputLabel id='demo-multiple-checkbox-label'>Status</InputLabel>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        value={withdrawalStatus}
        onChange={handleChange}
        input={<OutlinedInput label='Status' />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        <MenuItem
          value='All'
          classes={{
            root: isAllSelected ? classes.selectedAll : '',
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                withdrawalStatus.length > 0 &&
                withdrawalStatus.length < statusTypes.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary='Select All'
          />
        </MenuItem>
        {statusTypes.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={withdrawalStatus.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


const FromToDateFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const [from, setFrom] = React.useState();
  const [to, setTo] = React.useState();
  useEffect(() => {
    if (from && to) {
      
      dispatch(FilterWithdrawDateAction(from, to));
    }
  }, [from, to, dispatch]);
  // let o =moment('06/23/2022').isBetween(from, to); // true
 
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label='From'
          value={from}
          onChange={(newValue) => {
            setFrom(moment(newValue).format('L'));
          }}
          renderInput={(params) => (
            <TextField
              style={{ marginLeft: '50px', width: '150px' }}
              size='small'
              {...params}
            />
          )}
        />
        <DesktopDatePicker
          label='To'
          value={to}
          onChange={(newValue) => {
            let date = moment(newValue).format('L');
            setTo(date);
          }}
          renderInput={(params) => (
            <TextField
              style={{ marginLeft: '5px', width: '150px' }}
              size='small'
              {...params}
            />
          )}
        />
      </LocalizationProvider>

      {/* <Button
        sx={{ marginLeft: "5px", bgcolor: "#e75c05" }}
        variant="contained"
      >
        Submit
      </Button> */}
    </>
  );
};

const SearchFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const [content, setContent] = useState();
  useEffect(() => {
    dispatch(SearchWithdrawRequestsAction(content));
  }, [content]);

  return (
    <>
      <TextField
        onChange={(e) => {
          setContent(e.target.value);
        }}
        size='small'
        label='Search'
        value={content}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export {
  StatusDrowpDown,
  FromToDateFilter,
  SearchFilter,
};
