import axios from "axios";
import io from "socket.io-client";
import { LOADRUNNR_SOCKET_API } from "../socketConst/BaseApi";
import moment from "moment";
import "moment-timezone";
import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT,
  ADMIN_MANAGE_CUSTOMER_LIST_REQUEST,
  ADMIN_MANAGE_CUSTOMER_LIST_SUCCESS,
  ADMIN_MANAGE_CUSTOMER_LIST_FAIL,
  ADMIN_UPDATE_CUSTOMER_REQUEST,
  ADMIN_UPDATE_CUSTOMER_FAIL,
  ADMIN_UPDATE_CUSTOMER_SUCCESS,
  ADMIN_SINGLE_CUSTOMER_REQUEST,
  ADMIN_SINGLE_CUSTOMER_SUCCESS,
  ADMIN_SINGLE_CUSTOMER_FAIL,
  ADMIN_PRICING_DATA_REQUEST,
  ADMIN_PRICING_DATA_SUCCESS,
  ADMIN_PRICING_DATA_FAIL,
  ADMIN_UPDATE_PRICINGTYPE_REQUEST,
  ADMIN_UPDATE_PRICINGTYPE_SUCCESS,
  ADMIN_UPDATE_PRICINGTYPE_FAIL,
  ADMIN_SINGLE_PRICINGTYPE_REQUEST,
  ADMIN_SINGLE_PRICINGTYPE_SUCCESS,
  ADMIN_SINGLE_PRICINGTYPE_FAIL,
  ADMIN_ADD_DRIVER_BANNER_REQUEST,
  ADMIN_ADD_DRIVER_BANNER_SUCCESS,
  ADMIN_ADD_DRIVER_BANNER_FAIL,
  ADMIN_DRIVER_BANNER_LIST_REQUEST,
  ADMIN_DRIVER_BANNER_LIST_SUCCESS,
  ADMIN_DRIVER_BANNER_LIST_FAIL,
  ADMIN_UPDATE_DRIVER_BANNER_REQUEST,
  ADMIN_UPDATE_DRIVER_BANNER_SUCCESS,
  ADMIN_UPDATE_DRIVER_BANNER_FAIL,
  ADMIN_DELETE_DRIVER_BANNER_REQUEST,
  ADMIN_DELETE_DRIVER_BANNER_SUCCESS,
  ADMIN_DELETE_DRIVER_BANNER_FAIL,
  ADMIN_LIST_ALL_BOOKINGS_REQUEST,
  ADMIN_LIST_ALL_BOOKINGS_SUCCESS,
  ADMIN_LIST_ALL_BOOKINGS_FAIL,
  ADMIN_ADD_USER_BANNER_REQUEST,
  ADMIN_ADD_USER_BANNER_SUCCESS,
  ADMIN_ADD_USER_BANNER_FAIL,
  ADMIN_USER_BANNER_LIST_REQUEST,
  ADMIN_USER_BANNER_LIST_SUCCESS,
  ADMIN_USER_BANNER_LIST_FAIL,
  ADMIN_UPDATE_USER_BANNER_REQUEST,
  ADMIN_UPDATE_USER_BANNER_SUCCESS,
  ADMIN_UPDATE_USER_BANNER_FAIL,
  ADMIN_DELETE_USER_BANNER_REQUEST,
  ADMIN_DELETE_USER_BANNER_SUCCESS,
  ADMIN_DELETE_USER_BANNER_FAIL,
  ADMIN_BOOKING_ADDCOMMENT_REQUEST,
  ADMIN_GET_DASHBOARD_REQUEST,
  ADMIN_GET_DASHBOARD_SUCCESS,
  ADMIN_GET_DASHBOARD_FAIL,
  ADMIN_BOOKING_ADDCOMMENT_SUCCESS,
  ADMIN_BOOKING_ADDCOMMENT_FAIL,
  ADMIN_BOOKING_UPDATE_REQUEST,
  ADMIN_BOOKING_UPDATE_SUCCESS,
  ADMIN_BOOKING_UPDATE_FAIL,
  ADMIN_ALL_DRIVER_LIST_REQUEST,
  ADMIN_ALL_DRIVER_LIST_SUCCESS,
  ADMIN_ALL_DRIVER_LIST_FAIL,
  ADMIN_FILTERED_DRIVER_LIST_REQUEST,
  ADMIN_FILTERED_DRIVER_LIST_SUCCESS,
  ADMIN_FILTERED_DRIVER_LIST_FAIL,
  ADMIN_DRIVER_APPROVAL_REQUEST,
  ADMIN_DRIVER_APPROVAL_SUCCESS,
  ADMIN_DRIVER_APPROVAL_FAIL,
  ADMIN_DRIVER_DETAILS_REQUEST,
  ADMIN_DRIVER_DETAILS_SUCCESS,
  ADMIN_DRIVER_DETAILS_FAIL,
  ADMIN_DRIVER_FILTER_STATUS,
  ADMIN_UPDATE_DRIVER_REQUEST,
  ADMIN_UPDATE_DRIVER_SUCCESS,
  ADMIN_UPDATE_DRIVER_FAIL,
  ADMIN_PROMOCODE_GENRATION_REQUEST,
  ADMIN_PROMOCODE_GENRATION_SUCCESS,
  ADMIN_PROMOCODE_GENRATION_FAIL,
  ADMIN_CREATE_PROMOCODE_REQUEST,
  ADMIN_CREATE_PROMOCODE_SUCCESS,
  ADMIN_CREATE_PROMOCODE_FAIL,
  PROMOCODE_LIST_REQUEST,
  PROMOCODE_LIST_SUCCESS,
  PROMOCODE_LIST_FAIL,
  DELETE_PROMOCODE_REQUEST,
  DELETE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_FAIL,
  UPDATE_PROMOCODE_REQUEST,
  UPDATE_PROMOCODE_SUCCESS,
  UPDATE_PROMOCODE_FAIL,
  SINGLE_PROMOCODE_REQUEST,
  SINGLE_PROMOCODE_SUCCESS,
  SINGLE_PROMOCODE_FAIL,
  ADMIN_DRIVER_FILTER_VEHICLE_TYPE,
  ADMIN_DRIVER_FILTER_SUB_VEHICLE_TYPE,
  ADMIN_CUSTOMER_FILTER_STATUS,
  ADMIN_CUSTOMER_SEARCH_STATUS,
  ADMIN_SEARCH_DRIVER,
  ADMIN_FILTER_BOOKING,
  ADMIN_DATE_FILTER_DRIVER,
  ADMIN_CUSTOMER_COMMENT_REQUEST,
  ADMIN_CUSTOMER_COMMENT_SUCCESS,
  ADMIN_CUSTOMER_COMMENT_FAIL,
  ADMIN_DRIVER_COMMENT_REQUEST,
  ADMIN_DRIVER_COMMENT_SUCCESS,
  ADMIN_DRIVER_COMMENT_FAIL,
  ADMIN_FILTER_REFRESH,
  ADMIN_FILTER_USER_REFRESH,
  ADMIN_NEW_ORDER_REQUEST,
  ADMIN_NEW_ORDER_SUCCESS,
  ADMIN_NEW_ORDER_FAIL,
  ADMIN_DRIVER_ADDRESS_SAVING,
  ADMIN_GET_WITHDRAWALREQUESTS_FAIL,
  ADMIN_GET_WITHDRAWALREQUESTS_REQUEST,
  ADMIN_GET_WITHDRAWALREQUESTS_SUCCESS,
  ADMIN_PUSH_NOTIFICATION_REQUEST,
  ADMIN_PUSH_NOTIFICATION_SUCCESS,
  ADMIN_PUSH_NOTIFICATION_FAIL,
  ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_REQUEST,
  ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_SUCCESS,
  ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_FAIL,
  ADMIN_REJECT_WITHDRAWALREQUEST_REQUEST,
  ADMIN_REJECT_WITHDRAWALREQUEST_SUCCESS,
  ADMIN_REJECT_WITHDRAWALREQUEST_FAIL,
  ADMIN_APPROVE_WITHDRAWAlREQUEST_REQUEST,
  ADMIN_APPROVE_WITHDRAWAlREQUEST_SUCCESS,
  ADMIN_APPROVE_WITHDRAWAlREQUEST_FAIL,
  ADD_COMMISION_REQUEST,
  ADD_COMMISION_SUCCESS,
  ADD_COMMISION_FAIL,
  CURRENT_COMMISION_REQUEST,
  CURRENT_COMMISION_SUCCESS,
  CURRENT_COMMISION_FAIL,
  DRIVER_CURRENTLOCATION_BOOKING_REQUEST,
  DRIVER_CURRENTLOCATION_BOOKING_SUCCESS,
  DRIVER_CURRENTLOCATION_BOOKING_FAIL,
  ADMIN_DASHBOARD_UPDATE_REQUEST,
  ADMIN_DASHBOARD_UPDATE_SUCCESS,
  ADMIN_DASHBOARD_UPDATE_FAIL,
  ADMIN_SUB_ADMIN_LIST_REQUEST,
  ADMIN_SUB_ADMIN_LIST_SUCCESS,
  ADMIN_SUB_ADMIN_LIST_FAIL,
  ADMIN_CREATE_SUB_ADMIN_REQUEST,
  ADMIN_CREATE_SUB_ADMIN_SUCCESS,
  ADMIN_CREATE_SUB_ADMIN_FAIL,
  ADMIN_UPDATE_SUB_ADMIN_REQUEST,
  ADMIN_UPDATE_SUB_ADMIN_SUCCESS,
  ADMIN_UPDATE_SUB_ADMIN_FAIL,
  ADMIN_DELETE_SUB_ADMIN_REQUEST,
  ADMIN_DELETE_SUB_ADMIN_SUCCESS,
  ADMIN_DELETE_SUB_ADMIN_FAIL,
  ADMIN_DASHBOARD_DATE_FILTER,
  GET_CUSTOMER_ORDERS_REQUEST,
  GET_CUSTOMER_ORDERS_SUCCESS,
  GET_CUSTOMER_ORDERS_FAIL,
  ADMIN_GET_DASHBOARD_WEEK_GRAPH_REQUEST,
  ADMIN_GET_DASHBOARD_WEEK_GRAPH_SUCCESS,
  ADMIN_GET_DASHBOARD_WEEK_GRAPH_FAIL,
  GET_DRIVER_ORDERS_REQUEST,
  GET_DRIVER_ORDERS_SUCCESS,
  GET_DRIVER_ORDERS_FAIL,
  GET_DRIVER_REVIEWS_FAIL,
  GET_DRIVER_REVIEWS_SUCCESS,
  GET_DRIVER_REVIEWS_REQUEST,
  ADMIN_GET_OFFER_COINS_REQUEST,
  ADMIN_GET_OFFER_COINS_SUCCESS,
  ADMIN_GET_OFFER_COINS_FAIL,
  ADMIN_ACTION_COIN_ClAIM_REQUEST,
  ADMIN_ACTION_COIN_ClAIM_SUCCESS,
  ADMIN_ACTION_COIN_ClAIM_FAIL,
  ADMIN_ALL_LOADRUNNR_DRIVER_LIST_FAIL,
  ADMIN_ALL_LOADRUNNR_DRIVER_LIST_REQUEST,
  ADMIN_ALL_LOADRUNNR_DRIVER_LIST_SUCCESS,
  ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_REQUEST,
  ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_SUCCESS,
  ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_FAIL
} from "../Constants/AdminConstants";

const LOADRUNNR_API = "https://api.loadrunnr.in"; //http://15.207.150.21......7

export const adminChangeDetailsAction = (data) => async (dispatch) => {
  dispatch({
    type: ADMIN_LOGIN_SUCCESS,
    payload: data,
  });
  localStorage.setItem("adminInfo", JSON.stringify(data));
};
export const adminLoginAction = (details) => async (dispatch) => {

  try {
    dispatch({
      type: ADMIN_LOGIN_REQUEST,
    });

    const { data } = await axios.post(
      `${LOADRUNNR_API}/api/admin/login`,
      details
    );
    dispatch({
      type: ADMIN_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem("adminInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: ADMIN_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const AdminLogout = () => (dispatch) => {
  localStorage.removeItem("adminInfo");
  dispatch({ type: ADMIN_LOGOUT });
};

export const PushNotificationAction =
  (notificationData) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_PUSH_NOTIFICATION_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${LOADRUNNR_API}/api/admin/add-push-notification`,
        notificationData,
        config
      );
      dispatch({
        type: ADMIN_PUSH_NOTIFICATION_SUCCESS,
        payload: data,
      });
      if (data) {
        return data;
      }
    } catch (error) {
      dispatch({
        type: ADMIN_PUSH_NOTIFICATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const refreshBookingAction =
  ({
    rows,
    skip,
    content = "",
    status_filter,
    vehicleType,
    subType,
    places,
    from,
    to,
  }) =>
  async (dispatch, getState) => {
  
  
   

    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/bookings/?rowCount=${rows}&skip=${skip}&content=${content}&status_filter=${status_filter}&vehicleType=${vehicleType}&subType=${subType}&places=${places}&from=${from}&to=${to}`,
      
    );
    dispatch({
      type: ADMIN_LIST_ALL_BOOKINGS_SUCCESS,
      payload: data,
    });
  
  };

export const UpdateBookingAction = (data) => async (dispatch, getState) => {
  const states = getState();
  states.bookings.bookingsInfo.some((booking, index) => {
    if (booking._id === data._id) {
      states.bookings.bookingsInfo[index] = data;
    }
  });
  dispatch({
    type: ADMIN_LIST_ALL_BOOKINGS_SUCCESS,
    payload: states.bookings.bookingsInfo,
  });
};

export const driverDetailsAction = (driverId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_DRIVER_DETAILS_REQUEST,
    });
    // const {
    //   adminLogin: { adminInfo },
    // } = getState();
    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${adminInfo.token}`,
    //   },
    // };
    // console.log("ssssssssssssssssssssssssssssssssss");
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/driver-details/${driverId}`
      // config
    );

    dispatch({
      type: ADMIN_DRIVER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_DRIVER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const approveDriverAction = (driverId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_DRIVER_APPROVAL_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${LOADRUNNR_API}/api/admin/approve-driver`,
      {
        id: driverId,
      },
      config
    );

    if (data) {
      const socket = io(LOADRUNNR_SOCKET_API, {
        withCredentials: true,
        transports: ["websocket"],
        extraHeaders: {
          "my-custom-header": "abcd",
        },
      });

      socket.emit("message", {
        status: "approved",
        driverId,
        token: data.token,
      });
    }

    dispatch({
      type: ADMIN_DRIVER_APPROVAL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_DRIVER_APPROVAL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const AddressPickUpAction = (address) => async (dispatch, getState) => {
  dispatch({
    type: ADMIN_DRIVER_ADDRESS_SAVING,
    payload: address,
  });
};

export const ListAllForLoadRunnrViewDriverAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_ALL_LOADRUNNR_DRIVER_LIST_REQUEST,
      });
      const { data } = await axios.get(
        `${LOADRUNNR_API}/api/admin/list-drivers-for-notification`
      );
      dispatch({
        type: ADMIN_ALL_LOADRUNNR_DRIVER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_ALL_LOADRUNNR_DRIVER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const ListAllDriverAction =
  ({
    rows,
    skip,
    content = "",
    status_filter="",
    vehicleType="",
    subType="",
    places="",
    from = "06/15/2022",
    to = moment(new Date()).format("L"),
  }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_ALL_DRIVER_LIST_REQUEST,
      });
  
      
      // const {
      //   adminLogin: { adminInfo },
      // } = getState();

      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${adminInfo.token}`,
      //   },
      // };
      const start = Date.now();
      const { data } = await axios.get(
        `${LOADRUNNR_API}/api/admin/list-drivers/?rowCount=${rows}&skip=${skip}&content=${content}&status_filter=${status_filter}&vehicleType=${vehicleType}&subType=${subType}&places=${places}&from=${from}&to=${to}`
      ); // //config
      const millis = Date.now() - start;
      dispatch({
        type: ADMIN_ALL_DRIVER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      // console.log(error, "This is eroor");
      dispatch({
        type: ADMIN_ALL_DRIVER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const FilterBookingAction = (value) => (dispatch) => {
  dispatch({
    type: ADMIN_FILTER_BOOKING,
    payload: value,
  });
};

export const subAdminListAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_SUB_ADMIN_LIST_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/list-subadmins`,
      config
    );

    dispatch({
      type: ADMIN_SUB_ADMIN_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_SUB_ADMIN_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const FilteredDriversAction =
  (AllDrivers) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_FILTERED_DRIVER_LIST_REQUEST,
      });

      dispatch({
        type: ADMIN_FILTERED_DRIVER_LIST_SUCCESS,
        payload: AllDrivers,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_FILTERED_DRIVER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const deleteSubAdminAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_DELETE_SUB_ADMIN_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${LOADRUNNR_API}/api/admin/delete-sub-admin/${id}`,
      config
    );

    dispatch({
      type: ADMIN_DELETE_SUB_ADMIN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_DELETE_SUB_ADMIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const deleteDriverBanner = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_DELETE_DRIVER_BANNER_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${LOADRUNNR_API}/api/admin/delete-driver-banner/${id}`,
      config
    );

    dispatch({
      type: ADMIN_DELETE_DRIVER_BANNER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_DELETE_DRIVER_BANNER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateDriverBanner =
  (id, bannerImage) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_BANNER_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/update-driver-banner`,
        {
          id,
          bannerImage,
        },
        config
      );

      dispatch({
        type: ADMIN_UPDATE_DRIVER_BANNER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_BANNER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addDriverBanner =
  (bannerImage, fileName) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_ADD_DRIVER_BANNER_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${LOADRUNNR_API}/api/admin/add-driver-banner`,
        {
          bannerImage,
          fileName,
        },
        config
      );

      dispatch({
        type: ADMIN_ADD_DRIVER_BANNER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_ADD_DRIVER_BANNER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listDriverBanner = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_DRIVER_BANNER_LIST_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/list-driver-banner`,
      config
    );
    dispatch({
      type: ADMIN_DRIVER_BANNER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_DRIVER_BANNER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getDashboardDataAction =
  (from, to, place) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_GET_DASHBOARD_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      var format2 = "YYYY-MM-DD";
      var dateFrom = new Date(from);
      var fromDt = moment(dateFrom).format(format2);

      if (to === "null") {
        var toDt = "null";
      } else {
        var format3 = "YYYY-MM-DD";
        var toDate = new Date(to);
        var toDt = moment(toDate).format(format3);
      }

      const { data } = await axios.get(
        `${LOADRUNNR_API}/api/admin/dashboard/${fromDt}/${toDt}/${place}`,
        config
      );
      dispatch({
        type: ADMIN_GET_DASHBOARD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_GET_DASHBOARD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getGraphAction = (dt, place) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_GET_DASHBOARD_WEEK_GRAPH_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };

    const format = "YYYY-MM-DD";
    if (dt) {
      var date = dt;
    } else {
      var date = new Date();
    }

    let today = moment(date).format(format);
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/dashboard-graph/${today}/${place}`,
      config
    );

    dispatch({
      type: ADMIN_GET_DASHBOARD_WEEK_GRAPH_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_DASHBOARD_WEEK_GRAPH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getDashboardAction = (place) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_GET_DASHBOARD_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };

    let to = "null";
    let from = "null";

    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/dashboard/${from}/${to}/${place}`,
      config
    );

    dispatch({
      type: ADMIN_GET_DASHBOARD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_DASHBOARD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// USER BANNER

export const deleteUserBanner = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_DELETE_USER_BANNER_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.delete(
      `${LOADRUNNR_API}/api/admin/delete-user-banner/${id}`,
      config
    );

    dispatch({
      type: ADMIN_DELETE_USER_BANNER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_DELETE_USER_BANNER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserBanner =
  (id, bannerImage) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_USER_BANNER_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/update-user-banner`,
        {
          id,
          bannerImage,
        },
        config
      );

      dispatch({
        type: ADMIN_UPDATE_USER_BANNER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_USER_BANNER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addUserBanner = (bannerImage) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_ADD_USER_BANNER_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `${LOADRUNNR_API}/api/admin/add-user-banner`,
      {
        bannerImage,
      },
      config
    );

    dispatch({
      type: ADMIN_ADD_USER_BANNER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_ADD_USER_BANNER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listUserBanner = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_USER_BANNER_LIST_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/list-user-banner`,
      config
    );
    dispatch({
      type: ADMIN_USER_BANNER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_USER_BANNER_LIST_FAIL, // END
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const FilterCustomerStatusAction =
  (values) => async (dispatch, getState) => {
    dispatch({
      type: ADMIN_CUSTOMER_FILTER_STATUS,
      payload: values,
    });
  };

export const FilterDriverStatusAction = (values) => async (dispatch) => {
  dispatch({
    type: ADMIN_DRIVER_FILTER_STATUS,
    payload: values,
  });
};

export const refreshUserAction = () => async (dispatch) => {
  dispatch({
    type: ADMIN_FILTER_USER_REFRESH,
  });
};
export const refreshAction = () => async (dispatch) => {
  dispatch({
    type: ADMIN_FILTER_REFRESH,
  });
};
export const FilterWithDate =
  ({ from, to }) =>
  async (dispatch) => {
    dispatch({
      type: ADMIN_DATE_FILTER_DRIVER,
      payload: { from, to },
    });
  };
export const FilterDashboardWithDate =
  (from, to, place) => async (dispatch) => {
    dispatch({
      type: ADMIN_DASHBOARD_DATE_FILTER,
      payload: { from, to },
    });
  };
export const searchAction = (content) => async (dispatch) => {
 
  dispatch({
    type: ADMIN_SEARCH_DRIVER,
    payload: content,
  });
};

export const SearchCustomersAction = (values) => async (dispatch, getState) => {
  dispatch({
    type: ADMIN_CUSTOMER_SEARCH_STATUS,
    payload: values,
  });
};

export const FilterVehicleTypeAction =
  (values) => async (dispatch, getState) => {
    dispatch({
      type: ADMIN_DRIVER_FILTER_VEHICLE_TYPE,
      payload: values,
    });
  };
export const FilterVehicleSubTypeAction =
  (values) => async (dispatch, getState) => {
    dispatch({
      type: ADMIN_DRIVER_FILTER_SUB_VEHICLE_TYPE,
      payload: values,
    });
  };

// END BANNER
export const ListUsersNotificationAction = () =>
async (dispatch, getState) => {

  try {
    dispatch({
      type: ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/manage-customers-notification/`,
      config
    );
    dispatch({
      type: ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}
export const ListAllCustomersAction =
  ({ rows, skip, content = "", status_filter }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_MANAGE_CUSTOMER_LIST_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `${LOADRUNNR_API}/api/admin/manage-customers/?rowCount=${rows}&skip=${skip}&status_filter=${status_filter}&content=${content}`,
        config
      );
      dispatch({
        type: ADMIN_MANAGE_CUSTOMER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_MANAGE_CUSTOMER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const blockDriverAction =
  (driverId, comment) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/block-driver`,
        { driverId, comment },
        config
      );
      if (data) {
        const socket = io(LOADRUNNR_SOCKET_API, {
          withCredentials: true,
          transports: ["websocket"],
          extraHeaders: {
            "my-custom-header": "abcd",
          },
        });
        socket.emit("blockDriver", { status: "blocked", ...data });
      }
      dispatch({
        type: ADMIN_UPDATE_DRIVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const unblockDriverAction =
  (driverId, comment) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/unblock-driver`,
        { driverId, comment },
        config
      );
      if (data) {
        const socket = io(LOADRUNNR_SOCKET_API, {
          withCredentials: true,
          transports: ["websocket"],
          extraHeaders: {
            "my-custom-header": "abcd",
          },
        });
        socket.emit("unBlockDriver", { status: "unBlocked", ...data });
      }

      dispatch({
        type: ADMIN_UPDATE_DRIVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const blockCustomerAction =
  (customerId, comment) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_CUSTOMER_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/block-customer`,
        { customerId, comment },
        config
      );
      if (data) {
        const socket = io(LOADRUNNR_SOCKET_API, {
          withCredentials: true,
          transports: ["websocket"],
          extraHeaders: {
            "my-custom-header": "abcd",
          },
        });
        socket.emit("blockUser", { status: "blocked", ...data });
      }
      dispatch({
        type: ADMIN_UPDATE_CUSTOMER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_CUSTOMER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const unblockCustomerAction =
  (customerId, comment) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_CUSTOMER_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/unblock-customer`,
        { customerId, comment },
        config
      );
      if (data) {
        const socket = io(LOADRUNNR_SOCKET_API, {
          withCredentials: true,
          transports: ["websocket"],
          extraHeaders: {
            "my-custom-header": "abcd",
          },
        });
        socket.emit("unblockUser", { status: "unblocked", ...data });
      }
      dispatch({
        type: ADMIN_UPDATE_CUSTOMER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_CUSTOMER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateInsuranceDetails =
  (details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_REQUEST,
      });

      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/edit-driver-insurance-details`,
        details,

        config
      );
      dispatch({
        type: ADMIN_UPDATE_DRIVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const updateLicenseDetails = (details) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_UPDATE_DRIVER_REQUEST,
    });

    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `${LOADRUNNR_API}/api/admin/edit-driver-license-details`,
      details,

      config
    );
    dispatch({
      type: ADMIN_UPDATE_DRIVER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_UPDATE_DRIVER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateDriverVehicleDetails =
  (details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_REQUEST,
      });

      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/edit-driver-vehicle-details`,
        details,

        config
      );
      dispatch({
        type: ADMIN_UPDATE_DRIVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateRcDetails = (details) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_UPDATE_DRIVER_REQUEST,
    });

    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `${LOADRUNNR_API}/api/admin/edit-driver-rc-details`,
      details,

      config
    );
    dispatch({
      type: ADMIN_UPDATE_DRIVER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_UPDATE_DRIVER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updatePanDetails = (details) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_UPDATE_DRIVER_REQUEST,
    });

    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `${LOADRUNNR_API}/api/admin/edit-driver-pan-details`,
      details,

      config
    );
    dispatch({
      type: ADMIN_UPDATE_DRIVER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_UPDATE_DRIVER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updateBankDetails = (details) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_UPDATE_DRIVER_REQUEST,
    });

    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `${LOADRUNNR_API}/api/admin/edit-driver-bank-details`,
      details,

      config
    );
    dispatch({
      type: ADMIN_UPDATE_DRIVER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_UPDATE_DRIVER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updateVaccineDetails = (details) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_UPDATE_DRIVER_REQUEST,
    });

    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `${LOADRUNNR_API}/api/admin/edit-driver-Vaccine-details`,
      details,

      config
    );
    dispatch({
      type: ADMIN_UPDATE_DRIVER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_UPDATE_DRIVER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updateDriverAadhaarDetails =
  (details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_REQUEST,
      });

      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/edit-driver-aadhaar-details`,
        details,

        config
      );
      dispatch({
        type: ADMIN_UPDATE_DRIVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const updateDriverPersonalDetails =
  (details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_REQUEST,
      });

      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/edit-driver-personal-details`,
        details,

        config
      );
      dispatch({
        type: ADMIN_UPDATE_DRIVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const customerCommentAction =
  (customerId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_CUSTOMER_COMMENT_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `${LOADRUNNR_API}/api/admin/get-customer-comment/${customerId}`,
        config
      );
      dispatch({
        type: ADMIN_CUSTOMER_COMMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_CUSTOMER_COMMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const driverCommentAction = (driverId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_DRIVER_COMMENT_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/get-driver-comment/${driverId}`,
      config
    );
    dispatch({
      type: ADMIN_DRIVER_COMMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_DRIVER_COMMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addDriverCommentAction =
  (driverId, comment, creater) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/add-driver-comment`,
        { driverId, comment, creater },
        config
      );
      dispatch({
        type: ADMIN_UPDATE_DRIVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addCommentAction =
  (customerId, comment, adminName) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_CUSTOMER_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/add-comment`,
        { customerId, comment, adminName },
        config
      );
      dispatch({
        type: ADMIN_UPDATE_CUSTOMER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_CUSTOMER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const addDriverWalletAction =
  (driverId, wallet, comment) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/add-driver-wallet`,
        { driverId, wallet, comment },
        config
      );

      if (data) {
        if (data.driverWallet) {
          const socket = io(LOADRUNNR_SOCKET_API, {
            withCredentials: true,
            transports: ["websocket"],
            extraHeaders: {
              "my-custom-header": "abcd",
            },
          });
          socket.emit("updateWallet", data);
        }
      }
      dispatch({
        type: ADMIN_UPDATE_DRIVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_DRIVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const addWalletAction =
  (customerId, wallet, comment) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_CUSTOMER_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/add-wallet`,
        { customerId, wallet, comment },
        config
      );

      if (data) {
        if (data.customerWallet) {
          const socket = io(LOADRUNNR_SOCKET_API, {
            withCredentials: true,
            transports: ["websocket"],
            extraHeaders: {
              "my-custom-header": "abcd",
            },
          });
          socket.emit("updateCustomerWallet", data);
        }
      }
      dispatch({
        type: ADMIN_UPDATE_CUSTOMER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_CUSTOMER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getSingleCustomerAction =
  (customerId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_SINGLE_CUSTOMER_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `${LOADRUNNR_API}/api/admin/single-customer/${customerId}`,
        config
      );
      dispatch({
        type: ADMIN_SINGLE_CUSTOMER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_SINGLE_CUSTOMER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getPricingListAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_PRICING_DATA_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/pricing-data`,
      config
    );
    dispatch({
      type: ADMIN_PRICING_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_PRICING_DATA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSinglePrcingTypeAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_SINGLE_PRICINGTYPE_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/single-pricing-type/${id}`,
      config
    );
    dispatch({
      type: ADMIN_SINGLE_PRICINGTYPE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_SINGLE_PRICINGTYPE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePrcingTypeAction =
  (
    vehicleType,
    subType,
    baseKM,
    baseFare,
    minKM1,
    maxKM1,
    fareBetweenRange1,
    minKM2,
    maxKM2,
    fareBetweenRange2,
    fareAfterRange2,
    extraCharge,
    extraChargeReason,
    nightSurgeCharge,
    nightSurgeTimeFrom,
    nightSurgeTimeTo,
    greaterThanKm,
    id,
    rentalAmount,
    perminute
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_PRICINGTYPE_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${LOADRUNNR_API}/api/admin/update-pricing-type`,
        {
          vehicleType,
          subType,
          baseKM,
          baseFare,
          minKM1,
          maxKM1,
          fareBetweenRange1,
          minKM2,
          maxKM2,

          fareBetweenRange2,
          fareAfterRange2,
          extraCharge,
          extraChargeReason,
          nightSurgeCharge,
          nightSurgeTimeFrom,
          nightSurgeTimeTo,
          greaterThanKm,

          id,
          rentalAmount,
          perminute,
        },
        config
      );
      dispatch({
        type: ADMIN_UPDATE_PRICINGTYPE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_PRICINGTYPE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const updateRentalPrcingTypeAction =
  (vehicleType, subType, description, id, rentalAmount) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_PRICINGTYPE_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${LOADRUNNR_API}/api/admin/update-rental-pricing-type`,
        {
          vehicleType,
          subType,
          description,
          id,
          rentalAmount,
        },
        config
      );
      dispatch({
        type: ADMIN_UPDATE_PRICINGTYPE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_PRICINGTYPE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const updateSubAdminAction =
  ({ fistName, lastName, email, password, subAdminId }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_SUB_ADMIN_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${LOADRUNNR_API}/api/admin/edit-subadmin`,
        {
          fistName,
          lastName,
          email,
          password,
          subAdminId,
        },
        config
      );
      dispatch({
        type: ADMIN_UPDATE_SUB_ADMIN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_SUB_ADMIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const submitNewSubAdminAction =
  ({ firstName, lastName, email, password }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_CREATE_SUB_ADMIN_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${LOADRUNNR_API}/api/admin/create-subadmin`,
        {
          firstName,
          lastName,
          email,
          password,
        },
        config
      );
      dispatch({
        type: ADMIN_CREATE_SUB_ADMIN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_CREATE_SUB_ADMIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const getBookingsAction =
  ({
    rows,
    skip,
    content = "",
    status_filter,
    vehicleType,
    subType,
    places,
    from ,
    to 
  }) =>
  async (dispatch, getState) => {

    try {
      dispatch({
        type: ADMIN_LIST_ALL_BOOKINGS_REQUEST,
      });
      
      const { data } = await axios.get(
        `${LOADRUNNR_API}/api/admin/bookings/?rowCount=${rows}&skip=${skip}&content=${content}&status_filter=${status_filter}&vehicleType=${vehicleType}&subType=${subType}&places=${places}&from=${from}&to=${to}`
        
      );
      dispatch({
        type: ADMIN_LIST_ALL_BOOKINGS_SUCCESS,
        payload: data,
      });
    } catch (error) {
    
      dispatch({
        type: ADMIN_LIST_ALL_BOOKINGS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const bookingAddComment =
  (id, comment, adminName) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_BOOKING_ADDCOMMENT_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/booking-add-comment`,
        {
          id,
          comment,
          adminName,
        },
        config
      );

      dispatch({
        type: ADMIN_BOOKING_ADDCOMMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_BOOKING_ADDCOMMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateBookings = (id, comment) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_BOOKING_UPDATE_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `${LOADRUNNR_API}/api/admin/booking-unassign-comment`,
      {
        id,
        comment,
      },
      config
    );
    if (data) {
      const socket = io(LOADRUNNR_SOCKET_API, {
        withCredentials: true,
        transports: ["websocket"],
        extraHeaders: {
          "my-custom-header": "abcd",
        },
      });

      if (data.status === "Cancel") {
        socket.emit("cancelOrder", {
          bookingId: data.Id,

          reason: "Reassigning limit reached",
          admin: adminInfo.firstName + " " + adminInfo.lastName,
          subAdmin: "",
        });
      } else {
        socket.emit("placeOrder", {
          reassinging: data.reassinging,

          bookingId: data.Id,

          orderId: data.bookingId,
        });
      }
    }

    dispatch({
      type: ADMIN_BOOKING_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_BOOKING_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const bookingCancelComment =
  (id, comment) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_BOOKING_UPDATE_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/booking-cancel-comment`,
        {
          id,
          comment,
        },
        config
      );

      dispatch({
        type: ADMIN_BOOKING_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_BOOKING_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const genratePromocodeAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_PROMOCODE_GENRATION_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/generate-promocode`,
      config
    );

    dispatch({
      type: ADMIN_PROMOCODE_GENRATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_PROMOCODE_GENRATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createPromocodeAction =
  (
    category,
    code,
    couponValue,
    couponType,
    expireDate,
    minPrice,
    maxDiscount,
    maxRides,
    users
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_CREATE_PROMOCODE_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${LOADRUNNR_API}/api/admin/create-promocode`,
        {
          category,
          code,
          couponValue,
          couponType,
          expireDate,
          minPrice,
          maxDiscount,
          maxRides,
          users,
        },
        config
      );

      dispatch({
        type: ADMIN_CREATE_PROMOCODE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_CREATE_PROMOCODE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getAllPromocodesAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROMOCODE_LIST_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/get-all-promocodes`,
      config
    );

    dispatch({
      type: PROMOCODE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROMOCODE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deletePromocodeAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_PROMOCODE_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.delete(
      `${LOADRUNNR_API}/api/admin/delete-promocode/${id}`,
      config
    );

    dispatch({
      type: DELETE_PROMOCODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PROMOCODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePromocodeAction =
  (
    id,
    category,
    code,
    couponValue,
    couponType,
    expireDate,
    minPrice,
    maxDiscount,
    maxRides,
    users
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_PROMOCODE_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/update-promocode`,
        {
          id,
          code,
          category,
          expireDate,
          couponValue,
          couponType,
          minPrice,
          maxDiscount,
          maxRides,
          users,
        },
        config
      );

      dispatch({
        type: UPDATE_PROMOCODE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PROMOCODE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getSinglePromocodeAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SINGLE_PROMOCODE_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/single-promocode/${id}`,
      config
    );

    dispatch({
      type: SINGLE_PROMOCODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SINGLE_PROMOCODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const activeInactivePromocodeAction =
  (id, status) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_PROMOCODE_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/promocode-active-inactive`,
        {
          id,
          status,
        },
        config
      );

      dispatch({
        type: UPDATE_PROMOCODE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PROMOCODE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getNewOrderAction = (data) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_NEW_ORDER_REQUEST,
    });

    dispatch({
      type: ADMIN_NEW_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_NEW_ORDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updateWithdrowalAction =
  (request) => async (dispatch, getState) => {
    const state = getState();
    state.withdrawalRequest.withdrawalRequests.unshift(request);
    dispatch({
      type: ADMIN_GET_WITHDRAWALREQUESTS_SUCCESS,
      payload: state.withdrawalRequest.withdrawalRequests,
    });
  };

export const getCoinOfferRequestsAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_GET_OFFER_COINS_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/offer-claim-requests`,
      config
    );

    dispatch({
      type: ADMIN_GET_OFFER_COINS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_OFFER_COINS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getWithdrawalRequestsAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_GET_WITHDRAWALREQUESTS_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/withdrawal-requests`,
      config
    );

    dispatch({
      type: ADMIN_GET_WITHDRAWALREQUESTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_GET_WITHDRAWALREQUESTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateVersionAction =
  (versionFor, versionNo) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_DASHBOARD_UPDATE_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/update-version`,
        {
          versionFor,
          versionNo,
        },
        config
      );

      dispatch({
        type: ADMIN_DASHBOARD_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_DASHBOARD_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const getWithdrawalRequestDriverDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `${LOADRUNNR_API}/api/admin/withdrawal-requests-driver-details/${id}`,
        config
      );

      dispatch({
        type: ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const rejectWithdrawalRequestAction =
  (id, comment) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_REJECT_WITHDRAWALREQUEST_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${LOADRUNNR_API}/api/admin/reject-withdrawal-request`,
        { id, comment },
        config
      );

      if (data) {
        const socket = io(LOADRUNNR_SOCKET_API, {
          withCredentials: true,
          transports: ["websocket"],
          extraHeaders: {
            "my-custom-header": "abcd",
          },
        });

        socket.emit("withdrawResponse", data);
      }

      dispatch({
        type: ADMIN_REJECT_WITHDRAWALREQUEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_REJECT_WITHDRAWALREQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const ActionRequestAction =
  (id, comment, action) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_ACTION_COIN_ClAIM_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/admin/offer-claim-request-approve`,
        { requestId: id, comment, status: action },
        config
      );

      dispatch({
        type: ADMIN_ACTION_COIN_ClAIM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_ACTION_COIN_ClAIM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const approveWithdrawalRequestAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_APPROVE_WITHDRAWAlREQUEST_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${LOADRUNNR_API}/api/admin/approve-withdraw-request`,
        { id },
        config
      );

      if (data) {
        const socket = io(LOADRUNNR_SOCKET_API, {
          withCredentials: true,
          transports: ["websocket"],
          extraHeaders: {
            "my-custom-header": "abcd",
          },
        });

        socket.emit("withdrawResponse", data);
      }

      dispatch({
        type: ADMIN_APPROVE_WITHDRAWAlREQUEST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_APPROVE_WITHDRAWAlREQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addCommisionRateAction = (rate) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_COMMISION_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `${LOADRUNNR_API}/api/admin/add-commision`,
      { rate },
      config
    );
    dispatch({
      type: ADD_COMMISION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_COMMISION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const currentCommisionAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRENT_COMMISION_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/current-commision`,
      config
    );
    dispatch({
      type: CURRENT_COMMISION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRENT_COMMISION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const bookingCurrentDriverLocationAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DRIVER_CURRENTLOCATION_BOOKING_REQUEST,
      });
      const {
        adminLogin: { adminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${adminInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `${LOADRUNNR_API}/api/admin/current-driver-location/${id}`,
        config
      );
      dispatch({
        type: DRIVER_CURRENTLOCATION_BOOKING_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DRIVER_CURRENTLOCATION_BOOKING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getCustomerOrdersAction =
  (id, user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_CUSTOMER_ORDERS_REQUEST,
      });

      const { data } = await axios.get(
        `${LOADRUNNR_API}/api/admin/getorders/${id}/${user}`
      );

      dispatch({
        type: GET_CUSTOMER_ORDERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CUSTOMER_ORDERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getDriverOrdersAction = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_DRIVER_ORDERS_REQUEST,
    });
    const {
      adminLogin: { adminInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${adminInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${LOADRUNNR_API}/api/admin/get-driver-orders`,
      config
    );

    dispatch({
      type: GET_DRIVER_ORDERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_DRIVER_ORDERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getDriverReviewsAction =
  (id, user) => async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_DRIVER_REVIEWS_REQUEST,
      });

      const { data } = await axios.get(
        `${LOADRUNNR_API}/api/admin/driver-reviews/${id}`
      );

      dispatch({
        type: GET_DRIVER_REVIEWS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_DRIVER_REVIEWS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
