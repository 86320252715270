import { useDispatch, useSelector } from "react-redux";
import { AdminLogout } from "../../../Actions/AdminActions";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import EditAdmin from "../edit-admin/EditAdmin";

const drawerWidth = 240;
  
export default function Navbar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open,setOpen] =useState(false)
  const [id,setId]=useState("")
  const resetAdminData = (id)=>{
   if(id){
    setId(id)
    setOpen(true)
   }
  }
  const handleLogout = () => {
    dispatch(AdminLogout());
    navigate("/login");
  };

  return (
    <>
      <AppBar
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              <Chip
                
                avatar={<Avatar alt="Admin_icon" src="https://w7.pngwing.com/pngs/340/946/png-transparent-avatar-user-computer-icons-software-developer-avatar-child-face-heroes-thumbnail.png" />}
                size="medium"
                sx={{border:"solid 1px #e75c05", mr: 2,fontSize:"16px",fontWeight:"bold",fontFamily:"cursive",color:"#e75c05"}}
                label={props.AdminName}
                onClick={()=>{
                  resetAdminData(props.Admin_id)
                }}
                variant="outlined"
              />

              <Button
                onClick={handleLogout}
                variant="contained"
                sx={{ backgroundColor: "#e75c05" }}
                startIcon={<LogoutIcon />}
              >
                Logout
              </Button>
            </Typography>
          </Toolbar>
        </div>
      </AppBar>
      
        <EditAdmin
          // subAdminId={subAdminId}
          // emailForUpdate={emailForUpdate}
          // firstNameForUpdate={firstNameForUpdate}
          // lastNameForUpdate={lastNameForUpdate}
          // passwordForUpdate={passwordForUpdate}
          id={id}
          open={open}
          handleClose={()=>{
            setOpen(false)
          }}
        />
     
    </>
  );
}
