import React from "react";
import NotificationForm from "./NotificationForm";
const UnRegistredDriver = () => {
  return (
    <div style={{ marginTop: 80 }}>
      <NotificationForm tokens={null} NotificationFor="Driver" type="unregistered" />
    </div>
  );
};
export default UnRegistredDriver;
