import * as React from "react";
import Box from "@mui/material/Box";
import { Drawer } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import NavBar from "../navbar/NavBar";
import { useNavigate } from "react-router-dom";
import FlagIcon from "@mui/icons-material/Flag";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MyLocationSharpIcon from "@mui/icons-material/MyLocationSharp";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useSelector, useDispatch } from "react-redux";
import CurrencyRupeeSharpIcon from "@mui/icons-material/CurrencyRupeeSharp";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import { FilterDriverStatusAction, FilterVehicleSubTypeAction, FilterVehicleTypeAction, SearchCustomersAction } from "../../../Actions/AdminActions";
import { filterDriverPlaceAction } from "../../../Actions/FilterAction";
const drawerWidth = 240;

export default function Sidebar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sidebar = (
    <div>
      <Toolbar>
        <img
          height={40}
          src="https://loadrunnr.in/images/614abccd6d626logoorange.png"
          alt="logo"
        />
        <Typography
          sx={{ fontWeight: "bold", marginLeft: "5px", color: "#e75c05" }}
        >
          Admin Panel
        </Typography>
      </Toolbar>
      <Divider />

      <List>
        {[
          "Dashboard", //1
          "Customer", //2
          "Pricing", //3
          "Rental Pricing", //4
          "Booking", //5
          "Driver Banner", //6
          "User Banner", //7
          "Driver", //8
          "Promo Code", //9
          "Loadrunnr View", //10
          "Withdrawal", //11
          "Offer Requests", //12
          "Driver Notification", //13
          "User Notification", //14
          "Sub Admin", //15
          "Coins Management", //16
          "Driver Transactions", //17
          "Customer Transactions"
        ].map((text, index) => (
          <ListItem
            style={
              text === props.ActiveTab
                ? { backgroundColor: "#e75c05", color: "#fff" }
                : {}
            }
            button
            onClick={() => {
              handleSideBar(text);
            }}
            key={text}
          >
            <ListItemIcon>
              {index === 0 ? (
                <DashboardIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 1 ? (
                <AccountCircleIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 2 ? (
                <MonetizationOnIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 3 ? (
                <PriceChangeIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 4 ? (
                <BookmarkBorderIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 5 ? (
                <FlagIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 6 ? (
                <FlagCircleIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 7 ? (
                <AirlineSeatReclineNormalIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 8 ? (
                <LocalOfferIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 9 ? (
                <MyLocationSharpIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 10 ? (
                <RequestPageIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
             {index === 11 ? (
                <AccountBalanceIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 12 ? (
                <CircleNotificationsIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 13 ? (
                <NotificationsIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 14 ? (
                <SupervisorAccountIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 15 ? (
                <EmojiEmotionsIcon
                sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
              {index === 16 ? (
                <AccountBalanceIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
               {index === 17 ? (
                <AccountBalanceIcon
                  sx={text === props.ActiveTab ? { color: "#fff" } : {}}
                />
              ) : (
                ""
              )}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const handleSideBar = (element) => {
    dispatch(FilterDriverStatusAction([]));
    dispatch(FilterVehicleTypeAction([]));
    dispatch(FilterVehicleSubTypeAction([]));
    dispatch(filterDriverPlaceAction([]));
    if (element === "Dashboard") {
      navigate("/");
    } else if (element === "Customer") {
      navigate("/manage-customer");
    } else if (element === "Pricing") {
      navigate("/pricing");
    } else if (element === "Rental Pricing") {
      navigate("/rental-pricing");
    } else if (element === "Driver Banner") {
      navigate("/driver-banner");
    } else if (element === "User Banner") {
      navigate("/user-banner");
    } else if (element == "Booking") {
      navigate("/bookings");
    } else if (element === "Driver") {
      navigate("/manage-driver");
    } else if (element === "Promo Code") {
      navigate("/create-promo");
    } else if (element === "Customers list") {
      navigate("/admin/customer-list");
    } else if (element === "Loadrunnr View") {
      navigate("/loadrunnr-view");
    } else if (element === "Withdrawal") {
      navigate("/withdrawal-requests");
    } else if (element === "Driver Notification") {
      navigate("/driver-Notification");
    } else if (element === "User Notification") {
      navigate("/user-Notification");
    } else if (element === "Sub Admin") {
      navigate("/sub-admin-management");
    } else if (element === "Coins Management") {
      navigate("/coin-management");
    }else if(element==="Offer Requests"){
      navigate("/coin-offer-requests")
    }else if(element==="Driver Transactions"){
      navigate("/driver-transactions")
    }else if(element==="Customer Transactions"){
      navigate("/user-transactions")
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar
        DashBoard={props.ActiveTab === "Dashboard" ? true : false}
        versions={props.versions}
        AdminName={props.name}
        Admin_id={props.id}
      />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        {sidebar}
      </Drawer>
    </Box>
  );
}
