//import WrappedMap from "../../Components/Admin/driversMap/Map";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import React, { useEffect, useState, useCallback, useRef } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import {
  SearchFilter,
  StatusDrowpDownForMap,
  StatusDrowpDown,
  VehicleSubTypeDrowpDown,
  VehicleTypeDrowpDown,
} from "../../Components/Admin/driverHelper/Filter";
import { Button, fabClasses, TextField } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Map from "../../Components/Admin/driversMap/Map";

import ShowAddress from "../../Components/Admin/driversMap/ShowAddress";
import {
  FilterDriverStatusAction,
  FilterVehicleSubTypeAction,
  FilterVehicleTypeAction,
  ListAllForLoadRunnrViewDriverAction,
  refreshAction,
  searchAction,
  SearchCustomersAction,
} from "../../Actions/AdminActions";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import {
  SearchLocation,
  FilterSearchDriver,
} from "../../Components/Admin/driversMap/SearchBox";
import useOnclickOutside from "react-cool-onclickoutside";
import { LOADRUNNR_SOCKET_API } from "../../socketConst/BaseApi";
import io from "socket.io-client";
import MenuIcon from "@mui/icons-material/Menu";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import { React_App_Google_map_api } from "../../mapKey/MapKey";
import SpinnerLoader from "../../Components/SpinnerLoader";

const AdminLoadrunnrView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const refMap = useRef(null);
  const [latitude, setLnt] = useState(13.0343026);
  const [longitude, setLng] = useState(77.5900799);
  const [locSearch, setLocSearch] = useState(false);
  const [zoom, setZoom] = useState(13);
  const [Address, setAddress] = useState("Click any Driver location...");
  const [driverinfo, setDriverInfo] = useState();
  const { adminInfo } = useSelector((state) => state.adminLogin);
  let { AllDriversList, loading, change } = useSelector(
    (state) => state.alldriverLdView
  );

  const filterStatus = useSelector((state) => state.driverStausFilter);

  const filterSubType = useSelector(
    (state) => state.driverVehicleSubTypeFilter
  );
  const filterVehicleType = useSelector(
    (state) => state.driverVehcleTypeFilter
  );

  const { content } = useSelector((state) => state.searchFilter);

  let allKey = filterVehicleType.filters.concat(filterSubType.filters).concat(filterStatus.filters)

  useEffect(() => {
    //console.log("refresh");
  }, [Address]);

  function getCoordinates(lat, lng, details) {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${React_App_Google_map_api}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "OK") {
          setAddress(data.results[0].formatted_address);
          setDriverInfo(details);
        } else {
          alert(data.status);
        }
      });
  }

  // const [clust, setCluster] = useState();
  // const [driverCluster, setDriverCluster] = useState([]);
  // var [clstrs, setClustore] = useState([]);
  //let clstrs = [];

  function locationPoint(map, options, locSearch) {
    // if (locSearch) {
    // var marker = new window.google.maps.Marker({
    //   position: options.center,
    //   map,
    // });
    // } else {
    // var marker = new window.google.maps.Marker({
    //   position: options.center,
    //   map,
    //   icon: "no_icon",
    // });
    // }
    // marker.setMap(map);
  }

  function addMarkers(map, links) {
    let markers = [];
    links.forEach((link, index) => {
      let icon;

      if (link.v_type === "Tata ACE 7ft")
        icon =
          "https://res.cloudinary.com/db1tmyw64/image/upload/v1656400784/markers_Loadrunnr/icons8-delivery-truck-32_l9j4nz.png";
      if (link.v_type === "Tata 407")
        icon =
          "https://res.cloudinary.com/db1tmyw64/image/upload/v1656401972/markers_Loadrunnr/truck_ng89wh.png";
      if (link.v_type === "Motor Cycle")
        icon =
          "https://res.cloudinary.com/db1tmyw64/image/upload/v1656402454/markers_Loadrunnr/cruise_bike_rrzih8.png";
      if (link.v_type === "Scooter")
        icon =
          "https://res.cloudinary.com/db1tmyw64/image/upload/v1656402773/markers_Loadrunnr/icons8-scooter-32_3_qkr8cb.png";
      if (link.v_type === "Three Wheeler & APE")
        icon =
          "https://res.cloudinary.com/db1tmyw64/image/upload/v1656400635/markers_Loadrunnr/icons8-auto-rickshaw-32_ql1nae.png";
      if (link.v_type === "Tata ACE 8ft / Bolero")
        icon =
          "https://res.cloudinary.com/db1tmyw64/image/upload/v1656401189/markers_Loadrunnr/lorrygreen_tousbm.png";

      const marker = new window.google.maps.Marker({
        map,
        position: link.coords,
        uniqueData: {
          driverId: link.driverId,
          Type: link.v_type,
          SubType: link.v_sub_type,
        },
        title: `Vehicle: ${link.v_type}, Id: ${link.driverId}, Name: ${link.driver_Name}`,
        icon,
      });
      if (marker) {
        markers.push(marker);
      }

      marker.addListener(`click`, () => {
        getCoordinates(link.coords.lat, link.coords.lng, link.details);
      });
    });

    // if (markers.length !== 0) {
    //   map.addListener("zoom_changed", function () {
    //     let mutipleMarkers = new MarkerClusterer({ markers, map });
    //     setCluster(mutipleMarkers);
    //   });
    // }
  }

  // useEffect(() => {
  //   if (clust && clust.algorithm && clust.algorithm.clusters) {
  //     let arrClutr = clust.algorithm.clusters;
  //     if (arrClutr) {
  //       var markers = arrClutr[0].markers;
  //     }

  //     let cls = [];
  //     if (markers.length !== 0) {
  //       for (let i = 0; i < markers.length; i++) {
  //         const element = markers[i].uniqueData;
  //         cls.push(element);
  //       }
  //       setClustore(cls);
  //     }
  //   }
  // }, [clust, clstrs]);

  const link = [];

  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }

    dispatch(ListAllForLoadRunnrViewDriverAction());
  }, [dispatch, adminInfo]);

  const handleClick = () => {
    // setZoom2(zoom)
    dispatch(ListAllForLoadRunnrViewDriverAction());
  };
  // useEffect(()=>{

  // },[])

  console.log(allKey,";;;;;;;;;;;;;;;;");

  if (AllDriversList && AllDriversList.length !== 0) {
    GetDrivers(AllDriversList);
  }

  async function GetDrivers(DriversList) {
    await DriversList.map((row) => {
      if (content) {
        let online;
        row.isOnline ? (online = "Online") : (online = "Offline");
        function escapeRegExp(string) {
          if (string.length != 0) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
          }
        }
        let escpd = escapeRegExp(content);
        var regex = new RegExp(escpd, "i");
        let isdata = online.match(regex);
        let isData2 = row.status.match(regex);
        let isData3 = row.driverId.match(regex);
        let isData4;
        if (row.personalDetails) {
          isData4 = row.personalDetails.firstName.match(regex);
        }
        let isData5;
        if (row.personalDetails) {
          isData5 = row.personalDetails.defaultPhoneNumber.match(regex);
        }

        let isData6;
        let isData7;
        if (row.vehicleDetails) {
          isData6 = row.vehicleDetails.vehicleType.match(regex);
          isData7 = row.vehicleDetails.subType.match(regex);
        }
        let isData8;
        if (row.wallet) {
          isData8 = row.wallet.toString().match(regex);
        }

        let isData9;
        if (row.personalDetails.refferalNumber) {
          isData9 = row.personalDetails.refferalNumber.match(regex);
        }

        let isdata0 = row.dateAndTime.match(regex);

        if (
          isdata ||
          isData2 ||
          isData3 ||
          isData4 ||
          isData5 ||
          isData6 ||
          isData7 ||
          isData8 ||
          isData9 ||
          isdata0
        ) {
          if (
            filterStatus.statusFilter &&
            filterVehicleType.VehcleTypeFilter &&
            filterSubType.subTypeFilter
          ) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.status) &&
                allKey.includes(row.vehicleDetails.vehicleType) &&
                allKey.includes(row.vehicleDetails.subType)
              ) {
                const element = row.currentLocation;

                if ( element) {
                  let loc = {
                    details: row,
                    coords: { lat: element.lat, lng: element.lng },
                    v_type: row.vehicleDetails.vehicleType,
                    v_sub_type: row.vehicleDetails.subType,
                    driverId: row.driverId,
                    id: row._id,
                    mobile: row.personalDetails.defaultPhoneNumber,
                    driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                  };
                  link.push(loc);
                }
              }
            }
          } else if (
            filterStatus.statusFilter &&
            filterVehicleType.VehcleTypeFilter
          ) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.status) &&
                allKey.includes(row.vehicleDetails.vehicleType)
              ) {
                const element = row.currentLocation;

                if ( element) {
                  let loc = {
                    details: row,
                    coords: { lat: element.lat, lng: element.lng },
                    v_type: row.vehicleDetails.vehicleType,
                    v_sub_type: row.vehicleDetails.subType,
                    driverId: row.driverId,
                    id: row._id,
                    mobile: row.personalDetails.defaultPhoneNumber,
                    driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                  };
                  link.push(loc);
                }
              }
            }
          } else if (
            filterVehicleType.VehcleTypeFilter &&
            filterSubType.subTypeFilter
          ) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.vehicleDetails.vehicleType) &&
                allKey.includes(row.vehicleDetails.subType)
              ) {
                const element = row.currentLocation;

                if ( element) {
                  let loc = {
                    details: row,
                    coords: { lat: element.lat, lng: element.lng },
                    v_type: row.vehicleDetails.vehicleType,
                    v_sub_type: row.vehicleDetails.subType,
                    driverId: row.driverId,
                    id: row._id,
                    mobile: row.personalDetails.defaultPhoneNumber,
                    driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                  };
                  link.push(loc);
                }
              }
            }
          } else if (filterStatus.statusFilter && filterSubType.subTypeFilter) {
            if (row.vehicleDetails) {
              if (
                allKey.includes(row.status) &&
                allKey.includes(row.vehicleDetails.subType)
              ) {
                const element = row.currentLocation;

                if ( element) {
                  let loc = {
                    details: row,
                    coords: { lat: element.lat, lng: element.lng },
                    v_type: row.vehicleDetails.vehicleType,
                    v_sub_type: row.vehicleDetails.subType,
                    driverId: row.driverId,
                    id: row._id,
                    mobile: row.personalDetails.defaultPhoneNumber,
                    driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                  };
                  link.push(loc);
                }
              }
            }
          } else if (filterStatus.statusFilter) {
            if (allKey.includes(row.status)) {
              const element = row.currentLocation;

              if ( element) {
                let loc = {
                  details: row,
                  coords: { lat: element.lat, lng: element.lng },
                  v_type: row.vehicleDetails.vehicleType,
                  v_sub_type: row.vehicleDetails.subType,
                  driverId: row.driverId,
                  id: row._id,
                  mobile: row.personalDetails.defaultPhoneNumber,
                  driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                };
                link.push(loc);
              }
            }
          } else if (filterSubType.subTypeFilter) {
            if (row.vehicleDetails) {
              if (allKey.includes(row.vehicleDetails.subType)) {
                const element = row.currentLocation;

                if ( element) {
                  let loc = {
                    details: row,
                    coords: { lat: element.lat, lng: element.lng },
                    v_type: row.vehicleDetails.vehicleType,
                    v_sub_type: row.vehicleDetails.subType,
                    driverId: row.driverId,
                    id: row._id,
                    mobile: row.personalDetails.defaultPhoneNumber,
                    driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                  };
                  link.push(loc);
                }
              }
            }
          } else if (filterVehicleType.VehcleTypeFilter) {
            if (row.vehicleDetails) {
              if (allKey.includes(row.vehicleDetails.vehicleType)) {
                const element = row.currentLocation;

                if ( element) {
                  let loc = {
                    details: row,
                    coords: { lat: element.lat, lng: element.lng },
                    v_type: row.vehicleDetails.vehicleType,
                    v_sub_type: row.vehicleDetails.subType,
                    driverId: row.driverId,
                    id: row._id,
                    mobile: row.personalDetails.defaultPhoneNumber,
                    driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                  };
                  link.push(loc);
                }
              }
            }
          } else {
            if (row.vehicleDetails) {
              const element = row.currentLocation;

              if ( element) {
                let loc = {
                  details: row,
                  coords: { lat: element.lat, lng: element.lng },
                  v_type: row.vehicleDetails.vehicleType,
                  v_sub_type: row.vehicleDetails.subType,
                  driverId: row.driverId,
                  id: row._id,
                  mobile: row.personalDetails.defaultPhoneNumber,
                  driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                };
                link.push(loc);
              }
            }
          }
        }
      } else {
        if (
          filterStatus.statusFilter &&
          filterVehicleType.VehcleTypeFilter &&
          filterSubType.subTypeFilter
        ) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.status) &&
              allKey.includes(row.vehicleDetails.vehicleType) &&
              allKey.includes(row.vehicleDetails.subType)
            ) {
              const element = row.currentLocation;

              if ( element) {
                let loc = {
                  details: row,
                  coords: { lat: element.lat, lng: element.lng },
                  v_type: row.vehicleDetails.vehicleType,
                  v_sub_type: row.vehicleDetails.subType,
                  driverId: row.driverId,
                  id: row._id,
                  mobile: row.personalDetails.defaultPhoneNumber,
                  driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                };
                link.push(loc);
              }
            }
          }
        } else if (
          filterStatus.statusFilter &&
          filterVehicleType.VehcleTypeFilter
        ) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.status) &&
              allKey.includes(row.vehicleDetails.vehicleType)
            ) {
              const element = row.currentLocation;

              if ( element) {
                let loc = {
                  details: row,
                  coords: { lat: element.lat, lng: element.lng },
                  v_type: row.vehicleDetails.vehicleType,
                  v_sub_type: row.vehicleDetails.subType,
                  driverId: row.driverId,
                  id: row._id,
                  mobile: row.personalDetails.defaultPhoneNumber,
                  driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                };
                link.push(loc);
              }
            }
          }
        } else if (
          filterVehicleType.VehcleTypeFilter &&
          filterSubType.subTypeFilter
        ) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.vehicleDetails.vehicleType) &&
              allKey.includes(row.vehicleDetails.subType)
            ) {
              const element = row.currentLocation;

              if ( element) {
                let loc = {
                  details: row,
                  coords: { lat: element.lat, lng: element.lng },
                  v_type: row.vehicleDetails.vehicleType,
                  v_sub_type: row.vehicleDetails.subType,
                  driverId: row.driverId,
                  id: row._id,
                  mobile: row.personalDetails.defaultPhoneNumber,
                  driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                };
                link.push(loc);
              }
            }
          }
        } else if (filterStatus.statusFilter && filterSubType.subTypeFilter) {
          if (row.vehicleDetails) {
            if (
              allKey.includes(row.status) &&
              allKey.includes(row.vehicleDetails.subType)
            ) {
              const element = row.currentLocation;
              if (element) {
                let loc = {
                  details: row,
                  coords: { lat: element.lat, lng: element.lng },
                  v_type: row.vehicleDetails.vehicleType,
                  v_sub_type: row.vehicleDetails.subType,
                  driverId: row.driverId,
                  id: row._id,
                  mobile: row.personalDetails.defaultPhoneNumber,
                  driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                };
                link.push(loc);
              }
            }
          }
        } else if (filterStatus.statusFilter) {
          if (allKey.includes(row.status)) {
            console.log(".....................>>>>>>>>>>>");
            const element = row.currentLocation;
            console.log(element,"curront loction",row._id);
            if ( element) {
              let loc = {
                details: row,
                coords: { lat: element.lat, lng: element.lng },
                v_type: row.vehicleDetails.vehicleType,
                v_sub_type: row.vehicleDetails.subType,
                driverId: row.driverId,
                id: row._id,
                mobile: row.personalDetails.defaultPhoneNumber,
                driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
              };
              link.push(loc);
            }
          }
        } else if (filterSubType.subTypeFilter) {
          if (row.vehicleDetails) {
            if (allKey.includes(row.vehicleDetails.subType)) {
              const element = row.currentLocation;

              if ( element) {
                let loc = {
                  details: row,
                  coords: { lat: element.lat, lng: element.lng },
                  v_type: row.vehicleDetails.vehicleType,
                  v_sub_type: row.vehicleDetails.subType,
                  driverId: row.driverId,
                  id: row._id,
                  mobile: row.personalDetails.defaultPhoneNumber,
                  driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                };
                link.push(loc);
              }
            }
          }
        } else if (filterVehicleType.VehcleTypeFilter) {
          if (row.vehicleDetails) {
            if (allKey.includes(row.vehicleDetails.vehicleType)) {
              const element = row.currentLocation;
              if ( element) {
                let loc = {
                  details: row,
                  coords: { lat: element.lat, lng: element.lng },
                  v_type: row.vehicleDetails.vehicleType,
                  v_sub_type: row.vehicleDetails.subType,
                  driverId: row.driverId,
                  id: row._id,
                  mobile: row.personalDetails.defaultPhoneNumber,
                  driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
                };
                link.push(loc);
              }
            }
          }
        } else {
          if (row.vehicleDetails) {
            const element = row.currentLocation;
              
            if (element) {
              let loc = {
                details: row,
                coords: { lat: element.lat, lng: element.lng },
                v_type: row.vehicleDetails.vehicleType,
                v_sub_type: row.vehicleDetails.subType,
                driverId: row.driverId,
                id: row._id,
                mobile: row.personalDetails.defaultPhoneNumber,
                driver_Name: `${row.personalDetails.firstName} ${row.personalDetails.lastName}`,
              };
              link.push(loc);
            }
          }
        }
      }
    });
  }

  var mapProps = {
    options: {
      center: { lat: latitude, lng: longitude },
      zoom: zoom,
      // minZoom: 4,
      // maxZoom: 20,
    },

    locSearch,
    onMount: addMarkers,
    locationPoint: locationPoint,
    onMountProps: link,
    change,
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };
  const clearInput = () => {
    setValue("");
    dispatch(searchAction());
    setLnt(13.0343026);
    setLng(77.5900799);
    setLocSearch(false);
    // setClustore([]);
  };
  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          setLng(lng);
          setLnt(lat);
          setZoom(zoom);
          setLocSearch(true);
        })
        .catch((error) => {
          console.log("😱 Error: ", error);
        });
    };
  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <>
          <ListItem
            alignItems="flex-start"
            key={place_id}
            onClick={handleSelect(suggestion)}
          >
            <ListItemAvatar>
              <Avatar
                alt="Remy Sharp"
                src="https://st.depositphotos.com/1765561/1533/i/600/depositphotos_15339035-stock-photo-shiny-gloss-red-map-pointer.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primary={main_text}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {secondary_text}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </>
      );
    });

  let { refresh } = useSelector((state) => state.refresh);
  const [refresh2, setRefresh] = useState(false);
  useEffect(() => {
    setRefresh(!refresh2);
  }, [refresh]);

  const selectOneDriver = (driver_id) => {
    dispatch(searchAction(driver_id));
  };
  const refresh1 = async () => {
    //dispatch(FilterDriverStatusAction([]));
    dispatch(FilterVehicleTypeAction([]));
    dispatch(FilterVehicleSubTypeAction([]));
    dispatch(SearchCustomersAction([]));
    dispatch(refreshAction());
    dispatch(ListAllForLoadRunnrViewDriverAction());
    clearInput();
  };

  // const returnToClustor = () => {
  //   setReturnTocluster(true);
  // };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar
        ActiveTab="Loadrunnr View"
        id={adminInfo && adminInfo._id}
        name={`${adminInfo.firstName} ${adminInfo.lastName}`}
        email={adminInfo && adminInfo.email}
        place={adminInfo && adminInfo.place}
        token={adminInfo && adminInfo.token}
      />

      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Toolbar />

        <h5 style={{ display: "block", color: "green" }}>Loadrunnr View </h5>
        <div>
          <StatusDrowpDownForMap />
          <VehicleTypeDrowpDown />
          <VehicleSubTypeDrowpDown />
          <span>
            <FilterSearchDriver />
          </span>

          <LoadingButton
            sx={{ height: "40px", ml: 1 }}
            size="small"
            color="secondary"
            onClick={handleClick}
            loading={loading}
            loadingPosition="start"
            startIcon={<RefreshIcon />}
            variant="contained"
          >
            Refresh Map
          </LoadingButton>
          <LoadingButton
            sx={{ height: "40px", ml: 1 }}
            size="small"
            color="success"
            onClick={refresh1}
            loading={loading}
            loadingPosition="start"
            startIcon={<RefreshIcon />}
            variant="contained"
          >
            Refresh Filter
          </LoadingButton>
          {/* {
            clstrs && clstrs.length ===0 ? (<LoadingButton
              sx={{ height: "40px", m: 2 }}
              size="small"
              color="primary"
              onClick={clearInput}
              loading={loading}
              loadingPosition="start"
              startIcon={<RefreshIcon />}
              variant="contained"
            >
              Refresh Cluster
            </LoadingButton>) : "" 
          }*/}
        </div>

        <Grid container spacing={1}>
          <Grid item lg={9}>
            <div>
              {AllDriversList &&
              AllDriversList.length !== 0 &&
              link.length !== 0 ? (
                <Map {...mapProps} />
              ) : (
                <Box
                  component="div"
                  sx={{
                    height: "50vh",
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ position: "relative", left: 120 }}>
                    {" "}
                    <SpinnerLoader />
                  </div>
                </Box>
              )}
            </div>
          </Grid>
          <Grid item lg>
            <span>
              <div ref={ref}>
                <SearchLocation
                  value={value}
                  handleInput={handleInput}
                  clearInput={clearInput}
                  renderSuggestions={renderSuggestions}
                  status={status}
                  ready={ready}
                />
                {status === "OK" && (
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 400,
                      bgcolor: "background.paper",
                    }}
                  >
                    {renderSuggestions()}
                  </List>
                )}
              </div>
            </span>

            {driverinfo ? (
              <ShowAddress Show="locationAddress" Address={Address} />
            ) : (
              ""
            )}

            {driverinfo ? (
              <ShowAddress Show="driverDetails" DriverInfo={driverinfo} />
            ) : (
              ""
            )}

            {/* {clstrs && clstrs.length >= 2 ? (
              <>
                <strong>There is {clstrs.length} Drivers</strong>
                <br />
                {clstrs.map((cls, i) => {
                  return (
                    <>
                      <ListItem
                        alignItems="flex-start"
                        key={cls.driverId}
                        onClick={() => {
                          selectOneDriver(cls.driverId);
                        }} //handleSelect(suggestion)
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt="Remy Sharp"
                            src="https://previews.123rf.com/images/dxinerz/dxinerz1510/dxinerz151000121/45731518-car-driving-driver-icon-vector-image-can-also-be-used-for-activities-suitable-for-use-on-web-apps-mo.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={cls.driverId}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                {`${cls.Type} : ${cls.SubType}`}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </>
                  );
                })}
              </>
            ) : (
              ""
            )} */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminLoadrunnrView;
