import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import { Table } from "react-bootstrap";
import "../../../src/App.css";
import Button from "@mui/material/Button";
import Loader from "../../Components/Loader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Rejection from "../../Components/Admin/rejection/Rejection";
import {
  driverDetailsAction,
  approveDriverAction,
} from "../../Actions/AdminActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import Chip from "@mui/material/Chip";
import EditIcon from "@mui/icons-material/Edit";
import EditPerosonal from "../../Components/Admin/edit_popUp/DriverPopUps";
import { LOADRUNNR_SOCKET_API } from "../../socketConst/BaseApi";
import io from "socket.io-client";
import PopUpImage from "../../Components/Admin/image_modal/PopUpImage";

const DriverVerify = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
 
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;

  const [open, setOpen] = React.useState(false);
  const [openRej, setOpenRej] = React.useState(false);
  const [rejTitle, setRejTitle] = useState("");
  const [rejDocType, setRejDocType] = useState("");

  const handleCloseRej = () => {
    dispatch(driverDetailsAction(id));
    setOpenRej(false);
  };
  const handleOpenRej = ({ title, type }) => {
    setRejTitle(title);
    setOpenRej(true);
    setRejDocType(type);
  };
  // states fo edit
  const [editFor, setEditFor] = React.useState("");
  const [profileImg, setProfileImg] = React.useState("");
  const [profileImg_id, setProfileImgId] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [defaultPhoneNumber, setDefaultPhoneNumber] = React.useState("");
  const [alternativeNumber, setAlterNativeNumber] = React.useState("");
  const [emargenceynumber, setEmergencynumber] = React.useState("");
  const [city, setCity] = React.useState("");
  const [locality, setLocality] = React.useState("");
  const [vehicleNo, setVehicleNO] = React.useState("");
  const [vehicleType, setVehicleType] = React.useState("");
  const [vehicleSubType, setVehicleSubType] = React.useState("");
  const [aadhaarFrontImg, setAadhaarFrontImg] = React.useState("");
  const [aadhaarBackImg, setAadhaarBackImg] = React.useState("");
  const [aadhaarFrontImg_id, setAadhaarFrontImgId] = React.useState("");
  const [aadhaarBackImg_id, setAadhaarBackImgId] = React.useState("");
  const [aadhaarNo, setAadharNo] = React.useState("");
  const [vaccineImg, setVaccineImg] = React.useState("");
  const [vaccineImg_id, setVaccineImgId] = React.useState("");
  const [passbookImg, setPassBookImg] = React.useState("");
  const [passbookImg_id, setPassBookImgId] = React.useState("");
  const [bankAcNo, setBankAcNo] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [bankIfsc, setBankIfsc] = React.useState("");
  const [panImg, setpanCardImg] = React.useState("");
  const [panImg_id, setpanCardImgId] = React.useState("");
  const [panNo, setpanCardNo] = React.useState("");
  const [rcImg, setRcImg] = React.useState("");
  const [rcImg_id, setRcImgId] = React.useState("");
  const [vehicleFrontImg, setVehicleFrontImg] = React.useState("");
  const [vehicleFrontImg_id, setVehicleFrontImgId] = React.useState("");
  const [vehicleBackImg, setVehicleBackImg] = React.useState("");
  const [vehicleBackImg_id, setVehicleBackImgId] = React.useState("");
  const [licenseImg, setLicenseImg] = React.useState("");
  const [licenseImg_id, setLicenseImgId] = React.useState("");
  const [licenseNo, setLicenseNo] = React.useState("");
  const [insuranceImg, setInsuranceImg] = React.useState("");
  const [insuranceImg_id, setInsuranceImgId] = React.useState("");
  const [insuranceNo, setInsuranceNo] = React.useState("");
  const [InsuranceExpiryDate, setInsuranceExpiryDate] = React.useState("");
  const updateDriverWallet = useSelector((state) => state.updatedDriver);
  const { updateSuccess, waiting } = updateDriverWallet;
  const [imgUrl, setImgUrl] = useState("");
  const [openImg, setOpenImg] = React.useState(false);
  const [left, setLeft] = React.useState([]);
  const { approve, drId, change, token } = useSelector(
    (state) => state.driverApprove
  );
  const { loading, DriverDetails } = useSelector(
    (state) => state.driverDetails
  );
  
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }
    dispatch(driverDetailsAction(id));
  }, [adminInfo, dispatch, navigate, updateSuccess, approve]);

  const Approve = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Approve?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(approveDriverAction(id));
        Swal.fire("Approved!", "This Driver successfully Approved.", "success");
      }
    });
  };

  const handleCloseimg = () => {
    setOpenImg(false);
  };

  return (
    <>
      {waiting ? <Loader loading={waiting} /> : ""}
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="Driver"
          id={adminInfo && adminInfo._id}
          name={`${adminInfo.firstName} ${adminInfo.lastName}`}
          email={adminInfo && adminInfo.email}
          place={adminInfo && adminInfo.place}
          token={adminInfo && adminInfo.token}
        />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          <h5 style={{ display: "block", color: "green" }}>
            Driver Add / Verify
          </h5>

          {!DriverDetails ? (
            <Loader loading={loading} />
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <div>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#e75c05", mr: 2, mb: 2 }}
                    color="primary"
                    onClick={() => {
                      navigate("/manage-driver");
                    }}
                  >
                    <ArrowBackIcon /> Back
                  </Button>
                  {DriverDetails.isApproved && DriverDetails.status==="Active" ? (
                    <Chip
                      sx={{ mb: 2, width: 100, height: 40, fontSize: 18 }}
                      label="Active"
                      color="success"
                    />
                  ) : (
                    <Button
                      onClick={() => {
                        if (DriverDetails.regCompleted) {
                          Approve();
                        } else {
                          Swal.fire(
                            "Not Approved!",
                            "This driver cannot be authorized.",
                            "error"
                          );
                        }
                      }}
                      variant="contained"
                      sx={{ backgroundColor: "#e75c05", mr: 5, mb: 2 }}
                      color="primary"
                    >
                      <CheckCircleIcon /> Approve
                    </Button>
                  )}
                </div>
              </div>

              <Grid container spacing={5} sx={{ marginTop: "5px" }}>
                <Grid item xs={12} md={3}>
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="Remy Sharp"
                      bordered="true"
                      onClick={() => {
                        setImgUrl(
                          DriverDetails.personalDetails.profileImg &&
                            DriverDetails.personalDetails.profileImg
                        );
                        setOpenImg(true);
                      }}
                      src={
                        DriverDetails.personalDetails.profileImg &&
                        DriverDetails.personalDetails.profileImg
                      }
                      sx={{ width: 180, height: 180, ml: 5, mr: 5, mb: 2 }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      fontWeight: "bolder",
                      color: "#e75c05",
                      marginBottom: 15,
                    }}
                  >
                    <span>Driver ID :</span>
                    <span>
                      {DriverDetails.driverId && DriverDetails.driverId}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    <span>Name :</span>
                    <span>
                      {DriverDetails.personalDetails.firstName &&
                      DriverDetails.personalDetails.lastName
                        ? DriverDetails.personalDetails.firstName +
                          " " +
                          DriverDetails.personalDetails.lastName
                        : ""}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    <span>Mobile Number :</span>{" "}
                    <span>
                      {DriverDetails.personalDetails.defaultPhoneNumber &&
                        DriverDetails.personalDetails.defaultPhoneNumber}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    <span>Alternative Number :</span>{" "}
                    <span>
                      {DriverDetails.personalDetails.alternativeNumber &&
                        DriverDetails.personalDetails.alternativeNumber}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    <span> Emergency Number :</span>
                    <span>
                      {DriverDetails.personalDetails.emergencyNumber &&
                        DriverDetails.personalDetails.emergencyNumber}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    {DriverDetails.personalDetails.refferalNumber ? (
                      <>
                        <span>Referral Number :</span>
                        <span>
                          {DriverDetails.personalDetails.refferalNumber}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    <span>City :</span>{" "}
                    <span>
                      {DriverDetails.personalDetails.addCity &&
                        DriverDetails.personalDetails.addCity}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    <span>Locality :</span>{" "}
                    <span>
                      {DriverDetails.personalDetails.addLocality &&
                        DriverDetails.personalDetails.addLocality}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      fontWeight: "bolder",

                      marginBottom: 15,
                    }}
                  >
                    <span style={{ color: "#e75c05" }}>Vehicle No :</span>
                    <span>
                      {DriverDetails.vehicleDetails &&
                        DriverDetails.vehicleDetails.vehicleNumber}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",

                      marginBottom: 15,
                    }}
                  >
                    <span style={{ color: "#e75c05" }}>Vehicle Type :</span>
                    <span>
                      {DriverDetails.vehicleDetails &&
                        DriverDetails.vehicleDetails.vehicleType}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",

                      marginBottom: 15,
                    }}
                  >
                    <span style={{ color: "#e75c05" }}>Sub Type :</span>
                    <span>
                      {DriverDetails.vehicleDetails &&
                        DriverDetails.vehicleDetails.subType}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "60px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setProfileImg(DriverDetails.personalDetails.profileImg);
                        setProfileImgId(
                          DriverDetails.personalDetails.profileImg_id
                        );
                        setFirstName(DriverDetails.personalDetails.firstName);
                        setLastName(DriverDetails.personalDetails.lastName);
                        setDefaultPhoneNumber(
                          DriverDetails.personalDetails.defaultPhoneNumber
                        );
                        setAlterNativeNumber(
                          DriverDetails.personalDetails.alternativeNumber
                        );
                        setEmergencynumber(
                          DriverDetails.personalDetails.emergencyNumber
                        );
                        setCity(DriverDetails.personalDetails.addCity);
                        setLocality(DriverDetails.personalDetails.addLocality);
                        setVehicleNO(
                          DriverDetails.vehicleDetails.vehicleNumber
                        );
                        setVehicleType(
                          DriverDetails.vehicleDetails.vehicleType
                        );
                        setVehicleSubType(DriverDetails.vehicleDetails.subType);

                        setEditFor("personal-details");
                        handleOpen();
                      }}
                      variant="contained"
                      style={{ background: "#e75c05", marginTop: "20px" }}
                      startIcon={<EditIcon />}
                    >
                      Update
                    </Button>
                    {!DriverDetails.isRejectBasicDetails && DriverDetails.status !== "Active" ? (
                     
                     
                          <Button
                            variant="contained"
                            style={{
                              background: "red",
                              marginTop: "20px",
                              marginLeft: "10px",
                            }}
                            onClick={() => {
                              setLeft([
                                "profile image",
                                // "first Name",
                                // "last Name",
                                // "default Phone Number",
                                // "Alternative Phone Number",
                                // "Emergency Phone Number",
                                // "City",
                                // "Locality",
                                // "vehicle Number",
                                // "vehicle Type",
                                // "Vehicle sub-type",
                              ]);
                              handleOpenRej({
                                title: "Basics info rejection",
                                type: "basics",
                              });
                            }}
                            startIcon={<NotInterestedIcon />}
                          >
                            reject
                          </Button>
                      
                      
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
              </Grid>

              <Table striped bordered="true" hover>
                <thead>
                  <tr>
                    <th>Documents Type</th>

                    <th>Documents images</th>
                    <th>Documents Details</th>
                    <th>Update</th>
                  </tr>
                </thead>
                <tbody>
                  {/* adhar details */}
                  {DriverDetails.personalDetails ? (
                    <tr>
                      <td>Aadhaar </td>
                      <td>
                        <div>
                          <img
                            onClick={() => {
                              setImgUrl(
                                DriverDetails.personalDetails.adharFrontImg
                              );
                              setOpenImg(true);
                            }}
                            style={{
                              margin: "5px",
                              maxWidth: "250px",
                              maxHeight: "100px",
                            }}
                            src={DriverDetails.personalDetails.adharFrontImg}
                            alt="img"
                          ></img>
                          <img
                            onClick={() => {
                              setImgUrl(
                                DriverDetails.personalDetails.adharBackImg
                              );
                              setOpenImg(true);
                            }}
                            style={{
                              margin: "5px",
                              maxWidth: "250px",
                              maxHeight: "100px",
                            }}
                            src={DriverDetails.personalDetails.adharBackImg}
                            alt="img"
                          ></img>
                        </div>
                      </td>
                      <td>
                        <span style={{ display: "block" }}>
                          <span>Aadhaar Number</span> :{" "}
                          {DriverDetails.personalDetails.adharNumber}
                        </span>
                      </td>
                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            width: "250px",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              setAadhaarFrontImg(
                                DriverDetails.personalDetails.adharFrontImg
                              );
                              setAadhaarBackImg(
                                DriverDetails.personalDetails.adharBackImg
                              );
                              setAadhaarFrontImgId(
                                DriverDetails.personalDetails.adharFrontImg_id
                              );
                              setAadhaarBackImgId(
                                DriverDetails.personalDetails.adharBackImg_id
                              );
                              setAadharNo(
                                DriverDetails.personalDetails.adharNumber
                              );
                              setEditFor("aadhaar-details");
                              handleOpen();
                            }}
                            variant="contained"
                            style={{ background: "#e75c05", marginTop: "20px" }}
                            startIcon={<EditIcon />}
                          >
                            Update
                          </Button>
                          {!DriverDetails.isRejectAadharDetails  && DriverDetails.status !== "Active" ? (
                            <Button
                              variant="contained"
                              style={{
                                background: "red",
                                marginTop: "20px",
                                marginLeft: "10px",
                              }}
                              startIcon={<NotInterestedIcon />}
                              onClick={() => {
                                setLeft([
                                  "Aadhar Front image",
                                  "Aadhar back image",
                                  // "Aadhar number",
                                ]);
                                handleOpenRej({
                                  title: "Adhar info rejection",
                                  type: "aadhar-docs",
                                });
                              }}
                            >
                              reject
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  {/* bank documents */}
                  {DriverDetails.bankDetails ? (
                    <tr key={2}>
                      <td>Bank </td>
                      <td>
                        <img
                          onClick={() => {
                            setImgUrl(
                              DriverDetails.bankDetails &&
                                DriverDetails.bankDetails
                                  .passbookStatementImg &&
                                DriverDetails.bankDetails.passbookStatementImg
                            );
                            setOpenImg(true);
                          }}
                          style={{
                            margin: "5px",
                            maxWidth: "250px",
                            maxHeight: "100px",
                          }}
                          src={
                            DriverDetails.bankDetails &&
                            DriverDetails.bankDetails.passbookStatementImg &&
                            DriverDetails.bankDetails.passbookStatementImg
                          }
                          alt="passbook"
                        ></img>
                      </td>
                      <td>
                        <span style={{ display: "block" }}>
                          <span>Account Number</span> :{" "}
                          {DriverDetails.bankDetails &&
                            DriverDetails.bankDetails.accountNumber}
                        </span>
                        <span style={{ display: "block" }}>
                          <span> Bank Name</span>:
                          {DriverDetails.bankDetails &&
                            DriverDetails.bankDetails.bankName}
                        </span>
                        <span style={{ display: "block" }}>
                          <span> Ifsc Code:</span>:
                          {DriverDetails.bankDetails &&
                            DriverDetails.bankDetails.ifscCode}
                        </span>
                      </td>
                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            width: "250px",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              setPassBookImg(
                                DriverDetails.bankDetails.passbookStatementImg
                              );

                              setPassBookImgId(
                                DriverDetails.bankDetails
                                  .passbookStatementImg_id
                              );

                              setBankAcNo(
                                DriverDetails.bankDetails.accountNumber
                              );
                              setBankName(DriverDetails.bankDetails.bankName);
                              setBankIfsc(DriverDetails.bankDetails.ifscCode);

                              setEditFor("bank-details");
                              handleOpen();
                            }}
                            variant="contained"
                            style={{ background: "#e75c05", marginTop: "20px" }}
                            startIcon={<EditIcon />}
                          >
                            Update
                          </Button>
                          {!DriverDetails.isRejectBankDetails && DriverDetails.status !== "Active" ? (
                            <Button
                              variant="contained"
                              style={{
                                background: "red",
                                marginTop: "20px",
                                marginLeft: "10px",
                              }}
                              startIcon={<NotInterestedIcon />}
                              onClick={() => {
                                setLeft([
                                  "pass book image",
                                  // "bank account number",
                                  // "bank name",
                                  // "ifsc",
                                ]);
                                handleOpenRej({
                                  title: "Bank info rejection",
                                  type: "bank-docs",
                                });
                              }}
                            >
                              reject
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  {/* pan details */}

                  {DriverDetails &&
                  DriverDetails.bankDetails &&
                  DriverDetails.bankDetails.panCardImg ? (
                    <tr key={3}>
                      <td>Pancard </td>
                      <td>
                        {DriverDetails.bankDetails &&
                        DriverDetails.bankDetails.panCardImg ? (
                          <img
                            onClick={() => {
                              setImgUrl(DriverDetails.bankDetails.panCardImg);
                              setOpenImg(true);
                            }}
                            style={{
                              margin: "5px",
                              maxWidth: "250px",
                              maxHeight: "100px",
                            }}
                            src={DriverDetails.bankDetails.panCardImg}
                            alt="img"
                          ></img>
                        ) : (
                          "Not added"
                        )}
                      </td>
                      <td>
                        <span> Pancard No: </span>
                        {DriverDetails.bankDetails &&
                        DriverDetails.bankDetails.panCardNumber ? (
                          <span style={{ display: "block" }}>
                            {DriverDetails.bankDetails.panCardNumber}
                          </span>
                        ) : (
                          " ---Not added---"
                        )}
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            width: "250px",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              setpanCardImg(
                                DriverDetails.bankDetails.panCardImg
                              );
                              setpanCardImgId(
                                DriverDetails.bankDetails.panCardImg_id
                              );
                              setpanCardNo(
                                DriverDetails.bankDetails.panCardNumber
                              );
                              setEditFor("pan-details");
                              handleOpen();
                            }}
                            variant="contained"
                            style={{ background: "#e75c05", marginTop: "20px" }}
                            startIcon={<EditIcon />}
                          >
                            Update
                          </Button>
                          {!DriverDetails.isRejectPancardDetails && DriverDetails.status !== "Active"? (
                            <Button
                              variant="contained"
                              style={{
                                background: "red",
                                marginTop: "20px",
                                marginLeft: "10px",
                              }}
                              startIcon={<NotInterestedIcon />}
                              onClick={() => {
                                setLeft([
                                  "pan card image",
                                  //  "pan number"
                                ]);
                                handleOpenRej({
                                  title: "Pancard info rejection",
                                  type: "pancard-docs",
                                });
                              }}
                            >
                              reject
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {DriverDetails.vehicleDetails ? (
                    <tr>
                      <td>Vehicle</td>
                      <td>
                        <img
                          style={{
                            margin: "5px",
                            maxWidth: "250px",
                            maxHeight: "100px",
                          }}
                          onClick={() => {
                            setImgUrl(
                              DriverDetails.vehicleDetails &&
                                DriverDetails.vehicleDetails.vehicleFrontImg
                            );
                            setOpenImg(true);
                          }}
                          src={
                            DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.vehicleFrontImg
                          }
                          alt="img"
                        ></img>
                        <img
                          onClick={() => {
                            setImgUrl(
                              DriverDetails.vehicleDetails &&
                                DriverDetails.vehicleDetails.vehicleBackImg
                            );
                            setOpenImg(true);
                          }}
                          style={{
                            margin: "5px",
                            maxWidth: "250px",
                            maxHeight: "100px",
                          }}
                          src={
                            DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.vehicleBackImg
                          }
                          alt="img"
                        ></img>
                      </td>
                      <td>
                        <span style={{ display: "block" }}>
                          <span>vehicle Number</span> :{" "}
                          {DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.vehicleNumber}
                        </span>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            width: "250px",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              setVehicleFrontImg(
                                DriverDetails.vehicleDetails.vehicleFrontImg
                              );
                              setVehicleFrontImgId(
                                DriverDetails.vehicleDetails.vehicleFrontImg_id
                              );
                              setVehicleBackImg(
                                DriverDetails.vehicleDetails.vehicleBackImg
                              );
                              setVehicleBackImgId(
                                DriverDetails.vehicleDetails.vehicleBackImg_id
                              );
                              setVehicleNO(
                                DriverDetails.vehicleDetails.vehicleNumber
                              );
                              setEditFor("vehicle-details");
                              handleOpen();
                            }}
                            variant="contained"
                            style={{ background: "#e75c05", marginTop: "20px" }}
                            startIcon={<EditIcon />}
                          >
                            Update
                          </Button>
                          {!DriverDetails.isRejectVehicleDetails && DriverDetails.status !== "Active" ? (
                            <Button
                              variant="contained"
                              style={{
                                background: "red",
                                marginTop: "20px",
                                marginLeft: "10px",
                              }}
                              startIcon={<NotInterestedIcon />}
                              onClick={() => {
                                setLeft([
                                  "vehicle front image",
                                  "vehicle back image",
                                ]);
                                handleOpenRej({
                                  title: "Vehicle info info rejection",
                                  type: "Vehicle-docs",
                                });
                              }}
                            >
                              reject
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  {DriverDetails.vehicleDetails ? (
                    <tr>
                      <td> Insurance </td>
                      <td>
                        <img
                          onClick={() => {
                            setImgUrl(
                              DriverDetails.vehicleDetails &&
                                DriverDetails.vehicleDetails.insuranceImg
                            );
                            setOpenImg(true);
                          }}
                          style={{
                            margin: "5px",
                            maxWidth: "250px",
                            maxHeight: "100px",
                          }}
                          src={
                            DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.insuranceImg
                          }
                          alt="img"
                        ></img>
                      </td>
                      <td>
                        <span style={{ display: "block" }}>
                          <span>Insurance Number</span> :{" "}
                          {DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.insuranceNumber}
                        </span>
                        <span style={{ display: "block" }}>
                          <span>Insurance expiry date</span> :{" "}
                          {DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.insuranceExpiryDate}
                        </span>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            width: "250px",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              setInsuranceImg(
                                DriverDetails.vehicleDetails.insuranceImg
                              );

                              setInsuranceImgId(
                                DriverDetails.vehicleDetails.insuranceImg_id
                              );

                              setInsuranceNo(
                                DriverDetails.vehicleDetails.insuranceNumber
                              );

                              setInsuranceExpiryDate(
                                DriverDetails.vehicleDetails.insuranceExpiryDate
                              );
                              setEditFor("insurance-details");
                              handleOpen();
                            }}
                            variant="contained"
                            style={{ background: "#e75c05", marginTop: "20px" }}
                            startIcon={<EditIcon />}
                          >
                            Update
                          </Button>
                          {!DriverDetails.isRejectInsuranceDetails && DriverDetails.status !== "Active" ? (
                            <Button
                              variant="contained"
                              style={{
                                background: "red",
                                marginTop: "20px",
                                marginLeft: "10px",
                              }}
                              startIcon={<NotInterestedIcon />}
                              onClick={() => {
                                setLeft([
                                  "insurance image",
                                  //  "insurance number",
                                  //  "insurance expiry date",
                                ]);
                                handleOpenRej({
                                  title: "insurance info info rejection",
                                  type: "insurance",
                                });
                              }}
                            >
                              reject
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  {DriverDetails.vehicleDetails ? (
                    <tr>
                      <td> License </td>
                      <td>
                        <img
                          style={{
                            margin: "5px",
                            maxWidth: "250px",
                            maxHeight: "100px",
                          }}
                          onClick={() => {
                            setImgUrl(
                              DriverDetails.vehicleDetails &&
                                DriverDetails.vehicleDetails.drivingLicenseImg
                            );
                            setOpenImg(true);
                          }}
                          src={
                            DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.drivingLicenseImg
                          }
                          alt="img"
                        ></img>
                      </td>
                      <td>
                        <span style={{ display: "block" }}>
                          <span>License Number</span> :
                          {DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.drivingLicenseNo}
                        </span>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            width: "250px",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              setLicenseImg(
                                DriverDetails.vehicleDetails.drivingLicenseImg
                              );
                              setLicenseImgId(
                                DriverDetails.vehicleDetails
                                  .drivingLicenseImg_id
                              );
                              setLicenseNo(
                                DriverDetails.vehicleDetails.drivingLicenseNo
                              );
                              setEditFor("licence-details");
                              handleOpen();
                            }}
                            variant="contained"
                            style={{ background: "#e75c05", marginTop: "20px" }}
                            startIcon={<EditIcon />}
                          >
                            Update
                          </Button>
                          {!DriverDetails.isRejectLicenceDetails && DriverDetails.status !== "Active" ? (
                            <Button
                              variant="contained"
                              style={{
                                background: "red",
                                marginTop: "20px",
                                marginLeft: "10px",
                              }}
                              startIcon={<NotInterestedIcon />}
                              onClick={() => {
                                setLeft([
                                  "licence image",
                                  //  "licence number"
                                ]);
                                handleOpenRej({
                                  title: "DL info info rejection",
                                  type: "dl",
                                });
                              }}
                            >
                              reject
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  {/* registraion */}
                  {DriverDetails.vehicleDetails ? (
                    <tr>
                      <td> Registration-certificate</td>
                      <td>
                        <img
                          style={{
                            margin: "5px",
                            maxWidth: "250px",
                            maxHeight: "100px",
                          }}
                          onClick={() => {
                            setImgUrl(
                              DriverDetails.vehicleDetails &&
                                DriverDetails.vehicleDetails.rcBookImg
                            );
                            setOpenImg(true);
                          }}
                          src={
                            DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.rcBookImg
                          }
                          alt="img"
                        ></img>
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            width: "250px",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              setRcImg(DriverDetails.vehicleDetails.rcBookImg);
                              setRcImgId(
                                DriverDetails.vehicleDetails.rcBookImg_id
                              );
                              setEditFor("rc-details");
                              handleOpen();
                            }}
                            variant="contained"
                            style={{ background: "#e75c05", marginTop: "20px" }}
                            startIcon={<EditIcon />}
                          >
                            Update
                          </Button>
                          {!DriverDetails.isRejectRcDetails && DriverDetails.status !== "Active" ? (
                            <Button
                              variant="contained"
                              style={{
                                background: "red",
                                marginTop: "20px",
                                marginLeft: "10px",
                              }}
                              startIcon={<NotInterestedIcon />}
                              onClick={() => {
                                setLeft(["rc image"]);
                                handleOpenRej({
                                  title: "RC info info rejection",
                                  type: "rc",
                                });
                              }}
                            >
                              reject
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}

                  {/* {DriverDetails.personalDetails ? (
                    <tr>
                      <td> vaccination </td>
                      <td>
                        <img
                          style={{
                            margin: "5px",
                            maxWidth: "250px",
                            maxHeight: "100px",
                          }}
                          src={
                            DriverDetails.personalDetails &&
                            DriverDetails.personalDetails.vaccineImg
                          }
                          alt="img"
                        ></img>
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            width: "250px",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                        >
                          <Button
                            onClick={() => {
                              setVaccineImg(
                                DriverDetails.personalDetails.vaccineImg
                              );
                              setVaccineImgId(
                                DriverDetails.personalDetails.vaccineImg_id
                              );
                              setEditFor("vaccination");
                              handleOpen();
                            }}
                            variant="contained"
                            style={{ background: "#e75c05", marginTop: "20px" }}
                            startIcon={<EditIcon />}
                          >
                            Update
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )} */}
                </tbody>
              </Table>
            </>
          )}
          {/* Modals */}
          <EditPerosonal
            open={open}
            handleClose={handleClose}
            handleOpen={handleOpen}
            EditFor={editFor}
            driverId={id} // personal edit
            profileImg={profileImg}
            profileImg_id={profileImg_id}
            firstName={firstName}
            lastName={lastName}
            defaultPhoneNumber={defaultPhoneNumber}
            alternativeNumber={alternativeNumber}
            emargenceynumber={emargenceynumber}
            vehicleNo={vehicleNo}
            vehicleType={vehicleType}
            vehicleSubType={vehicleSubType}
            city={city}
            locality={locality}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setDefaultPhoneNumber={setDefaultPhoneNumber}
            setAlterNativeNumber={setAlterNativeNumber}
            setEmergencynumber={setEmergencynumber}
            setCity={setCity}
            setLocality={setLocality}
            setVehicleNO={setVehicleNO}
            setVehicleType={setVehicleType}
            setVehicleSubType={setVehicleSubType}
            aadhaarFrontImg={aadhaarFrontImg} // aadhaar edit
            aadhaarBackImg={aadhaarBackImg}
            aadhaarFrontImg_id={aadhaarFrontImg_id}
            aadhaarBackImg_id={aadhaarBackImg_id}
            aadhaarNo={aadhaarNo}
            setAadharNo={setAadharNo}
            vaccineImg={vaccineImg}
            vaccineImg_id={vaccineImg_id}
            passbookImg={passbookImg} // bank edit
            passbookImg_id={passbookImg_id}
            bankAcNo={bankAcNo}
            bankName={bankName}
            bankIfsc={bankIfsc}
            setBankAcNo={setBankAcNo}
            setBankName={setBankName}
            setBankIfsc={setBankIfsc}
            setpanCardNo={setpanCardNo} // pan card
            panImg={panImg}
            panImg_id={panImg_id}
            panNo={panNo}
            rcImg={rcImg}
            rcImg_id={rcImg_id}
            vehicleFrontImg={vehicleFrontImg} // vehicle details
            vehicleFrontImg_id={vehicleFrontImg_id}
            vehicleBackImg={vehicleBackImg}
            vehicleBackImg_id={vehicleBackImg_id}
            licenseImg={licenseImg}
            licenseImg_id={licenseImg_id}
            licenseNo={licenseNo}
            setLicenseNo={setLicenseNo}
            insuranceImg={insuranceImg}
            insuranceImg_id={insuranceImg_id}
            insuranceNo={insuranceNo}
            InsuranceExpiryDate={InsuranceExpiryDate}
            setInsuranceExpiryDate={setInsuranceExpiryDate}
            setInsuranceNo={setInsuranceNo}
          />
        </Box>
      </Box>
      <PopUpImage
        handleCloseimg={handleCloseimg}
        open={openImg}
        imgUrl={imgUrl}
      />
      <Rejection
        left={left}
        setLeft={setLeft}
        type={rejDocType}
        title={rejTitle}
        handleClose={handleCloseRej}
        open={openRej}
        id={id}
        token={adminInfo.token}
      />
    </>
  );
};

export default DriverVerify;
