import Sidebar from "../../Components/SubAdmin/sidebar/SideBar";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import {
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";
import InfoIcon from "@mui/icons-material/Info";
import {
  SearchFilter,
  StatusDrowpDown,
} from "../../Components/Admin/customerHelper/FilterCustomer";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { UsersTableHead } from "../../Components/SubAdmin/TableHeadCustomer";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Loader from "../../Components/Loader";
import { Paper } from "@mui/material";
import {
  FilterCustomerStatusAction,
  SearchCustomersAction,
  refreshUserAction,
} from "../../Actions/AdminActions";
import AddWalletFrom from "../../Components/Admin/Modals/AddWalletFrom";
import WalletLogs from "../../Components/WalletLogs";
//import AddCommentForm from "../../Components/Admin/AddCommentForm";
import SubAddCommentForm from "../../Components/SubAdmin/SubAddCommentForm";
import BlockingForm from "../../Components/Admin/Modals/BlockingForm";
import UnBlockingForm from "../../Components/Admin/Modals/UnBlockingForm";
import exportFromJSON from "export-from-json";
import RefreshIcon from "@mui/icons-material/Refresh";

import { subAdminListAllCustomersAction } from "../../Actions/SubAdminAction";



UsersTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const Costomer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subAdminLogin = useSelector((state) => state.subAdminLogin);
  const { subAdminInfo } = subAdminLogin;
  // menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // end menu
  // wallt modal
  const [isOpen, setisOpen] = React.useState(false);
  const [isOpenComment, setisCommentOpen] = React.useState(false);
  const [subAdminName, setSubAdminName] = React.useState("");

  const handleClose = () => {
    setisOpen(false);
  };
  const handleCommentClose = () => {
    setisCommentOpen(false);
  };

  // end wallet

  // Pagination

  
  const [dense, setDense] = React.useState(false);
  const [walletLogs, setWalletLogs] = React.useState(false);
  const updateCustomer = useSelector((state) => state.updateCustomer);
  const { success } = updateCustomer;
  const [csnotes, setCsnotes] = React.useState([]);
  const [lrnr, setLrnr] = React.useState("");
  const [customerId, setCustomerId] = React.useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [unblockModalShow, setUnblockModalShow] = React.useState(false);
  const [rows, setRows] = React.useState(10);
  const [skip, setSkip] = React.useState(0);
  const { customerLists, loading } = useSelector(
    (state) => state.subAdminCostomerList
  );
  const filterStatus = useSelector((state) => state.customerStatusFilter);
  const { statusCustomerFilter } = useSelector(
    (state) => state.customerStatusFilter
  );
  const { content } = useSelector((state) => state.customerSearch);

  const { loading1 } = useSelector((state) => state.customerComment);

  const handleRowsCount = (event) => {
    setRows(event.target.value);
  };

  const handlePrev = () => {
    if (skip - rows < 0) {
      setSkip(0);
    } else {
      setSkip(skip - rows);
    }
  };
  const handleNext = () => {
    if (skip < 1) {
      setSkip(rows);
    } else {
      setSkip(skip + rows);
    }
  };

  const refresh = async () => {
    dispatch(refreshUserAction());
    dispatch(SearchCustomersAction([]));
    dispatch(FilterCustomerStatusAction([]));
    dispatch(
      subAdminListAllCustomersAction({
        rows: 10,
        content,
        skip: 0,
        status_filter: "",
      })
    );
  };

  //All drivers

  useEffect(() => {
    if (!subAdminInfo) {
      navigate("/sub-admin/login");
    }
    dispatch(
      subAdminListAllCustomersAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.customerfilters,
      })
    );
  }, [
    subAdminInfo,
    dispatch,
    navigate,
    rows,
    skip,
    loading1,
    success,
  ]);

 

  const handleWalletLogs = (customerId, lrnrID) => {
    setLrnr(lrnrID);
    setCustomerId(customerId);
    setWalletLogs(true);
  };

  const handleAddComment = async (sbName) => {
    setCsnotes([]);
    if (customerLists) {
      setSubAdminName(sbName);
      setisCommentOpen(true);
    }
  };

  let commentShow = (cmnt) => {
    let comment = "";
    let status = "";

    if (cmnt && cmnt.substring(0, 5) === "Block" && cmnt.length > 5) {
      status = "Block";
      comment = cmnt.slice(9);
    } else if (cmnt && cmnt.substring(0, 7) === "Unblock" && cmnt.length > 7) {
      status = "Unblock";
      comment = cmnt.slice(9);
    } else if (cmnt) {
      comment = cmnt;
    }

    switch (status) {
      case "Block":
        return (
          <>
            {" "}
            <span style={{ backgroundColor: "red", color: "white" }}>
              {status}
            </span>
            {" :"}
            <span> {comment} </span>{" "}
          </>
        );
        break;
      case "Unblock":
        return (
          <>
            {" "}
            <span style={{ backgroundColor: "green", color: "white" }}>
              {status}
            </span>{" "}
            <span> {comment} </span>{" "}
          </>
        );
        break;
      default:
        return <span>{comment}</span>;
        break;
    }
  };

  let userRecord = (row,i) => {
    return (
      <>
        <TableRow hover key={row._id}>
        <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {i+1}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.cutomerID}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.isBlock ? "Blocked" : "Active"}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.firstName}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.lastName}
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.phoneNumber}
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.email}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.gstNo}
          </TableCell>

          <TableCell
            sx={{ fontWeight: "bold", fontSize: "16px" }}
            onClick={() =>
              navigate(`/sub-admin/customer/bookinglist/${row._id}`)
            }
          >
            <Button
              sx={{ width: 100 }}
              variant="outlined"
              endIcon={<InfoIcon />}
            >
              {row.placedOrders}
            </Button>
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <Button
              sx={{
                backgroundColor: "#e75c05",
                width: "150px",
              }}
              variant="contained"
              onClick={() => {
                handleWalletLogs(row._id, row.cutomerID);
              }}
            >
              Wallet logs
            </Button>
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.wallet}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.reviews}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span>
                {row.csnote[row.csnote.length - 1]
                  ? commentShow(row.csnote[row.csnote.length - 1].comment)
                  : ""}
              </span>
            </div>
            <Button
              sx={{
                backgroundColor: "#e75c05",
                width: "150px",
              }}
              variant="contained"
              onClick={() => {
                setLrnr(row.cutomerID);
                setCustomerId(row._id);
                let sbName = `${subAdminInfo.firstName} ${subAdminInfo.lastName} `;
                handleAddComment(sbName);
              }}
            >
              Add Comment
            </Button>
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.dateAndTime}
          </TableCell>
        </TableRow>
      </>
    );
  };
  const submitFilter = () => {
    setSkip(0);
    dispatch(
      subAdminListAllCustomersAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.customerfilters,
      })
    );
  };
  let submitSearch = ()=>{
    if (skip > 10) {
      setSkip(0);
    }
    if (content) {
      setSkip(0);
    }
  
    dispatch(
      subAdminListAllCustomersAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.customerfilters,
      })
    );
  }
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="Customers"
          id={subAdminInfo && subAdminInfo._id}
          email={subAdminInfo && subAdminInfo.email}
          name={
            subAdminInfo && `${subAdminInfo.firstName} ${subAdminInfo.lastName}`
          }
          token={subAdminInfo && subAdminInfo.token}
        />

        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          {!walletLogs ? (
            <>
              <Toolbar />

              <h5 style={{ display: "block", color: "green" }}>
                Manage users{" "}
              </h5>

              <div style={{ paddingTop: 1, paddingBottom: 1 }}>
                <StatusDrowpDown />
                <Button sx={{mr:2}} onClick={submitFilter} variant="outlined">
                  submit filter
                </Button>
                <div
                  style={{
                    display: "inline-flex",
                    marginLeft: "5px",
                  }}
                >
                  <SearchFilter />
                  <Button sx={{ml:2,mt:1,height:"40px"}} onClick={submitSearch} variant="outlined">
                 
                 submit search
               </Button>
              
                  <Button
                    variant="contained"
                    sx={{
                      marginLeft: "10px",
                      color: "#fff",
                      bgcolor: "green",
                      height: "37px",
                      marginTop: "9px",
                    }}
                    onClick={refresh}
                  >
                    <RefreshIcon />
                  </Button>
                </div>
              </div>

              {customerLists && !loading ? (
                <Box sx={{ width: "100%" }}>
                  <Paper sx={{ width: "100%", mb: 2 }}>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                        id="table-to-xls"
                      >
                        <UsersTableHead />

                        <TableBody>
                          {customerLists &&
                            customerLists.map((row, i) => {
                              return userRecord(row, i);
                            })}
                        </TableBody>
                      </Table>
                      <Loader loading={loading} />
                    </TableContainer>
                  </Paper>

                  <ButtonGroup
                    disableElevation
                    variant="outlined"
                    aria-label="Disabled elevation buttons"
                    sx={{ mt: 2 }}
                  >
                    <Button onClick={handlePrev}>
                      <ArrowBackIosIcon />
                    </Button>
                    <Button onClick={handleNext}>
                      <ArrowForwardIosIcon />
                    </Button>
                  </ButtonGroup>
                  <FormControl sx={{ minWidth: 80, ml: 2, mt: 2 }} size="small">
                    <InputLabel id="demo-select-small">Rows </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={rows}
                      label="Rows"
                      onChange={handleRowsCount}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Loader />
              )}
            </>
          ) : (
            <WalletLogs
              onHide={() => setWalletLogs(false)}
              customerId={customerId}
              lrnr={lrnr}
              logsFor="customer"
            />
          )}
        </Box>
      </Box>
      <UnBlockingForm
        show={unblockModalShow}
        onHide={() => setUnblockModalShow(false)}
        lrnr={lrnr}
        customerId={customerId}
        setUnblockModalShow={setUnblockModalShow}
      />
      <BlockingForm
        show={modalShow}
        onHide={() => setModalShow(false)}
        lrnr={lrnr}
        customerId={customerId}
        setModalShow={setModalShow}
      />
      <SubAddCommentForm
        title="Customer"
        isOpen={isOpenComment}
        subAdminName={subAdminName}
        handleClose={handleCommentClose}
        costomId={lrnr}
        customerId={customerId}
        // csnotes={csnotes}
        commentFor="customer"
      />
      <AddWalletFrom
        title="Customer"
        isOpen={isOpen}
        handleClose={handleClose}
        costomId={lrnr}
        customerId={customerId}
        walletFor="customer"
      />
    </>
  );
};

export default Costomer;
