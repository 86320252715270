import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  adminLoginReducer,
  getPricingTypeReducer,
  getSingleCutomerReducer,
  listAllCustomersReducer,
  listPricingReducer,
  updateCustomerReducer,
  updateDriverReducer,
  updatePricingTypeReducer,
  adminBookingsAddCommentReducer,
  refreshReducer,
  refreshCustomerReducer,
  add_driver_banner,
  listAllDriverBannerReducer,
  update_driver_banner,
  delete_driver_banner,
  getBookingsReducer,
  add_user_banner,
  listAllUserBannerReducer,
  update_user_banner,
  delete_user_banner,
  adminBookingsUpdateReducer,
  listAllDriverReducer,
  filteredDriverReducer,
  approvalDriverReducer,
  driverDetailsReducer,
  filterDriverStatusReducer,
  filterDriverSubVehicleTypeReducer,
  filterDriverVehicleTypeReducer,
  adminPromocodeReducer,
  getAllPromocodeReducer,
  deletePromocodeReducer,
  singlePromocodeReducer,
  updatePromocodeReducer,
  filterCustomerStatusReducer,
  serachCustomerReducer,
  searchFilterReducer,
  searchDateFilterReducer,
  getNewOrderRducer,
  customerCommentReducer,
  driverCommentReducer,
  addressFromLatLng,
  BookingRefreshReducer,
  getWithdrawalRequestsReducers,
  createPromocodeReducer,
  PushNotifiationReducer,
  getWithdrawalRequestDriverDetailseducers,
  rejectWithdrawalRequestReducer,
  approveWithdrawalRequestReducer,
  addCommisionReducer,
  currentCommisionReducer,
  currentBookingDriverLocationReducer,
  getDashboardReducer,
  createSubAdminReducer,
  updateDashboardReducer,
  adminSubadminsRaducer,
  dashboardDateFilterReducer,
  editSubAdminReducer,
  delete_sub_admin_reducer,
  customerOrdersReducer,
  graphFilterWeekReducer,
  driverReviewsReducer,
  getCoinOfferRequestsReducers,
  claimOfferActionRequestReducer,
  loadrunnrviewAllDrivers,
  ListUsersNotificationReducer
} from './Reducers/AdminReducers';

import {
  subAdminLoginReducer,
  sub_admin_listAllCustomersReducer,
  subAdminupdateCustomerReducer,
  subAdminDriverListReducer,
  subAdmin_driverAddCommentReducer,
  subAdmin_booking_listReducer,
  subAdminBookingsAddCommentReducer,
  subFilteredDriverReducer,
  subFilterDriverStatusReducer,
  //subFilterDriverVehicleTypeReducer
} from './Reducers/SubAdminReducers';
import {
  bookingDateFilterReducer,
  filterBookingStatusReducer,
  filterDriverPlaceReducer,
  filterBookingPlaceReducer,
  filterBookingVehicleSubTypeReducer,
  filterBookingVehicleTypeReducer,
  filterWithdrawalStatusReducer,
  searchBookingsReducer,
  searchWithdrawRequestsReducer,
  withdrawDateFilterReducer,
} from './Reducers/FilterReducers';

const reducer = combineReducers({
  adminLogin: adminLoginReducer,
  coinOfferRequests:getCoinOfferRequestsReducers,
  actionUpdate: claimOfferActionRequestReducer,
  customerList: listAllCustomersReducer,
  updateCustomer: updateCustomerReducer,
  customerDetails: getSingleCutomerReducer,
  pricingDetails: listPricingReducer,
  updatePricing: updatePricingTypeReducer,
  singlePricingType: getPricingTypeReducer,
  adminDriverBanner: add_driver_banner,
  bookings: getBookingsReducer,
  customerSearch: serachCustomerReducer,
  weekfilerdashboard:graphFilterWeekReducer,
  bookingAddComment: adminBookingsAddCommentReducer,
  bookingSubAdminComment:subAdminBookingsAddCommentReducer,
  adminBookingUpdate: adminBookingsUpdateReducer,
  subAdminLogin: subAdminLoginReducer,
  subAdminCostomerList: sub_admin_listAllCustomersReducer,
  subAdminUpdateCostomer: subAdminupdateCustomerReducer,
  driverBanner: listAllDriverBannerReducer,
  updateDriverBanner: update_driver_banner,
  deleteDriverBanner: delete_driver_banner,
  adminAddDriverBanner: add_driver_banner,
  adminAddUserBanner: add_user_banner,
  userBanner: listAllUserBannerReducer,
  updateUserBanner: update_user_banner,
  dashboardDateFilter:dashboardDateFilterReducer,
  adminDeleteUserBanner: delete_user_banner,
  updatedDriver: updateDriverReducer,
  alldrivers: listAllDriverReducer,
  alldriverLdView:loadrunnrviewAllDrivers,
  filteredDriverList: filteredDriverReducer,
  subFilteredDriverList:subFilteredDriverReducer,
  driverStausFilter: filterDriverStatusReducer,
  subDriverStausFilter:subFilterDriverStatusReducer,
  customerStatusFilter: filterCustomerStatusReducer,
  driverVehcleTypeFilter: filterDriverVehicleTypeReducer,
  // subDriverVehcleTypeFilter:subFilterDriverVehicleTypeReducer,
  driverVehicleSubTypeFilter: filterDriverSubVehicleTypeReducer,
  driverApprove: approvalDriverReducer,
  driverDetails: driverDetailsReducer,
  genpromocode: adminPromocodeReducer,
  getAllPromocodes: getAllPromocodeReducer,
  deletePromocode: deletePromocodeReducer,
  singlePromocode: singlePromocodeReducer,
  updatePromocode: updatePromocodeReducer,
  searchFilter: searchFilterReducer,
  bookingStausFilter: filterBookingStatusReducer,
  bookingPlaceFilter:filterBookingPlaceReducer,
  driverPlaceFilter:filterDriverPlaceReducer,
  bookingVehicleTypeFilter: filterBookingVehicleTypeReducer,
  bookingSubTypeFilter: filterBookingVehicleSubTypeReducer,
  filterDate: searchDateFilterReducer,
  bookingSearch: searchBookingsReducer,
  bookingDateFilter: bookingDateFilterReducer,
  customerComment:customerCommentReducer,
  drivrComment:driverCommentReducer,
  refresh:refreshReducer,
  refresUser:refreshCustomerReducer,
  getNewOrder:getNewOrderRducer,
  getDriverAddress:addressFromLatLng,
  bookingRefresh:BookingRefreshReducer,
  withdrawalRequest : getWithdrawalRequestsReducers,
  dashboard:getDashboardReducer,
  notification:PushNotifiationReducer,
  withdrawalRequestDriverDetail : getWithdrawalRequestDriverDetailseducers,
  rejectWithdrawRequest : rejectWithdrawalRequestReducer,
  approveWithdrawRequest : approveWithdrawalRequestReducer,
  addPromoCode:createPromocodeReducer,
  withdrawalStatus : filterWithdrawalStatusReducer,
  withdrawDateFilter : withdrawDateFilterReducer,
  withdrawSearch  : searchWithdrawRequestsReducer,
  commisionRate : addCommisionReducer,
  currentCommisionRate : currentCommisionReducer,
  driverCurrentLocation : currentBookingDriverLocationReducer,
  updateDashboard:updateDashboardReducer,
  AllDriversListStore : subAdminDriverListReducer,
  driverAddComment : subAdmin_driverAddCommentReducer,
  subAdminBookings : subAdmin_booking_listReducer,
  subadmins:adminSubadminsRaducer,
  createSubadmin:createSubAdminReducer,
  updateSubAdmin:editSubAdminReducer,
  delete_sub_admin:delete_sub_admin_reducer,
  customerOrders : customerOrdersReducer,
  driverReview : driverReviewsReducer,
  userList:ListUsersNotificationReducer
});

const adminInfoFromStorage = localStorage.getItem('adminInfo')
  ? JSON.parse(localStorage.getItem('adminInfo'))
  : null;

const subAdminInfoFromStorage = localStorage.getItem('subAdminInfo')
  ? JSON.parse(localStorage.getItem('subAdminInfo'))
  : null;

const initialState = {
  adminLogin: { adminInfo: adminInfoFromStorage },
  subAdminLogin: { subAdminInfo: subAdminInfoFromStorage },
};

const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
