import React, { useState, useEffect } from "react";
import { updateTimerAction } from "../../../Actions/AdminActions";
import { useSelector, useDispatch } from "react-redux";
import { Chip } from "@mui/material";
const Timer = ({ count, orderid, startingTime }) => {
  const dispatch = useDispatch();

  const [time, setTime] = useState(count);
  useEffect(() => {
    setInterval(function () {
      var date1 = new Date(startingTime);
      var date2 = new Date();
      var diffTime = Math.abs(date2 - date1);
      function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);

        setTime(minutes + ":" + (seconds < 10 ? "0" : "") + seconds);
      }
      millisToMinutesAndSeconds(diffTime);
    }, 1000);
  }, []);
  return (
    <Chip
      sx={{ mt: 2, width: 100, height: 40, fontSize: 18, borderRadius: "5px" }}
      label={time}
      color="success"
    />
  );
};

export default Timer;
