export const options1 = ['All', 'Blocked', 'Active'];
export const statusTypes = ['Blocked', 'Active'];
export const options2 = [
  'All',
  'item 1',
  'item 3',
  'item 4',
  'item 6',
  'sfsdfs',
];
export const options3 = [
  'All',
  'item 1',
  'item 3',
  'item 4',
  'item 6',
  'sfsdfs',
];
