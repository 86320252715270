import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  FilterDashboardWithDate,
  getDashboardAction,
  getGraphAction,
} from "../../../Actions/AdminActions";
import SearchIcon from "@mui/icons-material/Search";
import "moment-timezone";
import RefreshIcon from "@mui/icons-material/Refresh";
import { makeStyles } from "@material-ui/core";
import { IconButton } from "@mui/material";


const useStyles = makeStyles((theme) => ({
  refresh: {
    margin: "auto",
  },
  spin: {
    margin: "auto",
    animation: "$spin 1s infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));


const DaysFilter = ({ spin, from2, to2,place }) => {
  const classes = useStyles();;
  const dispatch = useDispatch();
  const adminLogin = useSelector((state) => state.adminLogin);
  const [from, setFrom] = React.useState(from2);
  const [to, setTo] = React.useState(to2);
  const [filter, setFilter] = React.useState(false);
  const submit = (fromdt, todt) => {
    setFilter(true);
    if (fromdt && todt) {
      dispatch(FilterDashboardWithDate(fromdt, todt,place));
    } else if (fromdt) {
      dispatch(FilterDashboardWithDate(fromdt, "null",place));
    } else {
      setFrom("");
    }
  };

  function handleClrFrom(e) {
    e.stopPropagation();
    setFrom(null);
  }
  function handleClrTo(e) {
    e.stopPropagation();
    setTo(null);
  }
  const refreshdashboard = (e) => {
    e.stopPropagation();
    setTo(null);
    setFrom(null);
    setFilter(false);
    dispatch(getDashboardAction(place));
  };

  return (
    <>
      <span>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="From"
            value={from}
            helperText={
              from !== null && filter && from === "" ? "Field required" : ""
            }
            onChange={(date) => setFrom(date)}
            renderInput={(params) => (
              <TextField
                style={{ marginLeft: "10px", width: "150px" }}
                size="small"
                {...params}
              />
            )}
          />
          <DesktopDatePicker
            label="To"
            value={to}
            onChange={(date) => setTo(date)}
            renderInput={(params) => (
              <TextField
                style={{ marginLeft: "5px", width: "150px" }}
                size="small"
                {...params}
              />
            )}
          />
        </LocalizationProvider>

        <IconButton
          size="small"
          sx={{
            backgroundColor: "green",
            marginLeft: "10px",
            ":hover": {
              color: "whitesmoke",
              backgroundColor: "green",
            },
          }}
          onClick={() => {
            submit(from, to);
          }}
          aria-label="refresh"
        >
          <SearchIcon sx={{ color: "white" }} />
        </IconButton>
        <IconButton
          size="small"
          sx={{
            backgroundColor: "darkgray",
            marginLeft: "10px",
            ":hover": {
              color: "whitesmoke",
              backgroundColor: "brown",
            },
          }}
          onClick={(e) => refreshdashboard(e)}
          aria-label="refresh"
        >
          <RefreshIcon
            sx={{ color: "white" }}
            className={spin ? classes.spin : classes.refresh}
            spin={spin}
          />
        </IconButton>
      </span>
    </>
  );
};

const WeekDateFilter = ({ spin, from2,place }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const adminLogin = useSelector((state) => state.adminLogin);
  const [date, setDate] = React.useState(from2);

  const [filter, setFilter] = React.useState(false);
  const submit = (date) => {
    setFilter(true);
    if (date) {
      dispatch(getGraphAction(date,place));
    } else {
      setDate("");
    }
  };

  function handleClrDate(e) {
    e.stopPropagation();
    setDate(null);
    dispatch(getGraphAction(null,place));
  }

  return (
    <>
      <span>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            helperText={
              date !== null && filter && date === "" ? "Field required" : ""
            }
            label="Date"
            value={date}
            onChange={(date) => setDate(date)}
            renderInput={(params) => (
              <TextField
                style={{ marginLeft: "10px", width: "150px" }}
                size="small"
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        {!spin ? (
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#32a852",
              marginLeft: "10px",
              ":hover": {
                color: "whitesmoke",
                backgroundColor: "green",
              },
            }}
            onClick={() => {
              submit(date);
            }}
            aria-label="refresh"
          >
            <SearchIcon sx={{ color: "white" }} />
          </IconButton>
        ) : (
          <IconButton
            size="small"
            sx={{
              backgroundColor: "darkgray",
              marginLeft: "1px",
              ":hover": {
                color: "whitesmoke",
                backgroundColor: "brown",
              },
            }}
            aria-label="refresh"
          >
            <RefreshIcon
              sx={{ color: "white" }}
              className={spin ? classes.spin : classes.refresh}
              spin={spin}
            />
          </IconButton>
        )}
      </span>
    </>
  );
};

export { DaysFilter, WeekDateFilter };
