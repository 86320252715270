import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { updateVersionAction } from "../../../Actions/AdminActions";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
const helperText = makeStyles(({theme}) => ({
  root: {
    "& .MuiTextField-root": {
      margin: 1,
      width: 200,
    },
    "& .MuiFormHelperText-root": {
      color: "red !important",
    },
  },
  bg: {
    backgroundColor: "#7986cb",
  },
  textfield: {
    backgroundColor: "#fff",
  },
}));
export default function VersionUpdate({
  versionNo,
  versionFor,
  open,
  handleClose,
}) {
  const { loading } = useSelector((state) => state.updateDashboard);
  
  const [versionNumber, setVersionNumber] = React.useState(versionNo);
  const dispatch = useDispatch();
  const helperTxtClass = helperText();
  const UpdateVersion = (e) => {
    setVersionNumber(e.target.value);
  };

  const submit = () => {
    if (versionNumber) {
      dispatch(updateVersionAction(versionFor, versionNumber));
      handleClose()
    }
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{ color: "green" }}
        >{`${versionFor} application version`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To update to your applications, please enter your version number
            here. Your new updations will updates Soon
          </DialogContentText>
          <TextField
            onChange={UpdateVersion}
            helperText={versionNumber ? "" : "Field is required"}
            autoFocus
            margin="dense"
            label="Version number"
            value={versionNumber}
            InputLabelProps={{
              shrink: true,
            }}
            type="text"
            fullWidth
            variant="standard"
            className={helperTxtClass.root}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ color: "#e75c05" }}
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            sx={{ color: "#e75c05" }}
            variant="outlined"
            onClick={submit}
          >
            Save
          </LoadingButton>
          {/* <Button>Update</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
