
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import VersionUpdate from "./EditAlert";
import { Button, ClickAwayListener, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
export default function Version({ driverVersion, customerVersion }) {
  const [value, setValue] = useState("driver");
  const [loading, setLoading] = useState(false);
  const [Dopen, setDOpen] = React.useState(false);
  const [Uopen, setUOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [open,setOpen] = React.useState(false);
  const handleClickOpen = (For, Title) => {
    setTitle(Title);
    if (For === "driver") {
      setDOpen(true);
    } else {
      setUOpen(true);
    }
  };
  const handleClose = () => {
    setDOpen(false);
    setUOpen(false);
  };

  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const styles = {
    position: "absolute",
    top: 60,
    right: 0,
    zIndex: 1,
    width: 300,
    bgcolor: "background.paper",
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              sx={{ color: "green" }}
              label="Driver App Version"
              value="driver"
            />
            <Tab sx={{ color: "blue" }} label="User App Version" value="user" />
          </TabList>
        </Box>
        <TabPanel value="driver">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ color: "darkcyan", marginTop: "15px" }}>
              Version No
            </span>
            <span style={{ color: "green", marginTop: "15px" }}>
              <strong>
                {driverVersion &&
                  driverVersion.version &&
                  driverVersion.version}
              </strong>
            </span>
            <span style={{ color: "green" }}>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={{ position: "relative" }}>
                  <Button type="button" onClick={handleClick}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleClickOpen("driver", "Driver");
                      }}
                    >
                      <EditIcon />
                    </Button>
                    {Dopen ? <VersionUpdate
                      versionFor={title}
                      handleClose={handleClose}
                      open={Dopen}
                      versionNo={driverVersion && driverVersion.version}
                    /> : ""}
                    
                  </Button>
                </Box>
                </ClickAwayListener>
            </span>
          </div>
        </TabPanel>
        <TabPanel value="user">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ color: "darkcyan", marginTop: "15px" }}>
              Version No
            </span>
            <span style={{ color: "blue", marginTop: "15px" }}>
              <strong>
                {customerVersion &&
                  customerVersion.version &&
                  customerVersion.version}
              </strong>
            </span>
            <span style={{ color: "green" }}>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={{ position: "relative" }}>
                  <Button type="button" onClick={handleClick}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleClickOpen("user", "User");
                      }}
                    >
                      <EditIcon />
                    </Button>
                    {
                Uopen ? <VersionUpdate
                versionFor={title}
                handleClose={handleClose}
                open={Uopen}
                versionNo={customerVersion && customerVersion.version}
              /> 
              :""
                    }
                    
                  </Button>
                </Box>
                </ClickAwayListener>
            </span>
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  );
}
