import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form } from "react-bootstrap";
import { ActionRequestAction } from "../../../Actions/AdminActions";


const Action = (props) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [error, setError] = useState(false);
  
  const handleRejectComment = () => {
    if (comment) {
      setError(false);
      dispatch(ActionRequestAction(props.id, comment,props.action));
      setComment("");
      props.onHide();
    } else {
      setError(true);
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="mt-5"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.action==="rejected" ? "Reject" : "approve" } Withdrawal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-4">
          <Form>
            <Form.Control
              className="mt-3"
              as="textarea"
              rows="3"
              cols="7"
              placeholder="Add Your comment"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
            {error && (
              <p className="text-danger font-weight-bold mt-2 mb-2">
                Comment Required!..
              </p>
            )}
            
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          sx={{ background: "green", width: "100px", marginRight: "10px" }}
          onClick={() => handleRejectComment()}
          variant="contained"
        >
          submit
        </Button>
        <Button
          sx={{ width: "100px" ,color:"#fff",background:"red"}}
          variant="contained"
          onClick={props.onHide}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Action;
