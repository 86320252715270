import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { blockCustomerAction } from '../../../Actions/AdminActions';

const BlockingForm = (props) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleBlock = (data) => {
    const { comment } = data;
    dispatch(blockCustomerAction(props.customerId, comment));
    props.setModalShow(false);
  };
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton style={{ backgroundColor: 'rgb(231, 92, 5)' }}>
        <Modal.Title id='contained-modal-title-vcenter'>
          {'Customer ID : ' + props.lrnr}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2 style={{ fontWeight: 'bold' }}>Block</h2>
        {/* <pre>{JSON.stringify(props.customerId)}</pre> */}
        <div className='mt-4'>
          <Form onSubmit={handleSubmit(handleBlock)}>
            <Form.Control
              as='textarea'
              rows='3'
              cols='7'
              placeholder='Write comment...'
              {...register('comment', { required: true })}
            />
            {errors.comment && (
              <p className='text-danger font-weight-bold mt-2'>
                Comment is required.
              </p>
            )}
            <div className='d-flex justify-content-end'>
              <Button
                type='submit'
                className='m-3 w-25'
                style={{
                  backgroundColor: 'rgb(231, 92, 5)',
                  fontWeight: 'bold',
                }}
              >
                Add
              </Button>
              <Button onClick={props.onHide} className='m-3 w-25'>
                Close
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BlockingForm;
