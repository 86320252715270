import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Table } from "react-bootstrap";

import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import AddCoin from "../../Components/Admin/coins-offer/AddCoin";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { API, LOADRUNNR_SOCKET_API } from "../../socketConst/BaseApi";
import axios from "axios";
import AddRules from "../../Components/Admin/coin-rules/AddRules";
import EditCoin from "../../Components/Admin/coins-offer/EditCoin";
import EditRules from "../../Components/Admin/coin-rules/EditRules";
import Swal from "sweetalert2";
import PopUpImage from "../../Components/Admin/image_modal/PopUpImage";

const ManageCoin = () => {
  const [open, setOpen] = useState(false);
  const naviagate = useNavigate();
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const [coinData, setCoindata] = useState([]);
  const [rulesData, setRulesData] = useState([]);
  const [status, setStatus] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [openEditRules, setOpenEditRules] = useState(false);

  // update states pricing
  const [coinType, setCoinType] = useState();
  const [orderNumber, setOrderNumber] = useState();
  const [range1, setRange1] = useState();
  const [range2, setRange2] = useState();
  const [coins1, setCoins1] = useState();
  const [coins2, setCoins2] = useState();
  const [pricingId, setPricingId] = useState("");

  // update states offers
  const [title, setTitle] = useState("");
  const [coins, setCoins] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [offerId, setOfferId] = useState("");

  const [openImg, setOpenImg] = React.useState(false);

  const handleCloseimg = () => {
    setOpenImg(false);
  }; 

  const deleteOffer = (id, del) => {
    let element = "";
    del === "offer" ? (element = "Offer") : (element = "Rule");
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this ${element}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (del === "offer") {
          const { data } = await axios.delete(
            `${API}/api/admin/delete-claim-offer/${id}`
          );
          if (data) {
            setStatus(!status)
            Swal.fire("Deleted!", `This ${element} Deleted`, "success");
           
          }
        } else {
          const { data } = await axios.delete(
            `${API}/api/admin/delete-coin-pricing/${id}`
          );
          if (data) {
            setStatus(!status)
            Swal.fire("Deleted!", `This ${element} Deleted`, "success");
           
          }
        }
      }
    });
  };

  useEffect(() => {
    if (!adminInfo) {
      naviagate("/login");
    }
    async function fethCoin() {
      const { data } = await axios.get(
        `${API}/api/admin/get-claim-offers`
      );
      setCoindata(data);
    }
    async function fethRules() {
      const { data } = await axios.get(
        `${API}/api/admin/get-coin-pricing`
      );
     
      setRulesData(data);
    }
    fethCoin();
    fethRules();
  }, [adminInfo, naviagate, status]);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStatus(!status)
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="Coins Management"
          id={adminInfo && adminInfo._id}
          email={adminInfo && adminInfo.email}
          name={adminInfo && `${adminInfo.firstName} ${adminInfo.lastName}`}
          token={adminInfo && adminInfo.token}
        />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 1 }}
        >
          <Toolbar />

          {/* <h5 style={{ display: "block", color: "green" }}>Manage Coins</h5> */}
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  sx={{ fontWeight: "bold" }}
                  icon={<PriceChangeOutlinedIcon />}
                  iconPosition="start"
                  label="Manage Coin Pricing"
                  value="1"
                />
                <Tab
                  sx={{ fontWeight: "bold" }}
                  icon={<LocalOfferOutlinedIcon />}
                  iconPosition="start"
                  label="Manage Coins List"
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  justifyContent: "end",
                }}
              >
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  variant="outlined"
                  sx={{
                    border: "solid 1px #ff9900",
                    color: "#ff9900",
                    ":hover": {
                      color: "#ff99055",
                      backgroundColor: "#ffe0b3",
                      border: "solid 1px #ff9900",
                    },
                  }}
                >
                  add rule
                </Button>
                <AddRules
                  status={status}
                  setStatus={setStatus}
                  close={() => setOpen(false)}
                  open={open}
                />
                <EditRules
                  pricingId={pricingId}
                  coinType={coinType}
                  setCoinType={setCoinType}
                  orderNumber={orderNumber}
                  setOrderNumber={setOrderNumber}
                  range1={range1}
                  setRange1={setRange1}
                  range2={range2}
                  setRange2={setRange2}
                  coins1={coins1}
                  setCoins1={setCoins1}
                  coins2={coins2}
                  setCoins2={setCoins2}
                  status={status}
                  setStatus={setStatus}
                  close={() => setOpenEditRules(false)}
                  open={openEditRules}
                />
              </div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Order</th>
                    <th>Range 1</th>
                    <th>Range 2</th>
                    <th>Coins (Range-1) To (Range-2)</th>
                    <th>Coins Above (Range-2) </th>
                    <th>Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {rulesData &&
                    rulesData.map((rule, index) => {
                      return (
                        <tr key={rule._id}>
                          <td>
                            <span>{index + 1}</span>
                          </td>
                          <td>{`${rule.orderNumber}th`}</td>
                          <td>{rule.range1 === 0 ? "Nill" : rule.range1}</td>

                          <td>{rule.range}</td>
                          <td>{rule.range1 === 0 ? "Nill" : rule.coins1}</td>

                          <td>{rule.coins}</td>

                          <td>{rule.type}</td>

                          <td>
                            <IconButton
                              onClick={() => {
                                if (rule.orderNumber === 1) {
                                  setCoinType(rule.type);
                                  setOrderNumber(rule.orderNumber);
                                  setRange1(rule.range1);
                                  setRange2(rule.range);
                                  setCoins1(rule.coins1);
                                  setCoins2(rule.coins);
                                  setOpenEditRules(true);
                                  setPricingId(rule._id);
                                } else {
                                  setCoinType(rule.type);
                                  setOrderNumber(rule.orderNumber);
                                  setRange1(rule.range);
                                  setCoins1(rule.coins);
                                  setOpenEditRules(true);
                                  setPricingId(rule._id);
                                }
                              }}
                            >
                              <EditIcon sx={{ color: "green" }} />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                deleteOffer(rule._id, "rules");
                              }}
                              aria-label="delete"
                            >
                              <DeleteIcon sx={{ color: "red" }} />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TabPanel>
            <TabPanel value="2">
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  variant="outlined"
                  sx={{ border :"solid 1px green",color:"green"}}
                >
                  add coin
                </Button>
              </div>

              <AddCoin
                status={status}
                setStatus={setStatus}
                close={() => setOpen(false)}
                open={open}
              />
              <EditCoin
                status={status}
                setStatus={setStatus}
                offerId={offerId}
                title={title}
                coins={coins}
                imgUrl={imgUrl}
                setTitle={setTitle}
                // setCoins={setCoins}
                // setImgUrl={setImgUrl}
                // setOfferId={setOfferId}
                close={() => setEditOpen(false)}
                open={editOpen}
              />

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>image</th>
                    <th>Title</th>
                    <th>Coin</th>
                    <th>Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {coinData &&
                    coinData.map((coin, index) => {
                      return (
                        <tr key={coin._id}>
                          <td>
                            <span>{index + 1}</span>
                          </td>
                          <td>
                            <img
                              onClick={() => {
                                setImgUrl(coin.img_url);
                                setOpenImg(true);
                              }}
                              width="100px"
                              src={coin.img_url}
                            ></img>
                          </td>
                          <td>
                            <span>{coin.title}</span>
                          </td>
                          <td>
                            <img
                              width={30}
                              height={30}
                              src="https://cdn3d.iconscout.com/3d/free/thumb/rupee-coin-5860285-4898038.png"
                              alt="coin_icon"
                            />
                            <span style={{ color: "green", fontWeight: 900 }}>
                              {coin.coins}
                            </span>
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                width: "250px",
                                justifyContent: "center",
                                alignContent: "center",
                              }}
                            >
                              <IconButton
                                variant="contained"
                                component="label"
                                color="primary"
                                onClick={() => {
                                  setEditOpen(true);
                                  setOfferId(coin._id);
                                  setTitle(coin.title);
                                  setCoins(coin.coins);
                                  setImgUrl(coin.img_url);
                                }}
                              >
                                <EditIcon sx={{ color: "green" }} />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  deleteOffer(coin._id, "offer");
                                }}
                                aria-label="delete"
                              >
                                <DeleteIcon sx={{ color: "red" }} />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
      <PopUpImage
        handleCloseimg={handleCloseimg}
        open={openImg}
        imgUrl={imgUrl}
      />
    </>
  );
};

export default ManageCoin;
