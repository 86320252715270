import { Box } from "@mui/material";
import ReactLoading from "react-loading";
const SpinnerLoader = () => (
    <Box component='div'> 
      <ReactLoading type="spokes" color="#a8a8a8" /> 
      {/* #e75c05  loadrunnr color*/}
    </Box>
  );
  
  export default SpinnerLoader;
  