import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import Loader from "../../Components/Loader";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import {
  StatusDrowpDown,
  PlaceDrowpDown,
  VehicleTypeDrowpDown,
  VehicleSubTypeDrowpDown,
  FromToDateFilter,
  SearchFilter,
} from "../../Components/Admin/BookingsTableHelpers/Filters";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { BookingTableHead } from "../../Components/Admin/BookingsTableHelpers/TableHeadings";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import io from "socket.io-client";
import { LOADRUNNR_SOCKET_API } from "../../socketConst/BaseApi";
import { Chip, Paper } from "@mui/material";
import { Badge } from "@mui/material";
import {
  getBookingsAction,
  getCustomerOrdersAction,
  getNewOrderAction,
  refreshBookingAction,
  UpdateBookingAction,
} from "../../Actions/AdminActions";
import AddCommentForm from "../../Components/Admin/BookkingCommentForm.js/AddCommentForm";
import UnAssignComment from "../../Components/Admin/BookkingCommentForm.js/UnAssignComment";
import CancelComment from "../../Components/Admin/BookkingCommentForm.js/CancelComment";
import exportFromJSON from "export-from-json";
import moment from "moment";
import Moment from "react-moment";
import "moment-timezone";
import BookingsMap from "../../Components/Admin/Map/BookingsMap";
import Timer from "../../Components/Admin/timer/Timer";
import ShowCouponDetils from "../../Components/Admin/coupon-details/CouponDetails";
import ManualChangeComment from "../../Components/Admin/ManualChangeComment";

let count = 1;
var data = [];
let stop = 2;

const fileName = "download";
const exportType = "xls";

const handleDownload = () => {
  if (data.length > 0) {
    exportFromJSON({ data, fileName, exportType });
  } else {
    alert("No Data For Download!...");
  }
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(customerOrder, comparator) {
  const stabilizedThis = customerOrder.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

BookingTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const BookingList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  // menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // end menu
  // wallt modal

  const {customerOrder, loading} = useSelector(state => state.customerOrders)

  const { sucess, drvId, newBooking, statusChange } = useSelector(
    (state) => state.getNewOrder
  );

  useEffect(() => {
    const socket = io(LOADRUNNR_SOCKET_API, {
      withCredentials: true,
      transports: ["websocket"],
      extraHeaders: {
        "my-custom-header": "abcd",
      },
    });

    socket.on("newOrder", (data) => {
      dispatch(refreshBookingAction());
    });
  }, []);

  useEffect(() => {
    const socket = io(LOADRUNNR_SOCKET_API, {
      withCredentials: true,
      transports: ["websocket"],
      extraHeaders: {
        "my-custom-header": "abcd",
      },
    });
    socket.on("updateBooking", (updatedData) => {
      dispatch(refreshBookingAction());
    });
  }, []);
  const [ManualBookingId, setManualBookingId] = useState("");
  const [ManualStatus, setManualStatus] = useState("");
  const [isOpen, setisOpen] = React.useState(false);
  const [adminName, setAdminName] = useState("");

  const ChangeStatus = (bookingId, status, adName) => {
    setManualBookingId(bookingId);
    setManualStatus(status);
    setAdminName(adName);
    setisOpen(true);
  };

  const handleCloseManualComment = () => {
    setisOpen(false);
  };
  useEffect(() => {
    const socket = io(LOADRUNNR_SOCKET_API, {
      withCredentials: true,
      transports: ["websocket"],
      extraHeaders: {
        "my-custom-header": "abcd",
      },
    });
    socket.on("updateBookingForAdmin", () => {
      dispatch(refreshBookingAction());
    });
    socket.on("orderCompleted", (data) => {
      dispatch(refreshBookingAction());
    });
  }, []);

  useEffect(() => {
    const socket = io(LOADRUNNR_SOCKET_API, {
      withCredentials: true,
      transports: ["websocket"],
      extraHeaders: {
        "my-custom-header": "abcd",
      },
    });
    socket.on("removeOrderForDriver", () => {
      dispatch(refreshBookingAction());
    });
    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (sucess && newBooking && customerOrder) {
      if (sucess && customerOrder) {
        for (let index = 0; index < customerOrder.length; index++) {
          if (customerOrder[index]._id === newBooking._id) {
            customerOrder[index] = newBooking;
            break;
          } else if (newBooking.status == "assigning") {
            customerOrder.unshift(newBooking);
            break;
          }
        }
      } else {
        // console.log("no data", sucess);
      }
    }
  }, [sucess, drvId, statusChange]);

  // end wallet

  // Pagination
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);

  //const [dense, setDense] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [idForCouponDetails, setIdForCouponDetails] = React.useState("");
  const [walletLogs, setWalletLogs] = React.useState(false);
  const [bookingId, setBookingId] = useState("");
  const [showAddCommentForm, setShowAddCommentForm] = useState(false);
  const [showCancelCommentForm, setShowCancelCommentForm] = useState(false);
  const [showUnAssignedCommentForm, setShowUnAssignedCommentForm] =
    useState(false);
  const [csnote, setCsnotes] = useState([]);
  const [lrnrbid, setLrnrbid] = useState("");
  const [payment, setPayment] = React.useState(true);

  // map
  const [showMap, setShowMap] = useState(false);
  const { commentSuccess } = useSelector((state) => state.bookingAddComment);
  const adminBookingUpdate = useSelector((state) => state.adminBookingUpdate);
  const { success: updateSuccess } = adminBookingUpdate;

  const filterStatus = useSelector((state) => state.bookingStausFilter);
  const filterPlaces = useSelector((state) => state.bookingPlaceFilter);
  const vehiclesTypesFilter = useSelector(
    (state) => state.bookingVehicleTypeFilter
  );
  const subTypeFilter = useSelector((state) => state.bookingSubTypeFilter);
  const bookingSearch = useSelector((state) => state.bookingSearch);
  const { content } = bookingSearch;

  const { statusDateFilter, from, to } = useSelector(
    (state) => state.bookingDateFilter
  );
  let allKey = filterStatus.filters
    .concat(vehiclesTypesFilter.vehicleTypefilters)
    .concat(subTypeFilter.subTypefilters)
    .concat(filterPlaces.filters);
  const [openCouponDetails, setOpenCoupounDetails] = React.useState(false);
  const [payableAmount, setPayableAmount] = React.useState("");
  const [extraCharge, setExtraCharge] = React.useState("");
  const [oldChargeAmount, setOldAmount] = React.useState("");
  const [nightCharge, setNightCharge] = React.useState("");
  const [couponApplay, setCouponApplay] = React.useState(false);
  const [offPercentage, setOffPercentage] = React.useState("");
  const [offAmount, setOffAmount] = React.useState("");
  const { id, user } = useParams()
  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }
    dispatch(getCustomerOrdersAction(id, user));
  }, [adminInfo, updateSuccess, commentSuccess, dispatch, navigate]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = customerOrder.map((n) => n.personalDetails.firstName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleMapClose = () => {
    setShowMap(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const couponDetails = (
    id,
    amount,
    extraCharge,
    oldAmount,
    nightCharge,
    couponApplay
  ) => {
    setIdForCouponDetails(id);
    setExtraCharge(extraCharge);
    setPayableAmount(amount);
    setOldAmount(oldAmount);
    setNightCharge(nightCharge);
    setCouponApplay(couponApplay);
   
    setOpenCoupounDetails(true);
  };
  const handleCloseCouponDetails = () => {
    setOpenCoupounDetails(false);
  };

  const handleAddComment = (id, bid, adName) => {
    if (customerOrder) {
      var bookingData = customerOrder.filter((el) => {
        return el._id === id;
      });
      setCsnotes(bookingData[0].csNotes);
      setBookingId(id);
      setLrnrbid(bid);
      setAdminName(adName);
      setShowAddCommentForm(true);
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - customerOrder.length) : 0;

  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }
  }, [adminInfo, navigate]);

  const dataPushing = (row) => {
    data.push({
      ID: row.bookingId,
      Status: row.status,
      "Customer Name": row.customer.name,
      "Customer Phone Number": row.customer.mobNo,
      "Vehicle Type": row.vehicleType,
      "Sub Type": row.subType,
      "Pickup Point": row.mainAddress.pickupPoint.location,
      "Stop 1": row.address2.location ? row.address2.location : "",
      "Stop 2": row.address2.location ? row.address2.location : "",
      "Drop Point": row.mainAddress.dropPoint.location,
      Cash: row.paymentDetails.cash ? row.paymentDetails.cash : "",
      Wallet: row.paymentDetails.wallet ? row.paymentDetails.wallet : "",
      "Driver Name": row.driverDetails.name ? row.driverDetails.name : "",
      "Driver Phone Number": row.driverDetails.mobNo
        ? row.driverDetails.mobNo
        : "",
      "Booking Date": row.bookingDate,
      
    });
  };

  customerOrder &&
    customerOrder.map((row) => {
      if (customerOrder.length === data.length) {
        return data;
      } else {
        data.push({
          ID: row.bookingId,
          Status: row.status,
          "Customer Name": row.customer.name,
          "Customer Phone Number": row.customer.mobNo,
          "Vehicle Type": row.vehicleType,
          "Sub Type": row.subType,
          "Pickup Point": row.mainAddress.pickupPoint.location,
          "Stop 1": row.address2.location ? row.address2.location : "",
          "Stop 2": row.address2.location ? row.address2.location : "",
          "Drop Point": row.mainAddress.dropPoint.location,
          Cash: row.paymentDetails.cash ? row.paymentDetails.cash : "",
          Wallet: row.paymentDetails.wallet ? row.paymentDetails.wallet : "",
          "Driver Name": row.driverDetails.name ? row.driverDetails.name : "",
          "Driver Phone Number": row.driverDetails.mobNo
            ? row.driverDetails.mobNo
            : "",
          "Booking Date": row.bookingDate,
        });
      }
    });
  filterStatus.statusFilter && (data = []);
  vehiclesTypesFilter.vehicleTypeStatus && (data = []);
  subTypeFilter.subTypeStatus && (data = []);
  content && (data = []);
  const [location, setLocation] = useState({
    pickupLocation: "",
    pickuplog: 0,
    pickuplat: 0,
    pickupName: "",
    pickupPhoneNumber: "",
    address2Location: "",
    address2log: 0,
    address2lat: 0,
    address2Name: "",
    address2PhoneNumber: "",
    address3Location: "",
    address3log: 0,
    address3lat: 0,
    address3Name: "",
    address3PhoneNumber: "",
    dropLocation: "",
    dropLat: 0,
    dropLog: 0,
    dropName: "",
    dropPhoneNumber: "",
  });

  const [driverBookingId, setDriverBookingId] = useState("");
  const [bookingDriverId, setBookingDriverId] = useState("");

  const handleShowMap = (
    pickupLocation,
    pickuplog,
    pickuplat,
    pickupName,
    pickupPhoneNumber,
    address2Location,
    address2log,
    address2lat,
    address2Name,
    address2PhoneNumber,
    address3Location,
    address3log,
    address3lat,
    address3Name,
    address3PhoneNumber,
    dropLocation,
    dropLog,
    dropLat,
    dropName,
    dropPhoneNumber,
    id,
    driverId
  ) => {
    setBookingDriverId(driverId);
    setDriverBookingId(id);
    setLocation({
      pickupLocation: pickupLocation,
      pickuplog: pickuplog,
      pickuplat: pickuplat,
      pickupName: pickupName,
      pickupPhoneNumber: pickupPhoneNumber,
      address2Location: address2Location,
      address2log: address2log,
      address2lat: address2lat,
      address2Name: address2Name,
      address2PhoneNumber: address2PhoneNumber,
      address3Location: address3Location,
      address3log: address3log,
      address3lat: address3lat,
      address3Name: address3Name,
      address3PhoneNumber: address3PhoneNumber,
      dropLocation: dropLocation,
      dropLat: dropLat,
      dropLog: dropLog,
      dropName: dropName,
      dropPhoneNumber: dropPhoneNumber,
    });
    setShowMap(true);
  };
  let ManualStatusChange = (row) => {
    if (row.status === "Assigned") {
      return (
        <Button
          onClick={() => {
            let adName = `${adminInfo.firstName} ${adminInfo.lastName}`;
            ChangeStatus(row._id, "ReachPickupPoint", adName);
          }}
          variant="contained"
        >
          Reached Pickup Point
        </Button>
      );
    } else if (
      row.stops &&
      row.stops.total === 3 &&
      row.status === "Confirming"
    ) {
      return (
        <Button
          onClick={() => {
            let adName = `${adminInfo.firstName} ${adminInfo.lastName}`;
            ChangeStatus(row._id, "Ongoing", adName);
          }}
          variant="contained"
        >
          Ongoing
        </Button>
      );
    } else if (
      row.stops &&
      row.stops.total === 3 &&
      row.status === "Ongoing" &&
      row.trakingStatus === "stop1Completed"
    ) {
      return (
        <Button
          onClick={() => {
            let adName = `${adminInfo.firstName} ${adminInfo.lastName}`;
            ChangeStatus(row._id, "Complete", adName);
          }}
          variant="contained"
        >
          Complete
        </Button>
      );
    } else if (
      row.stops &&
      row.stops.total === 3 &&
      row.status === "Ongoing" &&
      row.trakingStatus === "stop1Available"
    ) {
      return (
        <Button
          onClick={() => {
            let adName = `${adminInfo.firstName} ${adminInfo.lastName}`;
            ChangeStatus(row._id, "Reached_Stop_1", adName);
          }}
          variant="contained"
        >
          Reached Stop 1
        </Button>
      );
    } else if (
      row.stops &&
      row.stops.total === 2 &&
      row.status === "Confirming"
    ) {
      return (
        <Button
          onClick={() => {
            let adName = `${adminInfo.firstName} ${adminInfo.lastName}`;
            ChangeStatus(row._id, "Ongoing", adName);
          }}
          variant="contained"
        >
          Ongoing
        </Button>
      );
    } else if (row.stops && row.stops.total === 2 && row.status === "Ongoing") {
      return (
        <Button
          onClick={() => {
            let adName = `${adminInfo.firstName} ${adminInfo.lastName}`;
            ChangeStatus(row._id, "Complete", adName);
          }}
          variant="contained"
        >
          Complete
        </Button>
      );
    }
  };
  let bookingRecord = (row) => {
    return (
      <>
        <TableRow hover key={row._id}>
          <TableCell>
            <Button
              sx={{
                backgroundColor: "blue",
                width: "100px",
              }}
              variant="contained"
            >
              {row.status}
            </Button>
            {row.status === "Assigning" ? (
              <div align="center">
                <Timer
                  timer={row.timer}
                  count={row.timer}
                  startingTime={row.reassinging ? row.updatedAt : row.createdAt}
                />
              </div>
            ) : (
              <div align="center">
                {row.status === "Expired" ? (
                  <Chip
                    sx={{
                      mt: 2,
                      width: 100,
                      height: 40,
                      fontSize: 18,
                      borderRadius: "5px",
                    }}
                    label="5:00"
                    color="error"
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </TableCell>
          <TableCell>{row.bookingId}</TableCell>
          <TableCell>{row.place}</TableCell>
          <TableCell>
            <p>{row.customer.name}</p>
            <p>{row.customer.mobNo}</p>
          </TableCell>
          <TableCell>{row.vehicleType}</TableCell>

          <TableCell>{row.subType}</TableCell>

          <TableCell
            style={
              row.rent
                ? { backgroundColor: "#fff" }
                : { backgroundColor: "#d9ebfc" }
            }
            onClick={() =>
              row.rent
                ? null
                : handleShowMap(
                    row.mainAddress.pickupPoint.location,
                    row.mainAddress.pickupPoint.lan,
                    row.mainAddress.pickupPoint.lat,
                    row.mainAddress.pickupPoint.name,
                    row.mainAddress.pickupPoint.phoneNumber,
                    row.address2.location,
                    row.address2.lan,
                    row.address2.lat,
                    row.address2.name,
                    row.address2.phoneNumber,
                    row.address3.location,
                    row.address3.lan,
                    row.address3.lat,
                    row.address3.name,
                    row.address3.phoneNumber,
                    row.mainAddress.dropPoint.location,
                    row.mainAddress.dropPoint.lan,
                    row.mainAddress.dropPoint.lat,
                    row.mainAddress.dropPoint.name,
                    row.mainAddress.dropPoint.phoneNumber,
                    row._id,
                    row.driverId
                  )
            }
          >
            <p>{row.mainAddress.pickupPoint.location}</p>
            {!row.address3.location && row.address2.location ? (
              <p
                style={{
                  backgroundColor: "orange",
                  borderRadius: "10px",
                  width: "25px",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                1
              </p>
            ) : (
              ""
            )}
            {row.address2.location && row.address3.location ? <p>2</p> : ""}
            <p>{row.mainAddress.dropPoint.location}</p>
          </TableCell>
          <TableCell>
            <Button
              onClick={() => {
                couponDetails(
                  row._id,
                  row.paymentDetails.amount,
                  row.paymentDetails.extraCharge,
                  row.paymentDetails.oldAmount,
                  row.paymentDetails.nightSurge,
                  row.appliedCoupon
                );
                if (row.appliedCoupon) {
                  setOffPercentage(row.promoCodeDetails.off);
                  setOffAmount(row.promoCodeDetails.discount);
                }
              }}
              sx={{ width: 100 }}
              variant="outlined"
              endIcon={<InfoIcon />}
            >
              {`₹${row.paymentDetails.amount.toFixed(0)}`}
            </Button>
          </TableCell>
          <TableCell>
            <p>{row.driverDetails.name}</p>
            <p>{row.driverDetails.mobNo}</p>
          </TableCell>
          <TableCell>{row.bookingDate}</TableCell>
          {row.status === "Completed" ||
          row.status === "Cancel" ||
          row.status === "Expired" ||
          row.status === "Assigning" ? (
            <TableCell>
              {" "}
              <Button variant="outlined">No_Action</Button>{" "}
            </TableCell>
          ) : (
            <TableCell>
              {row.status === "Cancel" ? (
                ""
              ) : (
                <>
                  <Button
                    sx={{
                      backgroundColor: "#e75c05",
                      width: "100px",
                    }}
                    variant="contained"
                    onClick={() => {
                      setBookingId(row._id);
                      setLrnrbid(row.bookingId);
                      setShowUnAssignedCommentForm(true);
                    }}
                  >
                    unassigin
                  </Button>
                  <br />
                </>
              )}
              <Button
                sx={{
                  backgroundColor: "red",
                  width: "100px",
                  marginTop: "4px",
                }}
                variant="contained"
                onClick={() => {
                  setBookingId(row._id);
                  setLrnrbid(row.bookingId);
                  setShowCancelCommentForm(true);
                  let adName = `${adminInfo.firstName} ${adminInfo.lastName}`;
                  setAdminName(adName);
                }}
              >
                cancel
              </Button>
            </TableCell>
          )}
          <TableCell>
            {row.status === "Completed" ||
            row.status === "Cancel" ||
            row.status === "Expired" ? (
              <Button variant="outlined">No_Action</Button>
            ) : (
              ManualStatusChange(row)
            )}
          </TableCell>
          <TableCell>
            {row.csNotes &&
            row.csNotes[row.csNotes.length - 1] &&
            row.csNotes[row.csNotes.length - 1].comment ? (
              <p>
                {row.csNotes &&
                  row.csNotes[row.csNotes.length - 1] &&
                  row.csNotes[row.csNotes.length - 1].comment &&
                  row.csNotes[row.csNotes.length - 1].comment.split(")")[0] ==
                    "cancel :" && (
                    <span
                      style={{
                        fontWeight: "bold",
                        background: "red",
                        color: "white",
                      }}
                    >
                      {row.csNotes[row.csNotes.length - 1].comment.split(
                        ")"
                      )[0] &&
                        row.csNotes[row.csNotes.length - 1].comment.split(
                          ")"
                        )[0]}
                    </span>
                  )}

                {row.csNotes &&
                  row.csNotes[row.csNotes.length - 1] &&
                  row.csNotes[row.csNotes.length - 1].comment &&
                  row.csNotes[row.csNotes.length - 1].comment.split(")")[0] ==
                    "unAssign :" && (
                    <span
                      style={{ fontWeight: "bold", background: "yellowgreen" }}
                    >
                      {row.csNotes[row.csNotes.length - 1].comment.split(
                        ")"
                      )[0] &&
                        row.csNotes[row.csNotes.length - 1].comment.split(
                          ")"
                        )[0]}
                    </span>
                  )}

                {row.csNotes &&
                row.csNotes[row.csNotes.length - 1] &&
                row.csNotes[row.csNotes.length - 1].comment &&
                row.csNotes[row.csNotes.length - 1].comment.split(")")[1]
                  ? row.csNotes[row.csNotes.length - 1].comment.split(")")[1]
                  : row.csNotes[row.csNotes.length - 1].comment}
              </p>
            ) : (
              ""
            )}
            <Button
              sx={{
                backgroundColor: "red",
                width: "100px",
                marginTop: "4px",
              }}
              variant="contained"
              onClick={() => {
                let adName = `${adminInfo.firstName} ${adminInfo.lastName}`;
                handleAddComment(row._id, row.bookingId, adName);
              }}
            >
              Add Comment
            </Button>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="Booking"
          id={adminInfo && adminInfo._id}
          email={adminInfo && adminInfo.email}
          name={`${adminInfo.firstName} ${adminInfo.lastName}`}
          place={adminInfo && adminInfo.place}
          token={adminInfo && adminInfo.token}
        />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <>
            <Toolbar />
            <h1>Bookings</h1>

            <Typography
              sx={{ fontSize: "15px", fontWeight: "bold" }}
              component="span"
            >
              Filter By:
            </Typography>
            <div style={{ paddingTop: 25, paddingBottom: 25 }}>
              <PlaceDrowpDown style={{ marginTop: "30px" }} />
              <StatusDrowpDown style={{ marginTop: "30px" }} />
              <VehicleTypeDrowpDown style={{ marginTop: "30px" }} />
              <VehicleSubTypeDrowpDown style={{ marginTop: "30px" }} />
              <span style={{ position: "relative", top: 9 }}>
                <FromToDateFilter />
              </span>
              <span style={{ marginLeft: "20px" }}>
                <SearchFilter />
              </span>

              <Button
                sx={{ marginLeft: "20px", bgcolor: "green" }}
                variant="contained"
                onClick={() => handleDownload()}
                endIcon={<DownloadIcon />}
              >
                Download
              </Button>
            </div>

            {!loading && customerOrder ? (
              <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2 }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"

                     // size={dense ? "small" : "medium"}

                    >
                      <BookingTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={customerOrder.length}
                      />

                      <TableBody>
                        {stableSort(customerOrder, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )

                          .map((row) => {
                            

                            if (statusDateFilter) {
                             
                              let date = row.createdAt;
                              var d = new Date(from);
                              d.setDate(d.getDate() - 1);
                              let from2 = moment(d).format("L");
                              var t = new Date(to);
                              t.setDate(t.getDate() + 1);
                              let to2 = moment(t).format("L");
                              date = moment(date).format("L");
                              let datefltr = moment(date).isBetween(from2, to2);
                              
                              if (datefltr) {
                                if (content) {
                                  function escapeRegExp(string) {
                                    return string.replace(
                                      /[.*+?^${}()|[\]\\]/g,
                                      "\\$&"
                                    );
                                  }
                                  let escpd = escapeRegExp(content);
                                  var regex = new RegExp(escpd, "gi");
                                  if (
                                    row.place.match(regex) ||
                                    row.status.match(regex) ||
                                    row.bookingId.match(regex) ||
                                    row.customer.name.match(regex) ||
                                    row.customer.mobNo.match(regex) ||
                                    (row.vehicleType &&
                                      row.vehicleType.match(regex)) ||
                                    (row.subType && row.subType.match(regex)) ||
                                    (row.mainAddress.pickupPoint.location &&
                                      row.mainAddress.pickupPoint.location.match(
                                        regex
                                      )) ||
                                    (row.mainAddress.dropPoint.location &&
                                      row.mainAddress.dropPoint.location.match(
                                        regex
                                      )) ||
                                    (row.driverDetails.name &&
                                      row.driverDetails.name.match(regex)) ||
                                    (row.driverDetails.mobNo &&
                                      row.driverDetails.mobNo.match(regex)) ||
                                    row.bookingDate.match(regex)
                                  ) {
                                    if (
                                      filterStatus.statusFilter &&
                                      vehiclesTypesFilter.vehicleTypeStatus &&
                                      subTypeFilter.subTypeStatus &&
                                      filterPlaces.placeFilter
                                    ) {
                                      if (
                                        allKey.includes(row.status) &&
                                        allKey.includes(row.vehicleType) &&
                                        allKey.includes(row.subType) &&
                                        allKey.includes(row.place)
                                      ) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (
                                      filterStatus.statusFilter &&
                                      vehiclesTypesFilter.vehicleTypeStatus &&
                                      subTypeFilter.subTypeStatus
                                    ) {
                                      if (
                                        allKey.includes(row.status) &&
                                        allKey.includes(row.vehicleType) &&
                                        allKey.includes(row.subType)
                                      ) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (
                                      filterPlaces.placeFilter &&
                                      vehiclesTypesFilter.vehicleTypeStatus &&
                                      subTypeFilter.subTypeStatus
                                    ) {
                                      if (
                                        allKey.includes(row.place) &&
                                        allKey.includes(row.vehicleType) &&
                                        allKey.includes(row.subType)
                                      ) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (
                                      filterPlaces.placeFilter &&
                                      filterStatus.statusFilter &&
                                      subTypeFilter.subTypeStatus
                                    ) {
                                      if (
                                        allKey.includes(row.place) &&
                                        allKey.includes(row.status) &&
                                        allKey.includes(row.subType)
                                      ) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (
                                      filterPlaces.placeFilter &&
                                      filterStatus.statusFilter &&
                                      subTypeFilter.vehicleTypeStatus
                                    ) {
                                      if (
                                        allKey.includes(row.place) &&
                                        allKey.includes(row.status) &&
                                        allKey.includes(row.vehicleType)
                                      ) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (
                                      filterStatus.statusFilter &&
                                      vehiclesTypesFilter.vehicleTypeStatus
                                    ) {
                                      if (
                                        allKey.includes(row.status) &&
                                        allKey.includes(row.vehicleType)
                                      ) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (
                                      vehiclesTypesFilter.vehicleTypeStatus &&
                                      filterPlaces.placeFilter
                                    ) {
                                      if (
                                        allKey.includes(row.vehicleType) &&
                                        allKey.includes(row.place)
                                      ) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (
                                      filterPlaces.placeFilter &&
                                      subTypeFilter.subTypeStatus
                                    ) {
                                      if (
                                        allKey.includes(row.subType) &&
                                        allKey.includes(row.place)
                                      ) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (
                                      vehiclesTypesFilter.vehicleTypeStatus &&
                                      subTypeFilter.subTypeStatus
                                    ) {
                                      if (
                                        allKey.includes(row.vehicleType) &&
                                        allKey.includes(row.subType)
                                      ) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (
                                      filterStatus.statusFilter &&
                                      subTypeFilter.subTypeStatus
                                    ) {
                                      if (
                                        allKey.includes(row.status) &&
                                        allKey.includes(row.subType)
                                      ) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (
                                      filterStatus.statusFilter &&
                                      filterPlaces.placeFilter
                                    ) {
                                      if (
                                        allKey.includes(row.status) &&
                                        allKey.includes(row.place)
                                      ) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (filterStatus.statusFilter) {
                                      if (allKey.includes(row.status)) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (
                                      vehiclesTypesFilter.vehicleTypeStatus
                                    ) {
                                      if (allKey.includes(row.vehicleType)) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (subTypeFilter.subTypeStatus) {
                                      if (allKey.includes(row.subType)) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else if (filterPlaces.placeFilter) {
                                      if (allKey.includes(row.place)) {
                                        dataPushing(row);
                                        return bookingRecord(row);
                                      }
                                    } else {
                                      return bookingRecord(row);
                                    }
                                  }
                                } else {
                                  if (
                                    filterStatus.statusFilter &&
                                    vehiclesTypesFilter.vehicleTypeStatus &&
                                    subTypeFilter.subTypeStatus &&
                                    filterPlaces.placeFilter
                                  ) {
                                    if (
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.vehicleType) &&
                                      allKey.includes(row.subType) &&
                                      allKey.includes(row.place)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterStatus.statusFilter &&
                                    vehiclesTypesFilter.vehicleTypeStatus &&
                                    subTypeFilter.subTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.vehicleType) &&
                                      allKey.includes(row.subType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterPlaces.placeFilter &&
                                    vehiclesTypesFilter.vehicleTypeStatus &&
                                    subTypeFilter.subTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.place) &&
                                      allKey.includes(row.vehicleType) &&
                                      allKey.includes(row.subType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterPlaces.placeFilter &&
                                    filterStatus.statusFilter &&
                                    subTypeFilter.subTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.place) &&
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.subType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterPlaces.placeFilter &&
                                    filterStatus.statusFilter &&
                                    subTypeFilter.vehicleTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.place) &&
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.vehicleType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterStatus.statusFilter &&
                                    vehiclesTypesFilter.vehicleTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.vehicleType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    vehiclesTypesFilter.vehicleTypeStatus &&
                                    filterPlaces.placeFilter
                                  ) {
                                    if (
                                      allKey.includes(row.vehicleType) &&
                                      allKey.includes(row.place)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterPlaces.placeFilter &&
                                    subTypeFilter.subTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.subType) &&
                                      allKey.includes(row.place)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    vehiclesTypesFilter.vehicleTypeStatus &&
                                    subTypeFilter.subTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.vehicleType) &&
                                      allKey.includes(row.subType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterStatus.statusFilter &&
                                    subTypeFilter.subTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.subType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterStatus.statusFilter &&
                                    filterPlaces.placeFilter
                                  ) {
                                    if (
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.place)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (filterStatus.statusFilter) {
                                    if (allKey.includes(row.status)) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    vehiclesTypesFilter.vehicleTypeStatus
                                  ) {
                                    if (allKey.includes(row.vehicleType)) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (subTypeFilter.subTypeStatus) {
                                    if (allKey.includes(row.subType)) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (filterPlaces.placeFilter) {
                                    if (allKey.includes(row.place)) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else {
                                    return bookingRecord(row);
                                  }
                                }
                              }
                            } else {
                              if (content) {
                                function escapeRegExp(string) {
                                  return string.replace(
                                    /[.*+?^${}()|[\]\\]/g,
                                    "\\$&"
                                  );
                                }
                                let escpd = escapeRegExp(content);
                                var regex = new RegExp(escpd, "gi");
                                if (
                                  row.place.match(regex) ||
                                  row.status.match(regex) ||
                                  row.bookingId.match(regex) ||
                                  row.customer.name.match(regex) ||
                                  row.customer.mobNo.match(regex) ||
                                  (row.vehicleType &&
                                    row.vehicleType.match(regex)) ||
                                  (row.subType && row.subType.match(regex)) ||
                                  (row.mainAddress.pickupPoint.location &&
                                    row.mainAddress.pickupPoint.location.match(
                                      regex
                                    )) ||
                                  (row.mainAddress.dropPoint.location &&
                                    row.mainAddress.dropPoint.location.match(
                                      regex
                                    )) ||
                                  (row.driverDetails.name &&
                                    row.driverDetails.name.match(regex)) ||
                                  (row.driverDetails.mobNo &&
                                    row.driverDetails.mobNo.match(regex)) ||
                                  row.bookingDate.match(regex)
                                ) {
                                  if (
                                    filterStatus.statusFilter &&
                                    vehiclesTypesFilter.vehicleTypeStatus &&
                                    subTypeFilter.subTypeStatus &&
                                    filterPlaces.placeFilter
                                  ) {
                                    if (
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.vehicleType) &&
                                      allKey.includes(row.subType) &&
                                      allKey.includes(row.place)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterStatus.statusFilter &&
                                    vehiclesTypesFilter.vehicleTypeStatus &&
                                    subTypeFilter.subTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.vehicleType) &&
                                      allKey.includes(row.subType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterPlaces.placeFilter &&
                                    vehiclesTypesFilter.vehicleTypeStatus &&
                                    subTypeFilter.subTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.place) &&
                                      allKey.includes(row.vehicleType) &&
                                      allKey.includes(row.subType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterPlaces.placeFilter &&
                                    filterStatus.statusFilter &&
                                    subTypeFilter.subTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.place) &&
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.subType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterPlaces.placeFilter &&
                                    filterStatus.statusFilter &&
                                    subTypeFilter.vehicleTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.place) &&
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.vehicleType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterStatus.statusFilter &&
                                    vehiclesTypesFilter.vehicleTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.vehicleType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    vehiclesTypesFilter.vehicleTypeStatus &&
                                    filterPlaces.placeFilter
                                  ) {
                                    if (
                                      allKey.includes(row.vehicleType) &&
                                      allKey.includes(row.place)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterPlaces.placeFilter &&
                                    subTypeFilter.subTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.subType) &&
                                      allKey.includes(row.place)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    vehiclesTypesFilter.vehicleTypeStatus &&
                                    subTypeFilter.subTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.vehicleType) &&
                                      allKey.includes(row.subType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterStatus.statusFilter &&
                                    subTypeFilter.subTypeStatus
                                  ) {
                                    if (
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.subType)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    filterStatus.statusFilter &&
                                    filterPlaces.placeFilter
                                  ) {
                                    if (
                                      allKey.includes(row.status) &&
                                      allKey.includes(row.place)
                                    ) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (filterStatus.statusFilter) {
                                    if (allKey.includes(row.status)) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (
                                    vehiclesTypesFilter.vehicleTypeStatus
                                  ) {
                                    if (allKey.includes(row.vehicleType)) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (subTypeFilter.subTypeStatus) {
                                    if (allKey.includes(row.subType)) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else if (filterPlaces.placeFilter) {
                                    if (allKey.includes(row.place)) {
                                      dataPushing(row);
                                      return bookingRecord(row);
                                    }
                                  } else {
                                    return bookingRecord(row);
                                  }
                                }
                              } else {
                                if (
                                  filterStatus.statusFilter &&
                                  vehiclesTypesFilter.vehicleTypeStatus &&
                                  subTypeFilter.subTypeStatus &&
                                  filterPlaces.placeFilter
                                ) {
                                  if (
                                    allKey.includes(row.status) &&
                                    allKey.includes(row.vehicleType) &&
                                    allKey.includes(row.subType) &&
                                    allKey.includes(row.place)
                                  ) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (
                                  filterStatus.statusFilter &&
                                  vehiclesTypesFilter.vehicleTypeStatus &&
                                  subTypeFilter.subTypeStatus
                                ) {
                                  if (
                                    allKey.includes(row.status) &&
                                    allKey.includes(row.vehicleType) &&
                                    allKey.includes(row.subType)
                                  ) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (
                                  filterPlaces.placeFilter &&
                                  vehiclesTypesFilter.vehicleTypeStatus &&
                                  subTypeFilter.subTypeStatus
                                ) {
                                  if (
                                    allKey.includes(row.place) &&
                                    allKey.includes(row.vehicleType) &&
                                    allKey.includes(row.subType)
                                  ) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (
                                  filterPlaces.placeFilter &&
                                  filterStatus.statusFilter &&
                                  subTypeFilter.subTypeStatus
                                ) {
                                  if (
                                    allKey.includes(row.place) &&
                                    allKey.includes(row.status) &&
                                    allKey.includes(row.subType)
                                  ) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (
                                  filterPlaces.placeFilter &&
                                  filterStatus.statusFilter &&
                                  subTypeFilter.vehicleTypeStatus
                                ) {
                                  if (
                                    allKey.includes(row.place) &&
                                    allKey.includes(row.status) &&
                                    allKey.includes(row.vehicleType)
                                  ) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (
                                  filterStatus.statusFilter &&
                                  vehiclesTypesFilter.vehicleTypeStatus
                                ) {
                                  if (
                                    allKey.includes(row.status) &&
                                    allKey.includes(row.vehicleType)
                                  ) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (
                                  vehiclesTypesFilter.vehicleTypeStatus &&
                                  filterPlaces.placeFilter
                                ) {
                                  if (
                                    allKey.includes(row.vehicleType) &&
                                    allKey.includes(row.place)
                                  ) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (
                                  filterPlaces.placeFilter &&
                                  subTypeFilter.subTypeStatus
                                ) {
                                  if (
                                    allKey.includes(row.subType) &&
                                    allKey.includes(row.place)
                                  ) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (
                                  vehiclesTypesFilter.vehicleTypeStatus &&
                                  subTypeFilter.subTypeStatus
                                ) {
                                  if (
                                    allKey.includes(row.vehicleType) &&
                                    allKey.includes(row.subType)
                                  ) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (
                                  filterStatus.statusFilter &&
                                  subTypeFilter.subTypeStatus
                                ) {
                                  if (
                                    allKey.includes(row.status) &&
                                    allKey.includes(row.subType)
                                  ) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (
                                  filterStatus.statusFilter &&
                                  filterPlaces.placeFilter
                                ) {
                                  if (
                                    allKey.includes(row.status) &&
                                    allKey.includes(row.place)
                                  ) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (filterStatus.statusFilter) {
                                  if (allKey.includes(row.status)) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (
                                  vehiclesTypesFilter.vehicleTypeStatus
                                ) {
                                  if (allKey.includes(row.vehicleType)) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (subTypeFilter.subTypeStatus) {
                                  if (allKey.includes(row.subType)) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else if (filterPlaces.placeFilter) {
                                  if (allKey.includes(row.place)) {
                                    dataPushing(row);
                                    return bookingRecord(row);
                                  }
                                } else {
                                  return bookingRecord(row);
                                }
                              }
                            }
                          })}
                        {emptyRows > 0 && (
                          <TableRow
                            style={{

                              height: 53 * emptyRows // (dense ? 33 : 53) * emptyRows,

                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>

                    {customerOrder.length === 0 ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {" "}
                        <h1 style={{ marginTop: "50px", marginRight: "500px" }}>
                          No Data
                        </h1>{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={customerOrder.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Box>
            ) : (
              "loading.."
            )}
          </>
        </Box>
        <BookingsMap
          show={showMap}
          location={location}
          
          onHide={() => setShowMap(false)}
          handleMapClose={handleMapClose}
          driverBookingId={driverBookingId}
          setDriverBookingId={setDriverBookingId}
          setBookingDriverId={setBookingDriverId}
          bookingDriverId={bookingDriverId}
        />
        <UnAssignComment
          onHide={() => setShowUnAssignedCommentForm(false)}
          lrnrbid={lrnrbid}
          show={showUnAssignedCommentForm}
          setShowUnAssignedCommentForm={setShowUnAssignedCommentForm}
          bookingId={bookingId}
          setBookingId={setBookingId}
        />
        <CancelComment
          onHide={() => {
            setShowCancelCommentForm(false);
          }}
          lrnrbid={lrnrbid}
          show={showCancelCommentForm}
          bookingId={bookingId}
          adminName={adminName}
          setBookingId={setBookingId}
          setShowCancelCommentForm={setShowCancelCommentForm}
        />
        <AddCommentForm
          onHide={() => {
            setShowAddCommentForm(false);
          }}
          lrnrbid={lrnrbid}
          show={showAddCommentForm}
          adminName={adminName}
          bookingId={bookingId}
          setBookingId={setBookingId}
          setShowAddCommentForm={setShowAddCommentForm}
          csNotes={csnote}
        />
      </Box>
      <ShowCouponDetils
        handleClose={handleCloseCouponDetails}
        open={openCouponDetails}
        payableAmount={payableAmount}
        nightCharge={nightCharge}
        extraCharge={extraCharge}
        oldAmount={oldChargeAmount}
        couponApplay={couponApplay}
        offPercentage={offPercentage}
        offAmount={offAmount}
      />
      <ManualChangeComment
        status={ManualStatus}
        bookingId={ManualBookingId}
        isOpen={isOpen}
        adminName={adminName}
        handleClose={handleCloseManualComment}
      />
    </>
  );
};

export default BookingList;
