import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AppBar from "@mui/material/AppBar";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import UploadIcon from "@mui/icons-material/Upload";
import { API, LOADRUNNR_SOCKET_API } from "../../../socketConst/BaseApi";
import axios from "axios";
import { Theme } from '@mui/material'
const helperText = makeStyles(() => ({
  root: {
    "& .MuiTextField-root": {
      margin: 1,
      width: 200,
    },
    "& .MuiFormHelperText-root": {
      color: "red !important",
    },
  },
  bg: {
    backgroundColor: "#7986cb",
  },
  textfield: {
    backgroundColor: "#fff",
  },
}));
const AddCoin = ({ close, open, status, setStatus }) => {
  const helperTxtClass = helperText();
  const [bannerPrivew, setBannerprivew] = useState("");
  const [imgErr, setImageErr] = useState(false);
  const [title, setTitle] = useState("");
  const [coin, setCoin] = useState("");
  const [titleErr, setTitleErr] = useState(false);
  const [coinErr, setCoinErr] = useState(false);
  const [ext, setExt] = useState("");
  const imgInputHandle = (e) => {
    setExt(e.target.value.split(".")[1]);
    const file = e.target.files[0];
    previewProfile(file);
  };
  const previewProfile = (file) => {
    setImageErr("");
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBannerprivew(reader.result);
    };
  };
  const handleSubmit = async () => {
    if (!bannerPrivew) setImageErr(true);
    if (!title) setTitleErr(true);
    if (!coin) setCoinErr(true);
    if ((bannerPrivew && title && coin, ext)) {
      const { data } = await axios.post(
        `${API}/api/admin/create-claim-offers`,
        {
          img: bannerPrivew,
          title: title,
          coins: coin,
          ext: ext,
        }
      );
      close();
      setStatus(!status);
    }
  };

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={close}>
        <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
          <DialogTitle sx={{ color: "white" }}>
            <AddIcon /> Add Coins
          </DialogTitle>
        </AppBar>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            {bannerPrivew ? (
              <img width={300} src={bannerPrivew} alt="coin"></img>
            ) : (
              <>
                {imgErr ? (
                  <span style={{ color: "red" }}>
                    𝐈𝐦𝐚𝐠𝐞 𝐫𝐞𝐪𝐮𝐢𝐫𝐞𝐝 𝐏𝐥𝐞𝐚𝐬𝐞 𝐂𝐡𝐨𝐨𝐬𝐞 𝐚𝐧 𝐢𝐦𝐚𝐠𝐞
                  </span>
                ) : (
                  ""
                )}
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            <TextField
              className={helperTxtClass.root}
              helperText={!title && titleErr ? "Field is required" : ""}
              label="𝐓𝐢𝐭𝐥𝐞"
              name="title"
              value={title}
              fullWidth
              sx={{
                width: "400px",
              }}
              type="text"
              onChange={(e) => {
                setTitle(e.target.value);
                setTitleErr(true);
              }}
            />
            <TextField
              className={helperTxtClass.root}
              helperText={!coin && coinErr ? "Field is required" : ""}
              label="𝐂𝐨𝐢𝐧𝐬"
              name="coin"
              value={coin}
              fullWidth
              sx={{
                width: "400px",
              }}
              type="number"
              onChange={(e) => {
                setCoin(e.target.value);
                setCoinErr(true);
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            <input
              name="addImage"
              onChange={imgInputHandle}
              type="file"
              id="image-coin"
              accept="image/*"
              hidden
            />
            <label htmlFor="image-coin">
              <span
                style={{
                  border: "solid 3px #e75c05",
                  padding: "10px",
                  borderRadius: "10px",
                  fontWeight: "bold",
                  color: "#e75c05",
                }}
              >
                Choose image <UploadIcon sx={{ marginLeft: "10px" }} />
              </span>
            </label>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddCoin;
