import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/SubAdmin/sidebar/SideBar";
import Loader from "../../Components/Loader";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InfoIcon from "@mui/icons-material/Info";
import BookingAddCommentForm from "../../Components/SubAdmin/Booking/BookingAddComment";
import {
  StatusDrowpDown,
  PlaceDrowpDown,
  VehicleTypeDrowpDown,
  VehicleSubTypeDrowpDown,
  FromToDateFilter,
  SearchFilter
} from "../../Components/Admin/BookingsTableHelpers/Filters";

import { BookingTableHead } from "../../Components/Admin/BookingsTableHelpers/TableHeadings";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import io from "socket.io-client";
import { LOADRUNNR_SOCKET_API } from "../../socketConst/BaseApi";
import { ButtonGroup, Chip, Paper } from "@mui/material";
import {
  getBookingsAction,
  refreshBookingAction,
} from "../../Actions/AdminActions";

import CancelComment from "../../Components/Admin/BookkingCommentForm.js/CancelComment";


import "moment-timezone";
import BookingsMap from "../../Components/Admin/Map/BookingsMap";
import Timer from "../../Components/Admin/timer/Timer";
import ShowCouponDetils from "../../Components/Admin/coupon-details/CouponDetails";
import ManualChangeComment from "../../Components/Admin/ManualChangeComment";

var data = [];

const fileName = "download";
const exportType = "xls";


BookingTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ManageBookings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const subAdminLogin = useSelector((state) => state.subAdminLogin);
  const { subAdminInfo } = subAdminLogin;
  // menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // end menu
  // wallt modal

  const bookings = useSelector((state) => state.bookings);
  const { loading, bookingsInfo } = bookings;

  const { sucess, drvId, newBooking, statusChange } = useSelector(
    (state) => state.getNewOrder
  );

  
  const [ManualBookingId, setManualBookingId] = useState("");
  const [ManualStatus, setManualStatus] = useState("");
  const [isOpen, setisOpen] = React.useState(false);
  const [adminName, setAdminName] = useState("");

  const ChangeStatus = (bookingId, status, adName) => {
    setManualBookingId(bookingId);
    setManualStatus(status);
    setAdminName(adName);
    setisOpen(true);
  };

  const handleCloseManualComment = () => {
    setisOpen(false);
  };
  
  // end wallet

  //
  //  const [dense, setDense] = React.useState(false);

  const [idForCouponDetails, setIdForCouponDetails] = React.useState("");

  const [bookingId, setBookingId] = useState("");
  const [showAddCommentForm, setShowAddCommentForm] = useState(false);
  const [showCancelCommentForm, setShowCancelCommentForm] = useState(false);
  const [showUnAssignedCommentForm, setShowUnAssignedCommentForm] =
    useState(false);
  const [csnote, setCsnotes] = useState([]);
  const [lrnrbid, setLrnrbid] = useState("");

  // map
  const [showMap, setShowMap] = useState(false);
  const { commentSuccess } = useSelector((state) => state.bookingAddComment);
  const adminBookingUpdate = useSelector((state) => state.adminBookingUpdate);
  const { success: updateSuccess } = adminBookingUpdate;

  const filterStatus = useSelector((state) => state.bookingStausFilter);
  const filterPlaces = useSelector((state) => state.bookingPlaceFilter);
  const vehiclesTypesFilter = useSelector(
    (state) => state.bookingVehicleTypeFilter
  );
  const subTypeFilter = useSelector((state) => state.bookingSubTypeFilter);
  const bookingSearch = useSelector((state) => state.bookingSearch);
  const { content } = bookingSearch;

  const { statusDateFilter, from, to } = useSelector(
    (state) => state.bookingDateFilter
  );
  let allKey = filterStatus.filters
    .concat(vehiclesTypesFilter.vehicleTypefilters)
    .concat(subTypeFilter.subTypefilters)
    .concat(filterPlaces.filters);
  const [openCouponDetails, setOpenCoupounDetails] = React.useState(false);
  const [payableAmount, setPayableAmount] = React.useState("");
  const [extraCharge, setExtraCharge] = React.useState("");
  const [oldChargeAmount, setOldAmount] = React.useState("");
  const [nightCharge, setNightCharge] = React.useState("");
  const [couponApplay, setCouponApplay] = React.useState(false);
  const [offPercentage, setOffPercentage] = React.useState("");
  const [offAmount, setOffAmount] = React.useState("");
  const [oldDriverDetails, setOldDriverDetails] = React.useState([]);
  const [rows, setRows] = React.useState(10);
  const [skip, setSkip] = React.useState(0);

  const handleRowsCount = (event) => {
    setRows(event.target.value);
  };



 

  const handlePrev = () => {
    if (skip - rows < 0) {
      setSkip(0);
      dispatch(
        getBookingsAction({
          rows,
          skip,
          content,
          status_filter: filterStatus.filters,
          vehicleType: vehiclesTypesFilter.vehicleTypefilters,
          subType: subTypeFilter.subTypefilters,
          places: filterPlaces.filters,  from, to
        })
      );
    } else {
      setSkip(skip - rows);
      dispatch(
        getBookingsAction({
          rows,
          skip,
          content,
          status_filter: filterStatus.filters,
          vehicleType: vehiclesTypesFilter.vehicleTypefilters,
          subType: subTypeFilter.subTypefilters,
          places: filterPlaces.filters,  from, to
        })
      );
    }
  };
  const handleNext = () => {
    if (skip < 1) {
      setSkip(rows);
      dispatch(
        getBookingsAction({
          rows,
          skip,
          content,
          status_filter: filterStatus.filters,
          vehicleType: vehiclesTypesFilter.vehicleTypefilters,
          subType: subTypeFilter.subTypefilters,
          places: filterPlaces.filters,  from, to
        })
      );
    } else {
      setSkip(skip + rows);

      dispatch(
        getBookingsAction({
          rows,
          skip,
          content,
          status_filter: filterStatus.filters,
          vehicleType: vehiclesTypesFilter.vehicleTypefilters,
          subType: subTypeFilter.subTypefilters,
          places: filterPlaces.filters,  from, to
        })
      );
    }
  };

  useEffect(() => {
    if (!subAdminInfo) {
      navigate("/sub-admin/login");
    }
    dispatch(
      getBookingsAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.filters,
        vehicleType: vehiclesTypesFilter.vehicleTypefilters,
        subType: subTypeFilter.subTypefilters,
        places: filterPlaces.filters,  from, to
      })
    );
  }, [subAdminInfo, updateSuccess, commentSuccess, dispatch, navigate, rows,content,from, to,statusDateFilter]);

  const handleMapClose = () => {
    setShowMap(false);
  };

  const couponDetails = (
    id,
    type,
    amount,
    extraCharge,
    oldAmount,
    nightCharge,
    couponApplay
  ) => {
    setIdForCouponDetails(id);
    setExtraCharge(extraCharge);
    if (type === "Cash") {
      setPayableAmount(`₹${amount}`);
    } else {
      setPayableAmount(`👜 ${amount}`);
    }

    setOldAmount(oldAmount);
    setNightCharge(nightCharge);
    setCouponApplay(couponApplay);

    setOpenCoupounDetails(true);
  };
  const handleCloseCouponDetails = () => {
    setOpenCoupounDetails(false);
  };

  const [subAdminName, setSubAdminName] = useState("");
  const handleAddComment = (id, bid, sbName) => {
    if (bookingsInfo) {
      var bookingData = bookingsInfo.filter((el) => {
        return el._id === id;
      });
      setCsnotes(bookingData[0].csNotes);
      setBookingId(id);
      setLrnrbid(bid);
      setSubAdminName(sbName);
      setShowAddCommentForm(true);
    }
  };


 

  bookingsInfo &&
    bookingsInfo.map((row) => {
      if (bookingsInfo.length === data.length) {
        return data;
      } else {
        data.push({
          ID: row.bookingId,
          Status: row.status,
          "Customer Name": row.customer.name,
          "Customer Phone Number": row.customer.mobNo,
          "Vehicle Type": row.vehicleType,
          "Sub Type": row.subType,
          "Pickup Point": row.mainAddress.pickupPoint.location,
          "Stop 1": row.address2.location ? row.address2.location : "",
          "Stop 2": row.address2.location ? row.address2.location : "",
          "Drop Point": row.mainAddress.dropPoint.location,
          Cash: row.paymentDetails.cash ? row.paymentDetails.cash : "",
          Wallet: row.paymentDetails.wallet ? row.paymentDetails.wallet : "",
          "Driver Name": row.driverDetails.name ? row.driverDetails.name : "",
          "Driver Phone Number": row.driverDetails.mobNo
            ? row.driverDetails.mobNo
            : "",
          "Booking Date": row.bookingDate,
        });
      }
    });
  filterStatus.statusFilter && (data = []);
  vehiclesTypesFilter.vehicleTypeStatus && (data = []);
  subTypeFilter.subTypeStatus && (data = []);
  content && (data = []);
  const [location, setLocation] = useState({
    pickupLocation: "",
    pickuplog: 0,
    pickuplat: 0,
    pickupName: "",
    pickupPhoneNumber: "",
    address2Location: "",
    address2log: 0,
    address2lat: 0,
    address2Name: "",
    address2PhoneNumber: "",
    address3Location: "",
    address3log: 0,
    address3lat: 0,
    address3Name: "",
    address3PhoneNumber: "",
    dropLocation: "",
    dropLat: 0,
    dropLog: 0,
    dropName: "",
    dropPhoneNumber: "",
  });

  const [driverBookingId, setDriverBookingId] = useState("");
  const [bookingDriverId, setBookingDriverId] = useState("");
  const [bookingStatus, setBookingStatus] = useState("");
  
  useEffect(() => {
    const socket = io(LOADRUNNR_SOCKET_API, {
      transports: ["websocket", "polling"],
    });
    socket.on("removeOrderForDriver", () => {
     
      dispatch(refreshBookingAction({
          rows,
          skip,
          content,
          status_filter: filterStatus.filters,
          vehicleType: vehiclesTypesFilter.vehicleTypefilters,
          subType: subTypeFilter.subTypefilters,
          places: filterPlaces.filters,
          from, to
        }));
    });
    socket.on("newOrder", (data) => {
      dispatch(refreshBookingAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.filters,
        vehicleType: vehiclesTypesFilter.vehicleTypefilters,
        subType: subTypeFilter.subTypefilters,
        places: filterPlaces.filters,  from, to
      }));
    });
    socket.on("updateBooking", (updatedData) => {
      dispatch(refreshBookingAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.filters,
        vehicleType: vehiclesTypesFilter.vehicleTypefilters,
        subType: subTypeFilter.subTypefilters,
        places: filterPlaces.filters,  from, to
      }));
    });
    socket.on("updateBookingForAdmin", () => {
      dispatch(refreshBookingAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.filters,
        vehicleType: vehiclesTypesFilter.vehicleTypefilters,
        subType: subTypeFilter.subTypefilters,
        places: filterPlaces.filters,  from, to
      }));
    });
    socket.on("orderCompleted", () => {
      dispatch(refreshBookingAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.filters,
        vehicleType: vehiclesTypesFilter.vehicleTypefilters,
        subType: subTypeFilter.subTypefilters,
        places: filterPlaces.filters,  from, to
      }));
    });
    socket.on("adminOrderCompleted", (data) => {
      socket.emit("orderFinish", data);
    });
    return () => {
      socket.close();
    };
  }, []);
  useEffect(() => {
    if (sucess && newBooking && bookingsInfo) {
      if (sucess && bookingsInfo) {
        for (let index = 0; index < bookingsInfo.length; index++) {
          if (bookingsInfo[index]._id === newBooking._id) {
            bookingsInfo[index] = newBooking;
            break;
          } else if (newBooking.status == "assigning") {
            bookingsInfo.unshift(newBooking);
            break;
          }
        }
      } else {
        // console.log("no data", sucess);
      }
    }
  }, [sucess, drvId, statusChange]);
  
  const handleShowMap = (
    pickupLocation,
    pickuplog,
    pickuplat,
    pickupName,
    pickupPhoneNumber,
    address2Location,
    address2log,
    address2lat,
    address2Name,
    address2PhoneNumber,
    address3Location,
    address3log,
    address3lat,
    address3Name,
    address3PhoneNumber,
    dropLocation,
    dropLog,
    dropLat,
    dropName,
    dropPhoneNumber,
    id,
    driverId,
    status
  ) => {
    setBookingStatus(status);
    setBookingDriverId(driverId);
    setDriverBookingId(id);
    setLocation({
      pickupLocation: pickupLocation,
      pickuplog: pickuplog,
      pickuplat: pickuplat,
      pickupName: pickupName,
      pickupPhoneNumber: pickupPhoneNumber,
      address2Location: address2Location,
      address2log: address2log,
      address2lat: address2lat,
      address2Name: address2Name,
      address2PhoneNumber: address2PhoneNumber,
      address3Location: address3Location,
      address3log: address3log,
      address3lat: address3lat,
      address3Name: address3Name,
      address3PhoneNumber: address3PhoneNumber,
      dropLocation: dropLocation,
      dropLat: dropLat,
      dropLog: dropLog,
      dropName: dropName,
      dropPhoneNumber: dropPhoneNumber,
    });
    setShowMap(true);
  };
  let ManualStatusChange = (row) => {
    if (row.status === "Assigned") {
      return (
        <Button
          onClick={() => {
            let sbName = `${subAdminInfo.firstName} ${subAdminInfo.lastName}`;
            ChangeStatus(row.Id, "ReachPickupPoint", sbName);
          }}
          variant="contained"
        >
          Reached Pickup Point
        </Button>
      );
    } else if (
      row.stops &&
      row.stops.total === 3 &&
      row.status === "Confirming"
    ) {
      return (
        <Button
          onClick={() => {
            let sbName = `${subAdminInfo.firstName} ${subAdminInfo.lastName}`;
            ChangeStatus(row.Id, "Ongoing", sbName);
          }}
          variant="contained"
        >
          Ongoing
        </Button>
      );
    } else if (
      row.stops &&
      row.stops.total === 3 &&
      row.status === "Ongoing" &&
      row.trakingStatus === "stop1Completed"
    ) {
      return (
        <Button
          onClick={() => {
            let sbName = `${subAdminInfo.firstName} ${subAdminInfo.lastName}`;
            ChangeStatus(row.Id, "Complete", sbName);
          }}
          variant="contained"
        >
          Complete
        </Button>
      );
    } else if (
      row.stops &&
      row.stops.total === 3 &&
      row.status === "Ongoing" &&
      row.trakingStatus === "Confirming"
    ) {
      return (
        <Button
          onClick={() => {
            let sbName = `${subAdminInfo.firstName} ${subAdminInfo.lastName}`;
            ChangeStatus(row.Id, "stop1Completed", sbName);
          }}
          variant="contained"
        >
          Goto last stop
        </Button>
      );
    } else if (
      row.stops &&
      row.stops.total === 3 &&
      row.status === "Ongoing" &&
      row.trakingStatus === "stop1Available"
    ) {
      return (
        <Button
          onClick={() => {
            let sbName = `${subAdminInfo.firstName} ${subAdminInfo.lastName}`;
            ChangeStatus(row.Id, "Reached_Stop_1", sbName);
          }}
          variant="contained"
        >
          Reached Stop 1
        </Button>
      );
    } else if (
      row.stops &&
      row.stops.total === 2 &&
      row.status === "Confirming"
    ) {
      return (
        <Button
          onClick={() => {
            let sbName = `${subAdminInfo.firstName} ${subAdminInfo.lastName}`;
            ChangeStatus(row.Id, "Ongoing", sbName);
          }}
          variant="contained"
        >
          Ongoing
        </Button>
      );
    } else if (row.stops && row.stops.total === 2 && row.status === "Ongoing") {
      return (
        <Button
          onClick={() => {
            let sbName = `${subAdminInfo.firstName} ${subAdminInfo.lastName}`;
            ChangeStatus(row.Id, "Complete", sbName);
          }}
          variant="contained"
        >
          Complete
        </Button>
      );
    } else {
      return <Button variant="outlined">No_Action</Button>;
    }
  };
  let bookingRecord = (row, i) => {
    return (
      <>
        <TableRow hover key={row._id}>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <span>{i + 1}</span>
          </TableCell>
          <TableCell>
            <Button
              sx={{
                backgroundColor: "blue",
                width: "100px",
              }}
              variant="contained"
            >
              {row.status}
            </Button>
            {row.status === "Assigning" ? (
              <div align="center">
                <Timer
                  timer={row.timer}
                  count={row.timer}
                  startingTime={row.reassinging ? row.updatedAt : row.createdAt}
                />
              </div>
            ) : (
              <div align="center">
                {row.status === "Expired" ? (
                  <Chip
                    sx={{
                      mt: 2,
                      width: 100,
                      height: 40,
                      fontSize: 18,
                      borderRadius: "5px",
                    }}
                    label="5:00"
                    color="error"
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </TableCell>
          <TableCell>{row.bookingId}</TableCell>
          <TableCell>{row.place}</TableCell>
          <TableCell>
            <p>{row.customer.name}</p>
            <p>{row.customer.mobNo}</p>
          </TableCell>
          <TableCell>{row.vehicleType}</TableCell>

          <TableCell>{row.subType}</TableCell>

          <TableCell
            style={
              row.rent
                ? { backgroundColor: "#fff" }
                : { backgroundColor: "#d9ebfc" }
            }
            onClick={() =>
              row.rent
                ? null
                : handleShowMap(
                    row.mainAddress.pickupPoint.location,
                    row.mainAddress.pickupPoint.lan,
                    row.mainAddress.pickupPoint.lat,
                    row.mainAddress.pickupPoint.name,
                    row.mainAddress.pickupPoint.phoneNumber,
                    row.address2.location,
                    row.address2.lan,
                    row.address2.lat,
                    row.address2.name,
                    row.address2.phoneNumber,
                    row.address3.location,
                    row.address3.lan,
                    row.address3.lat,
                    row.address3.name,
                    row.address3.phoneNumber,
                    row.mainAddress.dropPoint.location,
                    row.mainAddress.dropPoint.lan,
                    row.mainAddress.dropPoint.lat,
                    row.mainAddress.dropPoint.name,
                    row.mainAddress.dropPoint.phoneNumber,
                    row._id,
                    row.driverId,
                    row.status
                  )
            }
          >
            <p>{row.mainAddress.pickupPoint.location}</p>
            {!row.address3.location && row.address2.location ? (
              <p
                style={{
                  backgroundColor: "orange",
                  borderRadius: "10px",
                  width: "25px",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                }}
              >
                1
              </p>
            ) : (
              ""
            )}
            {row.address2.location && row.address3.location ? <p>2</p> : ""}
            <p>{row.mainAddress.dropPoint.location}</p>
          </TableCell>
          <TableCell>
            <Button
              onClick={() => {
                couponDetails(
                  row._id,
                  row.paymentDetails.paymentType,
                  row.paymentDetails.amount,
                  row.paymentDetails.extraCharge,
                  row.paymentDetails.oldAmount,
                  row.paymentDetails.nightSurge,
                  row.appliedCoupon
                );
                if (row.appliedCoupon) {
                  setOffPercentage(row.promoCodeDetails.off);
                  setOffAmount(row.promoCodeDetails.discount);
                }
              }}
              sx={{ width: 110 }}
              variant="outlined"
              endIcon={<InfoIcon />}
            >
              {row.paymentDetails.paymentType === "Cash"
                ? `₹${row.paymentDetails.amount.toFixed(0)}`
                : `👜${row.paymentDetails.amount.toFixed(0)}`}
            </Button>
          </TableCell>
          <TableCell>
            <p>{row.driverDetails.name}</p>
            <p>{row.driverDetails.mobNo}</p>
          </TableCell>
          <TableCell>{row.bookingDate}</TableCell>
          {row.status === "Completed" ||
          row.status === "Cancel" ||
          row.status === "Expired" ? ( //||
            // row.status === "Assigning"
            <TableCell>
              {" "}
              <Button variant="outlined">No_Action</Button>{" "}
            </TableCell>
          ) : (
            <TableCell>
              {row.status === "Cancel" ? (
                ""
              ) : (
                <>
                  <Button
                    sx={{
                      backgroundColor: "#e75c05",
                      width: "100px",
                    }}
                    variant="contained"
                    onClick={() => {
                      setBookingId(row.Id);
                      setLrnrbid(row.bookingId);
                      setShowUnAssignedCommentForm(true);
                    }}
                  >
                    unassigin
                  </Button>
                  <br />
                </>
              )}
              <Button
                sx={{
                  backgroundColor: "red",
                  width: "100px",
                  marginTop: "4px",
                }}
                variant="contained"
                onClick={() => {
                  setBookingId(row.Id);
                  setLrnrbid(row.bookingId);
                  setShowCancelCommentForm(true);
                  let sbName = `${subAdminInfo.firstName} ${subAdminInfo.lastName}`;
                  setAdminName(sbName);
                }}
              >
                cancel
              </Button>
            </TableCell>
          )}
          <TableCell>
            {/* {row.status === "Completed" ||
            row.status === "Cancel" ||
            row.status === "Expired" ? (
              <Button variant="outlined">No_Action</Button>
            ) : ( */}
            {ManualStatusChange(row)}
            {/* // )} */}
          </TableCell>
          <TableCell>
            {row.csNotes &&
            row.csNotes[row.csNotes.length - 1] &&
            row.csNotes[row.csNotes.length - 1].comment ? (
              <p>
                {row.csNotes &&
                  row.csNotes[row.csNotes.length - 1] &&
                  row.csNotes[row.csNotes.length - 1].comment &&
                  row.csNotes[row.csNotes.length - 1].comment.split(")")[0] ==
                    "cancel :" && (
                    <span
                      style={{
                        fontWeight: "bold",
                        background: "red",
                        color: "white",
                      }}
                    >
                      {row.csNotes[row.csNotes.length - 1].comment.split(
                        ")"
                      )[0] &&
                        row.csNotes[row.csNotes.length - 1].comment.split(
                          ")"
                        )[0]}
                    </span>
                  )}

                {row.csNotes &&
                  row.csNotes[row.csNotes.length - 1] &&
                  row.csNotes[row.csNotes.length - 1].comment &&
                  row.csNotes[row.csNotes.length - 1].comment.split(")")[0] ==
                    "unAssign :" && (
                    <span
                      style={{ fontWeight: "bold", background: "yellowgreen" }}
                    >
                      {row.csNotes[row.csNotes.length - 1].comment.split(
                        ")"
                      )[0] &&
                        row.csNotes[row.csNotes.length - 1].comment.split(
                          ")"
                        )[0]}
                    </span>
                  )}

                {row.csNotes &&
                row.csNotes[row.csNotes.length - 1] &&
                row.csNotes[row.csNotes.length - 1].comment &&
                row.csNotes[row.csNotes.length - 1].comment.split(")")[1]
                  ? row.csNotes[row.csNotes.length - 1].comment.split(")")[1]
                  : row.csNotes[row.csNotes.length - 1].comment}
              </p>
            ) : (
              ""
            )}
            <Button
              sx={{
                backgroundColor: "red",
                width: "100px",
                marginTop: "4px",
              }}
              variant="contained"
              onClick={() => {
                let sbName = `${subAdminInfo.firstName} ${subAdminInfo.lastName}`;
                handleAddComment(row._id, row.bookingId, sbName);
              }}
            >
              Add Comment
            </Button>
          </TableCell>
        </TableRow>
      </>
    );
  };
  // const { placeFilter } = filterPlaces;
  // const { vehicleTypeStatus } = vehiclesTypesFilter;
  // const { statusFilter } = filterStatus;
  const submitFilter = () => {
    setSkip(0);
    dispatch(
      getBookingsAction({
        rows,
        skip: 0,
        content,
        status_filter: filterStatus.filters,
        vehicleType: vehiclesTypesFilter.vehicleTypefilters,
        subType: subTypeFilter.subTypefilters,
        places: filterPlaces.filters,  from, to
      })
    );
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
      <Sidebar
          ActiveTab="Bookings"
          id={subAdminInfo && subAdminInfo._id}
          email={subAdminInfo && subAdminInfo.email}
          name={subAdminInfo && `${subAdminInfo.firstName} ${subAdminInfo.lastName}`}
          token={subAdminInfo && subAdminInfo.token}
        />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <>
            <Toolbar />

            <h5 style={{ display: "block", color: "green" }}>Bookings</h5>
            <Typography
              sx={{ fontSize: "15px", fontWeight: "bold" }}
              component="span"
            >
              Filter By:
            </Typography>
            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
              <PlaceDrowpDown style={{ marginTop: "30px" }} />
              <StatusDrowpDown style={{ marginTop: "30px" }} />
              <VehicleTypeDrowpDown style={{ marginTop: "30px" }} />
             
              <VehicleSubTypeDrowpDown style={{ marginTop: "30px" }} />
              <Button onClick={submitFilter} variant="outlined">
                submit filter
              </Button>
              <span style={{ position: "relative", top: 9 }}>
                <FromToDateFilter />
              </span>
              <span style={{ marginLeft: "20px" }}>
                <SearchFilter />
              </span>

           
            </div>

            {!loading && bookingsInfo ? (
              <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2 }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      //={dense ? "small" : "medium"}
                    >
                      <BookingTableHead />

                      <TableBody>
                        {bookingsInfo.map((row, i) => {
                       
                          return bookingRecord(row, i);
                        })}
                      </TableBody>
                    </Table>

                    
                  </TableContainer>
                </Paper>
                <ButtonGroup
                  disableElevation
                  variant="outlined"
                  aria-label="Disabled elevation buttons"
                  sx={{ mt: 2 }}
                >
                  <Button onClick={handlePrev}>
                    <ArrowBackIosIcon />
                  </Button>
                  <Button onClick={handleNext}>
                    <ArrowForwardIosIcon />
                  </Button>
                </ButtonGroup>
                <FormControl sx={{ minWidth: 80, ml: 2, mt: 2 }} size="small">
                  <InputLabel id="demo-select-small">Rows </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={rows}
                    label="Rows"
                    onChange={handleRowsCount}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            ) : (
              <Loader loading={true} />
            )}
          </>
        </Box>
        <BookingsMap
          show={showMap}
          location={location}
          onHide={() => setShowMap(false)}
          handleMapClose={handleMapClose}
          driverBookingId={driverBookingId}
          setDriverBookingId={setDriverBookingId}
          setBookingDriverId={setBookingDriverId}
          bookingDriverId={bookingDriverId}
        />
         <CancelComment 
          onHide={() => {
            setShowCancelCommentForm(false);
          }}
          lrnrbid={lrnrbid}
          show={showCancelCommentForm}
          bookingId={bookingId}
          adminName={subAdminName}
          setBookingId={setBookingId}
          setShowCancelCommentForm={setShowCancelCommentForm}
        />
        <ShowCouponDetils
          handleClose={handleCloseCouponDetails}
          open={openCouponDetails}
          payableAmount={payableAmount}
          nightCharge={nightCharge}
          extraCharge={extraCharge}
          oldAmount={oldChargeAmount}
          couponApplay={couponApplay}
          offPercentage={offPercentage}
          offAmount={offAmount}
        />
        <BookingAddCommentForm
          onHide={() => {
            setShowAddCommentForm(false);
          }}
          lrnrbid={lrnrbid}
          show={showAddCommentForm}
          subAdminName={subAdminName}
          bookingId={bookingId}
          setBookingId={setBookingId}
          setShowAddCommentForm={setShowAddCommentForm}
          csNotes={csnote}
        />
        <ManualChangeComment
          status={ManualStatus}
          bookingId={ManualBookingId}
          isOpen={isOpen}
          adminName={subAdminName}
          handleClose={handleCloseManualComment}
        />
      </Box>
    </>
  );
};

export default ManageBookings;
