import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  addWalletAction,
  addDriverWalletAction,
} from "../../../Actions/AdminActions";
import "./css/style.css";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  DialogActions,
  AppBar,
  TextField,
  TextareaAutosize,
} from "@mui/material";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
const danger = { mr: "5px", border: "solid 1px red" };
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: 2,
  },
  "& .MuiDialogActions-root": {
    padding: 1,
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddWalletFrom = ({  
  title,
  isOpen,
  handleClose,
  walletFor,
  driverId,
  costomId,
  customerId
}) => {
  const dispatch = useDispatch();

  const [amount, setAmount] = useState("");
  const [discription, setDiscription] = useState("");
  const [amountErr, setAmountErr] = useState(false);
  const [discriptionErr, setDiscriptionErr] = useState(false);

  const submit = () => {
    let error = false;
    if (amount === "") {
      setAmountErr(true);

      error = true;
    } else {
      setAmountErr(false);
    }
    if (discription === "") {
      error = true;
      setDiscriptionErr(true);
    } else {
      setDiscriptionErr(false);
    }
    if (walletFor === "driver") {
      if (!error) {
        dispatch(addDriverWalletAction(driverId, amount, discription));
        setAmount("");
        setDiscription("");
        handleClose();
      }
    } else {
      if (!error) {
        dispatch(addWalletAction(customerId, amount, discription));
        setAmount("");
        setDiscription("");
        handleClose();
      }
    }
  };

  const handleInput = (e) => {
    const name = e.target.name;
    if (name === "amount") {
      setAmount(e.target.value);
      setAmountErr(false);
    } else if (name === "description") {
      setDiscription(e.target.value);
      setDiscriptionErr(false);
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <BootstrapDialogTitle id="customized-dialog-title">
          <AppBar
            sx={{
              position: "relative",
              height: "50px",
              backgroundColor: "#e75c05",
              display: "flex",
              justifyContent: "center",
              pl: "20px",
            }}
          >
            {title + " ID"} : {costomId}
          </AppBar>
        </BootstrapDialogTitle>
        <DialogContent sx={{ width: "600px", height: "200px" }} dividers>
          <div
            style={{
              padding: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TextField
              onChange={handleInput}
              name="amount"
              value={amount}
              sx={amountErr ? danger : { mr: "5px" }}
              label="Amount"
              type="number"
              id="fullWidth"
            />

            <TextField
              onChange={handleInput}
              sx={discriptionErr ? danger : { mr: "5px" }}
              name="description"
              value={discription}
              fullWidth
              label="Discription"
              id="fullWidth"
            />
           
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={submit}
            variant="contained"
            style={{ backgroundColor: "#e75c05", color: "#fff" }}
          >
            add
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "red", color: "#fff" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
       
      </Dialog>
    </div>
  );


};

export default AddWalletFrom;
