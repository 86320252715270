import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import MyLocationSharpIcon from '@mui/icons-material/MyLocationSharp';
import { searchAction } from "../../../Actions/AdminActions";
import ClearIcon from '@mui/icons-material/Clear';

const  SearchLocation =({value,handleInput,ready,clearInput}) => {
  return (
    <Paper
      variant="outlined" square
      sx={{ display: "flex", alignItems: "center", width: "100%", height:50, marginTop:1 }} //, width: 400
    >
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton sx={{ p: "10px" }} aria-label="menu" color="primary">
        <MyLocationSharpIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Location"
        inputProps={{ "aria-label": "search google maps" }}
        value={value}
        onChange={handleInput}
        disabled={!ready}
      />
      <IconButton onClick={clearInput}  sx={{ p: "10px" }} aria-label="search">
        <ClearIcon />
      </IconButton>
       {/* <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
        <DirectionsIcon />
      </IconButton> */}
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      
      
     
    </Paper>
  );
}

const  FilterSearchDriver =({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const [content, setContent] = useState();
  useEffect(() => {
    dispatch(searchAction(content));
  }, [content]);
  let { refresh } = useSelector((state) => state.refresh);
  useEffect(()=>{
    setContent("")
  },[refresh])
  return (
    <>
    <Paper
      variant="outlined" square
      sx={{ display: "inline-block",  width: 250, height:40, marginTop:1,borderRadius:1  }} // width: 400
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search Driver"
        inputProps={{ "aria-label": "search google maps" }}
        value={content}
        onChange={(e) => {
          setContent(e.target.value.trimStart());
        }}   
      />
      <IconButton onClick={()=>{
        setContent("")
      }}  aria-label="search">
        <ClearIcon />
      </IconButton>
    </Paper>
      
    </>
  );
}

export {
  SearchLocation,
  FilterSearchDriver
};