import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AppBar from "@mui/material/AppBar";
import InfoIcon from "@mui/icons-material/Info";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import MoneyIcon from "@mui/icons-material/Money";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import ModeNightIcon from "@mui/icons-material/ModeNight";
const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const ShowCouponDetils = ({
  handleClose,
  open,
  payableAmount,
  extraCharge,
  oldAmount,
  nightCharge,
  couponApplay,
  offPercentage,
  offAmount
}) => {
  
 
  let close = () => {
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={handleClose}>
        <AppBar position="static" sx={{ backgroundColor: "green" }}>
          <DialogTitle sx={{ color: "white" }}>
            <InfoIcon /> Amount Details
            <span style={{ marginLeft: "20px" }}></span>
          </DialogTitle>
        </AppBar>
        <DialogContent>
          <Box
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            <nav aria-label="main mailbox folders">
              <List>
                <ListItem>
                  <ListItemButton sx={{ width: "250px" }}>
                    <ListItemIcon>
                      <CurrencyRupeeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Total Amount" />
                  </ListItemButton>
                  <ListItemText primary={`₹${oldAmount-(extraCharge+nightCharge)}`} />
                </ListItem>
                <ListItem>
                  <ListItemButton sx={{ width: "250px" }}>
                    <ListItemIcon>
                      <MoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Extra Charges" />
                  </ListItemButton>
                  <ListItemText primary={`₹${extraCharge}`} />
                </ListItem>
                <ListItem>
                  <ListItemButton sx={{ width: "250px" }}>
                    <ListItemIcon>
                      <ModeNightIcon />
                    </ListItemIcon>
                    <ListItemText primary="Night Charges" />
                  </ListItemButton>
                  <ListItemText
                    primary={nightCharge === null ? "₹0" : `₹${nightCharge}`}
                  />
                </ListItem>
                {
                  couponApplay ? <ListItem>
                  <ListItemButton sx={{ width: "250px" }}>
                    <ListItemIcon>
                      <LocalOfferIcon />
                    </ListItemIcon>
                    <ListItemText primary={`Applied Coupon (${offPercentage}%)`} />
                  </ListItemButton>
                  <ListItemText primary={`₹${offAmount}`} />
                </ListItem> : ""
                }
                
              </List>
            </nav>
            <Divider sx={{ color: "red" }} />
            <nav aria-label="secondary mailbox folders">
              <List>
                <ListItem sx={{ color: "green" }}>
                  <ListItemButton sx={{ width: "250px" }}>
                    <ListItemIcon>
                      <CurrencyRupeeIcon sx={{ color: "green" }} />
                    </ListItemIcon>
                    <ListItemText primary="Payable Amount" />
                  </ListItemButton>
                  <ListItemText primary={`${payableAmount}`} />
                </ListItem>
              </List>
            </nav>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ShowCouponDetils;
