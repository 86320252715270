import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import {
  Chip,
  DialogContentText,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { API } from "../../../socketConst/BaseApi";
import { el } from "date-fns/locale";
var rejedtedDocsArray = [];
var dataNotUpdated = [];
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function Rejection({
  open,
  handleClose,
  title,
  type,
  token,
  data,
  id,
  setLeft,
  left,
}) {
  const [checked, setChecked] = React.useState([]);

  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  //  const [field,setField]=useState("")
  //  const [remark,setRemark]=useState("")
  //  const [Rtype,setRtype]=useState("")

  const [err, setErr] = useState(false);
  const handleCloseErr = () => {
    setErr(false);
  };
  const [profileImg, setProfileImg] = useState("");
  const [profileImgRemark, setProImgRemark] = useState(""); //1

  const [firstName, setFirstName] = useState("");
  const [firstNameRemark, setFirstNameRemark] = useState(""); //2

  const [lastName, setLastName] = useState("");
  const [LastNameRemark, setLastNameRemark] = useState(""); // 3

  const [defaultNo, setDfNo] = useState("");
  const [defaultNoRemark, setdefaultNoRemark] = useState(""); //4

  const [alNo, setAlNo] = useState("");
  const [alNoRemark, setAlNoRemark] = useState(""); // 5

  const [emNo, setEmNo] = useState("");
  const [emNoRemark, setEmNoRemark] = useState(""); //6

  const [city, setCity] = useState("");
  const [cityRemark, setCityRemark] = useState(""); //7

  const [locality, setLocality] = useState("");
  const [localityRemark, setLocalityRemark] = useState(""); // 8

  const [vhNo, setVhNo] = useState("");
  const [vhNoRemark, setVhNoRemark] = useState(""); //9

  const [vhType, setVhType] = useState("");
  const [vhTypeRemark, setVhTypeRemark] = useState(""); //10

  const [vhSubType, setVhSubType] = useState("");
  const [vhSubTypeRemark, setVhSubTypeRemark] = useState(""); //11

  const [aadharFrontImg, setAadharFrontImage] = useState("");
  const [aadharFrontImgRemark, setAadharFrontImageRemark] = useState(""); // 12

  const [aadharBackImg, setAadharBackImage] = useState("");
  const [aadharBackImgRemark, setAadharBackImageRemark] = useState(""); //13

  const [aadharNo, setAadharNo] = useState("");
  const [aadharNoRemark, setAadharNoRemark] = useState(""); //14

  const [passbookImg, setPassBookImg] = useState("");
  const [passbookImgRemark, setPassBookImgRemark] = useState(""); //15

  const [bankAcNo, setBankAcNo] = useState("");
  const [bankAcNoRemark, setBankAcNoRemark] = useState(""); // 16

  const [bankName, setBankName] = useState("");
  const [bankNameRemark, setBankNameRemark] = useState(""); //17

  const [ifcs, setIfcs] = useState("");
  const [ifcsRemark, setIfcsRemark] = useState(""); // 18

  const [pancardImg, setPancardImg] = useState("");
  const [pancardImgRemark, setPancardImgRemark] = useState(""); //19

  const [panCardNumber, setPancardNumber] = useState("");
  const [panCardNumberRemark, setPanCardNumberRemark] = useState(""); // 21

  const [vehicleFrontImg, setVehicleFrontImg] = useState("");
  const [vehicleFrontImgRemark, setVehicleFrontImgRemark] = useState(""); // 21

  const [vehicleBackImg, setVehicleBackImg] = useState("");
  const [vehicleBackImgRemark, setVehicleBackImgRemark] = useState(""); // 22

  const [insuranceImg, setInsuranceImg] = useState("");
  const [insuranceImgRemark, setInsuranceImgRemark] = useState("");

  const [insuranceNo, setInsuranceNo] = useState("");
  const [insuranceNoRemark, setInsuranceNoRemark] = useState("");

  const [insuranceExpDate, setInsuranceExpDate] = useState("");
  const [insuranceExpDateRemark, setInsuranceExpDateRemark] = useState("");

  const [dlImg, setDlImg] = useState("");
  const [dlImgRemark, setDlImgRemark] = useState("");

  const [dlNo, setDlNo] = useState("");
  const [dlNoRemark, setDlNoRemark] = useState("");

  const [rcImg, setRcImg] = useState("");
  const [rcImgRemark, setrcImgRemark] = useState("");

  const handleRemarks = async (value, remark, type) => {
    
    switch (value) {
      case "profile image":
        if (remark) {
          setProfileImg("profileImg");
          setProImgRemark(remark);
        } else {
          setProfileImg("");
          setProImgRemark("");
        }
        break;
      case "first Name":
        if (remark) {
          setFirstName("firstName");
          setFirstNameRemark(remark);
        } else {
          setFirstName("");
          setFirstNameRemark("");
        }
        break;
      case "last Name":
        if (remark) {
          setLastName("lastName");
          setLastNameRemark(remark);
        } else {
          setLastName("");
          setLastNameRemark("");
        }

        break;
      case "default Phone Number":
        if (remark) {
          setDfNo("defaultPhoneNumber");
          setdefaultNoRemark(remark);
        } else {
          setDfNo("");
          setdefaultNoRemark("");
        }

        break;
      case "Alternative Phone Number":
        if (remark) {
          setAlNo("alternativeNumber");
          setAlNoRemark(remark);
        } else {
          setAlNo("");
          setAlNoRemark("");
        }

        break;
      case "Emergency Phone Number":
        if (remark) {
          setEmNo("emergencyNumber");
          setEmNoRemark(remark);
        } else {
          setEmNo("");
          setEmNoRemark("");
        }

        break;
      case "City":
        if (remark) {
          setCity("addCity");
          setCityRemark(remark);
        } else {
          setCity("");
          setCityRemark("");
        }

        break;
      case "Locality":
        if (remark) {
          setLocality("addLocality");
          setLocalityRemark(remark);
        } else {
          setLocality("");
          setLocalityRemark("");
        }
        break;
      case "vehicle Number":
        if (remark) {
          setVhNo("vehicleNumber");
          setVhNoRemark(remark);
        } else {
          setVhNo("");
          setVhNoRemark("");
        }
        break;
      case "vehicle Type":
        if (remark) {
          setVhType("vehicleType");
          setVhTypeRemark(remark);
        } else {
          setVhType("");
          setVhTypeRemark("");
        }
        break;
      case "Vehicle sub-type":
        if (remark) {
          setVhSubType("subType");
          setVhSubTypeRemark(remark);
        } else {
          setVhSubType("");
          setVhSubTypeRemark("");
        }
        break;
      case "Aadhar Front image":
        if (remark) {
          setAadharFrontImage("adharFrontImg");
          setAadharFrontImageRemark(remark);
        } else {
          setAadharFrontImage("");
          setAadharFrontImageRemark("");
        }
        break;
      case "Aadhar back image":
        if (remark) {
          setAadharBackImage("adharBackImg");
          setAadharBackImageRemark(remark);
        } else {
          setAadharBackImage("");
          setAadharBackImageRemark("");
        }
        break;
      case "Aadhar number":
        if (remark) {
          setAadharNo("adharNumber");
          setAadharNoRemark(remark);
        } else {
          setAadharNo("");
          setAadharNoRemark("");
        }
        break;

      case "pass book image":
        if (remark) {
          setPassBookImg("passbookStatementImg");
          setPassBookImgRemark(remark);
        } else {
          setPassBookImg("");
          setPassBookImgRemark("");
        }
        break;
      case "bank account number":
        if (remark) {
          setBankAcNo("accountNumber");
          setBankAcNoRemark(remark);
        } else {
          setBankAcNo("");
          setBankAcNoRemark("");
        }
        break;
      case "bank name":
        if (remark) {
          setBankName("bankName");
          setBankNameRemark(remark);
        } else {
          setBankName("");
          setBankNameRemark("");
        }
        break;
      case "ifsc":
        if (remark) {
          setIfcs("ifscCode");
          setIfcsRemark(remark);
        } else {
          setIfcs("");
          setIfcsRemark("");
        }
        break;
      case "pan card image":
        if (remark) {
          setPancardImg("panCardImg");
          setPancardImgRemark(remark);
        } else {
          setPancardImg("");
          setPancardImgRemark("");
        }
        break;
      case "pan number":
        if (remark) {
          setPancardNumber("panCardNumber");
          setPanCardNumberRemark(remark);
        } else {
          setPancardNumber("");
          setPanCardNumberRemark("");
        }
        break;
      case "vehicle front image":
        if (remark) {
          setVehicleFrontImg("vehicleFrontImg");
          setVehicleFrontImgRemark(remark);
        } else {
          setVehicleFrontImg("");
          setVehicleFrontImgRemark("");
        }
        break;
      case "vehicle back image":
        if (remark) {
          setVehicleBackImg("vehicleBackImg");
          setVehicleBackImgRemark(remark);
        } else {
          setVehicleBackImg("");
          setVehicleBackImgRemark("");
        }
        break;
      case "insurance image":
        if (remark) {
          setInsuranceImg("insuranceImg");
          setInsuranceImgRemark(remark);
        } else {
          setInsuranceImg("");
          setInsuranceImgRemark("");
        }
        break;
      case "insurance number":
        if (remark) {
          setInsuranceNo("insuranceNumber");
          setInsuranceNoRemark(remark);
        } else {
          setInsuranceNo("");
          setInsuranceNoRemark("");
        }
        break;
      case "insurance expiry date":
        if (remark) {
          setInsuranceExpDate("insuranceExpiryDate");
          setInsuranceExpDateRemark(remark);
        } else {
          setInsuranceExpDate("");
          setInsuranceExpDateRemark("");
        }
        break;

      case "licence image":
        if (remark) {
          setDlImg("drivingLicenseImg");
          setDlImgRemark(remark);
        } else {
          setDlImg("");
          setDlImgRemark("");
        }
        break;
      case "licence number":
        if (remark) {
          setDlNo("drivingLicenseNo");
          setDlNoRemark(remark);
        } else {
          setDlNo("");
          setDlNoRemark("");
        }
        break;
      case "rc image":
        if (remark) {
          setRcImg("rcBookImg");
          setrcImgRemark(remark);
        } else {
          setRcImg("");
          setrcImgRemark("");
        }

        break;

      default:
        break;
    }
  };
  const ClearAll = () => {
    setProfileImg("");
    setProImgRemark("");
    setFirstName("");
    setFirstNameRemark("");
    setLastName("");
    setLastNameRemark("");
    setDfNo("");
    setdefaultNoRemark("");
    setAlNo("");
    setAlNoRemark("");
    setEmNo("");
    setEmNoRemark("");
    setCity("");
    setCityRemark("");
    setLocality("");
    setLocalityRemark("");
    setVhNo("");
    setVhNoRemark("");
    setVhType("");
    setVhTypeRemark("");
    setVhSubType("");
    setVhSubTypeRemark("");
    setAadharFrontImage("");
    setAadharFrontImageRemark("");
    setAadharBackImage("");
    setAadharBackImageRemark("");
    setAadharNo("");
    setAadharNoRemark("");
    setPassBookImg("");
    setPassBookImgRemark("");
    setBankAcNo("");
    setBankAcNoRemark("");
    setBankName("");
    setBankNameRemark("");
    setIfcs("");
    setIfcsRemark("");
    setPancardImg("");
    setPancardImgRemark("");
    setPancardNumber("");
    setPanCardNumberRemark("");
    setVehicleFrontImg("");
    setVehicleFrontImgRemark("");
    setVehicleBackImg("");
    setVehicleBackImgRemark("");
    setInsuranceImg("");
    setInsuranceImgRemark("");
    setInsuranceNo("");
    setInsuranceNoRemark("");
    setInsuranceExpDate("");
    setInsuranceExpDateRemark("");
    setDlImg("");
    setDlImgRemark("");
    setDlNo("");
    setDlNoRemark("");
    setRcImg("");
    setrcImgRemark("");
    setRight([]);
  };
  const submit = async () => {
    let rejectedDocs = [];
    rejedtedDocsArray = [];
    let obj1 = {};
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (type === "basics") {
      if (profileImg) {
        rejectedDocs.push({
          remark: profileImgRemark,
          field: profileImg,
        });
        rejedtedDocsArray.push("profile image");
      }
      if (firstName) {
        rejectedDocs.push({
          remark: firstNameRemark,
          field: firstName,
        });
        rejedtedDocsArray.push("first Name");
      }
      if (lastName) {
        rejectedDocs.push({
          remark: LastNameRemark,
          field: lastName,
        });
        rejedtedDocsArray.push("last Name");
      }
      if (defaultNo) {
        rejectedDocs.push({
          remark: defaultNoRemark,
          field: defaultNo,
        });
        rejedtedDocsArray.push("default Phone Number");
      }
      if (alNo) {
        rejectedDocs.push({
          remark: alNoRemark,
          field: alNo,
        });
        rejedtedDocsArray.push("Alternative Phone Number");
      }
      if (emNo) {
        rejectedDocs.push({
          remark: emNoRemark,
          field: emNo,
        });
        rejedtedDocsArray.push("Emergency Phone Number");
      }
      if (city) {
        rejectedDocs.push({
          remark: cityRemark,
          field: city,
        });
        rejedtedDocsArray.push("City");
      }
      if (locality) {
        rejectedDocs.push({
          remark: localityRemark,
          field: locality,
        });
        rejedtedDocsArray.push("Locality");
      }
      if (vhNo) {
        rejectedDocs.push({
          remark: vhNoRemark,
          field: vhNo,
        });
        rejedtedDocsArray.push("vehicle Number");
      }
      if (vhType) {
        rejectedDocs.push({
          remark: vhTypeRemark,
          field: vhType,
        });
        rejedtedDocsArray.push("vehicle Type");
      }
      if (vhSubType) {
        rejectedDocs.push({
          remark: vhSubTypeRemark,
          field: vhSubType,
        });
        rejedtedDocsArray.push("Vehicle sub-type");
      }

      obj1 = {
        driverId: id,
        type: "basic-details",
        rejectedDocs,
        count: rejectedDocs.length,
      };
    } else if (type === "aadhar-docs") {
      if (aadharFrontImg) {
        rejectedDocs.push({
          remark: aadharFrontImgRemark,
          field: aadharFrontImg,
        });
        rejedtedDocsArray.push("Aadhar Front image");
      }
      if (aadharBackImg) {
        rejectedDocs.push({
          remark: aadharBackImgRemark,
          field: aadharBackImg,
        });
        rejedtedDocsArray.push("Aadhar back image");
      }
      if (aadharNo) {
        rejectedDocs.push({
          remark: aadharNoRemark,
          field: aadharNo,
        });
        rejedtedDocsArray.push("Aadhar number");
      }
      obj1 = {
        driverId: id,
        type: "aadhar-details",
        rejectedDocs,
        count: rejectedDocs.length,
      };
    } else if (type === "bank-docs") {
      if (passbookImg) {
        rejectedDocs.push({
          remark: passbookImgRemark,
          field: passbookImg,
        });
        rejedtedDocsArray.push("pass book image");
      }
      if (bankAcNo) {
        rejectedDocs.push({
          remark: bankAcNoRemark,
          field: bankAcNo,
        });
        rejedtedDocsArray.push("bank account number");
      }
      if (bankName) {
        rejectedDocs.push({
          remark: bankNameRemark,
          field: bankName,
        });
        rejedtedDocsArray.push("bank name");
      }
      if (ifcs) {
        rejectedDocs.push({
          remark: ifcsRemark,
          field: ifcs,
        });
        rejedtedDocsArray.push("ifsc");
      }
      obj1 = {
        driverId: id,
        type: "bank-details",
        rejectedDocs,
        count: rejectedDocs.length,
      };
    } else if (type === "pancard-docs") {
      if (pancardImg) {
        rejectedDocs.push({
          remark: pancardImgRemark,
          field: pancardImg,
        });
        rejedtedDocsArray.push("pan card image");
      }
      if (panCardNumber) {
        rejectedDocs.push({
          remark: panCardNumberRemark,
          field: panCardNumber,
        });
        rejedtedDocsArray.push("pan number");
      }
      obj1 = {
        driverId: id,
        type: "pancard-details",
        rejectedDocs,
        count: rejectedDocs.length,
      };
    } else if (type === "Vehicle-docs") {
      if (vehicleFrontImg) {
        rejectedDocs.push({
          remark: vehicleFrontImgRemark,
          field: vehicleFrontImg,
        });
        rejedtedDocsArray.push("vehicle front image");
      }
      if (vehicleBackImg) {
        rejectedDocs.push({
          remark: vehicleBackImgRemark,
          field: vehicleBackImg,
        });
        rejedtedDocsArray.push("vehicle back image");
      }
      obj1 = {
        driverId: id,
        type: "vehicle-details",
        rejectedDocs,
        count: rejectedDocs.length,
      };
    } else if (type === "insurance") {
     
      if (insuranceImg) {
        rejectedDocs.push({
          remark: insuranceImgRemark,
          field: insuranceImg,
        });
        rejedtedDocsArray.push("insurance image");
      }
      if (insuranceNo) {
        rejectedDocs.push({
          remark: insuranceNoRemark,
          field: insuranceNo,
        });
        rejedtedDocsArray.push("insurance number");
      }
      if (insuranceExpDate) {
        rejectedDocs.push({
          remark: insuranceExpDateRemark,
          field: insuranceExpDate,
        });
        rejedtedDocsArray.push("insurance expiry date");
      }
      obj1 = {
        driverId: id,
        type: "insurance-details",
        rejectedDocs,
        count: rejectedDocs.length,
      };
    } else if (type === "dl") {
      if (dlImg) {
        rejectedDocs.push({
          remark: dlImgRemark,
          field: dlImg,
        });
        rejedtedDocsArray.push("licence image");
      }
      if (dlNo) {
        rejectedDocs.push({
          remark: dlNoRemark,
          field: dlNo,
        });
        
        rejedtedDocsArray.push("licence number");
      }
      obj1 = {
        driverId: id,
        type: "licence-details",
        rejectedDocs,
        count: rejectedDocs.length,
      };
    } else if (type === "rc") {
      if (rcImg) {
        rejectedDocs.push({
          remark: rcImgRemark,
          field: rcImg,
        });
        rejedtedDocsArray.push("rc image");
      }
      obj1 = {
        driverId: id,
        type: "rc-details",
        rejectedDocs,
        count: rejectedDocs.length,
      };
    }
    if (right.length !== 0 && rejectedDocs.length === right.length) {
      
      await axios.post(
        `${API}/api/admin/driver-rejection`,
        obj1,
        config
      );
      
      handleClose();
      setRight([]);
      ClearAll();
    } else {
      let docs = [];
      for (let i = 0; i < rejectedDocs.length; i++) {
        const element = rejectedDocs[i];
        docs.push(element.field);
      }

      dataNotUpdated = await right.filter(function (item) {
        return !rejedtedDocsArray.includes(item);
      });
      
      if(dataNotUpdated.length!==0){
        setErr(true);
      }
     
      // setRight([]);
      // ClearAll();
    }
  };

  const customList = (title, items) => {
    return (
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{
                "aria-label": "all items selected",
              }}
            />
          }
          title={title === "Chosen" ? `${title} For Reject` : title}
          subheader={`${numberOfChecked(items)}/${items.length} selected `}
        />

        <Divider />
        <List
          sx={{
            width: title === "Chosen" ? "650px" : "300px",
            height: "auto",
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
        >
          {items.map((value, i) => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
              <ListItem
                key={value}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${i + 1}. ${value}`} />{" "}
                {title === "Chosen" ? (
                  <>
                    <TextField
                      id="standard-basic"
                      label={`Add Remarks`}
                      multiline
                      sx={{ width: 350 }}
                      // error={val? true :false}
                      onChange={(e) => {
                        handleRemarks(value, e.target.value, type);
                      }}
                      InputProps={{
                        inputComponent: TextareaAutosize,
                      }}
                    />
                    {/* <TextareaAutosize 
                     
                      minRows={2}
                      onChange={(e) => {
                        handleRemarks(value, e.target.value, type);
                      }}
                      placeholder="Add Your Remarks"
                      
                      style={{ width: 250, marginRight: "10px" }}
                      label="Enter Remark"
                    /> */}
                    {/* <Button onClick={submit} variant="outlined">
                      <DoneIcon />
                    </Button> */}
                  </>
                ) : (
                  ""
                )}
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  };
  return (
    <React.Fragment>
      <Dialog
        // fullWidth={fullWidth}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ color: "#eb0740" }}>{title}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText> */}
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>{customList("Choices", left)}</Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                </Grid>
              </Grid>
              <Grid item>{customList("Chosen", right)}</Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {  right.length !== 0  && <Button onClick={submit}>submit</Button>} 
          
          <Button
            onClick={() => {
              handleClose();
              setRight([]);
              ClearAll();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={err}
        onClose={handleCloseErr}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ color: "red" }} id="alert-dialog-title">
          {"add remark for this items"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dataNotUpdated.map((item, i) => {
              return (
                <Chip
                  sx={{ m: 1, border: "solid 1px red" }}
                  label={`${i + 1} ${item}`}
                />
              );
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErr}>ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
