import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FileSaver from "file-saver";
import { AppBar, Dialog, IconButton, Slide } from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import DownloadIcon from '@mui/icons-material/Download';
import RotateRightIcon from '@mui/icons-material/RotateRight';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PopUpImage = ({ handleCloseimg, open, imgUrl }) => {
  const [zoom, setZoom] = useState(false);

  // const downloadFile = (url) => {
  //   FileSaver.saveAs(url, "loadrunnr-img.jpg");
  //   //fileDownload(url,"helo.jpg")
  // };

  const [rotate,setRotate]=useState(0)
  const handleRotateImg = ()=>{
    setRotate(rotate+90)
  }
  const zoomHandle = () => {
    setZoom(!zoom);
  };

  return (
    <>
      <Dialog
        fullScreen={zoom}
        maxWidth="md"
        open={open}
        onClose={handleCloseimg}
        TransitionComponent={Transition}
      >
        <AppBar   sx={{ position: "sticky", backgroundColor: "#e75c05" }}>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {/* <span>
              <IconButton
                // edge="end"
                color="inherit"
                onClick={()=>{
                  downloadFile(imgUrl)
                }}
                aria-label="close"
               
              >
                <DownloadIcon />
              </IconButton>
            </span> */}
            <span>
              <IconButton
                // edge="end"
                color="inherit"
                onClick={zoomHandle}
                aria-label="close"
              >
                {zoom ? <ZoomInMapIcon /> : <ZoomOutMapIcon />}
              </IconButton>
            </span>
            <span>
              <IconButton
                // edge="end"
                color="inherit"
                onClick={handleCloseimg}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </span>
            <span>
              <IconButton
                // edge="end"
                color="inherit"
                onClick={handleRotateImg}
                aria-label="close"
              >
                <RotateRightIcon />
              </IconButton>
            </span>
          </div>
        </AppBar>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            style={zoom ? { maxWidth: "1770px",transform: `rotate(${rotate}deg)` } : { maxWidth: "700px",transform: `rotate(${rotate}deg)` }}
            src={imgUrl}
            alt="Loadrunnr img"
          />
          {/* <ModalImage
            hideDownload={true}
            hideZoom={true}
            small={imgUrl}
            large={imgUrl}
            alt="Loadrunnr Image"
          
          /> */}
        </div>
      </Dialog>
    </>
  );
};

export default PopUpImage;
