import TableCell from '@mui/material/TableCell';
import { Box } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
const headCells = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'code',
    label: 'Code',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'coupon_value',
    label: 'Coupon value',
  },
  {
    id: 'minPrice',
    label: 'Minimum Price',
  },
  {
    id: 'maxPrice',
    label: 'Maximum Price',
  },
  {
    id: 'action',
    label: 'Action',
  },
  {
    id: 'expireAt',
    label: 'Expire At',
  },
  {
    id: 'createdAt',
    label: 'Created At',
  },
  {
    id: 'delete',
    label: 'Delete',
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export { EnhancedTableHead };
