import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AppBar from "@mui/material/AppBar";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import UploadIcon from "@mui/icons-material/Upload";
import { API, LOADRUNNR_SOCKET_API } from "../../../socketConst/BaseApi";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Theme } from '@mui/material'
const helperText = makeStyles(() => ({
  root: {
    "& .MuiTextField-root": {
      margin: 1,
      width: 200,
    },
    "& .MuiFormHelperText-root": {
      color: "red !important",
    },
  },
  bg: {
    backgroundColor: "#7986cb",
  },
  textfield: {
    backgroundColor: "#fff",
  },
}));
const EditRules = ({
  close,
  open,
  status,
  setStatus,
  coinType,
  setCoinType,
  orderNumber,
  setOrderNumber,
  range1,
  range2,
  coins1,
  coins2,
  pricingId,
  setCoins1,
  setCoins2,
  setRange1,
  setRange2,
}) => {
  const helperTxtClass = helperText();
  const [change, setChange] = useState(false);
  useEffect(() => {

    setCoins2(0);

    setRange2(0);
   
  }, [change]);

  const handleSubmit = async () => {
   

    if (
      pricingId &&
      coinType &&
      orderNumber &&
      range1 &&
      coins1 &&
      range2 &&
      coins2
    ) {
      if (orderNumber == 1) {
        
        var obj = {
          pricingId,
          type: coinType,
          orderNumber: parseInt(orderNumber),
          range: parseInt(range2),
          range1: parseInt(range1),
          coins: parseInt(coins2),
          coins1: parseInt(coins1),
        };
      }
    } else {
      var obj = {
        pricingId,
        type: coinType,
        orderNumber: parseInt(orderNumber),
        range: parseInt(range1),
        range1: 0,
        coins: parseInt(coins1),
        coins1: 0,
      };
    }
    
    const { data } = await axios.put(
      `${API}/api/admin/update-coin-pricing`,
      obj
    );
    if (data) {
      setStatus(!status);
      close();
    } else {
      alert("something went wrong");
    }
  };

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={close}>
        <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
          <DialogTitle sx={{ color: "white" }}>
            <AddIcon /> Add A New Rule
          </DialogTitle>
        </AppBar>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            <FormControl sx={{ m: 1, width: "100%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                Coin Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                helperText={!coinType ? "Field is required" : ""}
                id="demo-simple-select-helper"
                value={coinType}
                label="Coin Type"
                name="coinType"
                onChange={(e) => {
                  setCoinType(e.target.value);
                }}
              >
                <MenuItem value={coinType}>{coinType}</MenuItem>
                <MenuItem value="Fixed">Fixed</MenuItem>
                <MenuItem value="Per">Per</MenuItem>
              </Select>
            </FormControl>
            <TextField
              className={helperTxtClass.root}
              helperText={!orderNumber ? "Field is required" : ""}
              label="Order Number"
              name="orderNo"
              fullWidth
              value={orderNumber}
              type="number"
              onChange={(e) => {
                setChange(!change);
                setOrderNumber(e.target.value);
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            <TextField
              className={helperTxtClass.root}
              helperText={!range1 ? "Field is required" : ""}
              label={orderNumber == 1 ? "Range 1" : "Range"}
              name="range1"
              value={range1}
              fullWidth
              type="number"
              onChange={(e) => {
                setRange1(e.target.value);
              }}
            />
            <TextField
              className={helperTxtClass.root}
              label={
                range1 && range2 && orderNumber == 1
                  ? `Coins Between ₹${range2} & ₹${range1}`
                  : range1
                  ? `Coins Above ₹${range1}`
                  : "Coins"
              }
              name="amount"
              value={coins1}
              helperText={!coins1 ? "Field is required" : ""}
              fullWidth
              type="number"
              onChange={(e) => {
                setCoins1(e.target.value);
              }}
            />
          </Box>
          {/* {orderNumber == 1 ? ( */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            {orderNumber == 1 ? (
              <TextField
                className={helperTxtClass.root}
                helperText={!range2 || range2 == 0 ? "Field is required" : ""}
                label="Range 2"
                name="range2"
                value={range2}
                fullWidth
                type="number"
                onChange={(e) => {
                  setRange2(e.target.value);
                }}
              />
            ) : (
              ""
            )}
            {orderNumber == 1 ? (
              <TextField
                className={helperTxtClass.root}
                helperText={!coins2 ? "Field is required" : ""}
                label={
                  range2
                    ? `Coins Above ₹${range2}`
                    : range2
                    ? `Coins Above ₹${range2}`
                    : "Coins"
                }
                name="amount2"
                value={coins2}
                fullWidth
                type="number"
                onChange={(e) => {
                  setCoins2(e.target.value);
                }}
              />
            ) : (
              ""
            )}
          </Box>
          {/* ) : (
            ""
          )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default EditRules;
