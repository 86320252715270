import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import { EnhancedTableHead } from "../../Components/Admin/sub-admin/TableHead";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { subAdminListAction,deleteSubAdminAction } from "../../Actions/AdminActions";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@material-ui/icons/Add";
import AddSubAdmin from "../../Components/Admin/sub-admin/AddSubAdmin";
import EditSubAdmin from "../../Components/Admin/sub-admin/EditSubAdmin";
import { set } from "date-fns";
import Swal from "sweetalert2";

const AdminSubAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const { SubAdminsList } = useSelector((state) => state.subadmins);
  const [openAddSubAdmin, setOpenAddSubAdmin] = useState(false);
  const [openEditSubAdmin, setOpenEditSubAdmin] = useState(false);
  const [subAdminId, setSubAdminId] = useState("");
  const { status } = useSelector((state) => state.createSubadmin);
  const { updateStatus } = useSelector((state) => state.updateSubAdmin);
  const [emailForUpdate, setEmailForUpdate] = useState("");
  const [firstNameForUpdate, setFirstNameForUpdate] = useState("");
  const [lastNameForUpdate, setLastNameForUpdate] = useState("");
  const [passwordForUpdate, setPasswordForUpdate] = useState("");
  const { deleted }  = useSelector((state) => state.delete_sub_admin); 
  
  const handleClose = () => {
    setOpenAddSubAdmin(false);
  };
  const editHandleClose = () => {
    setOpenEditSubAdmin(false);
  };
  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }
  }, [adminInfo, navigate]);

  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }
    dispatch(subAdminListAction());
  }, [adminInfo, dispatch, status, updateStatus,deleted]);

  const deleteSubAdmin = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete this sub-admin?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSubAdminAction(id));

        Swal.fire(
          'Deleted!',
          'This sub-admin successfully deleted.',
          'success'
        )
      }
    });
  };

  let subAdminRecord = (row, i) => {
   
    return (
      <>
        <TableRow hover key={i}>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <span>{i + 1}</span>
          </TableCell>
          <TableCell>{row.firstName}</TableCell>
          <TableCell>{row.lastName}</TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.password}
          </TableCell>
          <TableCell>
            <IconButton
              onClick={() => {
                setSubAdminId(row._id);
                setEmailForUpdate(row.email);
                setFirstNameForUpdate(row.firstName);
                setLastNameForUpdate(row.lastName);
                setPasswordForUpdate(row.password);
                setOpenEditSubAdmin(true);
              }}
              aria-label="edit"
              size="large"
            >
              <EditIcon sx={{ color: "blue" }} fontSize="inherit" />
            </IconButton>
            <IconButton onClick={() => {
              deleteSubAdmin(row._id);
            }} aria-label="delete" size="large">
              <DeleteIcon sx={{ color: "red" }} fontSize="inherit" />
            </IconButton>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar  ActiveTab="Sub Admin" 
          id={adminInfo && adminInfo._id}
          email={adminInfo && adminInfo.email}
          name={adminInfo && `${adminInfo.firstName} ${adminInfo.lastName}`}
          place={adminInfo && adminInfo.place}
          token={adminInfo && adminInfo.token}
        />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          
          <h5 style={{ display: "block", color: "green" }}>Sub admin management</h5>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {" "}
            <div>
              <Button
                onClick={() => {
                  setOpenAddSubAdmin(true);
                }}
                variant="contained"
                sx={{ backgroundColor: "green", mb: 2 }}
                color="primary"
              >
                <AddIcon /> add sub-admin
              </Button>
            </div>
          </div>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                // size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  //   numSelected={selected.length}
                  //   order={order}
                  //   orderBy={orderBy}
                  //   onSelectAllClick={handleSelectAllClick}
                  //   onRequestSort={handleRequestSort}
                  rowCount={SubAdminsList && SubAdminsList.length}
                />
                <TableBody>
                  {
                    //   stableSort(SubAdminsList, getComparator(order, orderBy))
                    //     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    SubAdminsList &&
                      SubAdminsList.map((row, i) => {
                        return subAdminRecord(row, i);
                      })
                  }
                  {/* {emptyRows > 0 && ( */}
                  <TableRow
                  //   style={{
                  //     height: (dense ? 33 : 53) * emptyRows,
                  //   }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                  {/* )} */}
                </TableBody>
              </Table>
              {/* {JSONDATA.length === 0 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h1 style={{ marginTop: "50px", marginRight: "500px" }}>
                    No Data
                  </h1>
                </div>
              ) : (
                ""
              )} */}
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={JSONDATA.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper>
        </Box>
      </Box>
      <AddSubAdmin open={openAddSubAdmin} handleClose={handleClose} />
      {openEditSubAdmin ? (
        <EditSubAdmin
          subAdminId={subAdminId}
          emailForUpdate={emailForUpdate}
          firstNameForUpdate={firstNameForUpdate}
          lastNameForUpdate={lastNameForUpdate}
          passwordForUpdate={passwordForUpdate}
          open={openEditSubAdmin}
          handleClose={editHandleClose}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AdminSubAdmin;
