import TableCell from "@mui/material/TableCell";
import { Box } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
const headCells = [
  {
    id: "no",
    label: "No",

  },
  {
    id: "availability",
    label: "Availability",

  },
    {
      id: "Status",
      label: "Driver_Status",
     
    },
    {
      id: "city",
      label: "City",
     
    },
    {
      id: "locality",
      label: "Locality",
     
    },
    {
      id: "driverId",
      label: "Driver_ID",
     
    },
    {
      id: "DriverName",
      label: "DriverName",
     
    },
    {
      id: "DriverMobile",
      label: "Driver_Mobile",
     
    },
    {
      id: "vehicleType",
      label: "Vehicle_Type",
     
    },
    {
      id: "subType",
      label: "Sub_Type",
     
    },
    {
      id: "wallet",
      label: "Wallet",
     
    },
    {
      id: "actions",
      label: "Actions",
     
    },
    {
      id: "orders",
      label: "Orders",
     
    },
    {
      id: "reviews",
      label: "Reviews",
     
    },
    {
      id: "refferedBy",
      label: "Reffered_By",
     
    },
    {
      id: "registeredAt",
      label: "Registered_At",
     
    },
    {
      id: "csNotes",
      label: "Cs_Notes",
     
    },
  ];
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }


  export {
    EnhancedTableHead
  }