import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/SubAdmin/sidebar/SideBar";
import DashboardCard from "../../Components/Admin/cards/DashboardCard";
import Graph from "../../Components/Admin/graph/Graph";
import Version from "../../Components/Admin/version/Version";
import { Grid, IconButton, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import "moment-timezone";
import axios from "axios";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { API } from "../../socketConst/BaseApi";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";

const useStyles = makeStyles(() => ({
  refresh: {
    margin: "auto",
  },
  spin: {
    margin: "auto",
    animation: "$spin 1s infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

const SubAdminDashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [filter, setFilter] = React.useState(false);
  const [data, setData] = useState();
  const [graph, setGraph] = useState();
  const [place, setPlace] = React.useState("both");
  const [date, setDate] = React.useState(null);

  const [spin, setSpin] = React.useState();
  const [spin2, setSpin2] = React.useState();

  let fecthData = async () => {
    setSpin(true)
    const { data } = await axios.get(
      `${API}/api/sub-admin/dashboard/${from}/${to}/${place}`
    );
    if(data){
      setSpin(false)
      setData(data);
    }
    
  };

  let fecthGraph = async () => {
    setSpin(true);
    const format = "YYYY-MM-DD";
    if (date) {
      var date2 = date;
    } else {
      var date2 = new Date();
    }
    let today = moment(date2).format(format);
    const { data } = await axios.get(
      `${API}/api/sub-admin/dashboard-graph/${today}/${place}`
    );
    if (data) {
      setGraph(data);
      setSpin(false);
    }
  };

  function handleClrFrom(e) {
    e.stopPropagation();
    setFrom(null);
  }
  function handleClrTo(e) {
    e.stopPropagation();
    setTo(null);
  }
  const refreshdashboard = (e) => {
    setSpin(true);
    e.stopPropagation();
    setTo(null);
    setFrom(null);
    setFilter(false);
  };

  const subAdminLogin = useSelector((state) => state.subAdminLogin);
  const { subAdminInfo } = subAdminLogin;


  useEffect(() => {
    if (!subAdminInfo) {
      navigate("/sub-admin/login");
    }
  }, [subAdminInfo, navigate]);

  useEffect(() => {
    if (data) {
      setSpin(false);
    } else {
      setSpin(true);
    }
    if (graph) {
      setSpin2(false);
    } else {
      setSpin2(true);
    }
  }, [data, spin]);

  const submit = (fromdt, todt) => {
    setSpin(true);
    setFilter(true);
    if (fromdt && todt) {
      setFrom(moment(fromdt).format("YYYY-MM-DD"));
      setTo(moment(todt).format("YYYY-MM-DD"));
      fecthData();
    } else if (fromdt) {
      setFrom(moment(fromdt).format("YYYY-MM-DD"));
      setTo(null);
      fecthData();
    } else {
      setFrom("");
    }
  };

  useEffect(() => {
    fecthData();
    fecthGraph();
  }, [place]);

  function handleClrDate(e) {
    e.stopPropagation();
    setDate(null);
    fecthGraph();
  }
  const submitGraph = (date) => {
    setFilter(true);
    if (date) {
      fecthGraph();
    } else {
      setDate("");
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar
        ActiveTab="Dashboard"
        id={subAdminInfo && subAdminInfo._id}
        email={subAdminInfo && subAdminInfo.email}
        name={
          subAdminInfo && `${subAdminInfo.firstName} ${subAdminInfo.lastName}`
        }
        token={subAdminInfo && subAdminInfo.token}
      />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Toolbar />
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginRight: "",
          }}
        >
         
         
          <h5 style={{ display: "block", color: "green" }}>ANALYTICS DASHBOARD</h5>
          <span>
            <FormControl>
              <RadioGroup
                row
                onClick={(e) => {
                  setPlace(e.target.value);
                }}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="Place"
              >
                <FormControlLabel
                  value="Bangalore"
                  control={<Radio />}
                  label="Bangalore"
                />
                <FormControlLabel
                  value="Mysore"
                  control={<Radio />}
                  label="Mysore"
                />
                <FormControlLabel
                  value="both"
                  control={<Radio />}
                  label="Both"
                />
              </RadioGroup>
            </FormControl>
          </span>
          <span>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="From"
                value={from}
                helperText={
                  from !== null && filter && from === "" ? "Field required" : ""
                }
                onChange={(date) => setFrom(date)}
                renderInput={(params) => (
                  <TextField
                    style={{ marginLeft: "10px", width: "150px" }}
                    size="small"
                    {...params}
                  />
                )}
              />
              <DesktopDatePicker
                label="To"
                value={to}
                onChange={(date) => setTo(date)}
                renderInput={(params) => (
                  <TextField
                    style={{ marginLeft: "5px", width: "150px" }}
                    size="small"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>

            <IconButton
              size="small"
              sx={{
                backgroundColor: "green",
                marginLeft: "10px",
                ":hover": {
                  color: "whitesmoke",
                  backgroundColor: "green",
                },
              }}
              onClick={() => {
                submit(from, to);
              }}
              aria-label="refresh"
            >
              <SearchIcon sx={{ color: "white" }} />
            </IconButton>
            <IconButton
              size="small"
              sx={{
                backgroundColor: "darkgray",
                marginLeft: "10px",
                ":hover": {
                  color: "whitesmoke",
                  backgroundColor: "brown",
                },
              }}
              onClick={(e) => refreshdashboard(e)}
              aria-label="refresh"
            >
              <RefreshIcon
                sx={{ color: "white" }}
                className={spin ? classes.spin : classes.refresh}
                spin={spin}
              />
            </IconButton>
          </span>
        </div>

        <div>
          <DashboardCard
            cardWidth="48%"
            count={data && data.completedOrders}
            title="Orders Completed"
            spin={spin}
            color="#e75c05"
          />
          <DashboardCard
            cardWidth="48%"
            count={data && data.cancelledOrders}
            title="Canclelled order"
            spin={spin}
            color="#e75c05"
          />
          <DashboardCard
            cardWidth="48%"
            count={data && data.expireOrderCount}
            title="Expired Order"
            spin={spin}
            color="#e75c05"
          />
            <DashboardCard
            cardWidth="48%"
            count={data && data.ongoingOrders}
            title="Ongoing Order"
            spin={spin}
            color="#e75c05"
          />
          
        </div>
        <div style={{ margin: "20px" }}>
          <Grid container spacing={2}>
            <Grid sx={{ m: 1 }} item sm={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h6 style={{ display: "inline-block" }}>weekly graph</h6>
                <span>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      helperText={
                        date !== null && filter && date === ""
                          ? "Field required"
                          : ""
                      }
                      label="Date"
                      value={date}
                      onChange={(date) => setDate(date)}
                      renderInput={(params) => (
                        <TextField
                          style={{ marginLeft: "10px", width: "150px" }}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>

                  {!spin2 ? (
                    <IconButton
                      size="small"
                      sx={{
                        backgroundColor: "#32a852",
                        marginLeft: "10px",
                        ":hover": {
                          color: "whitesmoke",
                          backgroundColor: "green",
                        },
                      }}
                      onClick={() => {
                        submitGraph(date);
                      }}
                      aria-label="refresh"
                    >
                      <SearchIcon sx={{ color: "white" }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      sx={{
                        backgroundColor: "darkgray",
                        marginLeft: "10px",
                        ":hover": {
                          color: "whitesmoke",
                          backgroundColor: "brown",
                        },
                      }}
                      // onClick={(e) => refreshdashboard(e)}
                      aria-label="refresh"
                    >
                      <RefreshIcon
                        sx={{ color: "white" }}
                        className={spin2 ? classes.spin : classes.refresh}
                        spin={spin2}
                      />
                    </IconButton>
                  )}
                </span>
              </div>
              <Graph dataSource={graph && graph.graphData} />
            </Grid>
          </Grid>
        </div>
      </Box>
    </Box>
  );
};

export default SubAdminDashboard;
