import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT,
  ADMIN_MANAGE_CUSTOMER_LIST_REQUEST,
  ADMIN_MANAGE_CUSTOMER_LIST_SUCCESS,
  ADMIN_MANAGE_CUSTOMER_LIST_FAIL,
  ADMIN_UPDATE_CUSTOMER_REQUEST,
  ADMIN_UPDATE_CUSTOMER_SUCCESS,
  ADMIN_UPDATE_CUSTOMER_FAIL,
  ADMIN_SINGLE_CUSTOMER_REQUEST,
  ADMIN_SINGLE_CUSTOMER_SUCCESS,
  ADMIN_SINGLE_CUSTOMER_FAIL,
  ADMIN_PRICING_DATA_REQUEST,
  ADMIN_PRICING_DATA_SUCCESS,
  ADMIN_PRICING_DATA_FAIL,
  ADMIN_UPDATE_PRICINGTYPE_REQUEST,
  ADMIN_UPDATE_PRICINGTYPE_SUCCESS,
  ADMIN_UPDATE_PRICINGTYPE_FAIL,
  ADMIN_SINGLE_PRICINGTYPE_REQUEST,
  ADMIN_SINGLE_PRICINGTYPE_SUCCESS,
  ADMIN_SINGLE_PRICINGTYPE_FAIL,
  ADMIN_ADD_DRIVER_BANNER_REQUEST,
  ADMIN_ADD_DRIVER_BANNER_SUCCESS,
  ADMIN_ADD_DRIVER_BANNER_FAIL,
  ADMIN_DRIVER_BANNER_LIST_REQUEST,
  ADMIN_DRIVER_BANNER_LIST_SUCCESS,
  ADMIN_DRIVER_BANNER_LIST_FAIL,
  ADMIN_UPDATE_DRIVER_BANNER_REQUEST,
  ADMIN_UPDATE_DRIVER_BANNER_SUCCESS,
  ADMIN_GET_DASHBOARD_REQUEST,
  ADMIN_GET_DASHBOARD_SUCCESS,
  ADMIN_GET_DASHBOARD_FAIL,
  ADMIN_UPDATE_DRIVER_BANNER_FAIL,
  ADMIN_DELETE_DRIVER_BANNER_REQUEST,
  ADMIN_DELETE_DRIVER_BANNER_SUCCESS,
  ADMIN_DELETE_DRIVER_BANNER_FAIL,
  ADMIN_LIST_ALL_BOOKINGS_REQUEST,
  ADMIN_LIST_ALL_BOOKINGS_SUCCESS,
  ADMIN_LIST_ALL_BOOKINGS_FAIL,
  ADMIN_ADD_USER_BANNER_REQUEST,
  ADMIN_ADD_USER_BANNER_SUCCESS,
  ADMIN_ADD_USER_BANNER_FAIL,
  ADMIN_USER_BANNER_LIST_REQUEST,
  ADMIN_USER_BANNER_LIST_SUCCESS,
  ADMIN_USER_BANNER_LIST_FAIL,
  ADMIN_UPDATE_USER_BANNER_REQUEST,
  ADMIN_UPDATE_USER_BANNER_SUCCESS,
  ADMIN_UPDATE_USER_BANNER_FAIL,
  ADMIN_DELETE_USER_BANNER_REQUEST,
  ADMIN_DELETE_USER_BANNER_SUCCESS,
  ADMIN_DELETE_USER_BANNER_FAIL,
  ADMIN_BOOKING_ADDCOMMENT_REQUEST,
  ADMIN_BOOKING_ADDCOMMENT_SUCCESS,
  ADMIN_BOOKING_ADDCOMMENT_FAIL,
  ADMIN_BOOKING_UPDATE_REQUEST,
  ADMIN_BOOKING_UPDATE_SUCCESS,
  ADMIN_BOOKING_UPDATE_FAIL,
  ADMIN_ALL_DRIVER_LIST_REQUEST,
  ADMIN_ALL_DRIVER_LIST_SUCCESS,
  ADMIN_ALL_DRIVER_LIST_FAIL,
  ADMIN_FILTERED_DRIVER_LIST_REQUEST,
  ADMIN_FILTERED_DRIVER_LIST_SUCCESS,
  ADMIN_FILTERED_DRIVER_LIST_FAIL,
  ADMIN_DRIVER_APPROVAL_REQUEST,
  ADMIN_BOOKING_REFRESH,
  ADMIN_DRIVER_APPROVAL_SUCCESS,
  ADMIN_DRIVER_APPROVAL_FAIL,
  ADMIN_DRIVER_DETAILS_REQUEST,
  ADMIN_DRIVER_DETAILS_SUCCESS,
  ADMIN_DRIVER_DETAILS_FAIL,
  ADMIN_DRIVER_FILTER_STATUS,
  ADMIN_UPDATE_DRIVER_REQUEST,
  ADMIN_UPDATE_DRIVER_SUCCESS,
  ADMIN_UPDATE_DRIVER_FAIL,
  ADMIN_PROMOCODE_GENRATION_REQUEST,
  ADMIN_PROMOCODE_GENRATION_SUCCESS,
  ADMIN_PROMOCODE_GENRATION_FAIL,
  ADMIN_CREATE_PROMOCODE_REQUEST,
  ADMIN_CREATE_PROMOCODE_SUCCESS,
  ADMIN_CREATE_PROMOCODE_FAIL,
  PROMOCODE_LIST_REQUEST,
  PROMOCODE_LIST_SUCCESS,
  PROMOCODE_LIST_FAIL,
  DELETE_PROMOCODE_REQUEST,
  DELETE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_FAIL,
  SINGLE_PROMOCODE_REQUEST,
  SINGLE_PROMOCODE_SUCCESS,
  SINGLE_PROMOCODE_FAIL,
  UPDATE_PROMOCODE_REQUEST,
  UPDATE_PROMOCODE_SUCCESS,
  UPDATE_PROMOCODE_FAIL,
  ADMIN_DRIVER_FILTER_VEHICLE_TYPE,
  ADMIN_DRIVER_FILTER_SUB_VEHICLE_TYPE,
  ADMIN_CUSTOMER_FILTER_STATUS,
  ADMIN_CUSTOMER_SEARCH_STATUS,
  ADMIN_SEARCH_DRIVER,
  ADMIN_FILTER_BOOKING,
  ADMIN_DATE_FILTER_DRIVER,
  ADMIN_CUSTOMER_COMMENT_REQUEST,
  ADMIN_CUSTOMER_COMMENT_SUCCESS,
  ADMIN_CUSTOMER_COMMENT_FAIL,
  ADMIN_DRIVER_COMMENT_REQUEST,
  ADMIN_DRIVER_COMMENT_SUCCESS,
  ADMIN_DRIVER_COMMENT_FAIL,
  ADMIN_FILTER_REFRESH,
  ADMIN_FILTER_USER_REFRESH,
  ADMIN_NEW_ORDER_REQUEST,
  ADMIN_NEW_ORDER_SUCCESS,
  ADMIN_NEW_ORDER_FAIL,
  ADMIN_DRIVER_ADDRESS_SAVING,
  ADMIN_GET_WITHDRAWALREQUESTS_REQUEST,
  ADMIN_GET_WITHDRAWALREQUESTS_SUCCESS,
  ADMIN_GET_WITHDRAWALREQUESTS_FAIL,
  ADMIN_PUSH_NOTIFICATION_REQUEST,
  ADMIN_PUSH_NOTIFICATION_SUCCESS,
  ADMIN_PUSH_NOTIFICATION_FAIL,
  ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_REQUEST,
  ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_SUCCESS,
  ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_FAIL,
  ADMIN_REJECT_WITHDRAWALREQUEST_REQUEST,
  ADMIN_REJECT_WITHDRAWALREQUEST_SUCCESS,
  ADMIN_REJECT_WITHDRAWALREQUEST_FAIL,
  ADMIN_APPROVE_WITHDRAWAlREQUEST_REQUEST,
  ADMIN_APPROVE_WITHDRAWAlREQUEST_SUCCESS,
  ADMIN_APPROVE_WITHDRAWAlREQUEST_FAIL,
  ADD_COMMISION_REQUEST,
  ADD_COMMISION_SUCCESS,
  ADD_COMMISION_FAIL,
  CURRENT_COMMISION_REQUEST,
  CURRENT_COMMISION_SUCCESS,
  CURRENT_COMMISION_FAIL,
  DRIVER_CURRENTLOCATION_BOOKING_REQUEST,
  DRIVER_CURRENTLOCATION_BOOKING_SUCCESS,
  DRIVER_CURRENTLOCATION_BOOKING_FAIL,
  ADMIN_DASHBOARD_UPDATE_REQUEST,
  ADMIN_DASHBOARD_UPDATE_SUCCESS,
  ADMIN_DASHBOARD_UPDATE_FAIL,
  ADMIN_SUB_ADMIN_LIST_REQUEST,
  ADMIN_SUB_ADMIN_LIST_SUCCESS,
  ADMIN_SUB_ADMIN_LIST_FAIL,
  ADMIN_CREATE_SUB_ADMIN_REQUEST,
  ADMIN_CREATE_SUB_ADMIN_SUCCESS,
  ADMIN_CREATE_SUB_ADMIN_FAIL,
  ADMIN_UPDATE_SUB_ADMIN_REQUEST,
  ADMIN_UPDATE_SUB_ADMIN_SUCCESS,
  ADMIN_UPDATE_SUB_ADMIN_FAIL,
  ADMIN_DELETE_SUB_ADMIN_REQUEST,
  ADMIN_DELETE_SUB_ADMIN_SUCCESS,
  ADMIN_DELETE_SUB_ADMIN_FAIL,
  ADMIN_DASHBOARD_DATE_FILTER,
  GET_CUSTOMER_ORDERS_REQUEST,
  GET_CUSTOMER_ORDERS_SUCCESS,
  GET_CUSTOMER_ORDERS_FAIL,
  ADMIN_GET_DASHBOARD_WEEK_GRAPH_REQUEST,
  ADMIN_GET_DASHBOARD_WEEK_GRAPH_SUCCESS,
  ADMIN_GET_DASHBOARD_WEEK_GRAPH_FAIL,
  GET_DRIVER_ORDERS_REQUEST,
  GET_DRIVER_ORDERS_SUCCESS,
  GET_DRIVER_ORDERS_FAIL,
  GET_DRIVER_REVIEWS_REQUEST,
  GET_DRIVER_REVIEWS_SUCCESS,
  GET_DRIVER_REVIEWS_FAIL,
  ADMIN_GET_OFFER_COINS_REQUEST,
  ADMIN_GET_OFFER_COINS_SUCCESS,
  ADMIN_GET_OFFER_COINS_FAIL,
  ADMIN_ACTION_COIN_ClAIM_REQUEST,
  ADMIN_ACTION_COIN_ClAIM_SUCCESS,
  ADMIN_ACTION_COIN_ClAIM_FAIL,
  ADMIN_ALL_LOADRUNNR_DRIVER_LIST_FAIL,
  ADMIN_ALL_LOADRUNNR_DRIVER_LIST_REQUEST,
  ADMIN_ALL_LOADRUNNR_DRIVER_LIST_SUCCESS,
  ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_REQUEST,
  ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_SUCCESS,
  ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_FAIL
} from "../Constants/AdminConstants";

export const adminLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LOGIN_REQUEST:
      return { loading: true };
    case ADMIN_LOGIN_SUCCESS:
      return { loading: false, adminInfo: action.payload };
    case ADMIN_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case ADMIN_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const loadrunnrviewAllDrivers = (state = { change: 1 }, action) => {
  switch (action.type) {
    case ADMIN_ALL_LOADRUNNR_DRIVER_LIST_REQUEST:
      return { loading: true, change: 2 };
    case ADMIN_ALL_LOADRUNNR_DRIVER_LIST_SUCCESS:
      return {
        loading: false,
        AllDriversList: action.payload,
        change: state.change + 1,
      };
    case ADMIN_ALL_LOADRUNNR_DRIVER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const listAllDriverReducer = (state = { change: 1,AllDriversList:[] }, action) => {
  switch (action.type) {
    case ADMIN_ALL_DRIVER_LIST_REQUEST:
      return { loading: true, change: 2 ,AllDriversList:[]};
    case ADMIN_ALL_DRIVER_LIST_SUCCESS:
      return {
        loading: false,
        AllDriversList: action.payload,
        change: state.change + 1,
      };
    case ADMIN_ALL_DRIVER_LIST_FAIL:
      return { loading: false, error: action.payload,AllDriversList:[] };
    default:
      return state;
  }
};

export const customerCommentReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_CUSTOMER_COMMENT_REQUEST:
      return { loading1: true };
    case ADMIN_CUSTOMER_COMMENT_SUCCESS:
      return { loading1: false, customerComments: action.payload };
    case ADMIN_CUSTOMER_COMMENT_FAIL:
      return { loading1: false, error: action.payload };
    default:
      return state;
  }
};

export const createSubAdminReducer = (state = { status: false }, action) => {
  switch (action.type) {
    case ADMIN_CREATE_SUB_ADMIN_REQUEST:
      return { loading: true, status: false, exist: false };
    case ADMIN_CREATE_SUB_ADMIN_SUCCESS:
      if (action.payload.exist) {
        return { loading: false, status: false, exist: true };
      } else {
        return { loading: false, status: true, exist: false };
      }
    case ADMIN_CREATE_SUB_ADMIN_FAIL:
      return { loading1: false, error: action.payload, status: false };
    default:
      return state;
  }
};

export const dashboardDateFilterReducer = (
  state = { dashDateFilter: false, from: null, to: null, change: false },
  action
) => {
  if (action.type === ADMIN_DASHBOARD_DATE_FILTER) {
    if (action.payload.from !== null) {
      return {
        dashDateFilter: true,
        from: action.payload.from,
        to: action.payload.to,
        change: !state.change,
      };
    } else {
      return { ...state };
    }
  } else {
    return { ...state };
  }
};

export const editSubAdminReducer = (
  state = { updateStatus: false },
  action
) => {
  switch (action.type) {
    case ADMIN_UPDATE_SUB_ADMIN_REQUEST:
      return { loading: true, updateStatus: false };
    case ADMIN_UPDATE_SUB_ADMIN_SUCCESS:
      return { loading: false, updateStatus: !state.updateStatus };

    case ADMIN_UPDATE_SUB_ADMIN_FAIL:
      return { loading1: false, error: action.payload, updateStatus: false };
    default:
      return state;
  }
};
export const graphFilterWeekReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_DASHBOARD_WEEK_GRAPH_REQUEST:
      return { loading: true };
    case ADMIN_GET_DASHBOARD_WEEK_GRAPH_SUCCESS:
      return {
        loading: false,
        graphData: action.payload,
      };
    case ADMIN_GET_DASHBOARD_WEEK_GRAPH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getDashboardReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_DASHBOARD_REQUEST:
      return { loading: true, loading2: true };
    case ADMIN_GET_DASHBOARD_SUCCESS:
      return {
        loading: false,
        loading2: false,
        data: action.payload,
      };
    case ADMIN_GET_DASHBOARD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateDashboardReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case ADMIN_DASHBOARD_UPDATE_REQUEST:
      return { loading: true, success: false };
    case ADMIN_DASHBOARD_UPDATE_SUCCESS:
      return { loading: false, success: !state.success };
    case ADMIN_DASHBOARD_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const driverCommentReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_DRIVER_COMMENT_REQUEST:
      return { loading: true };
    case ADMIN_DRIVER_COMMENT_SUCCESS:
      return { loading: false, driverCommets: action.payload };
    case ADMIN_DRIVER_COMMENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const driverDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_DRIVER_DETAILS_REQUEST:
      return { loading: true };
    case ADMIN_DRIVER_DETAILS_SUCCESS:
      return { loading: false, DriverDetails: action.payload };
    case ADMIN_DRIVER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approvalDriverReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_DRIVER_APPROVAL_REQUEST:
      return { approvalLoding: true, approve: false, change: 1 };
    case ADMIN_DRIVER_APPROVAL_SUCCESS:
      return {
        approvalLoding: false,
        approve: true,
        drId: action.payload.driverId,
        token: action.payload.token,
        change: +state.change + 1,
      };
    case ADMIN_DRIVER_APPROVAL_FAIL:
      return { approvalLoding: false, error: action.payload, approve: false };
    default:
      return state;
  }
};

export const adminSubadminsRaducer = (state = { success: false }, action) => {
  switch (action.type) {
    case ADMIN_SUB_ADMIN_LIST_REQUEST:
      return { loading: true, success: false };
    case ADMIN_SUB_ADMIN_LIST_SUCCESS:
      return {
        loading: false,
        SubAdminsList: action.payload,
        success: !state.success,
      };
    case ADMIN_SUB_ADMIN_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const filteredDriverReducer = (state = { change: 1 }, action) => {
  switch (action.type) {
    case ADMIN_FILTERED_DRIVER_LIST_REQUEST:
      return { loading: true, change: state.change + 1 };
    case ADMIN_FILTERED_DRIVER_LIST_SUCCESS:
      return {
        loading: false,
        DriverList: action.payload,
        success: true,
        change: state.change + 1,
      };
    case ADMIN_FILTERED_DRIVER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const refreshCustomerReducer = (state = { refresh: false }, action) => {
  if (action.type === ADMIN_FILTER_USER_REFRESH) {
    return {
      refresh: !state.refresh,
    };
  } else {
    return state;
  }
};
export const refreshReducer = (state = { refresh: false }, action) => {
  if (action.type === ADMIN_FILTER_REFRESH) {
    return {
      refresh: !state.refresh,
    };
  } else {
    return state;
  }
};

export const BookingRefreshReducer = (state = { refresh: false }, action) => {
  if (action.type === ADMIN_BOOKING_REFRESH) {
    return {
      refresh: !state.refresh,
    };
  } else {
    return state;
  }
};

export const searchDateFilterReducer = (state = { content: null }, action) => {
  if (action.type === ADMIN_DATE_FILTER_DRIVER) {
    if (action.payload.from && action.payload.to) {
      return {
        from: action.payload.from,
        to: action.payload.to,
        statusDateFilter: true,
      };
    } else {
      return {
        statusDateFilter: false,
      };
    }
  } else {
    return state;
  }
};

export const searchFilterReducer = (state = { content: null }, action) => {
  if (action.type === ADMIN_SEARCH_DRIVER) {
    if (action.payload) {
      return {
        content: action.payload,
      };
    } else {
      return {
        content: null,
      };
    }
  } else {
    return state;
  }
};

export const serachCustomerReducer = (state = { content: null }, action) => {
  if (action.type === ADMIN_CUSTOMER_SEARCH_STATUS) {
    return { content: action.payload };
  } else {
    return state;
  }
};

export const filterDriverSubVehicleTypeReducer = (
  state = { subTypeFilter: false, filters: [], subChange: 1 },
  action
) => {
  if (action.type === ADMIN_DRIVER_FILTER_SUB_VEHICLE_TYPE) {
    if (action.payload.length === 0) {
      return {
        subChange: 2,
        subTypeFilter: false,
        filters: [],
      };
    } else {
      return {
        subChange: state.subChange + 1,
        subTypeFilter: true,
        filters: [...action.payload],
      };
    }
  } else {
    return { ...state };
  }
};

export const filterDriverVehicleTypeReducer = (
  state = { VehcleTypeFilter: false, filters: [], typeChange: 1 },
  action
) => {
  if (action.type === ADMIN_DRIVER_FILTER_VEHICLE_TYPE) {
    if (action.payload.length === 0) {
      return {
        VehcleTypeFilter: false,
        filters: [],
        typeChange: 2,
      };
    } else {
      return {
        VehcleTypeFilter: true,
        filters: [...action.payload],
        typeChange: state.typeChange + 1,
      };
    }
  } else {
    return state;
  }
};

export const filterCustomerStatusReducer = (
  state = { statusCustomerFilter: false, customerfilters: [] },
  action
) => {
  if (action.type === ADMIN_CUSTOMER_FILTER_STATUS) {
    if (action.payload.length === 0) {
      return {
        statusCustomerFilter: false,
        customerfilters: [],
      };
    } else {
      return {
        statusCustomerFilter: true,
        customerfilters: [...action.payload],
      };
    }
  } else {
    return state;
  }
};

export const filterDriverStatusReducer = (
  state = { statusFilter: false, filters: [], flt1: false },
  action
) => {
  if (action.type === ADMIN_DRIVER_FILTER_STATUS) {
    if (action.payload.length === 0) {
      return {
        statusFilter: false,
        filters: [],
      };
    } else {
      return {
        statusFilter: true,

        filters: [...action.payload],
      };
    }
  } else {
    return state;
  }
};

export const listAllCustomersReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_MANAGE_CUSTOMER_LIST_REQUEST:
      return { loading: true };
    case ADMIN_MANAGE_CUSTOMER_LIST_SUCCESS:
      return { loading: false, customerLists: action.payload };
    case ADMIN_MANAGE_CUSTOMER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const ListUsersNotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_REQUEST:
      return { loading: true };
    case ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_SUCCESS:
      return { loading: false, customerLists: action.payload };
    case ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const PushNotifiationReducer = (
  state = { success: false, fail: false },
  action
) => {
  switch (action.type) {
    case ADMIN_PUSH_NOTIFICATION_REQUEST:
      return { success: false, fail: false };
    case ADMIN_PUSH_NOTIFICATION_SUCCESS:
      return { success: true, fail: false };
    case ADMIN_PUSH_NOTIFICATION_FAIL:
      return { success: false, fail: false, error: action.payload };
    default:
      return state;
  }
};
export const updateCustomerReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_UPDATE_CUSTOMER_REQUEST:
      return {};
    case ADMIN_UPDATE_CUSTOMER_SUCCESS:
      if (state.success) {
        let createChange = state.success + 1;
        return { success: createChange };
      } else {
        return { success: 1 };
      }

    case ADMIN_UPDATE_CUSTOMER_FAIL:
      return { error: action.payload };
    default:
      return state;
  }
};

export const updateDriverReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_UPDATE_DRIVER_REQUEST:
      return { waiting: true };
    case ADMIN_UPDATE_DRIVER_SUCCESS:
      if (state.updateSuccess) {
        let createChange = state.updateSuccess + 1;
        return { waiting: false, updateSuccess: createChange };
      } else {
        return { waiting: false, updateSuccess: 1 };
      }
    case ADMIN_UPDATE_DRIVER_FAIL:
      return { waiting: false, error: action.payload };
    default:
      return state;
  }
};

export const getSingleCutomerReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_SINGLE_CUSTOMER_REQUEST:
      return {};
    case ADMIN_SINGLE_CUSTOMER_SUCCESS:
      return { customerInfo: action.payload };
    case ADMIN_SINGLE_CUSTOMER_FAIL:
      return { error: action.payload };
    default:
      return state;
  }
};
// export const getSingleDriverReducer = (state = {}, action) => {
//   switch (action.type) {
//     case ADMIN_SINGLE_DRIVER_REQUEST:
//       return {};
//     case ADMIN_SINGLE_DRIVER_SUCCESS:
//       return { driverInfo: action.payload };
//     case ADMIN_SINGLE_DRIVER_FAIL:
//       return { error: action.payload };
//     default:
//       return state;
//   }
// };

export const listPricingReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_PRICING_DATA_REQUEST:
      return {};
    case ADMIN_PRICING_DATA_SUCCESS:
      return { pricingListInfo: action.payload };
    case ADMIN_PRICING_DATA_FAIL:
      return { error: action.payload };
    default:
      return state;
  }
};

export const getPricingTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_SINGLE_PRICINGTYPE_REQUEST:
      return { loading: true };
    case ADMIN_SINGLE_PRICINGTYPE_SUCCESS:
      return { loading: false, pricingtype: action.payload };
    case ADMIN_SINGLE_PRICINGTYPE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const add_driver_banner = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_ADD_DRIVER_BANNER_REQUEST:
      return { loading: true };
    case ADMIN_ADD_DRIVER_BANNER_SUCCESS:
      return { loading: false, addsuccess: true };
    case ADMIN_ADD_DRIVER_BANNER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const listAllDriverBannerReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_DRIVER_BANNER_LIST_REQUEST:
      return { loading: true };
    case ADMIN_DRIVER_BANNER_LIST_SUCCESS:
      return { loading: false, driverBanners: action.payload };
    case ADMIN_DRIVER_BANNER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const delete_sub_admin_reducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_DELETE_SUB_ADMIN_REQUEST:
      return { loading: true, deleted: false };
    case ADMIN_DELETE_SUB_ADMIN_SUCCESS:
      return { loading: false, deleted: true };
    case ADMIN_DELETE_SUB_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const delete_driver_banner = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_DELETE_DRIVER_BANNER_REQUEST:
      return { loading: true };
    case ADMIN_DELETE_DRIVER_BANNER_SUCCESS:
      return { loading: false, deleted: true };
    case ADMIN_DELETE_DRIVER_BANNER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const update_driver_banner = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_UPDATE_DRIVER_BANNER_REQUEST:
      return { loading: true };
    case ADMIN_UPDATE_DRIVER_BANNER_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_UPDATE_DRIVER_BANNER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const add_user_banner = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_ADD_USER_BANNER_REQUEST:
      return { loading: true };
    case ADMIN_ADD_USER_BANNER_SUCCESS:
      return { loading: false, addsuccess: true };
    case ADMIN_ADD_USER_BANNER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const listAllUserBannerReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_USER_BANNER_LIST_REQUEST:
      return { loading: true };
    case ADMIN_USER_BANNER_LIST_SUCCESS:
      return { loading: false, userBanners: action.payload };
    case ADMIN_USER_BANNER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const delete_user_banner = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_DELETE_USER_BANNER_REQUEST:
      return { loading: true };
    case ADMIN_DELETE_USER_BANNER_SUCCESS:
      return { loading: false, deleted: true };
    case ADMIN_DELETE_USER_BANNER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const update_user_banner = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_UPDATE_USER_BANNER_REQUEST:
      return { loading: true };
    case ADMIN_UPDATE_USER_BANNER_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_UPDATE_USER_BANNER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updatePricingTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_UPDATE_PRICINGTYPE_REQUEST:
      return { loading: true };
    case ADMIN_UPDATE_PRICINGTYPE_SUCCESS:
      return { loading: false, success: true };
    case ADMIN_UPDATE_PRICINGTYPE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getBookingsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LIST_ALL_BOOKINGS_REQUEST:
      return { loading: true };
    case ADMIN_LIST_ALL_BOOKINGS_SUCCESS:
      return { loading: false, bookingsInfo: action.payload };
    case ADMIN_LIST_ALL_BOOKINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminBookingsAddCommentReducer = (
  state = { commentSuccess: false },
  action
) => {
  switch (action.type) {
    case ADMIN_BOOKING_ADDCOMMENT_REQUEST:
      return { loading: true, commentSuccess: false };
    case ADMIN_BOOKING_ADDCOMMENT_SUCCESS:
      return { loading: false, commentSuccess: true };
    case ADMIN_BOOKING_ADDCOMMENT_FAIL:
      return { loading: false, error: action.payload, commentSuccess: false };
    default:
      return state;
  }
};

export const getNewOrderRducer = (state = { sucess: false }, action) => {
  switch (action.type) {
    case ADMIN_NEW_ORDER_REQUEST:
      return { loading: true, sucess: null, drvId: null };
    case ADMIN_NEW_ORDER_SUCCESS:
      return {
        loading: false,
        sucess: action.payload.status,
        drvId: action.payload._id,
        newBooking: action.payload,
        statusChange: action.payload.status,
      };
    case ADMIN_NEW_ORDER_FAIL:
      return {
        loading: false,
        sucess: state.sucess + 1,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const adminBookingsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_BOOKING_UPDATE_REQUEST:
      return { loading: true };
    case ADMIN_BOOKING_UPDATE_SUCCESS:
      return { loading: false, sucess: true };
    case ADMIN_BOOKING_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminPromocodeReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_PROMOCODE_GENRATION_REQUEST:
      return { loading: true };
    case ADMIN_PROMOCODE_GENRATION_SUCCESS:
      return { loading: false, genPromocode: action.payload };
    case ADMIN_PROMOCODE_GENRATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createPromocodeReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_CREATE_PROMOCODE_REQUEST:
      return { loading: true, addpromo: false };
    case ADMIN_CREATE_PROMOCODE_SUCCESS:
      return {
        loading: false,
        promocodeDetails: action.payload,
        addpromo: true,
      };
    case ADMIN_CREATE_PROMOCODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getAllPromocodeReducer = (state = {}, action) => {
  switch (action.type) {
    case PROMOCODE_LIST_REQUEST:
      return { loading: true };
    case PROMOCODE_LIST_SUCCESS:
      return { loading: false, promocodes: action.payload };
    case PROMOCODE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deletePromocodeReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PROMOCODE_REQUEST:
      return { loading: true };
    case DELETE_PROMOCODE_SUCCESS:
      return { loading: false, deletesuccess: action.payload };
    case DELETE_PROMOCODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const singlePromocodeReducer = (state = {}, action) => {
  switch (action.type) {
    case SINGLE_PROMOCODE_REQUEST:
      return { loading: true };
    case SINGLE_PROMOCODE_SUCCESS:
      return { loading: false, codeDetails: action.payload };
    case SINGLE_PROMOCODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updatePromocodeReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROMOCODE_REQUEST:
      return { loading: true };
    case UPDATE_PROMOCODE_SUCCESS:
      return { loading: false, updatePromocode: true };
    case UPDATE_PROMOCODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const filterBookingReducer = (
  state = { bookingStatus: false, filters: [] },
  action
) => {
  if (action.type === ADMIN_FILTER_BOOKING) {
    if (action.payload.length === 0) {
      return {
        bookingStatus: false,
        filters: [...action.payload],
      };
    } else {
      return {
        bookingStatus: true,
        filters: [...action.payload],
      };
    }
  } else {
    return state;
  }
};

export const addressFromLatLng = (state = {}, action) => {
  if (action.type === ADMIN_DRIVER_ADDRESS_SAVING) {
    return {
      address_driver: action.payload,
    };
  } else {
    return state;
  }
};
export const getCoinOfferRequestsReducers = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_OFFER_COINS_REQUEST:
      return { loading: true };
    case ADMIN_GET_OFFER_COINS_SUCCESS:
      return {
        loading: false,
        coinOfferRequests: action.payload,
      };
    case ADMIN_GET_OFFER_COINS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const getWithdrawalRequestsReducers = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_GET_WITHDRAWALREQUESTS_REQUEST:
      return { loading: true };
    case ADMIN_GET_WITHDRAWALREQUESTS_SUCCESS:
      return {
        loading: false,
        withdrawalRequests: action.payload,
      };
    case ADMIN_GET_WITHDRAWALREQUESTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getWithdrawalRequestDriverDetailseducers = (
  state = {},
  action
) => {
  switch (action.type) {
    case ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_REQUEST:
      return { loading: true };
    case ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_SUCCESS:
      return {
        loading: false,
        withdrawalRequestDriverDetails: action.payload,
      };
    case ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const claimOfferActionRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_ACTION_COIN_ClAIM_REQUEST:
      return { loading: true, action: false };
    case ADMIN_ACTION_COIN_ClAIM_SUCCESS:
      return {
        loading: false,
        actionStatus: true,
        // rejectWithdrawalRequest: action.payload,
      };
    case ADMIN_ACTION_COIN_ClAIM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const rejectWithdrawalRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_REJECT_WITHDRAWALREQUEST_REQUEST:
      return { loading: true, reject: false };
    case ADMIN_REJECT_WITHDRAWALREQUEST_SUCCESS:
      return {
        loading: false,
        reject: true,
        rejectWithdrawalRequest: action.payload,
      };
    case ADMIN_REJECT_WITHDRAWALREQUEST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const approveWithdrawalRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_APPROVE_WITHDRAWAlREQUEST_REQUEST:
      return { loading: true, withdrowal: false };
    case ADMIN_APPROVE_WITHDRAWAlREQUEST_SUCCESS:
      return {
        loading: false,
        withdrowal: true,
        approveWithdrawalRequest: action.payload,
      };
    case ADMIN_APPROVE_WITHDRAWAlREQUEST_FAIL:
      return { loading: false, error: action.payload, withdrowal: false };
    default:
      return state;
  }
};

export const addCommisionReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_COMMISION_REQUEST:
      return { loading: true };
    case ADD_COMMISION_SUCCESS:
      return { loading: false, commision: action.payload };
    case ADD_COMMISION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const currentCommisionReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_COMMISION_REQUEST:
      return { loading: true };
    case CURRENT_COMMISION_SUCCESS:
      return { loading: false, currentCommision: action.payload };
    case CURRENT_COMMISION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const currentBookingDriverLocationReducer = (state = {}, action) => {
  switch (action.type) {
    case DRIVER_CURRENTLOCATION_BOOKING_REQUEST:
      return { loading: true };
    case DRIVER_CURRENTLOCATION_BOOKING_SUCCESS:
      return { loading: false, currentLocation: action.payload };
    case DRIVER_CURRENTLOCATION_BOOKING_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customerOrdersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CUSTOMER_ORDERS_REQUEST:
      return { loading: true };
    case GET_CUSTOMER_ORDERS_SUCCESS:
      return { loading: false, customerOrder: action.payload };
    case GET_CUSTOMER_ORDERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const driverOrdersReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DRIVER_ORDERS_REQUEST:
      return { loading: true };
    case GET_DRIVER_ORDERS_SUCCESS:
      return { loading: false, driverOrders: action.payload };
    case GET_DRIVER_ORDERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const driverReviewsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DRIVER_REVIEWS_REQUEST:
      return { loading: true };
    case GET_DRIVER_REVIEWS_SUCCESS:
      return { loading: false, driverReviews: action.payload };
    case GET_DRIVER_REVIEWS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
