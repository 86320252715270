import * as React from "react";
import { useDispatch } from "react-redux";
import { Avatar, Button, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { subAdminLogout } from "../../../Actions/SubAdminAction";

import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";

const drawerWidth = 240;

export default function Navbar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(subAdminLogout());
    navigate("/sub-admin/login");
  };
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              <Chip
                avatar={<Avatar alt="Admin_icon" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQm3RFDZM21teuCMFYx_AROjt-AzUwDBROFww&usqp=CAU" />}
                size="medium"
                sx={{border:"solid 1px #e75c05", mr: 2,fontSize:"16px",fontWeight:"bold",fontFamily:"cursive",color:"#e75c05"}}
                label={props.subAdminName}
                variant="outlined"
              />
              <Button
                onClick={handleLogout}
                variant="contained"
                sx={{ backgroundColor: "#e75c05" }}
                startIcon={<LogoutIcon />}
              >
                Logout
              </Button>
            </Typography>
          </Toolbar>
        </div>
      </AppBar>
    </>
  );
}
