import { functions, isEqual, omit } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { React_App_Google_map_api } from "../../../mapKey/MapKey";

function Map({ options, locSearch, onMount,locationPoint, className, onMountProps, change }) {
  const ref = useRef();
  var [map, setMap] = useState();
  useEffect(() => {
    const onLoad = () =>
      setMap(new window.google.maps.Map(ref.current, { ...options }));

    if (!window.google) {
      const script = document.createElement(`script`);
      script.src = `https://maps.googleapis.com/maps/api/js?key=${React_App_Google_map_api}`;

      document.head.append(script);
      script.addEventListener(`load`, onLoad);

      return () => script.removeEventListener(`load`, onLoad);
    } else onLoad();
  }, [options, change]);
  if (map && typeof onMount === `function`) {
    // locationPoint(map,options,locSearch);
    onMount(map, onMountProps);
  }

  const locationMarker = () => {
    var marker = new window.google.maps.Marker({
      position: options.center,
      map,
    });
    marker.setMap(map);
  };
  const removeMarker = () => {
    var marker  = new window.google.maps.Marker({
      position: options.center,
      map,
      icon:"no"
    });
    marker.setMap(map);
  };
  if (locSearch) {
    locationMarker()
  }else{
    removeMarker()
  }

  return (
    <div
      style={{
        width: "100%",
        height: `70vh`,
        margin: `1em 0`,
        borderRadius: `0.5em`,
      }}
      {...{ ref, className }}
    />
  );
}
function shouldNotUpdate(props, nextProps) {
  const [funcs, nextFuncs] = [functions(props), functions(nextProps)];
  const noPropChange = isEqual(omit(props, funcs), omit(nextProps, nextFuncs));
  const noFuncChange =
    funcs.length === nextFuncs.length &&
    funcs.every((fn) => props[fn].toString() === nextProps[fn].toString());
  return noPropChange && noFuncChange;
}

export default React.memo(Map, shouldNotUpdate);
Map.defaultProps = {
  options: {
    center: { lat: 12.971599, lng: 77.594566 },
    zoom: 13,
    disableDefaultUI: true,
  },
};
