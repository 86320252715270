export const SUB_ADMIN_LOGOUT = 'SUB_ADMIN_LOGOUT'
export const SUB_ADMIN_LOGIN_REQUEST = 'SUB_ADMIN_LOGIN-REQUEST';
export const SUB_ADMIN_LOGIN_SUCCESS = 'SUB_ADMIN_LOGIN-SUCCESS';
export const SUB_ADMIN_LOGIN_FAIL = 'SUB_ADMIN_LOGIN-FAIL';

export const SUB_ADMIN_CUSTOMER_REQUEST = 'SUB_ADMIN_CUSTOMER_REQUEST';
export const SUB_ADMIN_COSTOMER_SUCCESS = 'SUB_ADMIN_COSTOMER_SUCCESS';
export const SUB_ADMIN_COSTOMER_FAIL = 'SUB_ADMIN_COSTOMER_FAIL';

export const SUB_ADMIN_UPDATE_CUSTOMER_REQUEST = 'SUB_ADMIN_UPDATE_CUSTOMER_REQUEST';
export const SUB_ADMIN_UPDATE_CUSTOMER_SUCCESS = 'SUB_ADMIN_UPDATE_CUSTOMER_SUCCESS';
export const SUB_ADMIN_UPDATE_CUSTOMER_FAIL = 'SUB_ADMIN_UPDATE_CUSTOMER_SUCCESS';

export const SUB_ADMIN_DRIVER_LIST_REQUEST = 'SUB_ADMIN_DRIVER_LIST_REQUEST'
export const SUB_ADMIN_DRIVER_LIST_SUCCESS = 'SUB_ADMIN_DRIVER_LIST_SUCCESS'
export const SUB_ADMIN_DRIVER_LIST_FAIL = 'SUB_ADMIN_DRIVER_LIST_FAIL'

export const SUB_ADMIN_DRIVER_ADD_COMMENT_REQUEST = 'SUB_ADMIN_DRIVER_ADD_COMMENT_REQUEST'
export const SUB_ADMIN_DRIVER_ADD_COMMENT_SUCCESS = "SUB_ADMIN_DRIVER_ADD_COMMENT_SUCCESS"
export const SUB_ADMIN_DRIVER_ADD_COMMENT_FAIL = 'SUB_ADMIN_DRIVER_ADD_COMMENT_FAIL'

export const SUB_ADMIN_BOOKING_LIST_REQUEST = 'SUB_ADMIN_BOOKING_LIST_REQUEST'
export const SUB_ADMIN_BOOKING_LIST_SUCCESS = 'SUB_ADMIN_BOOKING_LIST_SUCCESS'
export const SUB_ADMIN_BOOKING_LIST_FAIL = 'SUB_ADMIN_BOOKING_LIST_FAIL'

export const SUB_ADMIN_UPDATE_DRIVER_REQUEST = 'SUB_ADMIN_UPDATE_DRIVER_REQUEST'
export const SUB_ADMIN_UPDATE_DRIVER_SUCCESS = 'SUB_ADMIN_UPDATE_DRIVER_SUCCESS'
export const SUB_ADMIN_UPDATE_DRIVER_FAIL = 'SUB_ADMIN_UPDATE_DRIVER_FAIL'

export const SUB_ADMIN_BOOKING_ADDCOMMENT_REQUEST = 'SUB_ADMIN_BOOKING_ADDCOMMENT_REQUEST'
export const SUB_ADMIN_BOOKING_ADDCOMMENT_SUCCESS = 'SUB_ADMIN_BOOKING_ADDCOMMENT_SUCCESS'
export const SUB_ADMIN_BOOKING_ADDCOMMENT_FAIL = 'SUB_ADMIN_BOOKING_ADDCOMMENT_FAIL'

export const SUB_ADMIN_FILTERED_DRIVER_LIST_REQUEST = 'SUB_ADMIN_FILTERED_DRIVER_LIST_REQUEST'
export const SUB_ADMIN_FILTERED_DRIVER_LIST_SUCCESS = 'SUB_ADMIN_FILTERED_DRIVER_LIST_SUCCESS'
export const SUB_ADMIN_FILTERED_DRIVER_LIST_FAIL = 'SUB_ADMIN_FILTERED_DRIVER_LIST_FAIL'

export const SUB_ADMIN_DRIVER_FILTER_STATUS = 'SUB_ADMIN_DRIVER_FILTER_STATUS'

export const SUB_ADMIN_DRIVER_FILTER_VEHICLE_TYPE = 'SUB_ADMIN_DRIVER_FILTER_VEHICLE_TYPE'
