import { Chart, Series } from "devextreme-react/chart";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";


export default function Graph({ dataSource }) {
  return (
    <Chart id="chart" dataSource={dataSource}>
      <Series
        dashStyle={{ innerWidth: "10px", outerWidth: "10px" }}
        barWidth={50}
        valueField="Orders"
        argumentField="day"
        name="Orders"
        type="bar"
        color="#ffaa66"
      />
    </Chart>
  );
}
