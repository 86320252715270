import { useDispatch, useSelector } from "react-redux";
import { PushNotificationAction } from "../../../Actions/AdminActions";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import { Button, TextareaAutosize, TextField } from "@mui/material";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";

const drawerWidth = 240;

export default function NotificationForm(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { success } = useSelector((state) => state.notification);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [start1, setStart1] = useState(false);
  const [start2, setStart2] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!success && props.click) setLoading(true);
    else setLoading(false);
  }, [success]);
  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "title") {
      setTitle(value);
      setStart1(true);
    } else if (name === "body") {
      setBody(value);
      setStart2(true);
    }
  };

  const submit = async () => {
    if (body && title) {
      let data = await dispatch(
        PushNotificationAction({
          body,
          title,
          notificationFor: props.NotificationFor,
          tokens: props.tokens,
          type: props.type,
        })
      );
      if (data) {
        Swal.fire("Success!", "This notifcation successfully sent.", "success");
      }
    } else {
      if (!body) {
        Swal.fire(
          "Somthing Wrong!",
          "This notifcation missing Notification body.",
          "warning"
        );
      }
      if (!title) {
        Swal.fire(
          "Somthing Wrong!",
          "This notifcation missing Notification title.",
          "warning"
        );
      }
    }
  };

  return (
    <>
      <div align="center" style={{ fontSize: "18px", color: "blue" }}>
        <strong>Enter Something....🖊️🖊️🖊️</strong>
        <LoadingButton
          size="small"
          sx={{ position: "relative", left: 260 }}
          onClick={submit}
          loading={loading}
          loadingPosition="end"
          variant="contained"
          endIcon={<SendIcon />}
        >
          send
        </LoadingButton>
      </div>
      <div align="center" style={{ marginTop: 20 }}>
        {start1 && !title ? (
          <p style={{ color: "red" }}>𝒫𝓁𝑒𝒶𝓈𝑒 𝐸𝓃𝓉𝑒𝓇 𝒩𝑜𝓉𝒾𝒻𝒾𝒸𝒶𝓉𝒾𝑜𝓃 𝒯𝒾𝓉𝓁𝑒</p>
        ) : (
          ""
        )}

        <TextField
          name="title"
          onChange={handleInput}
          sx={{ width: "80%" }}
          label="🎀 𝙀𝙣𝙩𝙚𝙧 𝙉𝙤𝙩𝙞𝙛𝙞𝙘𝙖𝙩𝙞𝙤𝙣 𝙏𝙞𝙩𝙡𝙚... 🎀 "
          id="fullWidth"
          value={title}
        />
      </div>
      <div align="center" style={{ marginTop: 20 }}>
        {start2 && !body ? (
          <p style={{ color: "red" }}>𝒫𝓁𝑒𝒶𝓈𝑒 𝐸𝓃𝓉𝑒𝓇 𝒩𝑜𝓉𝒾𝒻𝒾𝒸𝒶𝓉𝒾𝑜𝓃 𝐵𝑜𝒹𝓎</p>
        ) : (
          ""
        )}

        <TextareaAutosize
          name="body"
          onChange={handleInput}
          aria-label="minimum height"
          minRows={5}
          placeholder="𝙀𝙣𝙩𝙚𝙧 𝙉𝙤𝙩𝙞𝙛𝙞𝙘𝙖𝙩𝙞𝙤𝙣 𝘽𝙤𝙙𝙮...📗📗📗"
          style={{
            width: "80%",
            borderColor: "lightgray",
            borderRadius: "5px",
          }}
          value={body}
        />
      </div>
    </>
  );
}
