import { functions, isEqual, omit } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { React_App_Google_map_api } from '../../../mapKey/MapKey';
function Map({ options, onMount, className, onMountProps, polyline, google }) {
  const ref = useRef();
  const [map, setMap] = useState();
 
  useEffect(() => {
    // The Google Maps API modifies the options object passed to
    // the Map constructor in place by adding a mapTypeId with default
    // value 'roadmap'. { ...options } prevents this by creating a copy.
    const onLoad = () =>
      setMap(new window.google.maps.Map(ref.current, { ...options }));

    if (!window.google) {
      const script = document.createElement(`script`);
     
      script.src = `https://maps.googleapis.com/maps/api/js?key=${React_App_Google_map_api}`;
      document.head.append(script);
      script.addEventListener(`load`, onLoad);  
      return () => script.removeEventListener(`load`, onLoad);
    } else onLoad();
  }, [options]);

  if (map && typeof onMount === `function`) onMount(map, onMountProps);
  if (window.google) {
    let waypts = [];
    if (polyline.length == 3) {
      waypts[0] = {
        stopover: true,
        location: new window.google.maps.LatLng(
          polyline[1].lat,
          polyline[1].lng
        ),
      };
    } else if (polyline.length == 4) {
      waypts[0] = {
        stopover: true,
        location: new window.google.maps.LatLng(
          polyline[1].lat,
          polyline[1].lng
        ),
      };
      waypts[1] = {
        stopover: true,
        location: new window.google.maps.LatLng(
          polyline[2].lat,
          polyline[2].lng
        ),
      };
    }
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer();
    directionsRenderer.setMap(map);

    directionsService
      .route({
        origin: new window.google.maps.LatLng(polyline[0].lat, polyline[0].lng),
        destination: new window.google.maps.LatLng(
          polyline[polyline.length - 1].lat,
          polyline[polyline.length - 1].lng
        ),
        waypoints: waypts,
        optimizeWaypoints: true,
        travelMode: 'DRIVING',
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
      })
      .catch((e) => window.alert('Directions request failed due to ' + e));
  }

  return (
    <div
      style={{
        width: '750px',
        height: `400px`,
        borderRadius: `0.5em`,
      }}
      {...{ ref, className }}
    />
  );
}
function shouldNotUpdate(props, nextProps) {
  const [funcs, nextFuncs] = [functions(props), functions(nextProps)];
  const noPropChange = isEqual(omit(props, funcs), omit(nextProps, nextFuncs));
  const noFuncChange =
    funcs.length === nextFuncs.length &&
    funcs.every((fn) => props[fn].toString() === nextProps[fn].toString());
  return noPropChange && noFuncChange;
}
export default React.memo(Map, shouldNotUpdate);
Map.defaultProps = {
  options: {
    center: { lat: 48, lng: 8 },
    zoom: 3,
  },
};
