import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import { useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import axios from "axios";
import DashboardCard from "../../Components/Admin/cards/DashboardCard";
import Graph from "../../Components/Admin/graph/Graph";
import Version from "../../Components/Admin/version/Version";
import {
  DaysFilter,
  WeekDateFilter,
} from "../../Components/Admin/dashboard-helper/Filters";
import {
  getDashboardAction,
  getDashboardDataAction,
  getGraphAction,
} from "../../Actions/AdminActions";
import RefreshIcon from "@mui/icons-material/Refresh";
import { makeStyles } from "@material-ui/core";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
const useStyles = makeStyles((theme) => ({
  refresh: {
    margin: "auto",
  },
  spin: {
    margin: "auto",
    animation: "$spin 1s infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

const AdminlDashboard = () => {
  const naviagate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.dashboard);
  const { success } = useSelector((state) => state.updateDashboard);
  const { graphData } = useSelector((state) => state.weekfilerdashboard);

  const { dashDateFilter, from, change, to } = useSelector(
    (state) => state.dashboardDateFilter
  );
  const [place, setPlace] = React.useState("both");
  const [from2, setFrom2] = useState(null);
  const [to2, setTo2] = useState(null);
  const [spin, setSpin] = React.useState();
  const classes = useStyles();

  useEffect(() => {
    if (data) {
      setSpin(false);
    } else {
      setSpin(true);
    }
 
  }, [data, success, spin, dispatch]);

  const refreshCanvas = () => {
    setSpin(true);
    console.log("Refreshed");
  };
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;

  useEffect(() => {
    if (!adminInfo) {
      naviagate("/login");
    }
  }, [adminInfo, naviagate]);

  useEffect(() => {
    if (dashDateFilter && to !== "null") {
      dispatch(getDashboardDataAction(from, to, place));
    } else if (dashDateFilter && to == "null") {
      dispatch(getDashboardDataAction(from, "null", place));
    } else {
      dispatch(getDashboardAction(place));
    }
  }, [success, dashDateFilter, from, to, change, place]);

  useEffect(() => {
    if (place) {
      dispatch(getGraphAction(null, place));
    }
  }, [place]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="Dashboard"
          id={adminInfo && adminInfo._id}
          email={adminInfo && adminInfo.email}
          name={adminInfo && `${adminInfo.firstName} ${adminInfo.lastName}`}
          token={adminInfo && adminInfo.token}
        />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 1 }}
        >
          <Toolbar />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginRight: "10px",
            }}
          >
            <h5 style={{ display: "inline-block",marginTop:"12px",color:"green" }}>ANALYTICS DASHBOARD</h5>
            <span>
              <FormControl>
                <RadioGroup
                  row
                  onClick={(e) => {  
                    setPlace(e.target.value);
                  }}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="Place"
                >
                  <FormControlLabel
                    value="Bangalore"
                    control={<Radio />}
                    label="Bangalore"
                  />
                  <FormControlLabel
                    value="Mysore"
                    control={<Radio />}
                    label="Mysore"
                  />
                  <FormControlLabel
                    value="both"
                    control={<Radio />}
                    label="Both"
                  />
                </RadioGroup>
              </FormControl>
            </span>
            <span>
              <DaysFilter
                from2={from2}
                to2={to2}
                refreshCanvas={refreshCanvas}
                spin={spin}
                place={place}
              />
            </span>
          </div>
          <div>
            <DashboardCard
              cardWidth="48.5%"
              count={data && data.activeDrivers && data.activeDrivers}
              title="Active Drivers"
              spin={spin}
              color="#e75c05"
            />
            <DashboardCard
              cardWidth="48.5%"
              spin={spin}
              count={data && data.pendingDrivers}
              title="Pending Drivers"
              color="#e75c05"
            />
            <DashboardCard
              cardWidth="347px"
              spin={spin}
              count={data && data.completedOrders}
              title="Orders Completed"
              color="#e75c05"
            />
            <DashboardCard
              cardWidth="347px"
              spin={spin}
              count={data && data.cancelledOrders}
              title="Canclelled order"
              color="#e75c05"
            />
            <DashboardCard
              cardWidth="347px"
              spin={spin}
              count={data && data.ongoingOrders}
              title="Ongoing order"
              color="#e75c05"
            />
            <DashboardCard
              cardWidth="347px"
              spin={spin}
              count={data && data.expireOrderCount}
              title="Expired Order"
              color="#e75c05"
            />
            <DashboardCard
              cardWidth="347px"
              spin={spin}
              count={data && data.totalCustomers}
              title="Customers"
              color="#e75c05"
            />
            <DashboardCard
              cardWidth="347px"
              spin={spin}
              count={data && data.totalOrderValue}
              title="Total Orders Value"
              color="#e75c05"
            />
          </div>
          <div style={{ margin: "20px" }}>
            <Grid container spacing={2}>
              <Grid sx={{ m: 1 }} item sm={7}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h6 style={{ display: "inline-block" }}>weekly graph</h6>
                  <span style={{ marginRight: 20 }}>
                    <WeekDateFilter
                      from2={from2}
                      to2={to2}
                      refreshCanvas={refreshCanvas}
                      spin={spin}
                      place={place}
                    />
                  </span>
                </div>

                <Graph dataSource={graphData && graphData.graphData} />
              </Grid>
              <Grid sx={{ m: 1 }} item sm={4}>
                <h5>Versions</h5>
                <Version
                  driverVersion={data && data.driverVersion}
                  customerVersion={data && data.customerVersion}
                />
              </Grid>
            </Grid>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default AdminlDashboard;
