import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import NotificationForm from "../../Components/Admin/NotificationForm/NotificationForm";
import {
  ListUsersNotificationAction,
  ListAllCustomersAction,
  FilterCustomerStatusAction,
  SearchCustomersAction,
  refreshUserAction,
} from "../../Actions/AdminActions";
import Snackbar from "@mui/material/Snackbar";
import {
  SearchFilter,
  StatusDrowpDown,
} from "../../Components/Admin/customerHelper/FilterCustomer";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Avatar, Chip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";
import SpinnerLoader from "../../Components/SpinnerLoader";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const UserNotification = () => {
  const naviagate = useNavigate();
  const dispatch = useDispatch();
  const adminLogin = useSelector((state) => state.adminLogin);
  const [click, setClick] = useState(false);
  const { adminInfo } = adminLogin;
  const [info, setInfo] = useState(false);
  let USERS_TOKENS = [];
  let USERS_IDS = [];

  const refresh = async () => {
    dispatch(refreshUserAction());
    dispatch(SearchCustomersAction([]));
    dispatch(FilterCustomerStatusAction([]));
    dispatch(ListUsersNotificationAction());
  };

  useEffect(() => {
    refresh();
    USERS_TOKENS = [];
    USERS_IDS = [];
  }, [dispatch]);

  useEffect(() => {
    if (!adminInfo) {
      naviagate("/login");
    }
    dispatch(ListUsersNotificationAction());
  }, [adminInfo, naviagate]);
  const { success, fail } = useSelector((state) => state.notification);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const { customerLists, loading } = useSelector((state) => state.userList);
  const filterStatus = useSelector((state) => state.customerStatusFilter);
  const { content } = useSelector((state) => state.customerSearch);
  useEffect(() => {
    if (click && success) setOpen(success);
    if (fail && success) setError(fail);
  }, [success, click, fail]);

  if (customerLists) {
    customerLists.map((row) => {
      let status;
      row.isBlock ? (status = "Blocked") : (status = "Active");
      if (content) {
        function escapeRegExp(string) {
          if (string.length != 0) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
          }
        }
        let escpd = escapeRegExp(content);
        var regex = new RegExp(escpd, "i");
        if (
          status.match(regex) ||
          row.cutomerID.match(regex) ||
          row.firstName.match(regex) ||
          row.lastName.match(regex) ||
          row.phoneNumber.match(regex) ||
          row.email.match(regex) ||
          row.dateAndTime.match(regex)
        ) {
          if (filterStatus.statusCustomerFilter) {
            if (filterStatus.customerfilters.includes(status)) {
              USERS_IDS.push(row.cutomerID);
              USERS_TOKENS.push(row.fcmToken);
            }
          } else {
            USERS_IDS.push(row.cutomerID);
            USERS_TOKENS.push(row.fcmToken);
          }
        }
      } else {
        if (filterStatus.statusCustomerFilter) {
          if (filterStatus.customerfilters.includes(status)) {
            USERS_IDS.push(row.cutomerID);
            USERS_TOKENS.push(row.fcmToken);
          }
        } else {
          USERS_IDS.push(row.cutomerID);
          USERS_TOKENS.push(row.fcmToken);
        }
      }
    });
  }

  const handleClose = () => {
    setOpen(false);
    setError(false);
    setInfo(false);
  };
  useEffect(() => {
    if (filterStatus.customerfilters.length == 0 && USERS_TOKENS.length != 0) {
      setInfo(true);
    } else {
      setInfo(false);
    }
  }, [dispatch,loading]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="User Notification"
          id={adminInfo && adminInfo._id}
          email={adminInfo && adminInfo.email}
          name={`${adminInfo.firstName} ${adminInfo.lastName}`}
          place={adminInfo && adminInfo.place}
          token={adminInfo && adminInfo.token}
        />

        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />

          <h5 style={{ display: "block", color: "green" }}>User notificatin</h5>
          <div style={{ paddingTop: 1 }}>
            <StatusDrowpDown style={{ marginTop: "25px" }} />
            <div
              style={{
                display: "inline-flex",
              }}
            >
              <span>
                <SearchFilter />
              </span>

              <Button
                variant="contained"
                sx={{
                  bgcolor: "green",
                  height: "39px",
                  marginLeft: "10px",
                  marginTop: "9px",
                }}
                onClick={refresh}
              >
                <RefreshIcon />
              </Button>
            </div>
          </div>
          <div style={{ marginTop: 30 }}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "50vh",
                alignItems: "center",
              }}
            >
              {" "}
              <SpinnerLoader />
            </div>
          ) : (
            <NotificationForm
              click={click}
              tokens={USERS_TOKENS}
              setClick={setClick}
              NotificationFor="User"
            /> )}
            {filterStatus.customerfilters.length != 0 ? (
              <div>
                <strong style={{ display: "inline-block", color: "blue" }}>
                  Who can see This notification
                </strong>
              </div>
            ) : (
              ""
            )}
            <Typography
              sx={{ fontSize: "10px", fontWeight: "bold" }}
              component="span"
            >
              {filterStatus.customerfilters.length != 0
                ? filterStatus.customerfilters.map((el, i) => {
                 
                   

                    return (
                      // " hai"
                      <Chip
                        avatar={
                          <Avatar
                            alt="userAvatar"
                            src={
                              "https://toppng.com//public/uploads/preview/vu-thi-ha-user-pro-icon-115534024853ae3gswzwd.png"
                            }
                          />
                        }
                        sx={{ margin: 0.5 }}
                        size="small"
                        label={`${el} Users`}
                        variant="outlined"
                        color="success"
                      />
                    );
                  })
                : ""}
            </Typography>
          </div>
          <div style={{ marginTop: 2 }}>
                <div align="center">
                  {USERS_TOKENS.length !== 0 ? (
                    <strong
                      style={{ display: "inline-block", color: "#ff0051" }}
                    >
                      This notification will be sent to {USERS_TOKENS.length}{" "}
                      {USERS_TOKENS.length === 1 ? "user" : "users"}
                    </strong>
                  ) : (
                    ""
                  )}
                </div>

                <Typography
                  sx={{ fontSize: "10px", fontWeight: "bold" }}
                  component="span"
                >
                  {/* {USERS_IDS.length != 0
                    ? USERS_IDS.map((el, i) => {
                        return (
                          <Chip
                            avatar={
                              <Avatar
                                alt="usersAvatar"
                                src="https://toppng.com//public/uploads/preview/vu-thi-ha-user-pro-icon-115534024853ae3gswzwd.png"
                              />
                            }
                            sx={{ margin: 0.5 }}
                            size="small"
                            label={el}
                            variant="outlined"
                            color="success"
                          />
                        );
                      })
                    : ""} */}
                </Typography>
              </div>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        severity="success"
        onClose={handleClose}
      >
        <Alert severity="success">Notification sent successfully!</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={error}
        severity="error"
        onClose={handleClose}
      >
        <Alert severity="error">Notification Failed!</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={USERS_IDS.length === 0 && !loading ? true : false}
        severity="warning"
        onClose={handleClose}
      >
        <Alert severity="warning">Users Not Found Please Try Again!</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={info}
        onClose={handleClose}
      >
        <Alert severity="info">All Users Can see This Notification!</Alert>
      </Snackbar>
    </>
  );
};

export default UserNotification;
