import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/SubAdmin/sidebar/SideBar";
import { Table } from "react-bootstrap";
import "../../../src/App.css";
import Button from "@mui/material/Button";
import Loader from "../../Components/Loader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import {
  driverDetailsAction,
 
} from "../../Actions/AdminActions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import Chip from "@mui/material/Chip";
import EditIcon from "@mui/icons-material/Edit";
import EditPerosonal from "../../Components/Admin/edit_popUp/DriverPopUps";
import { LOADRUNNR_SOCKET_API } from "../../socketConst/BaseApi";
import io from "socket.io-client";
import PopUpImage from "../../Components/Admin/image_modal/PopUpImage";

const SubDriverProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const subAdminLogin = useSelector((state) => state.subAdminLogin);
  const { subAdminInfo } = subAdminLogin;
 
  

  // states fo edit
  
  
  const updateDriverWallet = useSelector((state) => state.updatedDriver);
  const { updateSuccess, waiting } = updateDriverWallet;
  const [imgUrl,setImgUrl]= useState('');
  const [openImg,setOpenImg]= React.useState(false);
  const { approve, approvalLoding, drId, change, token } = useSelector(
    (state) => state.driverApprove
  );
  const { loading, DriverDetails } = useSelector(
    (state) => state.driverDetails
  );
  console.log(DriverDetails," DriverDetailsDriverDetailsDriverDetails");
  const handleCloseimg = () => {
    setOpenImg(false);
  };


  
  useEffect(() => {
    if (approve) {
      const socket = io(LOADRUNNR_SOCKET_API, {
        withCredentials: true,
        transports: ["websocket"] ,
        extraHeaders: {
          "my-custom-header": "abcd"
        }
      });
     
      socket.emit("message", { status: "approved", driverId: drId, token });
    }
  }, [approve, change]);

  useEffect(() => {
    if (!subAdminLogin) {
      navigate("/sub-admin/login");
    } 
    dispatch(driverDetailsAction(id));
  }, [subAdminInfo, dispatch, navigate, updateSuccess,approve]);

  



  return (
    <>
      {waiting ? <Loader loading={waiting} /> : ""}
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="Driver"
          id={subAdminInfo && subAdminInfo._id}
          email={subAdminInfo && subAdminInfo.email}
          name={subAdminInfo && `${subAdminInfo.firstName} ${subAdminInfo.lastName}`}
          token={subAdminInfo && subAdminInfo.token}
        />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <Toolbar />
          <h5 style={{ display: "block", color: "green" }}>Driver Profile  </h5>
          {!DriverDetails ? (
            <Loader loading={loading} />
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <div>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#e75c05", mr: 2, mb: 2 }}
                    color="primary"
                    onClick={() => {
                      navigate("/sub-admin/manage-drivers");
                    }}
                  >
                    <ArrowBackIcon /> Back
                  </Button>
                  
                </div>
              </div>

              <Grid container spacing={5} sx={{ marginTop: "5px" }}>
                <Grid item xs={12} md={3}>
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt="Remy Sharp"
                      bordered="true"
                      onClick={()=> {
                        setImgUrl(DriverDetails.personalDetails.profileImg &&
                          DriverDetails.personalDetails.profileImg)
                        setOpenImg(true)
                     }}
                      src={
                        DriverDetails.personalDetails.profileImg &&
                        DriverDetails.personalDetails.profileImg
                      }
                      sx={{ width: 180, height: 180, ml: 5, mr: 5, mb: 2 }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      fontWeight: "bolder",
                      color: "#e75c05",
                      marginBottom: 15,
                    }}
                  >
                    <span>Driver ID :</span>
                    <span>
                      {DriverDetails.driverId && DriverDetails.driverId}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    <span>Name :</span>
                    <span>
                      {DriverDetails.personalDetails.firstName &&
                      DriverDetails.personalDetails.lastName
                        ? DriverDetails.personalDetails.firstName +
                          " " +
                          DriverDetails.personalDetails.lastName
                        : ""}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    <span>Mobile Number :</span>{" "}
                    <span>
                      {DriverDetails.personalDetails.defaultPhoneNumber &&
                        DriverDetails.personalDetails.defaultPhoneNumber}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    <span>Alternative Number :</span>{" "}
                    <span>
                      {DriverDetails.personalDetails.alternativeNumber &&
                        DriverDetails.personalDetails.alternativeNumber}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    <span> Emergency Number :</span>
                    <span>
                      {DriverDetails.personalDetails.emergencyNumber &&
                        DriverDetails.personalDetails.emergencyNumber}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    {DriverDetails.personalDetails.refferalNumber ? (
                      <>
                        <span>Referral Number :</span>
                        <span>
                          {DriverDetails.personalDetails.refferalNumber}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    <span>City :</span>{" "}
                    <span>
                      {DriverDetails.personalDetails.addCity &&
                        DriverDetails.personalDetails.addCity}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",
                      marginBottom: 15,
                    }}
                  >
                    <span>Locality :</span>{" "}
                    <span>
                      {DriverDetails.personalDetails.addLocality &&
                        DriverDetails.personalDetails.addLocality}
                    </span>
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      fontWeight: "bolder",

                      marginBottom: 15,
                    }}
                  >
                    <span style={{ color: "#e75c05" }}>Vehicle No :</span>
                    <span>
                      {DriverDetails.vehicleDetails &&
                        DriverDetails.vehicleDetails.vehicleNumber ? DriverDetails.vehicleDetails.vehicleNumber:"Not added"}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",

                      marginBottom: 15,
                    }}
                  >
                    <span style={{ color: "#e75c05" }}>Vehicle Type :</span>
                    <span>
                      {DriverDetails.vehicleDetails &&
                        DriverDetails.vehicleDetails.vehicleType ? DriverDetails.vehicleDetails.vehicleType :"Not added" }
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: "bolder",

                      marginBottom: 15,
                    }}
                  >
                    <span style={{ color: "#e75c05" }}>Sub Type :</span>
                    <span>
                      {DriverDetails.vehicleDetails &&
                        DriverDetails.vehicleDetails.subType ? DriverDetails.vehicleDetails.subType :"Not added"}
                    </span>
                  </div>
                 
                </Grid>
              </Grid>

              <Table striped bordered="true" hover>
                <thead>
                  <tr>
                    <th>Documents Type</th>

                    <th>Documents images</th>
                    <th>Documents Details</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {/* adhar details */}
                  {
                    DriverDetails.personalDetails ? <tr >
                    <td>Aadhaar </td>
                    <td>
                      <div>
                        <img
                          onClick={()=> {
                            setImgUrl(DriverDetails.personalDetails.adharFrontImg)
                            setOpenImg(true)
                         }}
                          style={{
                            margin: "5px",
                            maxWidth: "250px",
                            maxHeight: "100px",
                          }}
                          src={DriverDetails.personalDetails.adharFrontImg}
                          alt="img"
                        ></img>
                        <img
                        
                        onClick={()=> {
                          setImgUrl(DriverDetails.personalDetails.adharBackImg)
                          setOpenImg(true)
                       }}
                          style={{
                            margin: "5px",
                            maxWidth: "250px",
                            maxHeight: "100px",
                          }}
                          src={DriverDetails.personalDetails.adharBackImg}
                          alt="img"
                        ></img>
                      </div>
                    </td>
                    <td>
                      <span style={{ display: "block" }}>
                        <span>Aadhaar Number</span> :{" "}
                        {DriverDetails.personalDetails.adharNumber}
                      </span>
                    </td>
                    
                  </tr>
 : ""
                  }
                  
                  {/* bank documents */}
                  {DriverDetails.bankDetails ? (
                    <tr >
                      <td>Bank </td>
                      <td>
                        <img
                         onClick={()=> {
                          setImgUrl( DriverDetails.bankDetails &&
                            DriverDetails.bankDetails.passbookStatementImg &&
                            DriverDetails.bankDetails.passbookStatementImg)
                          setOpenImg(true)
                       }}
                          style={{
                            margin: "5px",
                            maxWidth: "250px",
                            maxHeight: "100px",
                          }}
                          src={
                            DriverDetails.bankDetails &&
                            DriverDetails.bankDetails.passbookStatementImg &&
                            DriverDetails.bankDetails.passbookStatementImg
                          }
                          alt="passbook"
                        ></img>
                      </td>
                      <td>
                        <span style={{ display: "block" }}>
                          <span>Account Number</span> :{" "}
                          {DriverDetails.bankDetails &&
                            DriverDetails.bankDetails.accountNumber}
                        </span>
                        <span style={{ display: "block" }}>
                          <span> Bank Name</span>:
                          {DriverDetails.bankDetails &&
                            DriverDetails.bankDetails.bankName}
                        </span>
                        <span style={{ display: "block" }}>
                          <span> Ifsc Code:</span>:
                          {DriverDetails.bankDetails &&
                            DriverDetails.bankDetails.ifscCode}
                        </span>
                      </td>
                      
                    </tr>
                  ) : (
                    ""
                  )}

                  {/* pan details */}

                  {DriverDetails &&
                  DriverDetails.bankDetails &&
                  DriverDetails.bankDetails.panCardImg ? (
                    <tr key={3}>
                      <td>Pancard </td>
                      <td>
                        {DriverDetails.bankDetails &&
                        DriverDetails.bankDetails.panCardImg ? (
                          <img
                            style={{
                              margin: "5px",
                              maxWidth: "250px",
                              maxHeight: "100px",
                            }}
                            onClick={()=> {
                              setImgUrl(DriverDetails.bankDetails.panCardImg)
                              setOpenImg(true)
                           }}
                            src={DriverDetails.bankDetails.panCardImg}
                            alt="img"
                          ></img>
                        ) : (
                          "Not added"
                        )}
                      </td>
                      <td>
                        <span> Pancard No: </span>
                        {DriverDetails.bankDetails &&
                        DriverDetails.bankDetails.panCardNumber ? (
                          <span style={{ display: "block" }}>
                            {DriverDetails.bankDetails.panCardNumber}
                          </span>
                        ) : (
                          " ---Not added---"
                        )}
                      </td>
                      
                    </tr>
                  ) : null}
                  {DriverDetails.vehicleDetails ? <tr>
                    <td>Vehicle</td>
                    <td>
                      <img
                        style={{
                          margin: "5px",
                          maxWidth: "250px",
                          maxHeight: "100px",
                        }}
                        onClick={()=> {
                          setImgUrl(DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.vehicleFrontImg)
                          setOpenImg(true)
                       }}
                        src={
                          DriverDetails.vehicleDetails &&
                          DriverDetails.vehicleDetails.vehicleFrontImg
                        }
                        alt="img"
                      ></img>
                      <img
                        style={{
                          margin: "5px",
                          maxWidth: "250px",
                          maxHeight: "100px",
                        }}
                        onClick={()=> {
                          setImgUrl(DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.vehicleBackImg)
                          setOpenImg(true)
                       }}
                        src={
                          DriverDetails.vehicleDetails &&
                          DriverDetails.vehicleDetails.vehicleBackImg
                        }
                        alt="img"
                      ></img>
                    </td>
                    <td>
                      <span style={{ display: "block" }}>
                        <span>vehicle Number</span> :{" "}
                        {DriverDetails.vehicleDetails &&
                          DriverDetails.vehicleDetails.vehicleNumber}
                      </span>
                    </td>
                    
                  </tr>:""}
                  
                  {
                    DriverDetails.vehicleDetails ? <tr >
                    <td> Insurance </td>
                    <td>
                      <img
                        style={{
                          margin: "5px",
                          maxWidth: "250px",
                          maxHeight: "100px",
                        }}
                        onClick={()=> {
                          setImgUrl(DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.insuranceImg)
                          setOpenImg(true)
                       }}
                        src={
                          DriverDetails.vehicleDetails &&
                          DriverDetails.vehicleDetails.insuranceImg
                        }
                        alt="img"
                      ></img>
                    </td>
                    <td>
                      <span style={{ display: "block" }}>
                        <span>Insurance Number</span> :{" "}
                        {DriverDetails.vehicleDetails &&
                          DriverDetails.vehicleDetails.insuranceNumber}
                      </span>
                      <span style={{ display: "block" }}>
                        <span>Insurance expiry date</span> :{" "}
                        {DriverDetails.vehicleDetails &&
                          DriverDetails.vehicleDetails.insuranceExpiryDate}
                      </span>
                    </td>
                    
                  </tr> : ""
                  }
                  
                  {
                    DriverDetails.vehicleDetails ? <tr>
                    <td> License </td>
                    <td>
                      <img
                        style={{
                          margin: "5px",
                          maxWidth: "250px",
                          maxHeight: "100px",
                        }}
                        onClick={()=> {
                          setImgUrl(DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.drivingLicenseImg)
                          setOpenImg(true)
                       }}
                        src={
                          DriverDetails.vehicleDetails &&
                          DriverDetails.vehicleDetails.drivingLicenseImg
                        }
                        alt="img"
                      ></img>
                    </td>
                    <td>
                      <span style={{ display: "block" }}>
                        <span>License Number</span> :
                        {DriverDetails.vehicleDetails &&
                          DriverDetails.vehicleDetails.drivingLicenseNo}
                      </span>
                    </td>
                    
                  </tr>: ""
                  }
                  

                  {/* registraion */}
                  {DriverDetails.vehicleDetails ? (
                    <tr>
                      <td> Registration-certificate</td>
                      <td>
                        <img
                          style={{
                            margin: "5px",
                            maxWidth: "250px",
                            maxHeight: "100px",
                          }}
                          onClick={()=> {
                            setImgUrl( DriverDetails.vehicleDetails &&
                              DriverDetails.vehicleDetails.rcBookImg)
                            setOpenImg(true)
                         }}
                          src={
                            DriverDetails.vehicleDetails &&
                            DriverDetails.vehicleDetails.rcBookImg
                          }
                          alt="img"
                        ></img>
                      </td>

                      
                    </tr>
                  ) : (
                    ""
                  )}

                  {/* {DriverDetails.personalDetails ? (
                    <tr>
                      <td> vaccination </td>
                      <td>
                        <img
                          style={{
                            margin: "5px",
                            maxWidth: "250px",
                            maxHeight: "100px",
                          }}
                          src={
                            DriverDetails.personalDetails &&
                            DriverDetails.personalDetails.vaccineImg
                          }
                          alt="img"
                        ></img>
                      </td>

                     
                    </tr>
                  ) : (
                    ""
                  )} */}
                </tbody>
              </Table>
            </>
          )}
          
          
        </Box>
      </Box>
      <PopUpImage 
      handleCloseimg={handleCloseimg}
      open={openImg}
      imgUrl={imgUrl}
      />
    </>
  );
};

export default SubDriverProfile;
