export const statusTypes = [
  "Pending",
  "Pending-1",
  "Pending-2",
  "Rejected",
  "Active",
  "Blocked",
  "PendingVehicleDetails",
  "PendingBankDetails",
];
export const driverPlaceTypes = ["Bengaluru", "Mysore"];
export const statusTypesForMap = ["Active", "Pending"];

export const vehiclesTypes = [
  "Motor Cycle",
  "Scooter",
  "Three Wheeler",
  "Tata ACE 7ft",
  "Tata ACE 8ft / Bolero",
  "TATA 407",
];

export const vehiclesSubTypes = ["Open", "Close", "Tarpaulin"];
