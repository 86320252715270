import React, { useEffect, useState} from 'react'
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form } from 'react-bootstrap';
import { rejectWithdrawalRequestAction } from '../../../Actions/AdminActions';

const ApprovalDetails = (props) => {
    const dispatch = useDispatch()
    const [comment, setComment] = useState("")
    const [error, setError] = useState(false)
    // const withdrawalRequestDriverDetail = useSelector((state) => state.withdrawalRequestDriverDetail)
    // const { withdrawalRequestDriverDetails} = withdrawalRequestDriverDetail
    // useEffect(() => {
    //     // dispatch(getWithdrawalRequestDriverDetailsAction(props.id)) 
    // }, [])

    const handleRejectComment = () => {
      if(comment){
        setError(false)
        dispatch(rejectWithdrawalRequestAction(props.id, comment))
        setComment('')
        props.onHide()
      }else{
        setError(true)
      }
    }
    return(
        <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='mt-5'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Reject Withdrawal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='mt-4'>
          <Form>
            <Form.Control
              className='mt-3'
              as='textarea'
              rows='3'
              cols='7'
              placeholder='Add Your comment'
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
            {error && (
              <p className='text-danger font-weight-bold mt-2 mb-2'>Comment Required!..</p>
            )}
            {/* <div className='d-flex justify-content-end'>
              <Button
                type='submit'
                className='m-3 w-25'
                style={{
                  backgroundColor: 'rgb(231, 92, 5)',
                  fontWeight: 'bold',
                }}
              >
                Add
              </Button>
              <Button onClick={props.onHide} className='m-3 w-25'>
                Close
              </Button>
            </div> */}
          </Form>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button sx={{ background : "red", width : "100px", marginRight : "10px"}} onClick={() => handleRejectComment()} variant = "contained">Reject</Button>
          <Button sx={{ width : "100px"}} variant = "contained" onClick={props.onHide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ApprovalDetails