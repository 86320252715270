import React from 'react'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../Components/Admin/sidebar/SideBar";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom'
import { getCustomerOrdersAction } from '../Actions/AdminActions'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from "prop-types";
import { BookingTableHead } from "../Components/Admin/BookingsTableHelpers/TableHeadings";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(JSONDATA, comparator) {
    const stabilizedThis = JSONDATA.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  BookingTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

const UserBookings = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id, user, customid} = useParams()
    const  { adminInfo } = useSelector(state => state.adminLogin)
    const {customerOrder, l } = useSelector(state => state.customerOrders)
    const [page, setPage] = useState(0);

    // const [dense, setDense] = useState(false);

    const [rowsPerPage, setRowsPerPage] = useState(10);

useEffect(() => {
    if(!adminInfo){
        navigate('/login')
    }
    dispatch(getCustomerOrdersAction(id, user))
}, [dispatch])

let bookings = (row) => {
    return (
        <>
          <TableRow
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             
              <TableCell component="th" scope="row">
                {row.bookingDate}
              </TableCell>
              <TableCell align="left">{row.status}</TableCell>
              <TableCell align="left">{row.bookingId}</TableCell>
              <TableCell align="right">{row.place}</TableCell>
              <TableCell align="right">
                <p>{row.mainAddress.pickupPoint.location.substring(0, 30)}</p>
            
                    <p>{row.mainAddress.dropPoint.location ? row.mainAddress.dropPoint.location.substring(0, 30) : ''}</p>
        
              </TableCell>
              <TableCell align="right">
                <p>{row.driverDetails.name}</p>
                <p>{row.driverDetails.mobNo}</p>
              </TableCell>
              <TableCell align="right">₹{row.paymentDetails.amount}</TableCell>
            </TableRow>
        </>
    )
}

    return (
        <>
        <Box sx={{ display: "flex" }}>
            <Sidebar
              ActiveTab="bo"
              id={adminInfo && adminInfo._id}
              email={adminInfo && adminInfo.email}
              name={`${adminInfo.firstName} ${adminInfo.lastName}`}
              place={adminInfo && adminInfo.place}
              token={adminInfo && adminInfo.token}
            />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        > 

        <h1 style={{ marginTop : "45px"}}>User Bookings</h1>
        <h4>{user} id : {customid}</h4>
        {/* <pre>{JSON.stringify(customerOrder, null, 4)}</pre> */}
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000, marginTop : "10px"}} size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Booking Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Booking Id</TableCell>
            <TableCell align="right">Place</TableCell>
            <TableCell align="right">Pickup Point & Drop Point</TableCell>
            <TableCell align="right">Driver</TableCell>
            <TableCell align="right">Payment Amount</TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
        {
          customerOrder && customerOrder.map(row => {           
            return bookings(row)
                    })
        }
        </TableBody>
      </Table>
    </TableContainer>

       
        </Box>
        </Box>
        </>
    )
}


export default UserBookings