import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact';
import { getDriverReviewsAction } from '../../Actions/AdminActions'
import Box from "@mui/material/Box";
import Sidebar from "./sidebar/SideBar";

const DriverReview = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

      // All drivers
  const subAdminLogin = useSelector((state) => state.subAdminLogin);
  const { subAdminInfo } = subAdminLogin;
    const { driverReviews } = useSelector(state => state.driverReview)
    const data1 = {
        columns: [
          {
            label: 'Order ID',
            field: 'orderId',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Customer Name',
            field: 'name',
            sort: 'asc',
            width: 270,
          },
          {
            label: 'Customer Phone Number',
            field: 'phoneNumber',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Rating',
            field: 'rating',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Comment',
            field: 'comment',
            sort: 'asc',
            width: 100,
          },
        ],
        rows:  driverReviews && driverReviews.reviews, 
      };
    

    useEffect(() => {
        if(!subAdminInfo){
            navigate('/sub-admin/login')
        }
        dispatch(getDriverReviewsAction(id, 'subadmin'))
    }, [])

    return (
        <>

<Box sx={{ display: "flex" }}>
<Sidebar
          ActiveTab="Drivers"
          id={subAdminInfo && subAdminInfo._id}
          email={subAdminInfo && subAdminInfo.email}
          name={subAdminInfo && `${subAdminInfo.firstName} ${subAdminInfo.lastName}`}
          token={subAdminInfo && subAdminInfo.token}
        />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
        
        {/* <h2>{id}</h2> */}
        <div className='container-fluid walletCustomerDetails'>
        <span
          className='btn btn-dark text-light'
          style={{ float: 'right' }}
          onClick={() => { navigate('/manage-driver')}}
        >
          X
        </span>
        <div className='container'>
          <div className='detailsBox p-3'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6'>
                  <h5 style={{ color: 'rgb(231, 92, 5)' }}>
                    DriverId : {driverReviews && driverReviews.driverId}
                  </h5>
                  <p>
                    Name :
                    <span>
                      {driverReviews &&
                        driverReviews.personalDetails.firstName + ' ' + driverReviews.personalDetails.lastName}
                    </span>
                  </p>
                  <p>
                    Mobile Number :
                    <span>{driverReviews && driverReviews.personalDetails.defaultPhoneNumber}</span>
                  </p>
                  <p>
                  Emergency Number : <span>{driverReviews && driverReviews.personalDetails.emergencyNumber}</span>
                  </p>
                  <p>
                    City : <span>{driverReviews && driverReviews.personalDetails.addCity}</span>
                  </p>
                  <p>
                    Wallet Amount :
                    <span>₹ {driverReviews && driverReviews.wallet}</span>
                  </p>
                  <p>
                    Number Of Reviews :
                    <span> {driverReviews && driverReviews.numReviews}</span>
                  </p>
                  <p>
                    Rating :
                    <span> {driverReviews && driverReviews.rating}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h1 style={{ marginTop : "20px", marginLeft : "20px"}}>Reviews</h1>
      <div className='walletlogTable p-5'>
        <MDBDataTable striped bordered small data={data1} />
      </div>
        {/* <pre>{JSON.stringify(driverReviews, null, 4)}</pre> */}
        </Box>
        </Box>
        </>
    )
}

export default DriverReview