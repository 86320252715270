export const statusTypes = ['Assigning', 'Ongoing', 'Cancel','Confirming','Completed'];
export const placeTypes = ['Bangalore', 'Mysore'];
export const vehiclesTypes = [
  'Motor Cycle',
  'Scooter',
  'Three Wheeler',
  'Tata ACE 7ft',
  'Tata ACE 8ft / Bolero',
  'TATA 407',
  
];

export const vehiclesSubTypes = ['Open', 'Close', 'Tarpaulin','Any'];
