import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import Loader from "../../Components/Loader";
import Typography from "@mui/material/Typography";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InfoIcon from "@mui/icons-material/Info";
import {
  PlaceDrowpDown,
  StatusDrowpDown,
  VehicleTypeDrowpDown,
  VehicleSubTypeDrowpDown,
  FromToDateFilter,
  SearchFilter,
} from "../../Components/Admin/driverHelper/Filter";
import { EnhancedTableHead } from "../../Components/Admin/driverHelper/DriveTableHead";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import io from "socket.io-client";
import { API, LOADRUNNR_SOCKET_API } from "../../socketConst/BaseApi";
import { ButtonGroup, Paper } from "@mui/material";
import {
  FilterDriverStatusAction,
  FilterVehicleSubTypeAction,
  FilterVehicleTypeAction,
  FilterWithDate,
  ListAllDriverAction,
  searchAction,
} from "../../Actions/AdminActions";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddWalletFrom from "../../Components/Admin/Modals/AddWalletFrom";
import WalletLogs from "../../Components/WalletLogs";
import AddCommentForm from "../../Components/Admin/AddCommentForm";
import exportFromJSON from "export-from-json";
import "moment-timezone";
import BlockingForm from "../../Components/Admin/Modals/DriverBlockForm";
import UnBlockingForm from "../../Components/Admin/Modals/DriverUnblock";
import Swal from "sweetalert2";
import SpinnerLoader from "../../Components/SpinnerLoader";
import { useState } from "react";
import { filterDriverPlaceAction } from "../../Actions/FilterAction";
import axios from "axios";

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const AdminManageDriver = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  // menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // end menu
  // wallt modal
  const [isOpen, setisOpen] = React.useState(false);
  const [isOpenComment, setisCommentOpen] = React.useState(false);

  const handleOpen = (complete, status) => {
    if (complete && status !== "Pending") {
      setisOpen(true);
    } else {
      Swal.fire("Warning!", "Cannot add wallet for this driver.", "error");
    }
  };

  const handleClose = () => {
    setisOpen(false);
  };
  const handleCommentClose = () => {
    setisCommentOpen(false);
  };

  // const [dense, setDense] = React.useState(false);

  const [online, setOnline] = React.useState(true);
  const [costomId, setCostomId] = React.useState("");
  const [driverId, setDriverId] = React.useState("");
  const [walletLogs, setWalletLogs] = React.useState(false);

  const updateDriverWallet = useSelector((state) => state.updatedDriver);
  const { content } = useSelector((state) => state.searchFilter);
  const { updateSuccess } = updateDriverWallet;
  const [csnotes, setCsnotes] = React.useState([]);
  const [filterChange, setFilterChange] = useState(1);
  const [unblockModalShow, setUnblockModalShow] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [rows, setRows] = React.useState(10);
  const [skip, setSkip] = React.useState(0);
  const filterPlaces = useSelector((state) => state.driverPlaceFilter);
  const { placeFilter } = filterPlaces;
  const filterStatus = useSelector((state) => state.driverStausFilter);
  const { statusFilter } = filterStatus;
  const filterSubType = useSelector(
    (state) => state.driverVehicleSubTypeFilter
  );
  const { subTypeFilter } = filterSubType;
  const filterVehicleType = useSelector(
    (state) => state.driverVehcleTypeFilter
  );
  let { from, to, statusDateFilter } = useSelector((state) => state.filterDate);
  const { VehcleTypeFilter } = filterVehicleType;

  console.log(
    from,
    to,
    statusDateFilter,
    "from, to, statusDateFilterfrom, to, statusDateFilter"
  );

  // let allKey = filterStatus.filters
  //   .concat(filterVehicleType.filters)
  //   .concat(filterSubType.filters)
  //   .concat(filterPlaces.filters);

  useEffect(() => {
    dispatch(
      ListAllDriverAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.filters,
        vehicleType: filterVehicleType.filters,
        subType: filterSubType.filters,
        places: filterPlaces.filters,
        from,
        to,
      })
    );
  }, [rows]);

  const submitFilter = () => {
    setSkip(0);
    dispatch(
      ListAllDriverAction({
        rows,
        skip: 0,
        content,
        status_filter: filterStatus.filters,
        vehicleType: filterVehicleType.filters,
        subType: filterSubType.filters,
        places: filterPlaces.filters,
        from,
        to,
      })
    );
  };

  useEffect(() => {
    if (skip > 10) {
      setSkip(0);
    }
    if (content) {
      setSkip(0);
    }
    dispatch(
      ListAllDriverAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.filters,
        vehicleType: filterVehicleType.filters,
        subType: filterSubType.filters,
        places: filterPlaces.filters,
        from,
        to,
      })
    );
  }, [content]);

  const handlePrev = () => {
    if (skip - rows < 0) {
      setSkip(0);
      dispatch(
        ListAllDriverAction({
          rows,
          skip,
          content,
          status_filter: filterStatus.filters,
          vehicleType: filterVehicleType.filters,
          subType: filterSubType.filters,
          places: filterPlaces.filters,
          from,
          to,
        })
      );
    } else {
      setSkip(skip - rows);
      dispatch(
        ListAllDriverAction({
          rows,
          skip,
          content,
          status_filter: filterStatus.filters,
          vehicleType: filterVehicleType.filters,
          subType: filterSubType.filters,
          places: filterPlaces.filters,
          from,
          to,
        })
      );
    }
  };
  const handleNext = () => {
    if (skip < 1) {
      setSkip(rows);
      dispatch(
        ListAllDriverAction({
          rows,
          skip,
          content,
          status_filter: filterStatus.filters,
          vehicleType: filterVehicleType.filters,
          subType: filterSubType.filters,
          places: filterPlaces.filters,
          from,
          to,
        })
      );
    } else {
      setSkip(skip + rows);
      dispatch(
        ListAllDriverAction({
          rows,
          skip,
          content,
          status_filter: filterStatus.filters,
          vehicleType: filterVehicleType.filters,
          subType: filterSubType.filters,
          places: filterPlaces.filters,
          from,
          to,
        })
      );
    }
  };
  const handleRowsCount = (event) => {
    setRows(event.target.value);
  };

  // useEffect(() => {
  //   const socket = io(LOADRUNNR_SOCKET_API, { transports: ["websocket"] });
  //   socket.on("online", (data) => {
  //     dispatch(
  //       ListAllDriverAction({
  //         rows,
  //         skip,
  //         content,
  //         status_filter: filterStatus.filters,
  //         vehicleType: filterVehicleType.filters,
  //         subType: filterSubType.filters,
  //         places: filterPlaces.filters,
  //       })
  //     );
  //     setOnline(!online)
  //   });
  // }, [online]);
  const [update, setUpdate] = React.useState(false);

  useEffect(() => {
    dispatch(
      ListAllDriverAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.filters,
        vehicleType: filterVehicleType.filters,
        subType: filterSubType.filters,
        places: filterPlaces.filters,
        from,
        to,
      })
    );
  }, [updateSuccess]);

  // All drivers
  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }
    dispatch(
      ListAllDriverAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.filters,
        vehicleType: filterVehicleType.filters,
        subType: filterSubType.filters,
        places: filterPlaces.filters,
        from,
        to,
      })
    );
  }, [adminInfo, dispatch, navigate]);

  const { AllDriversList, loading } = useSelector((state) => state.alldrivers);

  //,searchChange,typeChange,drStChange,
  useEffect(() => {
    dispatch(
      ListAllDriverAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.filters,
        vehicleType: filterVehicleType.filters,
        subType: filterSubType.filters,
        places: filterPlaces.filters,
        from,
        to,
      })
    );
  }, [from, to, statusDateFilter]);

  const [adminName, setAdminName] = React.useState("");
  const handleAddComment = async (adminName) => {
    setCsnotes([]);
    if (AllDriversList) {
      setAdminName(adminName);
      setisCommentOpen(true);
    }
  };

  const refresh = async () => {
    dispatch(
      ListAllDriverAction({ rows: 10, skip: 0, content: "", from: "", to: "" })
    );
    dispatch(FilterDriverStatusAction([]));
    dispatch(FilterVehicleTypeAction([]));
    dispatch(FilterVehicleSubTypeAction([]));
    dispatch(filterDriverPlaceAction([]));
    dispatch(FilterWithDate({ from: "", to: "" }));
    setFilterChange(filterChange + 1);
    setSkip(0);
    dispatch(searchAction(""));
  };

  const [isStartedDownload, setIsStartedDownload] = useState(false);
  const ExportToExcel = async () => {
    setIsStartedDownload(true);
    var { data } = await axios.get(`${API}/api/admin/download-all-drivers`); 
    if (data) {
      setIsStartedDownload(false);

      if (data.length > 0) {
        const list = await data.map((records) => {
          let driverArray = {
            ID: records.driverId,
            FIRST_NAME: records.personalDetails.firstName,
            LAST_NAME: records.personalDetails.lastName,
            PROFILE_IMAGE: records.personalDetails.profileImg,
            REFFERAL_NO: records.personalDetails.refferalNumber
              ? records.personalDetails.refferalNumber
              : "NILL",
            STATUS: records.status,
            DATE_AND_TIME: records.dateAndTime,
            PHONE: records.personalDetails.defaultPhoneNumber,
            ALTERNATIVE_PHONE: records.personalDetails.alternativeNumber,
            EMERGENCY_PHONE: records.personalDetails.emergencyNumber,
            CITY: records.personalDetails.addCity,
            LOCALITY: records.personalDetails.addLocality,
            AADHAAR_CARD_NO: records.personalDetails.adharNumber,
            AADHAAR_CARD_FRONT_IMAGE: records.personalDetails.adharFrontImg,
            AADHAAR_CARD_BACK_IMAGE: records.personalDetails.adharBackImg,
            VACCINE_IMAGE: records.personalDetails.vaccineImg,
            VEHICLE_TYPE:
              records.vehicleDetails && records.vehicleDetails.vehicleType
                ? records.vehicleDetails.vehicleType
                : "NILL",
            VEHICLE_SUB_TYPE:
              records.vehicleDetails && records.vehicleDetails.subType
                ? records.vehicleDetails.subType
                : "NILL",
            RC_BOOK_IMAGE:
              records.vehicleDetails && records.vehicleDetails.rcBookImg
                ? records.vehicleDetails.rcBookImg
                : "NILL",
            VEHICLE_NO:
              records.vehicleDetails && records.vehicleDetails.vehicleNumber
                ? records.vehicleDetails.vehicleNumber
                : "NILL",
            VEHICLE_FRONT_IMAGE:
              records.vehicleDetails && records.vehicleDetails.vehicleFrontImg
                ? records.vehicleDetails.vehicleFrontImg
                : "NILL",
            VEHICLE_BACK_IMAGE:
              records.vehicleDetails && records.vehicleDetails.vehicleBackImg
                ? records.vehicleDetails.vehicleBackImg
                : "NILL",
            INSURANCE_NO:
              records.vehicleDetails && records.vehicleDetails.insuranceNumber
                ? records.vehicleDetails.insuranceNumber
                : "NILL",
            INSURANCE_IMAGE:
              records.vehicleDetails && records.vehicleDetails.insuranceImg
                ? records.vehicleDetails.insuranceImg
                : "NILL",
            INSURANCE_EXP_DATE:
              records.vehicleDetails && records.vehicleDetails.insuranceExpiryDate
                ? records.vehicleDetails.insuranceExpiryDate
                : "NILL",
            DRIVING_LICENSE_NO:
              records.vehicleDetails && records.vehicleDetails.drivingLicenseNo
                ? records.vehicleDetails.drivingLicenseNo
                : "NILL",
            DRIVING_LICENSE_IMAGE:
              records.vehicleDetails && records.vehicleDetails.drivingLicenseImg
                ? records.vehicleDetails.drivingLicenseImg
                : "NILL",
            BANK_AC_NO:
              records.bankDetails && records.bankDetails.accountNumber
                ? records.bankDetails.accountNumber
                : "NILL",
            BANK_NAME:
              records.bankDetails && records.bankDetails.bankName
                ? records.bankDetails.bankName
                : "NILL",
            BANK_IFSC:
              records.bankDetails && records.bankDetails.ifscCode
                ? records.bankDetails.ifscCode
                : "NILL",
            PASSBOOK_IMAGE:
              records.bankDetails && records.bankDetails.passbookStatementImg
                ? records.bankDetails.passbookStatementImg
                : "NILL",
            PANCARD_NO:
              records.bankDetails && records.bankDetails.panCardNumber
                ? records.bankDetails.panCardNumber
                : "NILL",
            PANCARD_IMAGE:
              records.bankDetails && records.bankDetails.panCardImg
                ? records.bankDetails.panCardImg
                : "NILL",
            WALLET: records.wallet,
          };
          return driverArray;
        });

         data = list;

        const fileName = "LOADRUNNR_ALL_DRIVERS";
        const exportType = "xls";
        exportFromJSON({ data, fileName, exportType });
      } else {
        Swal.fire("Warning!", "Tata is not available for download.", "error");
      }
    }
  };

  const handleDriverBookings = (id, cstmId) => {
    navigate(`/driver/bookinglist/${id}`);
  };

  const handleDriverReviews = (id) => {
    navigate(`/${id}/driver-reviews`);
  };

  const handleDropdown = (action, id, complete, status) => {
    if (action === "AddVerify") {
      navigate("/admin/verifydriver/" + id);
    } else if (action === "walletLogs") {
      complete
        ? setWalletLogs(true)
        : Swal.fire(
            "Warning!",
            "This driver registration is not complete.",
            "error"
          );
    } else if (action === "Block") {
      if (complete && status === "Active") {
        setModalShow(true);
      } else {
        Swal.fire("Fail!", "This driver cannot be authorized.", "error");
      }
    } else if (action === "UnBlock") {
      setUnblockModalShow(true);
    } else if (action === "viewProfile") {
      navigate("/admin/driver-profile/" + id);
    }
  };

  let commentShow = (cmnt) => {
    let comment = "";
    let status = "";

    if (cmnt && cmnt.substring(0, 5) === "Block" && cmnt.length > 5) {
      status = "Block";
      comment = cmnt.slice(9);
    } else if (cmnt && cmnt.substring(0, 7) === "Unblock" && cmnt.length > 7) {
      status = "Unblock";
      comment = cmnt.slice(9);
    } else if (cmnt) {
      comment = cmnt;
    }

    switch (status) {
      case "Block":
        return (
          <>
            {" "}
            <span style={{ backgroundColor: "red", color: "white" }}>
              {status}
            </span>{" "}
            : <span> {comment} </span>{" "}
          </>
        );
        break;
      case "Unblock":
        return (
          <>
            {" "}
            <span style={{ backgroundColor: "green", color: "white" }}>
              {status}
            </span>{" "}
            <span> {comment} </span>{" "}
          </>
        );
        break;
      default:
        return <span>{comment}</span>;
        break;
    }
  };

  let driverRecord = (row, i) => {
    return (
      <>
        <TableRow hover key={row._id}>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <span>{i + 1}</span>
          </TableCell>
          <TableCell>
            {row.isOnline ? (
              <span
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Online
              </span>
            ) : (
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Offline
              </span>
            )}
          </TableCell>
          <TableCell>
            {/* {row.status } */}
            {row.status === "PendingVehicleDetails" ? (
              <span
                style={{
                  color: "yellowgreen",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {row.status}
              </span>
            ) : (
              ""
            )}
            {row.status === "PendingBankDetails" ? (
              <span
                style={{
                  color: "blue",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {row.status}
              </span>
            ) : (
              ""
            )}
            {row.status === "Pending" ? (
              <span
                style={{
                  color: "orange",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {row.status}
              </span>
            ) : (
              ""
            )}
            {row.status === "Active" ? (
              <span
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {row.status}
              </span>
            ) : (
              ""
            )}
            {row.status === "Blocked" ? (
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {row.status}
              </span>
            ) : (
              ""
            )}
            {row.status === "Rejected" ? (
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {row.status}
              </span>
            ) : (
              ""
            )}
            {row.status === "Pending-1" ||
            row.status === "Pending-2" ||
            row.status === "Pending-3" ||
            row.status === "Pending-4" ? (
              <span
                style={{
                  color: "orange",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {row.status}
              </span>
            ) : (
              ""
            )}
            {/* {!row.status === "Blocked" ||
            !row.status === "Rejected" ||
            !row.status === "PendingVehicleDetails" ||
            !row.status === "Active" ||
            !row.status === "Pending" ||
            !row.status === "PendingBankDetails" ? (
              <span
                style={{
                  color: "orange",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {row.status}
              </span>
            ) : (
              ""
            )} */}
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.personalDetails.addCity}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.personalDetails.addLocality}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <span>{row.driverId}</span>
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.personalDetails.firstName}
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.personalDetails.defaultPhoneNumber}
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.vehicleDetails && row.vehicleDetails.vehicleType}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.vehicleDetails && row.vehicleDetails.subType}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span> {row.wallet && `₹${row.wallet.toFixed(0)}`}</span>
            </div>
            <Button
              sx={{ backgroundColor: "#e75c05" }}
              variant="contained"
              onClick={() => {
                setCostomId(row.driverId);
                setDriverId(row._id);
                handleOpen(row.regCompleted, row.status);
              }}
            >
              Add
            </Button>
          </TableCell>
          <TableCell sx={{ color: "green" }}>
            <div>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small">Action</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  label="Action"
                  component="button"
                  onChange={(e) => {
                    setCostomId(row.driverId);
                    setDriverId(row._id);

                    handleDropdown(
                      e.target.value,
                      row._id,
                      row.regCompleted,
                      row.status
                    );
                  }}
                >
                  <MenuItem value="AddVerify">Add/verify Documents</MenuItem>
                  <MenuItem value="walletLogs">Wallet Logs</MenuItem>
                  <MenuItem value="viewProfile">View Profile</MenuItem>
                  {row.isBlock ? (
                    <MenuItem
                      value="UnBlock"
                      onClick={() => {
                        setCostomId(row.driverId);
                        setDriverId(row._id);
                      }}
                    >
                      UnBlock
                    </MenuItem>
                  ) : (
                    <MenuItem
                      value="Block"
                      onClick={() => {
                        setCostomId(row.driverId);
                        setDriverId(row._id);
                      }}
                    >
                      Block
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </TableCell>

          <TableCell
            sx={{ fontWeight: "bold", fontSize: "16px" }}
            onClick={() => handleDriverBookings(row._id, row.driverId)}
          >
            <Button
              sx={{ width: 100 }}
              variant="outlined"
              endIcon={<InfoIcon />}
            >
              {row.attemptedRides ? row.attemptedRides : 0}
            </Button>
          </TableCell>

          <TableCell
            sx={{ fontWeight: "bold", fontSize: "16px" }}
            onClick={() => handleDriverReviews(row._id)}
          >
            <Button
              sx={{ width: 100 }}
              variant="outlined"
              endIcon={<InfoIcon />}
            >
              {row.rating}/5
            </Button>
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.personalDetails.refferalNumber
              ? row.personalDetails.refferalNumber
              : "Nill"}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.dateAndTime}
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span>
                {row.csnotes[row.csnotes.length - 1]
                  ? commentShow(row.csnotes[row.csnotes.length - 1].comment)
                  : ""}
              </span>
            </div>
            <Button
              sx={{
                backgroundColor: "#e75c05",
                width: "200px",
              }}
              variant="contained"
              onClick={() => {
                setCostomId(row.driverId);
                setDriverId(row._id);
                let adName = `${adminInfo.firstName} ${adminInfo.lastName}`;
                handleAddComment(adName);
              }}
            >
              Add comment
            </Button>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="Driver"
          id={adminInfo && adminInfo._id}
          email={adminInfo && adminInfo.email}
          name={`${adminInfo.firstName} ${adminInfo.lastName}`}
          place={adminInfo && adminInfo.place}
          token={adminInfo && adminInfo.token}
        />

        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          {!walletLogs ? (
            <>
              {/* <Toolbar /> */}

              <div style={{ paddingTop: 45, paddingBottom: 20 }}>
                <h5
                  style={{
                    display: "inline-block",
                    marginTop: "4px",
                    color: "green",
                  }}
                >
                  Manage Drivers
                </h5>
                <PlaceDrowpDown
                  filterChange={filterChange}
                  setFilterChange={setFilterChange}
                  style={{ marginTop: "30px" }}
                />
                <StatusDrowpDown
                  filterChange={filterChange}
                  setFilterChange={setFilterChange}
                  style={{ marginTop: "30px" }}
                />
                <VehicleTypeDrowpDown
                  filterChange={filterChange}
                  setFilterChange={setFilterChange}
                  style={{ marginTop: "30px" }}
                />
                <VehicleSubTypeDrowpDown
                  filterChange={filterChange}
                  setFilterChange={setFilterChange}
                  style={{ marginTop: "30px" }}
                />

                <Button onClick={submitFilter} variant="outlined">
                  submit filter
                </Button>

                <span style={{ position: "relative", top: 9 }}>
                  <FromToDateFilter />
                </span>

                <span style={{ marginLeft: "20px" }}>
                  <SearchFilter />
                </span>

                <Button
                  variant="contained"
                  sx={{ bgcolor: "green", height: "39px", marginLeft: "10px" }}
                  onClick={refresh}
                >
                  <RefreshIcon />
                </Button>
                <Button
                  onClick={ExportToExcel}
                  variant="contained"
                  sx={{ height: "39px", marginLeft: "10px" }}
                >
                  <DownloadIcon />
                </Button>
              </div>

              {/* table */}
              {!loading ? (
                <Box sx={{ width: "100%" }}>
                  <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer>
                      {/* sx={{ maxHeight: 500 }} */}
                      <Table stickyHeader sx={{ minWidth: 750 }}>
                        <EnhancedTableHead />
                        <TableBody>
                          {AllDriversList.map((row, i) => {
                            return driverRecord(row, i);
                          })}
                        </TableBody>
                      </Table>
                      {AllDriversList.length === 0 ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Loader />
                        </div>
                      ) : (
                        ""
                      )}
                    </TableContainer>
                  </Paper>
                  <ButtonGroup
                    disableElevation
                    variant="outlined"
                    aria-label="Disabled elevation buttons"
                    sx={{ mt: 2 }}
                  >
                    <Button onClick={handlePrev}>
                      <ArrowBackIosIcon />
                    </Button>
                    <Button onClick={handleNext}>
                      <ArrowForwardIosIcon />
                    </Button>
                  </ButtonGroup>
                  <FormControl sx={{ minWidth: 80, ml: 2, mt: 2 }} size="small">
                    <InputLabel id="demo-select-small">Rows </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={rows}
                      label="Rows"
                      onChange={handleRowsCount}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Loader loading={loading} />
              )}
            </>
          ) : (
            <WalletLogs
              onHide={() => setWalletLogs(false)}
              driverId={driverId}
              lrnr={costomId}
              logsFor="driver"
            />
          )}
        </Box>
      </Box>
      <AddCommentForm
        title="Driver"
        isOpen={isOpenComment}
        adminName={adminName}
        handleClose={handleCommentClose}
        costomId={costomId}
        driverId={driverId}
        commentFor="driver"
      />
      <AddWalletFrom
        title="Driver"
        isOpen={isOpen}
        handleClose={handleClose}
        costomId={costomId}
        driverId={driverId}
        walletFor="driver"
      />
      <BlockingForm
        show={modalShow}
        onHide={() => setModalShow(false)}
        lrnr={costomId}
        driverId={driverId}
        setModalShow={setModalShow}
      />
      <UnBlockingForm
        show={unblockModalShow}
        onHide={() => setUnblockModalShow(false)}
        lrnr={costomId}
        driverId={driverId}
        setUnblockModalShow={setUnblockModalShow}
      />
      {isStartedDownload ? <Loader download={true} loading={isStartedDownload} /> : ""}
    </>
  );
  // ) : (
  //   <Box
  //   component="div"
  //   sx={{
  //     height: "50vh",
  //     display: "grid",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   }}
  // >
  //   <div style={{ position: "relative", left: 120 }}>
  //     {" "}
  //     <SpinnerLoader />
  //   </div>
  // </Box>
  // );
};

export default AdminManageDriver;
