import React, { useEffect, useState } from "react";
import { Tab, Col, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import RentalDataTable from "../../Components/RentalDataTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommisionRateAction,
  currentCommisionAction,
  getPricingListAction,
} from "../../Actions/AdminActions";
import Box from "@mui/material/Box";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";

const RentalPricingScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAddCommisionBtn, setShowAddCommisionBtn] = useState(true);
  const [err, setErr] = useState("");
  const [commisonRate, setCommisionRate] = useState(0);
  const [currentRate, setCurrentRate] = useState(0);
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const updatePricing = useSelector((state) => state.updatePricing);
  const { success: updateSuccess } = updatePricing;
  const pricingDetails = useSelector((state) => state.pricingDetails);
  const { pricingListInfo, loading } = pricingDetails;

  const { commision } = useSelector((state) => state.commisionRate);
  const { currentCommision } = useSelector(
    (state) => state.currentCommisionRate
  );

  useEffect(() => {
    if (commision && commision.rate) {
      setCurrentRate(commision.rate);
      setCommisionRate(commision.rate);
    }
  }, [commision]);

  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }
    dispatch(getPricingListAction());
    dispatch(currentCommisionAction());
  }, [adminInfo, navigate, dispatch, updateSuccess]);

  useEffect(() => {
    if (currentCommision) {
      setCurrentRate(currentCommision.rate);
      setCommisionRate(currentCommision.rate);
    }
  }, [currentCommision]);

  const handleCommisonSubmit = () => {
    if (commisonRate >= 5 && commisonRate <= 95) {
      dispatch(addCommisionRateAction(commisonRate));
      setErr("");
      setShowAddCommisionBtn(true);
    } else {
      setErr("Enter rate between 5 and 95");
    }
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="Rental Pricing"
          id={adminInfo && adminInfo._id}
          name={`${adminInfo.firstName} ${adminInfo.lastName}`}
          email={adminInfo && adminInfo.email}
          place={adminInfo && adminInfo.place}
          token={adminInfo && adminInfo.token}
        />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          <div className="mt-5">
        
            <h5 style={{ display: "inline-block", color: "green" }}>
            Rental Pricing Type
              </h5>
            <div style={{ display: "inline-block" }}>
              {showAddCommisionBtn ? (
                <>
                  <Button
                    style={{ display: "inline-block" }}
                    sx={{
                      backgroundColor: "blue",
                      width: "150px",
                      marginLeft: "20px",
                    }}
                    variant="contained"
                    onClick={() => setShowAddCommisionBtn(false)}
                  >
                    <AddIcon /> Commision
                  </Button>

                  <span style={{ marginLeft: "20px", fontWeight: "bold" }}>
                    {"Current Commision Rate : " + currentRate + "%"}
                  </span>
                </>
              ) : (
                <>
                  <TextField
                    id="standard-basic"
                    value={commisonRate}
                    label="Enter Commision Rate (%)"
                    variant="standard"
                    style={{ display: "inline-block", marginLeft: "20px" }}
                    onChange={(e) => setCommisionRate(e.target.value)}
                  />

                  <Button
                    style={{ display: "inline-block" }}
                    sx={{
                      backgroundColor: "blue",
                      width: "150px",
                      marginLeft: "20px",
                    }}
                    variant="contained"
                    onClick={() => handleCommisonSubmit()}
                  >
                    Submit
                  </Button>
                  {err && (
                    <p style={{ color: "red", marginLeft: "20px" }}>{err}</p>
                  )}
                </>
              )}
            </div>
            <RentalDataTable
              className="mt-5"
              loading
              pricingListInfo={pricingListInfo}
            />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default RentalPricingScreen;
