import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button} from 'react-bootstrap';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const CustomerBookings = (props) => {
  const dispatch = useDispatch();

  let bookings = (row) => {
    return (
        <>
        <TableRow
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             
              <TableCell component="th" scope="row">
                {row.bookingDate}
              </TableCell>
              <TableCell align="left">{row.status}</TableCell>
              <TableCell align="left">{row.bookingId}</TableCell>
              <TableCell align="right">{row.place}</TableCell>
              <TableCell align="right">
                <p>{row.mainAddress.pickupPoint.location.substring(0, 30)}</p>
            
                    <p>{row.mainAddress.dropPoint.location ? row.mainAddress.dropPoint.location.substring(0, 30) : ''}</p>
        
              </TableCell>
              <TableCell align="right">
                <p>{row.driverDetails.name}</p>
                <p>{row.driverDetails.mobNo}</p>
              </TableCell>
              <TableCell align="right">₹{row.paymentDetails.amount}</TableCell>
            </TableRow>
        </>
    )
  }

  return (
    <Modal
      {...props}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      style={{marginTop : "25px", maxWidth : "100%"}}
    >
      <Modal.Header closeButton style={{ backgroundColor: 'rgb(231, 92, 5)' }}>
        <Modal.Title id='contained-modal-title-vcenter'>
          {'Customer ID : ' + props.lrnr}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <h2>Customer Bookings</h2>
       <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1000, marginTop : "10px"}} size="medium" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Booking Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Booking Id</TableCell>
            <TableCell align="right">Place</TableCell>
            <TableCell align="right">Pickup Point & Drop Point</TableCell>
            <TableCell align="right">Driver</TableCell>
            <TableCell align="right">Payment Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
                    props.customerOrder && props.customerOrder.map(row => {
                       
            return bookings(row)
                    })
                }
        </TableBody>
      </Table>
    </TableContainer>
         {/* <pre>{JSON.stringify(props.customerOrder, null, 4)}</pre> */}
      </Modal.Body>
      <Modal.Footer>
              <Button onClick={props.onHide} className='m-3 w-25'>
                Close
              </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerBookings;
