import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { getSingleCustomerAction ,driverDetailsAction} from '../Actions/AdminActions';
import Loader from './Loader';
const WalletLogs = (props) => {
 
  const dispatch = useDispatch();
  const customerDetails = useSelector((state) => state.customerDetails);
  const { customerInfo } = customerDetails;
  const { loading, DriverDetails } = useSelector(
    (state) => state.driverDetails)

 
  useEffect(() => 
  { 
    if(props.logsFor==='driver'){
      dispatch(driverDetailsAction(props.driverId));
    }else if(props.log!=='coin'){
      dispatch(getSingleCustomerAction(props.customerId));
    }else if(props.log==="coin"){
      console.log(props,"This is props lllllll");
    }
    
  }, [dispatch]);

  const data = {
    columns: [
      {
        label: 'Wallet log ID',
        field: 'walletlogid',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Transaction by',
        field: 'transactionBy',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Wallet Holdername',
        field: 'holder',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Amount',
        field: 'amount',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Comment',
        field: 'comment',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Transaction Type',
        field: 'transactionType',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Created At',
        field: 'dateAndTime',
        sort: 'asc',
        width: 100,
      },
    ],
    rows: customerInfo && customerInfo.walletlogs, 
  };

  const data1 = {
    columns: [
      {
        label: 'Wallet log ID',
        field: 'walletlogid',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Transaction by',
        field: 'transactionBy',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Wallet Holdername',
        field: 'holder',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Amount',
        field: 'amount',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Comment',
        field: 'comment',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Transaction Type',
        field: 'transactionType',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Created At',
        field: 'dateAndTime',
        sort: 'asc',
        width: 100,
      },
    ],
    rows:   DriverDetails && DriverDetails.walletlogs, 
  };
  const data2 = {
    columns: [
      // {
      //   label: 'No',
      //   field: 'No',
      //   sort: 'asc',
      //   width: 150,
      // },
      {
        label: 'Date',
        field: 'date',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'details',
        field: 'details',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Coins',
        field: 'coins',
        sort: 'asc',
        width: 100,
      },
      
    ],
    rows:   props && props.coinsHistory, 
  };

 
  if(props.logsFor==='driver'){
    return DriverDetails ?(
      <>
    
    <div className='container-fluid walletCustomerDetails'>
        <span
          className='btn btn-dark text-light'
          style={{ float: 'right' }}
          onClick={props.onHide}
        >
          X
        </span>
        <div className='container'>
          <div className='detailsBox p-3'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-6'>
                  <h5 style={{ color: 'rgb(231, 92, 5)' }}>
                    DriverId : {DriverDetails && DriverDetails.driverId}
                  </h5>
                  <p>
                    Name :
                    <span>
                      {DriverDetails &&
                        DriverDetails.personalDetails.firstName + ' ' + DriverDetails.personalDetails.lastName}
                    </span>
                  </p>
                  <p>
                    Mobile Number :
                    <span>{DriverDetails && DriverDetails.personalDetails.defaultPhoneNumber}</span>
                  </p>
                  <p>
                  Emergency Number : <span>{DriverDetails && DriverDetails.personalDetails.emergencyNumber}</span>
                  </p>
                  <p>
                    City : <span>{DriverDetails && DriverDetails.personalDetails.addCity}</span>
                  </p>
                  <p>
                    Wallet Amount :
                    <span>₹ {DriverDetails && DriverDetails.wallet}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify(customerInfo, null, 4)}</pre> */}
      <div className='walletlogTable p-5'>
        <MDBDataTable striped bordered small data={data1} />
      </div>
    </>
    ): ( <Loader loading={true}/> )
  }else if(props.log!=="coin"){
    return  customerInfo ? (
      <>
        <div className='container-fluid walletCustomerDetails'>
          <span
            className='btn btn-dark text-light'
            style={{ float: 'right' }}
            onClick={props.onHide}
          >
            X
          </span>
          <div className='container'>
            <div className='detailsBox p-3'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6'>
                    <h5 style={{ color: 'rgb(231, 92, 5)' }}>
                      CustomerId : {customerInfo && customerInfo.cutomerID}
                    </h5>
                    <p>
                      Name :{' '}
                      <span>
                        {customerInfo &&
                          customerInfo.firstName + ' ' + customerInfo.lastName}
                      </span>
                    </p>
                    <p>
                      Mobile Number :{' '}
                      <span>{customerInfo && customerInfo.phoneNumber}</span>
                    </p>
                    <p>
                      Email ID : <span>{customerInfo && customerInfo.email}</span>
                    </p>
                    <p>
                      Gst : <span>{customerInfo && customerInfo.gstNo}</span>
                    </p>
                    <p>
                      Wallet Amount :{' '}
                      <span>₹ {customerInfo && customerInfo.wallet}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <pre>{JSON.stringify(customerInfo, null, 4)}</pre> */}
        <div className='walletlogTable p-5'>
          <MDBDataTable striped bordered small data={data} />
        </div>
      </>
    ) : ( <Loader loading={true}/> )
  }else if(props.log==="coin"){
      
    // props.coinsHistory ? (
      return <>
        <div className='container-fluid walletCustomerDetails'>
          <span
            className='btn btn-dark text-light'
            style={{ float: 'right' }}
            onClick={props.onHide}
          >
            X
          </span>
          <div className='container'>
            <div className='detailsBox p-3'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6'>
                    <h5 style={{ color: 'rgb(231, 92, 5)' }}>
                      CustomerId : {props && props.details.cutomerID}
                    </h5>
                    <p>
                      Name :{' '}
                      <span>
                        {props &&
                          props.details.firstName + ' ' + props.details.lastName}
                      </span>
                    </p>
                    <p>
                      Mobile Number :{' '}
                      <span>{props && props.details.phoneNumber}</span>
                    </p>
                    <p>
                      Email ID : <span>{props && props.details.email}</span>
                    </p>
                    {/* <p>
                      Gst : <span>{props.details && props.details.gstNo}</span>
                    </p> */}
                    <p>
                     Total Coin :
                      <span> {props && props.details.coins}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <pre>{JSON.stringify(customerInfo, null, 4)}</pre> */}
        <div className='walletlogTable p-5'>
          <MDBDataTable striped bordered small data={data2} />
        </div>
      </>
    // ) : ( <Loader loading={true}/> )
  }
  
};

export default WalletLogs;
