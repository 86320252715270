import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { statusTypes,placeTypes, vehiclesTypes, vehiclesSubTypes } from './Values';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {  Button, InputBase, Paper } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { makeStyles } from '@material-ui/core/styles';
import {
  FilterBookingPlacesAction,
  FilterBookingStatusAction,
  FilterBookingVehicleSubTypeAction,
  FilterBookingVehicleTypeAction,
  FilterWithDateAction,
  SearchBookingsAction,
} from '../../../Actions/FilterAction';

import moment from 'moment';
import 'moment-timezone';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const useStyles = makeStyles(() => ({
  formControl: {
    margin: 1,
    width: 300,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

let filters = [];

const PlaceDrowpDown
= () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [BookingPlace, setBookingPlace] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === 'All') {
      setBookingPlace(
        BookingPlace.length === placeTypes.length ? [] : placeTypes
      );
      BookingPlace.length === placeTypes.length
        ? dispatch(FilterBookingPlacesAction([]))
        : dispatch(FilterBookingPlacesAction(placeTypes));

      return;
    }
    setBookingPlace(typeof value === 'string' ? value.split(',') : value);
    dispatch(FilterBookingPlacesAction(value));
  };

  const isAllSelected =
  placeTypes.length > 0 && BookingPlace.length === placeTypes.length;

  return (
    <FormControl sx={{ m: 1, width: 100 }} size='small'>
      <InputLabel id='demo-multiple-checkbox-label'>Place</InputLabel>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        value={BookingPlace}
        onChange={handleChange}
        input={<OutlinedInput label='Place' />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        <MenuItem
          value='All'
          classes={{
            root: isAllSelected ? classes.selectedAll : '',
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                BookingPlace.length > 0 &&
                BookingPlace.length < placeTypes.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary='Select All'
          />
        </MenuItem>
        {placeTypes.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={BookingPlace.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const StatusDrowpDown = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [DriverStatus, setDriverStatus] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === 'All') {
      setDriverStatus(
        DriverStatus.length === statusTypes.length ? [] : statusTypes
      );
      DriverStatus.length === statusTypes.length
        ? dispatch(FilterBookingStatusAction([]))
        : dispatch(FilterBookingStatusAction(statusTypes));

      return;
    }
    setDriverStatus(typeof value === 'string' ? value.split(',') : value);
    dispatch(FilterBookingStatusAction(value));
  };

  const isAllSelected =
    statusTypes.length > 0 && DriverStatus.length === statusTypes.length;

  return (
    <FormControl sx={{ m: 1, width: 100 }} size='small'>
      <InputLabel id='demo-multiple-checkbox-label'>Status</InputLabel>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        value={DriverStatus}
        onChange={handleChange}
        input={<OutlinedInput label='Status' />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        <MenuItem
          value='All'
          classes={{
            root: isAllSelected ? classes.selectedAll : '',
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                DriverStatus.length > 0 &&
                DriverStatus.length < statusTypes.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary='Select All'
          />
        </MenuItem>
        {statusTypes.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={DriverStatus.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const VehicleTypeDrowpDown = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [Types, setTypes] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === 'All') {
      setTypes(Types.length === vehiclesTypes.length ? [] : vehiclesTypes);
      Types.length === vehiclesTypes.length
        ? dispatch(FilterBookingVehicleTypeAction([]))
        : dispatch(FilterBookingVehicleTypeAction(vehiclesTypes));

      return;
    }
    setTypes(typeof value === 'string' ? value.split(',') : value);
    dispatch(FilterBookingVehicleTypeAction(value));
  };

  const isAllSelected =
    vehiclesTypes.length > 0 && Types.length === vehiclesTypes.length;

  return (
    <FormControl sx={{ m: 1, width: 150 }} size='small'>
      <InputLabel id='demo-multiple-checkbox-label'>Vehicle Type</InputLabel>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        value={Types}
        onChange={handleChange}
        input={<OutlinedInput label='Vehicle Type' />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        <MenuItem
          value='All'
          classes={{
            root: isAllSelected ? classes.selectedAll : '',
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                Types.length > 0 && Types.length < vehiclesTypes.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary='Select All'
          />
        </MenuItem>
        {vehiclesTypes.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={Types.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const VehicleSubTypeDrowpDown = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [subTypes, setSubTypes] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === 'All') {
      setSubTypes(
        subTypes.length === vehiclesSubTypes.length ? [] : vehiclesSubTypes
      );
      subTypes.length === vehiclesSubTypes.length
        ? dispatch(FilterBookingVehicleSubTypeAction([]))
        : dispatch(FilterBookingVehicleSubTypeAction(vehiclesSubTypes));

      return;
    }
    setSubTypes(typeof value === 'string' ? value.split(',') : value);
    dispatch(FilterBookingVehicleSubTypeAction(value));
  };

  const isAllSelected =
    statusTypes.length > 0 && subTypes.length === statusTypes.length;
  return (
    <FormControl sx={{ m: 1, width: 170}} size='small'>
      <InputLabel id='demo-multiple-checkbox-label'>
        Vehicle subtype
      </InputLabel>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        value={subTypes}
        onChange={handleChange}
        input={<OutlinedInput label='Vehicle subtype' />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        <MenuItem
          value='All'
          classes={{
            root: isAllSelected ? classes.selectedAll : '',
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                subTypes.length > 0 && subTypes.length < statusTypes.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary='Select All'
          />
        </MenuItem>
        {vehiclesSubTypes.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={subTypes.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const FromToDateFilter = () => {
  
  const dispatch = useDispatch();
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const [from, setFrom] = React.useState("08/15/2022");
    let { statusDateFilter } = useSelector((state) => state.bookingDateFilter);
    const [to, setTo] = React.useState(new Date());

    useEffect(() => {
      if (!statusDateFilter) {
        var t = new Date();
       t.setDate(t.getDate() + 1)
        setFrom("06/15/2022");
        setTo(t);
      }
    }, [statusDateFilter]);

    let submit = () => {
    
      var d = new Date(from);
      // d.setDate(d.getDate() - 1)
       let from2 = moment(d).format('L');
        var t = new Date(to);
       t.setDate(t.getDate() + 1)
       let to2 = moment(t).format('L');
      
      if (from2 && to2) {
        
        dispatch(FilterWithDateAction({ from: from2, to: to2 }));
      } else {
        alert("please fill both fields");
      }
    };
     
    

  
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label='From'
          value={from}
          onChange={(newValue) => {
      
            setFrom(moment(newValue).format('L'));
          }}
          renderInput={(params) => (
            <TextField
              style={{ marginLeft: '10px', width: '150px' }}
              size='small'
              {...params}
            />
          )}
        />
        <DesktopDatePicker
          label='To'
          value={to}
          onChange={(newValue) => {
            let date = moment(newValue).format('L');
            setTo(date);
          }}
          renderInput={(params) => (
            <TextField
              style={{ marginLeft: '5px', width: '150px' }}
              size='small'
              {...params}
            />
          )}
        />
      </LocalizationProvider>

      <Button
        sx={{ marginLeft: 1, marginBottom: 2 }}
        onClick={submit}
        variant="outlined"
      >
        submit Dates
      </Button>
    </>
  );
};

const SearchFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const [content, setContent] = useState();
  
  const submit =(content)=>{
    if(content){
      dispatch(SearchBookingsAction(content));
    }else{
      dispatch(SearchBookingsAction(""));
      // alert("Please Enter Somthing...")
    }
    
  }
  let { refresh } = useSelector((state) => state.refresh);
  useEffect(()=>{
    setContent("")
  },[refresh])
  return (
    <>
    <Paper
      variant="outlined" square
      sx={{ display: "inline-block",  width: 250, height:40, marginTop:1,borderRadius:1  }} // width: 400
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ "aria-label": "search google maps" }}
        value={content}
        onChange={(e) => {
          setContent(e.target.value.trimStart());
        }}   
      />
      <IconButton  onClick={()=>{
        submit(content)
        //  setContent("")
      }}  aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
   
    </>
    // <>
    //   <TextField
    //     onChange={(e) => {
    //       setContent(e.target.value);
    //     }}
    //     size='small'
    //     label='Search'
    //     value={content}
    //     InputProps={{
    //       endAdornment: (
    //         <InputAdornment>
    //           <IconButton>
    //             <SearchIcon />
    //           </IconButton>
    //         </InputAdornment>
    //       ),
    //     }}
    //   />
    // </>
  );
};

export {
  StatusDrowpDown,
  PlaceDrowpDown,
  VehicleTypeDrowpDown,
  VehicleSubTypeDrowpDown,
  FromToDateFilter,
  SearchFilter,
};
