import React, { useEffect } from 'react';
import { Container, Form, Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { subAdminLoginAction } from '../../Actions/SubAdminAction';
import Messages from '../../Components/Messages';

const SubAdminLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [place, setPlace] = useState('Bangalore');

  const subAdminLogin = useSelector((state) => state.subAdminLogin);
  const { error, subAdminInfo, loading } = subAdminLogin;

  useEffect(() => {
    if (subAdminInfo) {
      navigate('/sub-admin');
    }
  }, [subAdminInfo, dispatch, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(subAdminLoginAction(data));
  };
  return (
    <div className='d-flex justify-content-center align-items-center adminLoginPage'>
      {/* <pre>{JSON.stringify(superAdminInfo)}</pre> */}
      <div className='card shadow d-flex p-5 loginFormCard'>
        <Container className='mt-2'>
          <h1 className='text-center'>Sub-Admin Login</h1>
          {error && <Messages variant='danger'>{error}</Messages>}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='mt-4'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Email'
                {...register('email', { required: true })}
              />
              {errors.email && (
                <p className='text-danger font-weight-bold'>
                  email is required.
                </p>
              )}
            </Form.Group>
            <Form.Group className='mt-4'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter Password'
                {...register('password', { required: true })}
              />
              {errors.password && (
                <p className='text-danger font-weight-bold'>
                  password is required.
                </p>
              )}
            </Form.Group>
            {/* <Form.Group className='mt-4'>
              <Form.Select
                className='w-100'
                {...register('place', { required: true })}
              >
                <option value='Bangalore'>Bangalore</option>
                <option value='Mysore'>Mysore</option>
              </Form.Select>
            </Form.Group> */}
            <Button className='mt-5 w-25 btn-danger' type='submit'>
              {loading ? <Spinner animation='border' /> : 'Login'}
            </Button>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default SubAdminLogin;
