import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { options1, options2, options3, statusTypes } from './values';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../Components/Loader';
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import ClearIcon from '@mui/icons-material/Clear';
import {
  FilterAction,
  FilterCustomerStatusAction,
  ListAllCustomersAction,
  SearchCustomersAction,
} from '../../../Actions/AdminActions';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import { InputAdornment } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 1,
    width: 300,
  },
  indeterminateColor: {
    color: '#f50057',
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let filters = [];

const CheckDropDown1 = () => {
  const dispatch = useDispatch();
  const [values, setValus] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    
    setValus(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormControl sx={{ m: 1, width: 'auto', minWidth: 150 }} size='small'>
      <InputLabel id='demo-multiple-checkbox-label'>Status</InputLabel>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        value={values}
        onChange={handleChange}
        input={<OutlinedInput label='Status' />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {options1.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={values.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const CheckDropDown2 = () => {
  const dispatch = useDispatch();
  const [values, setValues] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValues(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormControl sx={{ m: 1, width: 'auto', minWidth: 150 }} size='small'>
      <InputLabel id='demo-multiple-checkbox-label'>Vehicle Type</InputLabel>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        value={values}
        onChange={handleChange}
        input={<OutlinedInput label='Vehicle Type' />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {options2.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={values.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const CheckDropDown3 = () => {
  const dispatch = useDispatch();
  const [values, setValues] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValues(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormControl sx={{ m: 1, width: 'auto', minWidth: 180 }} size='small'>
      <InputLabel id='demo-multiple-checkbox-label'>type3</InputLabel>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        value={values}
        onChange={handleChange}
        input={<OutlinedInput label='Vehicle subtype' />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {options3.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={values.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const FromToDateFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const [value, setValue] = React.useState(new Date());

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label='From'
          value={value}
          minDate={new Date()}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              style={{ marginLeft: '50px', width: '150px' }}
              size='small'
              {...params}
            />
          )}
        />
        <DesktopDatePicker
          label='To'
          value={value}
          minDate={new Date()}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              style={{ marginLeft: '5px', width: '150px' }}
              size='small'
              {...params}
            />
          )}
        />
      </LocalizationProvider>

      <Button
        sx={{ marginLeft: '5px', bgcolor: '#e75c05' }}
        variant='contained'
      >
        Submit
      </Button>
    </>
  );
};

const StatusDrowpDown = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [DriverStatus, setDriverStatus] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === 'All') {
      setDriverStatus(
        DriverStatus.length === statusTypes.length ? [] : statusTypes
      );
      DriverStatus.length === statusTypes.length
        ? dispatch(FilterCustomerStatusAction([]))
        : dispatch(FilterCustomerStatusAction(statusTypes));

      return;
    }
    setDriverStatus(typeof value === 'string' ? value.split(',') : value);
    dispatch(FilterCustomerStatusAction(value));
  };

  const isAllSelected =
    statusTypes.length > 0 && DriverStatus.length === statusTypes.length;

    let { refresh } = useSelector((state) => state.refresUser);
    useEffect(()=>{
      setDriverStatus([])
    },[refresh])
  
  return (
    <FormControl sx={{ m: 1, width: 200, minWidth: 150 }} size='small'>
      <InputLabel id='demo-multiple-checkbox-label'>Status</InputLabel>
      <Select
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        value={DriverStatus}
        onChange={handleChange}
        input={<OutlinedInput label='Status' />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        <MenuItem
          value='All'
          classes={{
            root: isAllSelected ? classes.selectedAll : '',
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                DriverStatus.length > 0 &&
                DriverStatus.length < statusTypes.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary='Select All'
          />
        </MenuItem>
        {statusTypes.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={DriverStatus.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const SearchFilter = () => {

  const dispatch = useDispatch();
  
  const [content, setContent] = useState();
  useEffect(() => {
    dispatch(SearchCustomersAction(content));
  }, [content]);

  let { refresh } = useSelector((state) => state.refresUser);
  useEffect(()=>{
    setContent("")
  },[refresh])
  return (
    <>
    <Paper
      variant="outlined" square
      sx={{ display: "inline-block",  width: 250, height:40, marginTop:1,borderRadius:1  }} // width: 400
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ "aria-label": "search google maps" }}
        value={content}
        onChange={(e) => {
          setContent(e.target.value.trimStart());
        }}   
      />
      <IconButton  onClick={()=>{
         setContent("")
      }}  aria-label="search">
        <ClearIcon />
      </IconButton>
    </Paper>
      {/* <TextField
        onChange={(e) => {
          setContent(e.target.value);
        }}
        size='small'
        label='Search'
        value={content}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      /> */}
    </>
  );
};

export {
  CheckDropDown1,
  CheckDropDown2,
  CheckDropDown3,
  FromToDateFilter,
  SearchFilter,
  StatusDrowpDown,
};
