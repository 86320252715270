import React, { createRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AppBar from "@mui/material/AppBar";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { Avatar as MuiAvatar, Button as MuiButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { grey } from "@material-ui/core/colors";
import {
  CloudUpload as MuiCloudUpload,
  Delete as MuiDelete,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";

import styled from "styled-components";
import {
  updateDriverPersonalDetails,
  updateDriverAadhaarDetails,
  updateVaccineDetails,
  updateBankDetails,
  updatePanDetails,
  updateRcDetails,
  updateDriverVehicleDetails,
  updateLicenseDetails,
  updateInsuranceDetails,
} from "../../../Actions/AdminActions";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
const helperText = makeStyles(() => ({
  root: {
    "& .MuiTextField-root": {
      margin: 1,
      width: 200,
    },
    "& .MuiFormHelperText-root": {
      color: "red !important",
    },
  },
  bg: {
    backgroundColor: "#7986cb",
  },
  textfield: {
    backgroundColor: "#fff",
  },
}));

const UploadButton = styled(MuiButton)(spacing);
const UploadIcon = styled(MuiCloudUpload)(spacing);
const DeleteIcon = styled(MuiDelete)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    "& > *": {
      margin: 1,
    },
  },
  large: {
    width: 200,
    height: 200,
  },
}));

const BigAvatar = styled(MuiAvatar)`
  width: 200x;
  height: 200px;
  margin: 0 auto 16px;
  border: 1px solid ${grey[500]};
  box-shadow: 0 0 1px 0 ${grey[500]} inset, 0 0 1px 0 ${grey[500]};
`;

const types = [
  {
    value: "Bengaluru",
    label: "Bengaluru",
  },
  {
    value: "Mysore",
    label: "Mysore",
  },
];

export default function EditPerosonal({
  open,
  handleClose,
  setFirstName,
  setLastName,
  setDefaultPhoneNumber,
  setAlterNativeNumber,
  setEmergencynumber,
  setCity,
  setVehicleNO,
  setLocality,
  setVehicleType,
  setVehicleSubType,
  EditFor,
  driverId,
  profileImg,
  profileImg_id,
  firstName,
  lastName,
  defaultPhoneNumber,
  alternativeNumber,
  emargenceynumber,
  city,
  locality,
  vehicleNo,
  vehicleType,
  vehicleSubType,
  aadhaarFrontImg,
  aadhaarBackImg,
  aadhaarFrontImg_id,
  aadhaarBackImg_id,
  aadhaarNo,
  setAadharNo,
  vaccineImg,
  vaccineImg_id,
  passbookImg,
  passbookImg_id,
  bankAcNo,
  bankName,
  bankIfsc,
  setBankAcNo,
  setBankName,
  setBankIfsc,
  setpanCardNo,
  panImg,
  panImg_id,
  panNo,
  rcImg,
  rcImg_id,
  vehicleFrontImg,
  vehicleFrontImg_id,
  vehicleBackImg,
  vehicleBackImg_id,
  licenseImg,
  licenseImg_id,
  licenseNo,
  setLicenseNo,
  insuranceImg,
  insuranceImg_id,
  insuranceNo,
  InsuranceExpiryDate,
  setInsuranceExpiryDate,
  setInsuranceNo,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const helperTxtClass = helperText();
  const inputFileRef = createRef(null);
  const [image, setImage] = useState(null);
  const [aadhaarFront, setAadhaarFront] = useState(null);
  const [aadhaarBack, setAadhaarBack] = useState(null);
  const [newVaccineImg, setNewVaccineImg] = useState(null);
  const [newPassbookImg, setNewPassbookImg] = useState(null);
  const [newPanImg, setNewPanImg] = useState(null);
  const [newRcImg, setNewRcImg] = useState(null);
  const [newVehicleFrontImg, setNewVehicleFrontImg] = useState(null);
  const [newVehicleBackImg, setNewVehicleBackImg] = useState(null);
  const [newDrivingLicense, setNewDrivingLicense] = useState(null);
  const [newInsuranceImg, setNewInsuranceImg] = useState(null);

  const [currency, setCurrency] = React.useState("EUR");

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const inputHandle = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    if (EditFor === "personal-details") {
      if (
        (inputName === "vhType" && inputValue !== "Motor Cycle") ||
        (inputName === "vhType" && inputValue !== "Scooter")
      ) {
        setVehicleSubType(vehicleSubType);
      } else {
        setVehicleSubType("Any");
      }

      if (inputName === "fName") setFirstName(inputValue);
      else if (inputName === "Lname") setLastName(inputValue);
      else if (inputName === "Mobile") setDefaultPhoneNumber(inputValue);
      else if (inputName === "altMob") setAlterNativeNumber(inputValue);
      else if (inputName === "emgMob") setEmergencynumber(inputValue);
      else if (inputName === "City") setCity(inputValue);
      else if (inputName === "locality") setLocality(inputValue);
      else if (inputName === "VhNo") setVehicleNO(inputValue);
      else if (inputName === "vhType") {
        setVehicleType(inputValue);
        if (inputValue === "Motor Cycle" || inputValue === "Scooter")
          setVehicleSubType("Any");
      } else if (inputName === "vhSubType") setVehicleSubType(inputValue);
    } else if (EditFor === "aadhaar-details") setAadharNo(inputValue);
    else if (EditFor === "bank-details") {
      if (inputName === "BankAcNo") setBankAcNo(inputValue);
      else if (inputName === "bankName") setBankName(inputValue);
      else if (inputName === "Ifsc") setBankIfsc(inputValue);
    } else if (EditFor === "pan-details") setpanCardNo(inputValue);
    else if (EditFor === "vehicle-details") setVehicleNO(inputValue);
    else if (EditFor === "licence-details") setLicenseNo(inputValue);
    else if (EditFor === "insurance-details") {
      if (inputName === "insuranceNo") setInsuranceNo(inputValue);
    }
  };

  const submit = () => {
    if (EditFor === "personal-details") {
      let ProImg = image && image;

      if (
        firstName &&
        lastName &&
        defaultPhoneNumber &&
        alternativeNumber &&
        emargenceynumber &&
        city &&
        locality &&
        vehicleNo &&
        vehicleType &&
        vehicleSubType
      ) {
        dispatch(
          updateDriverPersonalDetails({
            driverId,
            firstName,
            lastName,
            ProImg,
            profileImg_id,
            defaultPhoneNumber,
            alternativeNumber,
            emargenceynumber,
            city,
            locality,
            vehicleNo,
            vehicleType,
            vehicleSubType,
          })
        );
        handleClose();
        setImage(null);
      } else {
        alert("somthing wrong");
      }
    } else if (EditFor === "aadhaar-details") {
      let aadhaarF = aadhaarFront && aadhaarFront;
      let aadhaarB = aadhaarBack && aadhaarBack;
      if (aadhaarNo && aadhaarNo.length === 12) {
        dispatch(
          updateDriverAadhaarDetails({
            driverId,
            aadhaarF,
            aadhaarB,
            aadhaarFrontImg_id,
            aadhaarBackImg_id,
            aadhaarNo,
          })
        );
        handleClose();
        setAadhaarFront(null);
        setAadhaarBack(null);
      }
    } else if (EditFor === "vaccination") {
      let vaccinenew = newVaccineImg && newVaccineImg;
      if (vaccinenew) {
        dispatch(
          updateVaccineDetails({
            driverId,
            vaccinenew,
            vaccineImg_id,
          })
        );
        handleClose();
        setNewVaccineImg(null);
      }
    } else if (EditFor === "bank-details") {
      let newPassBkImg = newPassbookImg && newPassbookImg;
      if (bankAcNo && bankName && bankIfsc) {
        dispatch(
          updateBankDetails({
            driverId,
            newPassBkImg,
            passbookImg_id,
            bankAcNo,
            bankName,
            bankIfsc,
          })
        );
        handleClose();
        setNewPassbookImg(null);
      }
    } else if (EditFor === "pan-details") {
      let panimg = newPanImg && newPanImg;
      if (panimg || panNo.length === 10) {
        dispatch(
          updatePanDetails({
            driverId,
            panimg,
            panImg_id,
            panNo,
          })
        );
        handleClose();
        setNewPanImg(null);
      }
    } else if (EditFor === "rc-details") {
      let rc = newRcImg && newRcImg;
      if (rc) {
        dispatch(
          updateRcDetails({
            driverId,
            rc,
            rcImg_id,
          })
        );
        handleClose();
        setNewRcImg(null);
      }
    } else if (EditFor === "vehicle-details") {
      let vehicleF = newVehicleFrontImg && newVehicleFrontImg;
      let vehicleB = newVehicleBackImg && newVehicleBackImg;
      if (vehicleNo) {
        dispatch(
          updateDriverVehicleDetails({
            driverId,
            vehicleF,
            vehicleB,
            vehicleBackImg_id,
            vehicleFrontImg_id,
            vehicleNo,
          })
        );
        handleClose();
        setNewVehicleBackImg(null);
        setNewVehicleFrontImg(null);
      }
    } else if (EditFor === "licence-details") {
      let licenseImg = newDrivingLicense && newDrivingLicense;
      if (licenseNo) {
        dispatch(
          updateLicenseDetails({
            driverId,
            licenseImg,
            licenseImg_id,
            licenseNo,
          })
        );
        handleClose();
        setNewDrivingLicense(null);
      }
    } else if (EditFor === "insurance-details") {
      let insuranceImg = newInsuranceImg && newInsuranceImg;
      if (InsuranceExpiryDate && insuranceNo) {
        dispatch(
          updateInsuranceDetails({
            driverId,
            insuranceImg,
            insuranceImg_id,
            InsuranceExpiryDate,
            insuranceNo,
          })
        );
        handleClose();
        setNewInsuranceImg(null);
      }
    }
  };

  const handleOnChange = (event) => {
    const newImage = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(newImage);
    if (event.target.name === "profile")
      reader.onloadend = () => setImage(reader.result);
    else if (event.target.name === "aadhaarFront")
      reader.onloadend = () => setAadhaarFront(reader.result);
    else if (event.target.name === "aadhaarBack")
      reader.onloadend = () => setAadhaarBack(reader.result);
    else if (event.target.name === "vaccineImg")
      reader.onloadend = () => setNewVaccineImg(reader.result);
    else if (event.target.name === "passbook")
      reader.onloadend = () => setNewPassbookImg(reader.result);
    else if (event.target.name === "panImg")
      reader.onloadend = () => setNewPanImg(reader.result);
    else if (event.target.name === "rcImg")
      reader.onloadend = () => setNewRcImg(reader.result);
    else if (event.target.name === "vehicleBack")
      reader.onloadend = () => setNewVehicleBackImg(reader.result);
    else if (event.target.name === "vehicleFront")
      reader.onloadend = () => setNewVehicleFrontImg(reader.result);
    else if (event.target.name === "licenseImg")
      reader.onloadend = () => setNewDrivingLicense(reader.result);
    else if (event.target.name === "insurance")
      reader.onloadend = () => setNewInsuranceImg(reader.result);
  };

  const handleInsuranceImgClick = (event) => {
    if (newInsuranceImg) {
      event.preventDefault();
      setNewInsuranceImg(null);
    }
  };
  const handleLicenseClick = (event) => {
    if (newDrivingLicense) {
      event.preventDefault();
      setNewDrivingLicense(null);
    }
  };
  const handleVehicleFrontClick = (event) => {
    if (newVehicleFrontImg) {
      event.preventDefault();
      setNewVehicleFrontImg(null);
    }
  };
  const handleVehicleBackClick = (event) => {
    if (newVehicleBackImg) {
      event.preventDefault();
      setNewVehicleBackImg(null);
    }
  };
  const handleAadhaarFrontClick = (event) => {
    if (aadhaarFront) {
      event.preventDefault();
      setAadhaarFront(null);
    }
  };
  const handleAadhaarBackClick = (event) => {
    if (aadhaarBack) {
      event.preventDefault();
      setAadhaarBack(null);
    }
  };
  const handleClick = (event) => {
    if (image) {
      event.preventDefault();
      setImage(null);
    }
  };
  const handleVaccineClick = (event) => {
    if (newVaccineImg) {
      event.preventDefault();
      setImage(null);
    }
  };
  const handleRcClick = (event) => {
    if (newRcImg) {
      event.preventDefault();
      setNewRcImg(null);
    }
  };

  const handlePanClick = (event) => {
    if (newPanImg) {
      event.preventDefault();
      setNewPanImg(null);
    }
  };
  const handlePassBookImgClick = (event) => {
    if (newPassbookImg) {
      event.preventDefault();
      setNewPassbookImg(null);
    }
  };
  switch (EditFor) {
    case "insurance-details":
      return (
        <React.Fragment>
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
          >
            <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
              <DialogTitle sx={{ color: "white" }}>
                <EditIcon />
                <span style={{ marginLeft: "20px" }}>
                  Edit Insurance Details
                </span>
              </DialogTitle>
            </AppBar>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": { m: 2 },
                }}
              >
                <div>
                  <CenteredContent>
                    <div>
                      {
                        <img
                          width="400px"
                          height="300px"
                          src={newInsuranceImg || insuranceImg}
                          alt="chosen"
                        ></img>
                      }
                    </div>
                    <input
                      ref={inputFileRef}
                      accept="image/*"
                      hidden
                      name="insurance"
                      id="insuranceImg"
                      type="file"
                      onChange={handleOnChange}
                    />
                    <label htmlFor="insuranceImg">
                      <UploadButton
                        variant="contained"
                        color="primary"
                        component="span"
                        m={2}
                        onClick={handleInsuranceImgClick}
                      >
                        {newInsuranceImg ? (
                          <DeleteIcon mr={2} />
                        ) : (
                          <UploadIcon mr={2} />
                        )}
                        {newInsuranceImg ? "Cancel" : "Update"}
                      </UploadButton>
                    </label>
                  </CenteredContent>
                </div>
                <div>
                  <TextField
                    onChange={inputHandle}
                    label="Insurance Number"
                    name="insuranceNo"
                    value={insuranceNo}
                    fullWidth
                    sx={{ mb: "20px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={helperTxtClass.root}
                    helperText={
                      insuranceNo ? null : "Please Enter Insurance Number"
                    }
                  />

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Insurance Expiry Date"
                      inputFormat="MM/dd/yyyy"
                      value={InsuranceExpiryDate}
                      onChange={(newDt) => {
                        setInsuranceExpiryDate(newDt);
                      }}
                      renderInput={(params) => (
                        <TextField
                          helperText={
                            InsuranceExpiryDate
                              ? null
                              : "Please enter add insurance expiry date"
                          }
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                style={{ backgroundColor: "#e75c05", color: "#fff" }}
                onClick={submit}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "red", color: "#fff" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    case "licence-details":
      return (
        <React.Fragment>
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
          >
            <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
              <DialogTitle sx={{ color: "white" }}>
                <EditIcon /> <span>Edit License Details</span>
              </DialogTitle>
            </AppBar>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": { m: 2 },
                }}
              >
                <div>
                  <DialogContent dividers>
                    <div>
                      {
                        <img
                          width="450px"
                          height="250px"
                          src={newDrivingLicense || licenseImg}
                          alt="chosen"
                        ></img>
                      }
                    </div>
                  </DialogContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "5px",
                    }}
                  >
                    <input
                      ref={inputFileRef}
                      accept="image/*"
                      hidden
                      id="licenseImage"
                      type="file"
                      name="licenseImg"
                      onChange={handleOnChange}
                    />
                    <label htmlFor="licenseImage">
                      <UploadButton
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ width: "300px" }}
                        mb={2}
                        onClick={handleLicenseClick}
                      >
                        {newDrivingLicense ? (
                          <DeleteIcon mr={2} />
                        ) : (
                          <UploadIcon mr={2} />
                        )}
                        {newDrivingLicense ? "Cancel" : "Update"}
                      </UploadButton>
                    </label>
                  </div>
                </div>
              </Box>

              <TextField
                onChange={inputHandle}
                label="License number"
                name="licenseNo"
                value={licenseNo}
                fullWidth
                sx={{ mb: "20px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                className={helperTxtClass.root}
                helperText={licenseNo ? null : "Please enter license number"}
              />
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                style={{ backgroundColor: "#e75c05", color: "#fff" }}
                onClick={submit}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "red", color: "#fff" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    case "personal-details":
      return (
        <React.Fragment>
          <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={open}
            onClose={handleClose}
          >
            <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
              <DialogTitle sx={{ color: "white" }}>
                <EditIcon />{" "}
                <span style={{ marginLeft: "20px" }}>
                  Edit Personal Details
                </span>{" "}
              </DialogTitle>
            </AppBar>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": { m: 2 },
                }}
              >
                <div>
                  <CenteredContent>
                    <BigAvatar
                      className={classes.large}
                      $withBorder
                      alt="Avatar"
                      src={image || profileImg}
                    />
                    <input
                      ref={inputFileRef}
                      accept="image/*"
                      hidden
                      name="profile"
                      id="avatar-image-upload"
                      type="file"
                      onChange={handleOnChange}
                    />
                    <label htmlFor="avatar-image-upload">
                      <UploadButton
                        variant="contained"
                        color="primary"
                        component="span"
                        mb={2}
                        onClick={handleClick}
                      >
                        {image ? <DeleteIcon mr={2} /> : <UploadIcon mr={2} />}
                        {image ? "Cancel" : "Update"}
                      </UploadButton>
                    </label>
                  </CenteredContent>
                </div>
                <div>
                  
                  <TextField
                    onChange={inputHandle}
                    label="First Name"
                    name="fName"
                    value={firstName}
                    fullWidth
                    sx={{ mb: "20px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={helperTxtClass.root}
                    helperText={firstName ? null : "Please enter firstname"}
                  />
                  <TextField
                    onChange={inputHandle}
                    label="Last Name"
                    name="Lname"
                    value={lastName}
                    sx={{ mb: "20px" }}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={helperTxtClass.root}
                    helperText={lastName ? null : "Please enter lastname"}
                  />
                  <TextField
                    onChange={inputHandle}
                    label="Mobile"
                    name="Mobile"
                    type="number"
                    value={defaultPhoneNumber}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={helperTxtClass.root}
                    helperText={
                      defaultPhoneNumber
                        ? null
                        : "Please enter default mobile number"
                    }
                  />
                </div>
                <div>
                  <TextField
                    onChange={inputHandle}
                    label="Alternative Number"
                    name="altMob"
                    value={alternativeNumber}
                    type="number"
                    locality
                    fullWidth
                    sx={{ mb: "20px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={helperTxtClass.root}
                    helperText={
                      alternativeNumber
                        ? null
                        : "Please enter alternative number"
                    }
                  />
                  <TextField
                    onChange={inputHandle}
                    label="Emergency Number"
                    name="emgMob"
                    value={emargenceynumber}
                    fullWidth
                    sx={{ mb: "20px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={helperTxtClass.root}
                    helperText={
                      emargenceynumber ? null : "Please enter emergency number"
                    }
                  />
                 
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      City
                    </InputLabel>
                    <NativeSelect
                      value={vehicleType}
                      inputProps={{
                        name: "City",
                        id: "uncontrolled-native",
                      }}
                      onChange={inputHandle}
                    >
                      <option value={city}>{city}</option>
                      <option value="Bengaluru">Bengaluru</option>
                      <option value="Mysore">Mysore</option>
                    </NativeSelect>
                  </FormControl>
                  
                  {/* <TextField
                    onChange={inputHandle}
                    label="City"
                    name="City"
                    value={city}
                    fullWidth
                    sx={{ mb: "20px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={helperTxtClass.root}
                    helperText={city ? null : "Please enter city"}
                  /> */}
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": { m: 2 },
                }}
              >
                <TextField
                  onChange={inputHandle}
                  label="Locality"
                  name="locality"
                  value={locality}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={helperTxtClass.root}
                  helperText={locality ? null : "Please enter locality"}
                />
                <TextField
                  onChange={inputHandle}
                  label="Vehicle No"
                  name="VhNo"
                  value={vehicleNo}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={helperTxtClass.root}
                  helperText={vehicleNo ? null : "Please enter vehicle number"}
                />

                <Box
                  sx={{ minWidth: 250, borderBottom: 0, borderRadius: "5px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Vehicle Type
                    </InputLabel>
                    <NativeSelect
                      value={vehicleType}
                      inputProps={{
                        name: "vhType",
                        id: "uncontrolled-native",
                      }}
                      onChange={inputHandle}
                    >
                      <option value={vehicleType}>{vehicleType}</option>
                      {vehicleType === "Motor Cycle" ? null : (
                        <option value="Motor Cycle">Motor Cycle</option>
                      )}
                      {vehicleType === "Scooter" ? null : (
                        <option value="Scooter">Scooter</option>
                      )}
                      {vehicleType === "Three Wheeler & APE" ? null : (
                        <option value="Three Wheeler & APE">
                          Three Wheeler & APE
                        </option>
                      )}
                      {vehicleType === "Tata ACE 7ft" ? null : (
                        <option value="Tata ACE 7ft">Tata ACE 7ft</option>
                      )}
                      {vehicleType === "Tata ACE 8ft / Bolero" ? null : (
                        <option value="Tata ACE 8ft / Bolero">
                          Tata ACE 8ft / Bolero
                        </option>
                      )}
                      {vehicleType === "Tata 407" ? null : (
                        <option value="Tata 407">Tata 407</option>
                      )}
                    </NativeSelect>
                  </FormControl>
                </Box>
                <Box
                  sx={{ minWidth: 250, borderBottom: 0, borderRadius: "5px" }}
                >
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      htmlFor="uncontrolled-native"
                    >
                      Vehicle sub-type
                    </InputLabel>
                    <NativeSelect
                      defaultValue={vehicleSubType}
                      value={vehicleSubType}
                      inputProps={{
                        name: "vhSubType",
                        id: "uncontrolled-native",
                      }}
                      onChange={inputHandle}
                    >
                      {vehicleType === "Motor Cycle" ||
                      vehicleType === "Scooter" ? (
                        <option value="Open">Any</option>
                      ) : (
                        <option value={vehicleSubType}>{vehicleSubType}</option>
                      )}

                      {vehicleType === "Motor Cycle" ||
                      vehicleType === "Scooter" ? null : (
                        <option value="Close">Close</option>
                      )}
                      {vehicleType === "Motor Cycle" ||
                      vehicleType === "Scooter" ? null : (
                        <option value="Open">Open</option>
                      )}
                      {vehicleType === "Motor Cycle" ||
                      vehicleType === "Scooter" ? null : (
                        <option value="Tarpaulin">Tarpaulin</option>
                      )}
                    </NativeSelect>
                  </FormControl>
                </Box>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                style={{ backgroundColor: "#e75c05", color: "#fff" }}
                onClick={submit}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "red", color: "#fff" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    case "vehicle-details":
      return (
        <React.Fragment>
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
          >
            <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
              <DialogTitle sx={{ color: "white" }}>
                <EditIcon />
                <span style={{ marginLeft: "20px" }}>Edit Vehicle Details</span>
              </DialogTitle>
            </AppBar>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": { m: 2 },
                }}
              >
                <div>
                  <span style={{ marginLeft: "30px" }}>
                    Vehicle front Image
                  </span>
                  <DialogContent dividers>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <img
                          width="350px"
                          src={newVehicleFrontImg || vehicleFrontImg}
                          alt="chosen"
                        ></img>
                      }
                    </div>
                  </DialogContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "5px",
                    }}
                  >
                    <input
                      ref={inputFileRef}
                      accept="image/*"
                      hidden
                      id="vehicle-front"
                      type="file"
                      name="vehicleFront"
                      onChange={handleOnChange}
                    />
                    <label htmlFor="vehicle-front">
                      <UploadButton
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ width: "300px" }}
                        mb={2}
                        onClick={handleVehicleFrontClick}
                      >
                        {newVehicleFrontImg ? (
                          <DeleteIcon mr={2} />
                        ) : (
                          <UploadIcon mr={2} />
                        )}
                        {newVehicleFrontImg ? "Cancel" : "Update"}
                      </UploadButton>
                    </label>
                  </div>
                </div>
                <div>
                  <span style={{ marginLeft: "30px" }}>Vehicle back Image</span>
                  <DialogContent dividers>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <img
                          width="350px"
                          src={newVehicleBackImg || vehicleBackImg}
                          alt="chosen"
                        ></img>
                      }
                    </div>
                  </DialogContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "5px",
                    }}
                  >
                    <input
                      ref={inputFileRef}
                      accept="image/*"
                      hidden
                      id="vehicle-back"
                      type="file"
                      name="vehicleBack"
                      onChange={handleOnChange}
                    />
                    <label htmlFor="vehicle-back">
                      <UploadButton
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ width: "300px" }}
                        mb={2}
                        onClick={handleVehicleBackClick}
                      >
                        {newVehicleBackImg ? (
                          <DeleteIcon mr={2} />
                        ) : (
                          <UploadIcon mr={2} />
                        )}
                        {newVehicleBackImg ? "Cancel" : "Update"}
                      </UploadButton>
                    </label>
                  </div>
                </div>
              </Box>
              <div>
                <div style={{ display: "flex", justifyContent: "end" }}> </div>
                <TextField
                  onChange={inputHandle}
                  label="Vehcle number"
                  name="vehicleNumber"
                  value={vehicleNo}
                  fullWidth
                  size="Normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={helperTxtClass.root}
                  helperText={vehicleNo ? null : "Please enter vehicle number"}
                />
              </div>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                style={{ backgroundColor: "#e75c05", color: "#fff" }}
                onClick={submit}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "red", color: "#fff" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    case "aadhaar-details":
      return (
        <React.Fragment>
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
          >
            <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
              <DialogTitle sx={{ color: "white" }}>
                <EditIcon />{" "}
                <span style={{ marginLeft: "20px" }}>Edit Aadhaar Details</span>{" "}
              </DialogTitle>
            </AppBar>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": { m: 2 },
                }}
              >
                <div>
                  <span style={{ marginLeft: "30px" }}>
                    Aadhaar Front Image
                  </span>
                  <DialogContent dividers>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <img
                          width="350px"
                          src={aadhaarFront || aadhaarFrontImg}
                          alt="chosen"
                        ></img>
                      }
                    </div>
                  </DialogContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "5px",
                    }}
                  >
                    <input
                      ref={inputFileRef}
                      accept="image/*"
                      hidden
                      id="aadhaar-front"
                      type="file"
                      name="aadhaarFront"
                      onChange={handleOnChange}
                    />
                    <label htmlFor="aadhaar-front">
                      <UploadButton
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ width: "300px" }}
                        mb={2}
                        onClick={handleAadhaarFrontClick}
                      >
                        {aadhaarFront ? (
                          <DeleteIcon mr={2} />
                        ) : (
                          <UploadIcon mr={2} />
                        )}
                        {aadhaarFront ? "Cancel" : "Update"}
                      </UploadButton>
                    </label>
                  </div>
                </div>
                <div>
                  <span style={{ marginLeft: "30px" }}>Aadhaar Back Image</span>
                  <DialogContent dividers>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <img
                          width="350px"
                          src={aadhaarBack || aadhaarBackImg}
                          alt="chosen"
                        ></img>
                      }
                    </div>
                  </DialogContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "5px",
                    }}
                  >
                    <input
                      ref={inputFileRef}
                      accept="image/*"
                      hidden
                      id="aadhaar-back"
                      type="file"
                      name="aadhaarBack"
                      onChange={handleOnChange}
                    />
                    <label htmlFor="aadhaar-back">
                      <UploadButton
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ width: "300px" }}
                        mb={2}
                        onClick={handleAadhaarBackClick}
                      >
                        {aadhaarBack ? (
                          <DeleteIcon mr={2} />
                        ) : (
                          <UploadIcon mr={2} />
                        )}
                        {aadhaarBack ? "Cancel" : "Update"}
                      </UploadButton>
                    </label>
                  </div>
                </div>
              </Box>
              <div>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  {" "}
                  <span align="">{aadhaarNo.length}/12</span>{" "}
                </div>
                <TextField
                  onChange={inputHandle}
                  label="Aadhaar number"
                  type="number"
                  name="aadhaarNo"
                  value={aadhaarNo}
                  fullWidth
                  size="Normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={helperTxtClass.root}
                  helperText={
                    aadhaarNo ? (
                      <>
                        {aadhaarNo.length === 12
                          ? null
                          : "Aadhaar number must be 12th character"}{" "}
                      </>
                    ) : (
                      "Please enter aadhaar number"
                    )
                  }
                />
              </div>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                style={{ backgroundColor: "#e75c05", color: "#fff" }}
                onClick={submit}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "red", color: "#fff" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    case "vaccination":
      return (
        <React.Fragment>
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
          >
            <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
              <DialogTitle sx={{ color: "white" }}>
                <EditIcon /> <span>Edit Vaccine Details</span>
              </DialogTitle>
            </AppBar>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": { m: 2 },
                }}
              >
                <div>
                  <DialogContent dividers>
                    <div>
                      {
                        <img
                          width="450px"
                          src={newVaccineImg || vaccineImg}
                          alt="chosen"
                        ></img>
                      }
                    </div>
                  </DialogContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "5px",
                    }}
                  >
                    <input
                      ref={inputFileRef}
                      accept="image/*"
                      hidden
                      id="vaccine"
                      type="file"
                      name="vaccineImg"
                      onChange={handleOnChange}
                    />
                    <label htmlFor="vaccine">
                      <UploadButton
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ width: "300px" }}
                        mb={2}
                        onClick={handleVaccineClick}
                      >
                        {newVaccineImg ? (
                          <DeleteIcon mr={2} />
                        ) : (
                          <UploadIcon mr={2} />
                        )}
                        {newVaccineImg ? "Cancel" : "Update"}
                      </UploadButton>
                    </label>
                  </div>
                </div>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                style={{ backgroundColor: "#e75c05", color: "#fff" }}
                onClick={submit}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "red", color: "#fff" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    case "bank-details":
      return (
        <React.Fragment>
          <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={open}
            onClose={handleClose}
          >
            <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
              <DialogTitle sx={{ color: "white" }}>
                <EditIcon />
                <span style={{ marginLeft: "20px" }}>Edit bank Details</span>
              </DialogTitle>
            </AppBar>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": { m: 2 },
                }}
              >
                <div>
                  <CenteredContent>
                    <div>
                      {
                        <img
                          width="400px"
                          height="300px"
                          src={newPassbookImg || passbookImg}
                          alt="chosen"
                        ></img>
                      }
                    </div>
                    <input
                      ref={inputFileRef}
                      accept="image/*"
                      hidden
                      name="passbook"
                      id="passbook"
                      type="file"
                      onChange={handleOnChange}
                    />
                    <label htmlFor="passbook">
                      <UploadButton
                        variant="contained"
                        color="primary"
                        component="span"
                        m={2}
                        onClick={handlePassBookImgClick}
                      >
                        {newPassbookImg ? (
                          <DeleteIcon mr={2} />
                        ) : (
                          <UploadIcon mr={2} />
                        )}
                        {newPassbookImg ? "Cancel" : "Update"}
                      </UploadButton>
                    </label>
                  </CenteredContent>
                </div>
                <div>
                  <TextField
                    onChange={inputHandle}
                    label="Bank Ac Number"
                    name="BankAcNo"
                    type="number"
                    value={bankAcNo}
                    fullWidth
                    sx={{ mb: "20px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={helperTxtClass.root}
                    helperText={bankAcNo ? null : "Please Enter Bank Ac Number"}
                  />
                  <TextField
                    onChange={inputHandle}
                    label="Bank Name"
                    name="bankName"
                    value={bankName}
                    fullWidth
                    sx={{ mb: "20px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={helperTxtClass.root}
                    helperText={bankName ? null : "Please enter bank name"}
                  />
                  <TextField
                    onChange={inputHandle}
                    label="Ifsc"
                    name="Ifsc"
                    value={bankIfsc}
                    fullWidth
                    sx={{ mb: "20px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={helperTxtClass.root}
                    helperText={bankIfsc ? null : "Please enter ifsc code"}
                  />
                </div>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                style={{ backgroundColor: "#e75c05", color: "#fff" }}
                onClick={submit}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "red", color: "#fff" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    case "pan-details":
      return (
        <React.Fragment>
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
          >
            <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
              <DialogTitle sx={{ color: "white" }}>
                <EditIcon /> <span>Edit Pan Details</span>
              </DialogTitle>
            </AppBar>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": { m: 2 },
                }}
              >
                <div>
                  <DialogContent dividers>
                    <div>
                      {
                        <img
                          width="450px"
                          height="250px"
                          src={newPanImg || panImg}
                          alt="chosen"
                        ></img>
                      }
                    </div>
                  </DialogContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "5px",
                    }}
                  >
                    <input
                      ref={inputFileRef}
                      accept="image/*"
                      hidden
                      id="newPanImg"
                      type="file"
                      name="panImg"
                      onChange={handleOnChange}
                    />
                    <label htmlFor="newPanImg">
                      <UploadButton
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ width: "300px" }}
                        mb={2}
                        onClick={handlePanClick}
                      >
                        {newPanImg ? (
                          <DeleteIcon mr={2} />
                        ) : (
                          <UploadIcon mr={2} />
                        )}
                        {newPanImg ? "Cancel" : "Update"}
                      </UploadButton>
                    </label>
                  </div>
                </div>
              </Box>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <span align="">{panNo.length}/10</span>{" "}
              </div>
              <TextField
                onChange={inputHandle}
                label="Pancard number"
                name="PanNo"
                type="text"
                value={panNo}
                fullWidth
                sx={{ mb: "20px" }}
                InputLabelProps={{
                  shrink: true,
                }}
                className={helperTxtClass.root}
                helperText={
                  panNo ? (
                    <span>
                      {panNo.length === 10
                        ? null
                        : "PAN card number must have 10 digits"}
                    </span>
                  ) : (
                    "PAN card has not been added yet"
                  )
                }
              />
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                style={{ backgroundColor: "#e75c05", color: "#fff" }}
                onClick={submit}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "red", color: "#fff" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    case "rc-details":
      return (
        <React.Fragment>
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
          >
            <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
              <DialogTitle sx={{ color: "white" }}>
                <EditIcon /> <span>Edit Rc Details</span>
              </DialogTitle>
            </AppBar>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": { m: 2 },
                }}
              >
                <div>
                  <DialogContent dividers>
                    <div>
                      {
                        <img
                          width="450px"
                          src={newRcImg || rcImg}
                          alt="chosen"
                        ></img>
                      }
                    </div>
                  </DialogContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "5px",
                    }}
                  >
                    <input
                      ref={inputFileRef}
                      accept="image/*"
                      hidden
                      id="rcImg"
                      type="file"
                      name="rcImg"
                      onChange={handleOnChange}
                    />
                    <label htmlFor="rcImg">
                      <UploadButton
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ width: "300px" }}
                        mb={2}
                        onClick={handleRcClick}
                      >
                        {newRcImg ? (
                          <DeleteIcon mr={2} />
                        ) : (
                          <UploadIcon mr={2} />
                        )}
                        {newRcImg ? "Cancel" : "Update"}
                      </UploadButton>
                    </label>
                  </div>
                </div>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                style={{ backgroundColor: "#e75c05", color: "#fff" }}
                onClick={submit}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "red", color: "#fff" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    default:
      break;
  }
}
