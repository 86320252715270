import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Loader";
import {
  addCommentAction,
  addDriverCommentAction,
  driverCommentAction,
  customerCommentAction,
} from "../../Actions/AdminActions";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  AppBar,
  TextareaAutosize,
  Box,
} from "@mui/material";
import { useState } from "react";
import { io } from "socket.io-client";
import { LOADRUNNR_SOCKET_API } from "../../socketConst/BaseApi";

const ManualComment = ({ status, bookingId, isOpen,adminName, handleClose }) => {
  const [comment, setComment] = useState();
  const [enterStatus, setEnterStatus] = useState(false);
  const submit = () => {
    const socket = io(LOADRUNNR_SOCKET_API, {
      withCredentials: true,
      transports: ["websocket"],
      extraHeaders: {
        "my-custom-header": "abcd",
      },
    });
    if (comment && bookingId && status === "ReachPickupPoint") {
      socket.emit("reachPickupPoint", { comment, bookingId, isAdmin: true ,adminName});
    } else if (comment && bookingId && status === "Ongoing") {
      socket.emit("onGoing", { comment, bookingId, isAdmin: true,adminName });
    } else if (comment && bookingId && status === "Reached_Stop_1") {
      socket.emit("stop1Completed", { comment, bookingId, isAdmin: true,adminName });
    } else if (comment && bookingId && status === "Complete") {
      socket.emit("completed", { comment, bookingId, isAdmin: true,adminName });
    }else if(comment && bookingId && status === "stop1Completed"){
      socket.emit("stop1Completed", { comment, bookingId, isAdmin: true,adminName });
    }
    handleClose();
  };

  const handleInput = (e) => {
    setEnterStatus(true);
    setComment(e.target.value);
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={isOpen}
        onClose={handleClose}
      >
        <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
          <DialogTitle sx={{ color: "white" }}>
            <span style={{ marginLeft: "20px" }}>Update Order </span>{" "}
          </DialogTitle>
        </AppBar>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            <TextareaAutosize
              onChange={handleInput}
              value={comment}
              aria-label="minimum height"
              minRows={4}
              placeholder="Add Your Remarks"
              style={
                !comment && enterStatus
                  ? { width: 1200, borderColor: "red" }
                  : { width: 1200 }
              }
            />
          </Box>
          <p style={{ color: "red", marginLeft: "20px" }}>
            {!comment && enterStatus ? "Please Enter Somthing.." : ""}
          </p>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: "#e75c05", color: "#fff" }}
            onClick={submit}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "red", color: "#fff" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ManualComment;
