import axios from 'axios';
import {
  SUB_ADMIN_LOGIN_REQUEST,
  SUB_ADMIN_LOGIN_SUCCESS,
  SUB_ADMIN_LOGIN_FAIL,
  SUB_ADMIN_CUSTOMER_REQUEST,
  SUB_ADMIN_COSTOMER_SUCCESS,
  SUB_ADMIN_COSTOMER_FAIL,
  SUB_ADMIN_UPDATE_CUSTOMER_REQUEST,
  SUB_ADMIN_UPDATE_CUSTOMER_SUCCESS,
  SUB_ADMIN_UPDATE_CUSTOMER_FAIL,
  SUB_ADMIN_LOGOUT,
  SUB_ADMIN_DRIVER_LIST_REQUEST,
  SUB_ADMIN_DRIVER_LIST_SUCCESS,
  SUB_ADMIN_DRIVER_LIST_FAIL,
  SUB_ADMIN_DRIVER_ADD_COMMENT_REQUEST,
  SUB_ADMIN_DRIVER_ADD_COMMENT_SUCCESS,
  SUB_ADMIN_DRIVER_ADD_COMMENT_FAIL,
  SUB_ADMIN_BOOKING_LIST_REQUEST,
  SUB_ADMIN_BOOKING_LIST_SUCCESS,
  SUB_ADMIN_BOOKING_LIST_FAIL,
  SUB_ADMIN_UPDATE_DRIVER_REQUEST,
  SUB_ADMIN_UPDATE_DRIVER_SUCCESS,
  SUB_ADMIN_UPDATE_DRIVER_FAIL,
  SUB_ADMIN_BOOKING_ADDCOMMENT_REQUEST,
  SUB_ADMIN_BOOKING_ADDCOMMENT_SUCCESS,
  SUB_ADMIN_BOOKING_ADDCOMMENT_FAIL,
  SUB_ADMIN_FILTERED_DRIVER_LIST_REQUEST,
  SUB_ADMIN_FILTERED_DRIVER_LIST_SUCCESS,
  SUB_ADMIN_FILTERED_DRIVER_LIST_FAIL,
  SUB_ADMIN_DRIVER_FILTER_STATUS,
  SUB_ADMIN_DRIVER_FILTER_VEHICLE_TYPE
} from '../Constants/SubAdminConstants';


const LOADRUNNR_API = "https://api.loadrunnr.in"



export const subAdminLoginAction = (details) => async (dispatch) => {
  try {
    dispatch({
      type: SUB_ADMIN_LOGIN_REQUEST,
    });

    const { data } = await axios.post(
      `${LOADRUNNR_API}/api/sub-admin/login`,
      details
    );

    dispatch({
      type: SUB_ADMIN_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem('subAdminInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: SUB_ADMIN_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const refreshBookingAction = (data) => async (dispatch, getState) => {
  const {
    subAdminLogin: { subAdminInfo },
  } = getState();

  const config = {
    headers: {
      Authorization: `Bearer ${subAdminInfo.token}`,
    },
  };
  const { data } = await axios.get(
    `${LOADRUNNR_API}/api/sub-admin/bookinglist`,
    config
  );
  
  dispatch({
    type: SUB_ADMIN_BOOKING_LIST_SUCCESS,
    payload: data,
  });
};
export const subAdminListAllCustomersAction =
  ({rows, skip, content = "", status_filter }) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUB_ADMIN_CUSTOMER_REQUEST,
      });

      const {
        subAdminLogin: { subAdminInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${subAdminInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${LOADRUNNR_API}/api/sub-admin/customer-list/?rowCount=${rows}&skip=${skip}&status_filter=${status_filter}&content=${content}`,
        config
      );

      dispatch({
        type: SUB_ADMIN_COSTOMER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUB_ADMIN_COSTOMER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  export const bookingAddComment =
  (id, comment, subAdminName) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUB_ADMIN_BOOKING_ADDCOMMENT_REQUEST,
      });
      
      const {
        subAdminLogin: { subAdminInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${subAdminInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/sub-admin/booking-add-comment`,
        {
          id,
          comment,
          subAdminName,
        },
        config
      );
      dispatch({
        type: SUB_ADMIN_BOOKING_ADDCOMMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUB_ADMIN_BOOKING_ADDCOMMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


export const subAddCommentAction =
  (customerId, comment) => async (dispatch, getState) => {
  
    try {
      dispatch({
        type: SUB_ADMIN_UPDATE_CUSTOMER_REQUEST,
      });
      const {
        subAdminLogin: { subAdminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${subAdminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/sub-admin/add-comment`,
        { customerId, comment },
        config
      );
      dispatch({
        type: SUB_ADMIN_UPDATE_CUSTOMER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUB_ADMIN_UPDATE_CUSTOMER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const subAdminLogout = () => (dispatch) => {
    localStorage.removeItem('subAdminInfo');
    dispatch({ type: SUB_ADMIN_LOGOUT });
  };
  export const addCommentAction =
  (customerId, comment,adminName) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUB_ADMIN_UPDATE_CUSTOMER_REQUEST,
      });
      
      const {
        subAdminLogin: { subAdminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${subAdminInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/sub-admin/add-comment`,
        { customerId, comment ,adminName},
        config
      );
      dispatch({
        type: SUB_ADMIN_UPDATE_CUSTOMER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUB_ADMIN_UPDATE_CUSTOMER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const addDriverCommentAction =
  (driverId, comment,subAdminName) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUB_ADMIN_UPDATE_DRIVER_REQUEST,
      });
      const {
        subAdminLogin: { subAdminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${subAdminInfo.token}`,
        },
      };
      
      const { data } = await axios.put(
        `${LOADRUNNR_API}/api/sub-admin/add-driver-comment`,
        { driverId, comment , subAdminName },
        config
      );
      dispatch({
        type: SUB_ADMIN_UPDATE_DRIVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUB_ADMIN_UPDATE_DRIVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  export const FilterVehicleTypeAction =
  (values) => async (dispatch, getState) => {
    dispatch({
      type: SUB_ADMIN_DRIVER_FILTER_VEHICLE_TYPE,
      payload: values,
    });
  };
  export const FilterDriverStatusAction = (values) => async (dispatch) => {
    dispatch({
      type: SUB_ADMIN_DRIVER_FILTER_STATUS,
      payload: values,
    });
  };
  export const FilteredDriversAction =
  (AllDrivers) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SUB_ADMIN_FILTERED_DRIVER_LIST_REQUEST,
      });

      dispatch({
        type: SUB_ADMIN_FILTERED_DRIVER_LIST_SUCCESS,
        payload: AllDrivers,
      });
    } catch (error) {
      dispatch({
        type: SUB_ADMIN_FILTERED_DRIVER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  export const driverListAction = () => async(dispatch, getState) => {
   
   try{
    dispatch({
      type : SUB_ADMIN_DRIVER_LIST_REQUEST
    })

    const {
      subAdminLogin: { subAdminInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${subAdminInfo.token}`,
      },
    };
   
    const { data } = await axios.get(`${LOADRUNNR_API}/api/sub-admin/driver-list`, config);
   
    dispatch({
      type : SUB_ADMIN_DRIVER_LIST_SUCCESS,
      payload : data
    })

   }catch(error){
    
    dispatch({
      type: SUB_ADMIN_DRIVER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
   }
  }


  export const subAdmin_driverAddComment = (driverId, comment) => async(dispatch, getState) => {
    try{
      dispatch({
        type : SUB_ADMIN_DRIVER_ADD_COMMENT_REQUEST
      })
  
      const {
        subAdminLogin: { subAdminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${subAdminInfo.token}`,
        },
      };
  
      const { data } = await axios.put(`${LOADRUNNR_API}/api/sub-admin/add-driver-comment`,{driverId, comment}, config);
  
      dispatch({
        type : SUB_ADMIN_DRIVER_ADD_COMMENT_SUCCESS,
        payload : data
      })
    }catch(error){
      dispatch({
        type: SUB_ADMIN_DRIVER_ADD_COMMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }


  export const subadminBookingListAction = () => async(dispatch, getState) => {
    try{
      dispatch({
        type : SUB_ADMIN_BOOKING_LIST_REQUEST
      })
      const {
        subAdminLogin: { subAdminInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${subAdminInfo.token}`,
        },
      };
      const { data } = await axios.get(`${LOADRUNNR_API}/api/sub-admin/bookinglist`, config);
       
      dispatch({
        type : SUB_ADMIN_BOOKING_LIST_SUCCESS,
        payload : data
      })
    }catch(error){
      dispatch({
        type: SUB_ADMIN_BOOKING_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }

