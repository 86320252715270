export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN-REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN-SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN-FAIL";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

export const ADMIN_MANAGE_CUSTOMER_LIST_REQUEST =
  "ADMIN_MANAGE_CUSTOMER_LIST-REQUEST";
export const ADMIN_MANAGE_CUSTOMER_LIST_SUCCESS =
  "ADMIN_MANAGE_CUSTOMER_LIST-SUCCESS";
export const ADMIN_MANAGE_CUSTOMER_LIST_FAIL =
  "ADMIN_MANAGE_CUSTOMER_LIST-FAIL";

export const ADMIN_UPDATE_CUSTOMER_REQUEST = "ADMIN_UPDATE_CUSTOMER_REQUEST";
export const ADMIN_UPDATE_CUSTOMER_SUCCESS = "ADMIN_UPDATE_CUSTOMER_SUCCESS";
export const ADMIN_UPDATE_CUSTOMER_FAIL = "ADMIN_UPDATE_CUSTOMER_FAIL";

export const ADMIN_SINGLE_CUSTOMER_REQUEST = "ADMIN_SINGLE_CUSTOMER_REQUEST";
export const ADMIN_SINGLE_CUSTOMER_SUCCESS = "ADMIN_SINGLE_CUSTOMER_SUCCESS";
export const ADMIN_SINGLE_CUSTOMER_FAIL = "ADMIN_SINGLE_CUSTOMER_FAIL";

export const ADMIN_PRICING_DATA_REQUEST = "ADMIN_PRICING_DATA_REQUEST";
export const ADMIN_PRICING_DATA_SUCCESS = "ADMIN_PRICING_DATA_SUCCESS";
export const ADMIN_PRICING_DATA_FAIL = "ADMIN_PRICING_DATA_FAIL";

export const ADMIN_SINGLE_PRICINGTYPE_REQUEST =
  "ADMIN_SINGLE_PRICINGTYPE_REQUEST";
export const ADMIN_SINGLE_PRICINGTYPE_SUCCESS =
  "ADMIN_UPDATE_PRICINGTYPE_SUCCESS";
export const ADMIN_SINGLE_PRICINGTYPE_FAIL = "ADMIN_SINGLE_PRICINGTYPE_FAIL";

export const ADMIN_UPDATE_PRICINGTYPE_REQUEST =
  "ADMIN_UPDATE_PRICINGTYPE_REQUEST";
export const ADMIN_UPDATE_PRICINGTYPE_SUCCESS =
  "ADMIN_UPDATE_PRICINGTYPE_SUCCESS";
export const ADMIN_UPDATE_PRICINGTYPE_FAIL = "ADMIN_UPDATE_PRICINGTYPE_FAIL";

export const ADMIN_ADD_DRIVER_BANNER_REQUEST =
  "ADMIN_ADD_DRIVER_BANNER_REQUEST";
export const ADMIN_ADD_DRIVER_BANNER_SUCCESS =
  "ADMIN_ADD_DRIVER_BANNER_SUCCESS";
export const ADMIN_ADD_DRIVER_BANNER_FAIL = "ADMIN_ADD_DRIVER_BANNER_FAIL";

export const ADMIN_DRIVER_BANNER_LIST_REQUEST =
  "ADMIN_DRIVER_BANNER_LIST_REQUEST";
export const ADMIN_DRIVER_BANNER_LIST_SUCCESS =
  "ADMIN_DRIVER_BANNER_LIST_SUCCESS";
export const ADMIN_DRIVER_BANNER_LIST_FAIL = "ADMIN_DRIVER_BANNER_LIST_FAIL";

export const ADMIN_UPDATE_DRIVER_BANNER_REQUEST =
  "ADMIN_UPDATE_DRIVER_BANNER_REQUEST";
export const ADMIN_UPDATE_DRIVER_BANNER_SUCCESS =
  "ADMIN_UPDATE_DRIVER_BANNER_SUCCESS";
export const ADMIN_UPDATE_DRIVER_BANNER_FAIL =
  "ADMIN_UPDATE_DRIVER_BANNER_FAIL";

export const ADMIN_DELETE_DRIVER_BANNER_REQUEST =
  "ADMIN_DELETE_DRIVER_BANNER_REQUEST";
export const ADMIN_DELETE_DRIVER_BANNER_SUCCESS =
  "ADMIN_DELETE_DRIVER_BANNER_SUCCESS";
export const ADMIN_DELETE_DRIVER_BANNER_FAIL =
  "ADMIN_DELETE_DRIVER_BANNER_FAIL";

export const ADMIN_LIST_ALL_BOOKINGS_REQUEST =
  "ADMIN_LIST_ALL_BOOKINGS_REQUEST";
export const ADMIN_LIST_ALL_BOOKINGS_SUCCESS =
  "ADMIN_LIST_ALL_BOOKINGS_SUCCESS";
export const ADMIN_LIST_ALL_BOOKINGS_FAIL = "ADMIN_LIST_ALL_BOOKINGS_FAIL";

export const ADMIN_ADD_USER_BANNER_REQUEST = "ADMIN_ADD_USER_BANNER_REQUEST";
export const ADMIN_ADD_USER_BANNER_SUCCESS = "ADMIN_ADD_USER_BANNER_SUCCESS";
export const ADMIN_ADD_USER_BANNER_FAIL = "ADMIN_ADD_USER_BANNER_FAIL";

export const ADMIN_USER_BANNER_LIST_REQUEST = "ADMIN_USER_BANNER_LIST_REQUEST";
export const ADMIN_USER_BANNER_LIST_SUCCESS = "ADMIN_USER_BANNER_LIST_SUCCESS";
export const ADMIN_USER_BANNER_LIST_FAIL = "ADMIN_USER_BANNER_LIST_FAIL";

export const ADMIN_UPDATE_USER_BANNER_REQUEST =
  "ADMIN_UPDATE_USER_BANNER_REQUEST";
export const ADMIN_UPDATE_USER_BANNER_SUCCESS =
  "ADMIN_UPDATE_USER_BANNER_SUCCESS";
export const ADMIN_UPDATE_USER_BANNER_FAIL = "ADMIN_UPDATE_USER_BANNER_FAIL";

export const ADMIN_DELETE_USER_BANNER_REQUEST =
  "ADMIN_DELETE_USER_BANNER_REQUEST";
export const ADMIN_DELETE_USER_BANNER_SUCCESS =
  "ADMIN_DELETE_USER_BANNER_SUCCESS";
export const ADMIN_DELETE_USER_BANNER_FAIL = "ADMIN_DELETE_USER_BANNER_FAIL";

export const ADMIN_BOOKING_ADDCOMMENT_REQUEST =
  "ADMIN_BOOKING_ADDCOMMENT_REQUEST";
export const ADMIN_BOOKING_ADDCOMMENT_SUCCESS =
  "ADMIN_BOOKING_ADDCOMMENT_SUCCESS";
export const ADMIN_BOOKING_ADDCOMMENT_FAIL = "ADMIN_BOOKING_ADDCOMMENT_FAIL";

export const ADMIN_BOOKING_UPDATE_REQUEST = "ADMIN_BOOKING_UPDATE_REQUEST";
export const ADMIN_BOOKING_UPDATE_SUCCESS = "ADMIN_BOOKING_UPDATE_SUCCESS";
export const ADMIN_BOOKING_UPDATE_FAIL = "ADMIN_BOOKING_UPDATE_FAIL";

export const ADMIN_ALL_DRIVER_LIST_REQUEST = "ADMIN_ALL_DRIVER_LIST_REQUEST";
export const ADMIN_ALL_DRIVER_LIST_SUCCESS = "ADMIN_ALL_DRIVER_LIST_SUCCESS";
export const ADMIN_ALL_DRIVER_LIST_FAIL = "ADMIN_ALL_DRIVER_LIST_FAIL";

export const ADMIN_FILTERED_DRIVER_LIST_REQUEST =
  "ADMIN_FILTERED_DRIVER_LIST_REQUEST";
export const ADMIN_FILTERED_DRIVER_LIST_SUCCESS =
  "ADMIN_FILTERED_DRIVER_LIST_SUCCESS";
export const ADMIN_FILTERED_DRIVER_LIST_FAIL =
  "ADMIN_FILTERED_DRIVER_LIST_FAIL";

export const ADMIN_DRIVER_APPROVAL_REQUEST = "ADMIN_DRIVER_APPROVAL_REQUEST";
export const ADMIN_DRIVER_APPROVAL_SUCCESS = "ADMIN_DRIVER_APPROVAL_SUCCESS";
export const ADMIN_DRIVER_APPROVAL_FAIL = "ADMIN_DRIVER_APPROVAL_FAIL";

export const ADMIN_DRIVER_DETAILS_REQUEST = "ADMIN_DRIVER_DETAILS_REQUEST";
export const ADMIN_DRIVER_DETAILS_SUCCESS = "ADMIN_DRIVER_DETAILS_SUCCESS";
export const ADMIN_DRIVER_DETAILS_FAIL = "ADMIN_DRIVER_DETAILS_FAIL";

export const ADMIN_DRIVER_FILTER_STATUS = "ADMIN_DRIVER_FILTER_STATUS";
export const ADMIN_DRIVER_FILTER_VEHICLE_TYPE =
  "ADMIN_DRIVER_FILTER_VEHICLE_TYPE";
export const ADMIN_DRIVER_FILTER_SUB_VEHICLE_TYPE =
  "ADMIN_DRIVER_FILTER_SUB_VEHICLE_TYPE";

export const ADMIN_CUSTOMER_FILTER_STATUS = "ADMIN_CUSTOMER_FILTER_STATUS";
export const ADMIN_CUSTOMER_SEARCH_STATUS = "ADMIN_CUSTOMER_SEARCH_STATUS";

export const ADMIN_UPDATE_DRIVER_REQUEST = "ADMIN_UPDATE_DRIVER_WALLET_REQUEST";
export const ADMIN_UPDATE_DRIVER_SUCCESS = "ADMIN_UPDATE_DRIVER_WALLET_SUCCESS";
export const ADMIN_UPDATE_DRIVER_FAIL = "ADMIN_UPDATE_DRIVER_WALLET_FAIL";

export const ADMIN_PROMOCODE_GENRATION_REQUEST =
  "ADMIN_PROMOCODE_GENRATION_REQUEST";
export const ADMIN_PROMOCODE_GENRATION_SUCCESS =
  "ADMIN_PROMOCODE_GENRATION_SUCCESS";
export const ADMIN_PROMOCODE_GENRATION_FAIL = "ADMIN_PROMOCODE_GENRATION_FAIL";

export const ADMIN_CREATE_PROMOCODE_REQUEST = "ADMIN_CREATE_PROMOCODE_REQUEST";
export const ADMIN_CREATE_PROMOCODE_SUCCESS = "ADMIN_CREATE_PROMOCODE_SUCCESS";
export const ADMIN_CREATE_PROMOCODE_FAIL = "ADMIN_CREATE_PROMOCODE_FAIL";

export const PROMOCODE_LIST_REQUEST = "PROMOCODE_LIST_REQUEST";
export const PROMOCODE_LIST_SUCCESS = "PROMOCODE_LIST_SUCCESS";
export const PROMOCODE_LIST_FAIL = "PROMOCODE_LIST_FAIL";

export const SINGLE_PROMOCODE_REQUEST = "SINGLE_PROMOCODE_REQUEST";
export const SINGLE_PROMOCODE_SUCCESS = "SINGLE_PROMOCODE_SUCCESS";
export const SINGLE_PROMOCODE_FAIL = "SINGLE_PROMOCODE_FAIL";

export const UPDATE_PROMOCODE_REQUEST = "UPDATE_PROMOCODE_REQUEST";
export const UPDATE_PROMOCODE_SUCCESS = "UPDATE_PROMOCODE_SUCCESS";
export const UPDATE_PROMOCODE_FAIL = "UPDATE_PROMOCODE_FAIL";

export const DELETE_PROMOCODE_REQUEST = "DELETE_PROMOCODE_REQUEST";
export const DELETE_PROMOCODE_SUCCESS = "DELETE_PROMOCODE_SUCCESS";
export const DELETE_PROMOCODE_FAIL = "DELETE_PROMOCODE_FAIL";

export const ADMIN_SEARCH_DRIVER = "ADMIN_SEARCH_DRIVER";

export const ADMIN_FILTER_BOOKING = "ADMIN_FILTER_BOOKING";

export const ADMIN_DATE_FILTER_DRIVER = "ADMIN_DATE_FILTER_DRIVER";

export const ADMIN_CUSTOMER_COMMENT_REQUEST = "ADMIN_CUSTOMER_COMMENT_REQUEST";
export const ADMIN_CUSTOMER_COMMENT_SUCCESS = "ADMIN_CUSTOMER_COMMENT_SUCCESS";
export const ADMIN_CUSTOMER_COMMENT_FAIL = "ADMIN_CUSTOMER_COMMENT_FAIL";
export const ADMIN_DRIVER_COMMENT_REQUEST = "ADMIN_DRIVER_COMMENT_REQUEST";
export const ADMIN_DRIVER_COMMENT_SUCCESS = "ADMIN_DRIVER_COMMENT_SUCCESS";
export const ADMIN_DRIVER_COMMENT_FAIL = "ADMIN_DRIVER_COMMENT_FAIL";

export const ADMIN_FILTER_REFRESH = "ADMIN_FILTER_REFRESH";
export const ADMIN_FILTER_USER_REFRESH = "ADMIN_FILTER_USER_REFRESH";

export const ADMIN_NEW_ORDER_REQUEST = "ADMIN_NEW_ORDER_REQUEST";
export const ADMIN_NEW_ORDER_SUCCESS = "ADMIN_NEW_ORDER_SUCCESS";
export const ADMIN_NEW_ORDER_FAIL = "ADMIN_NEW_ORDER_FAIL";

export const ADMIN_DRIVER_ADDRESS_SAVING = "ADMIN_DRIVER_ADDRESS_SAVING";

export const ADMIN_BOOKING_REFRESH = "ADMIN_BOOKING_REFRESH";

export const ADMIN_GET_WITHDRAWALREQUESTS_REQUEST =
  "ADMIN_GET_WITHDRAWALREQUESTS_REQUEST";
export const ADMIN_GET_WITHDRAWALREQUESTS_SUCCESS =
  "ADMIN_GET_WITHDRAWALREQUESTS_SUCCESS";
export const ADMIN_GET_WITHDRAWALREQUESTS_FAIL =
  "ADMIN_GET_WITHDRAWALREQUESTS_FAIL";
export const ADMIN_PUSH_NOTIFICATION_REQUEST =
  "ADMIN_PUSH_NOTIFICATION_REQUEST";
export const ADMIN_PUSH_NOTIFICATION_SUCCESS =
  "ADMIN_PUSH_NOTIFICATION_SUCCESS";
export const ADMIN_PUSH_NOTIFICATION_FAIL = "ADMIN_PUSH_NOTIFICATION_FAIL";

export const ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_REQUEST =
  "ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_REQUEST";
export const ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_SUCCESS =
  "ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_SUCCESS";
export const ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_FAIL =
  "ADMIN_WITHDRAWALREQUEST_DRIVERDETAILS_FAIL";

export const ADMIN_REJECT_WITHDRAWALREQUEST_REQUEST =
  "ADMIN_REJECT_WITHDRAWALREQUEST_REQUEST";
export const ADMIN_REJECT_WITHDRAWALREQUEST_SUCCESS =
  "ADMIN_REJECT_WITHDRAWALREQUEST_SUCCESS";
export const ADMIN_REJECT_WITHDRAWALREQUEST_FAIL =
  "ADMIN_REJECT_WITHDRAWALREQUEST_FAIL";
export const ADMIN_APPROVE_WITHDRAWAlREQUEST_REQUEST =
  "ADMIN_APPROVE_WITHDRAWAlREQUEST_REQUEST";
export const ADMIN_APPROVE_WITHDRAWAlREQUEST_SUCCESS =
  "ADMIN_APPROVE_WITHDRAWAlREQUEST_SUCCESS";
export const ADMIN_APPROVE_WITHDRAWAlREQUEST_FAIL =
  "ADMIN_APPROVE_WITHDRAWAlREQUEST_FAIL";

export const ADD_COMMISION_REQUEST = "ADD_COMMISION_REQUEST";
export const ADD_COMMISION_SUCCESS = "ADD_COMMISION_SUCCESS";
export const ADD_COMMISION_FAIL = "ADD_COMMISION_FAIL";

export const CURRENT_COMMISION_REQUEST = "CURRENT_COMMISION_REQUEST";
export const CURRENT_COMMISION_SUCCESS = "CURRENT_COMMISION_SUCCESS";
export const CURRENT_COMMISION_FAIL = "CURRENT_COMMISION_FAIL";

export const DRIVER_CURRENTLOCATION_BOOKING_REQUEST =
  "DRIVER_CURRENTLOCATION_BOOKING_REQUEST";
export const DRIVER_CURRENTLOCATION_BOOKING_SUCCESS =
  "DRIVER_CURRENTLOCATION_BOOKING_SUCCESS";
export const DRIVER_CURRENTLOCATION_BOOKING_FAIL =
  "DRIVER_CURRENTLOCATION_BOOKING_FAIL";

export const ADMIN_GET_DASHBOARD_REQUEST = "ADMIN_GET_DASHBOARD_REQUEST";
export const ADMIN_GET_DASHBOARD_SUCCESS = "ADMIN_GET_DASHBOARD_SUCCESS";
export const ADMIN_GET_DASHBOARD_FAIL = "ADMIN_GET_DASHBOARD_FAIL";

export const ADMIN_DASHBOARD_UPDATE_REQUEST = "ADMIN_DASHBOARD_UPDATE_REQUEST";
export const ADMIN_DASHBOARD_UPDATE_SUCCESS = "ADMIN_DASHBOARD_UPDATE_SUCCESS";
export const ADMIN_DASHBOARD_UPDATE_FAIL = "ADMIN_DASHBOARD_UPDATE_FAIL";

export const ADMIN_SUB_ADMIN_LIST_REQUEST = "ADMIN_SUB_ADMIN_LIST_REQUEST";
export const ADMIN_SUB_ADMIN_LIST_SUCCESS = "ADMIN_SUB_ADMIN_LIST_SUCCESS";
export const ADMIN_SUB_ADMIN_LIST_FAIL = "ADMIN_SUB_ADMIN_LIST_FAIL";

export const ADMIN_CREATE_SUB_ADMIN_REQUEST = "ADMIN_CREATE_SUB_ADMIN_REQUEST";
export const ADMIN_CREATE_SUB_ADMIN_SUCCESS = "ADMIN_CREATE_SUB_ADMIN_SUCCESS";
export const ADMIN_CREATE_SUB_ADMIN_FAIL = "ADMIN_CREATE_SUB_ADMIN_FAIL";

export const ADMIN_UPDATE_SUB_ADMIN_REQUEST = "ADMIN_UPDATE_SUB_ADMIN_REQUEST";
export const ADMIN_UPDATE_SUB_ADMIN_SUCCESS = "ADMIN_UPDATE_SUB_ADMIN_SUCCESS";
export const ADMIN_UPDATE_SUB_ADMIN_FAIL = "ADMIN_UPDATE_SUB_ADMIN_FAIL";

export const ADMIN_DELETE_SUB_ADMIN_REQUEST = "ADMIN_DELETE_SUB_ADMIN_REQUEST";
export const ADMIN_DELETE_SUB_ADMIN_SUCCESS = "ADMIN_DELETE_SUB_ADMIN_SUCCESS";
export const ADMIN_DELETE_SUB_ADMIN_FAIL = "ADMIN_DELETE_SUB_ADMIN_FAIL";

export const ADMIN_DASHBOARD_DATE_FILTER = "ADMIN_DASHBOARD_DATE_FILTER";

export const GET_CUSTOMER_ORDERS_REQUEST = "GET_CUSTOMER_ORDERS_REQUEST";
export const GET_CUSTOMER_ORDERS_SUCCESS = "GET_CUSTOMER_ORDERS_SUCCESS";
export const GET_CUSTOMER_ORDERS_FAIL = "GET_CUSTOMER_ORDERS_FAIL";

export const ADMIN_GET_DASHBOARD_WEEK_GRAPH_REQUEST =
  "ADMIN_GET_DASHBOARD_WEEK_GRAPH_REQUEST";
export const ADMIN_GET_DASHBOARD_WEEK_GRAPH_SUCCESS =
  "ADMIN_GET_DASHBOARD_WEEK_GRAPH_SUCCESS";
export const ADMIN_GET_DASHBOARD_WEEK_GRAPH_FAIL =
  "ADMIN_GET_DASHBOARD_WEEK_GRAPH_FAIL";

export const GET_DRIVER_ORDERS_REQUEST = "GET_DRIVER_ORDERS_REQUEST";
export const GET_DRIVER_ORDERS_SUCCESS = "GET_DRIVER_ORDERS_SUCCESS";
export const GET_DRIVER_ORDERS_FAIL = "GET_DRIVER_ORDERS_FAIL";

export const GET_DRIVER_REVIEWS_REQUEST = "GET_DRIVER_REVIEWS_REQUEST";
export const GET_DRIVER_REVIEWS_SUCCESS = "GET_DRIVER_REVIEWS_SUCCESS";
export const GET_DRIVER_REVIEWS_FAIL = "GET_DRIVER_REVIEWS_FAIL";

export const ADMIN_GET_OFFER_COINS_REQUEST = "ADMIN_GET_OFFER_COINS_REQUEST";
export const ADMIN_GET_OFFER_COINS_SUCCESS = "ADMIN_GET_OFFER_COINS_SUCCESS";
export const ADMIN_GET_OFFER_COINS_FAIL = "ADMIN_GET_OFFER_COINS_FAIL";

export const ADMIN_ACTION_COIN_ClAIM_REQUEST =
  "ADMIN_ACTION_COIN_ClAIM_REQUEST";
export const ADMIN_ACTION_COIN_ClAIM_SUCCESS =
  "ADMIN_ACTION_COIN_ClAIM_SUCCESS";
export const ADMIN_ACTION_COIN_ClAIM_FAIL = "ADMIN_ACTION_COIN_ClAIM_FAIL";

export const ADMIN_ALL_LOADRUNNR_DRIVER_LIST_FAIL =
  "ADMIN_ALL_LOADRUNNR_DRIVER_LIST_FAIL";
export const ADMIN_ALL_LOADRUNNR_DRIVER_LIST_REQUEST =
  "ADMIN_ALL_LOADRUNNR_DRIVER_LIST_REQUEST";
export const ADMIN_ALL_LOADRUNNR_DRIVER_LIST_SUCCESS =
  "ADMIN_ALL_LOADRUNNR_DRIVER_LIST_SUCCESS";

export const ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_REQUEST =
  "ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_REQUEST";
export const ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_SUCCESS =
  "ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_SUCCESS";
export const ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_FAIL =
  "ADMIN_MANAGE_CUSTOMER_LIST_FOR_NOTIFICATION_FAIL";
