import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  getSinglePrcingTypeAction,
  updatePrcingTypeAction,
} from "../../../Actions/AdminActions";
import Loader from "../../Loader";

const PricingUpdateForm = (props) => {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [subType, setSubType] = useState("");
  const [baseKM, setBaseKM] = useState(0);
  const [baseFare, setBaseFare] = useState(0);
  const [minKM1, setMinKM1] = useState(0);
  const [maxKM1, setMaxKM1] = useState(0);
  const [fareBetweenRange1, setFareBetweenRange1] = useState(0);
  const [minKM2, setMinKM2] = useState(0);
  const [maxKM2, setMaxKM2] = useState(0);
  const [fareBetweenRange2, setFareBetweenRange2] = useState(0);
  const [fareAfterRange2, setFareAfterRange2] = useState(0);
  const [extraCharge, setExtraCharge] = useState(0);
  const [extraChargeReason, setExtraChargeReason] = useState("");
  const [nightSurgeCharge, setNightSurgeCharge] = useState(0);
  const [nightSurgeTimeFrom, setNightSurgeTimeFrom] = useState("");
  const [nightSurgeTimeTo, setNightSurgeTimeTo] = useState("");
  const [perminute, setPermintue] = useState(0);
  const [greaterThanKm, setGreaterThanKm] = useState("");
  const [timeChecker, setTimeChecker] = useState("");
  const [timeChecker1, setTimeChecker1] = useState("");
  const [ok, setOk] = useState(false);
  const [ok1, setOk1] = useState(false);
  const [formError, setFormError] = useState("");
  const [rentalAmount, setRentalAmount] = useState("");
  const [rental, setRenta] = useState("");
  const singlePricingType = useSelector((state) => state.singlePricingType);
  const { pricingtype, loading } = singlePricingType;
  useEffect(() => {
    if (props.id) {
      dispatch(getSinglePrcingTypeAction(props.id));
    }
    if (pricingtype) {
      setVehicleType(pricingtype.vehicleType);
    }
  }, [dispatch, props.id, props.restart]);

  useEffect(() => {
    if (pricingtype) {
      setVehicleType(pricingtype.vehicleType);
      setSubType(pricingtype.subType);
      setBaseFare(pricingtype.baseFare);
      setBaseKM(pricingtype.baseKM);
      setMaxKM1(pricingtype.maxKM1);
      setMinKM1(pricingtype.minKM1);
      setFareBetweenRange1(pricingtype.fareBetweenRange1);
      setMaxKM2(pricingtype.maxKM2);
      setMinKM2(pricingtype.minKM2);
      setFareBetweenRange2(pricingtype.fareBetweenRange2);
      setFareAfterRange2(pricingtype.fareAfterRange2);
      setNightSurgeCharge(pricingtype.nightSurgeCharge);
      setNightSurgeTimeFrom(pricingtype.nightSurgeTimeFrom);
      setRentalAmount(pricingtype.rentalAmount);
      setNightSurgeTimeTo(pricingtype.nightSurgeTimeTo);
      setPermintue(pricingtype.farePerMin);
      setGreaterThanKm(pricingtype.greaterThanKm);
      setExtraCharge(pricingtype.extraCharge);
      setExtraChargeReason(pricingtype.extraChargeReason);
      setId(pricingtype._id);
    }
  }, [pricingtype]);

  // calculate range

  const handleRange1 = (value) => {
    if (value) {
      let minRange = parseInt(value);
      setMinKM1(minRange);
    }
  };

  const handleRange2 = (value) => {
    if (value) {
      let minRange = parseInt(value);
      setMinKM2(minRange);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nightSurgeTimeFrom) {
      let timeFromLen = nightSurgeTimeFrom.length;

      if (timeFromLen === 10) {
        setTimeChecker("");
        setOk(true);
      } else {
        setTimeChecker("Check The time is correct format!..");
      }
    } else {
      // console.log('wrong');
    }
    if (nightSurgeTimeTo) {
      let timeFromLen = nightSurgeTimeTo.length;

      if (timeFromLen === 10) {
        setTimeChecker1("");
        setOk1(true);
      } else {
        setTimeChecker1("Check The time is correct format!..");
      }
    }

    if (ok && ok1) {
      setOk(false);
      setOk1(false);
      setFormError("");
      dispatch(
        updatePrcingTypeAction(
          vehicleType,
          subType,
          baseKM,
          baseFare,
          minKM1,
          maxKM1,
          fareBetweenRange1,
          minKM2,
          maxKM2,
          fareBetweenRange2,
          fareAfterRange2,
          extraCharge,
          extraChargeReason,
          nightSurgeCharge,
          nightSurgeTimeFrom,
          nightSurgeTimeTo,
          greaterThanKm,
          id,
          rentalAmount,
          perminute
        )
      );
      props.setShowUpdateForm(false);
    } else {
      setFormError("Something Went Wrong!... Please Check.");
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="mt-5"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Form onSubmit={handleSubmit}>
            {formError && <p className="text-danger">{formError}</p>}
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Vehicle Type : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    value={vehicleType}
                    onChange={(e) => setVehicleType(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Sub Type : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    value={subType}
                    onChange={(e) => setSubType(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Base KM : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    value={baseKM}
                    onChange={(e) => {
                      setBaseKM(e.target.value);
                      handleRange1(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Base Fare (in Rs) : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    value={baseFare}
                    type="number"
                    onChange={(e) => setBaseFare(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Range 1 (in KM) : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    placeholder="From"
                    value={minKM1}
                    type="number"
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="To"
                    value={maxKM1}
                    type="number"
                    onChange={(e) => {
                      setMaxKM1(e.target.value);
                      handleRange2(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Fare Between Range 1 (in Rs) : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    value={fareBetweenRange1}
                    type="number"
                    onChange={(e) => setFareBetweenRange1(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Range 2 (in KM) : </Form.Label>
                </Col>
                <Col>
                  <Form.Control placeholder="From" value={minKM2} />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="To"
                    value={maxKM2}
                    onChange={(e) => setMaxKM2(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Fare Between Range 2 (in Rs) : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    value={fareBetweenRange2}
                    onChange={(e) => setFareBetweenRange2(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Fare After Range 2 (per KM) : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    value={fareAfterRange2}
                    onChange={(e) => setFareAfterRange2(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Extra Charge : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    value={extraCharge}
                    onChange={(e) => setExtraCharge(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Extra Charge Reason : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    value={extraChargeReason}
                    onChange={(e) => setExtraChargeReason(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Night Surge Charge (in Rs) : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    value={nightSurgeCharge}
                    onChange={(e) => setNightSurgeCharge(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Night Surge Time From : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    placeholder="From : 12 : 00 AM"
                    value={nightSurgeTimeFrom}
                    onChange={(e) => setNightSurgeTimeFrom(e.target.value)}
                  />
                  {timeChecker && <p className="text-danger">{timeChecker}</p>}
                </Col>
                <Col>
                  <Form.Control
                    placeholder="To : 06 : 00 PM"
                    value={nightSurgeTimeTo}
                    onChange={(e) => setNightSurgeTimeTo(e.target.value)}
                  />
                  {timeChecker1 && (
                    <p className="text-danger">{timeChecker1}</p>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Per Minute: </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    placeholder="perminute"
                    value={perminute}
                    onChange={(e) => {
                      setPermintue(e.target.value);
                    }}
                  />
                  {/* {timeChecker && <p className="text-danger">{timeChecker}</p>} */}
                </Col>
              </Row>
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                className="m-3 w-25"
                style={{
                  backgroundColor: "rgb(231, 92, 5)",
                  fontWeight: "bold",
                }}
              >
                Update
              </Button>
              <Button onClick={props.onHide} className="m-3 w-25">
                Close
              </Button>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PricingUpdateForm;
