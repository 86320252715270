import React, { useState } from "react";
import { MDBDataTable } from "mdbreact";
import RentalPricingUpdateForm from "./Admin/Modals/RentalPricingUpdateForm";
import Loader from "./Loader";
const RentalDataTable = (props) => {
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [restart, setRestart] = useState(false);
  const [id, setId] = useState();
  var rowData = [];
  const handleShowForm = (typeId) => {
    setId(typeId);
    setRestart(true);
    setShowUpdateForm(true);
  };
  props.pricingListInfo &&
    props.pricingListInfo.map((list) => {
      if (list.rental) {
        rowData.unshift({
          vehicleType: list.vehicleType,
          subType: list.subType,
          baseKM: list.baseKM,
          baseFare: "₹" + list.baseFare,
          range1: list.range1,
          fareBetweenRange1: "₹" + list.fareBetweenRange1,
          range2: list.range2,
          fareBetweenRange2: "₹" + list.fareBetweenRange2,
          greaterThanKm: list.greaterThanKm + " " + "-",
          fareAfterRange2: "₹" + list.fareAfterRange2,
          extraCharge: "₹" + list.extraCharge,
          extraChargeReason: list.extraChargeReason,
          nightSurgeCharge: "₹" + list.nightSurgeCharge,
          nightSurgeTime: list.nightSurgeTime,
          rentalAmount: list.rentalAmount === 0 ? "No" : list.rentalAmount,
          description: list.description,
          update: (
            <button
              className="btn btn-danger"
              onClick={() => {
                handleShowForm(list._id);
              }}
            >
              update
            </button>
          ),
        });
      }
    });
  const data = {
    columns: [
      {
        label: "Vehicle Type",
        field: "vehicleType",
        sort: "asc",
        width: 200,
      },
      {
        label: "Sub Type",
        field: "subType",
        sort: "asc",
        width: 270,
      },

      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 300,
      },
      {
        label: "rental amount",
        field: "rentalAmount",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "update",
        sort: "asc",
        width: 150,
      },
    ],
    rows: rowData,
  };

  return data.rows[0] ? (
    <div>
      <MDBDataTable striped bordered small responsive btn data={data} />

      <RentalPricingUpdateForm
        show={showUpdateForm}
        id={id}
        onHide={() => setShowUpdateForm(false)}
        setShowUpdateForm={setShowUpdateForm}
        restart={restart}
      />
    </div>
  ) : (
    <Loader loading={true} />
  );
};

export default RentalDataTable;
