// booking Filters
export const BOOKING_STATUS_FILTER = 'BOOKING_STATUS_FILTER';
export const BOOKING_PLACES_FILTER ='BOOKING_PLACES_FILTER'
export const BOOKING_VEHICLETYPE_FILTER = 'BOOKING_VEHICLETYPE_FILTER';
export const BOOKING_VEHICLE_SUBTYPE_FILTER = 'BOOKING_VEHICLE_SUBTYPE_FILTER';
export const BOOKING_DATE_FILTER = 'BOOKING_DATE_FILTER';
export const BOOKING_SEARCH = 'BOOKING_SEARCH';


export const WITHDRAWAL_STATUS_FILTER = 'WITHDRAWAL_STATUS_FILTER'
export const WITHDRAWAL_DATE_FILTER = 'WITHDRAWAL_DATE_FILTER'
export const WITHDRAWAL_SEARCH_FILTER = 'WITHDRAWAL_SEARCH_FILTER'
export const DRIVER_PLACES_FILTER = 'DRIVER_PLACES_FILTER'