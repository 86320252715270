import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  statusTypes,
  vehiclesTypes,
  vehiclesSubTypes,
  statusTypesForMap,
  driverPlaceTypes,
} from "./values";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { useSelector, useDispatch } from "react-redux";
import {
  ListAllDriverAction,
  FilteredDriversAction,
} from "../../../Actions/AdminActions";
import moment from "moment";
import Moment from "react-moment";
import "moment-timezone";
import Loader from "../../../Components/Loader";
import {
  FilterDriverStatusAction,
  FilterVehicleTypeAction,
  FilterVehicleSubTypeAction,
  searchAction,
  FilterWithDate,
} from "../../../Actions/AdminActions";
import ClearIcon from "@mui/icons-material/Clear";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Drawer from "@mui/material/Drawer";

import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


import { filterDriverPlaceAction } from "../../../Actions/FilterAction";

const useStyles = makeStyles(({ theme }) => ({
  formControl: {
    margin: 1,
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PlaceDrowpDown = ({ setFilterChange, filterChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [DriverPlace, setDriverPlace] = React.useState([]);
  const filterPlaces = useSelector((state) => state.driverPlaceFilter);
  const { placeFilter } = filterPlaces;
  let { refresh } = useSelector((state) => state.refresh);
  useEffect(() => {
    setDriverPlace([]);
  }, [refresh]);
  useEffect(() => {
    if (!placeFilter) setDriverPlace([]);
  }, [placeFilter]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === "All") {
      setDriverPlace(
        DriverPlace.length === driverPlaceTypes.length ? [] : driverPlaceTypes
      );
      DriverPlace.length === driverPlaceTypes.length
        ? dispatch(filterDriverPlaceAction([]))
        : dispatch(filterDriverPlaceAction(driverPlaceTypes));

      return;
    }
    setDriverPlace(typeof value === "string" ? value.split(",") : value);
    dispatch(filterDriverPlaceAction(value));
    setFilterChange(filterChange + 1);
  };

  const isAllSelected =
    driverPlaceTypes.length > 0 &&
    DriverPlace.length === driverPlaceTypes.length;

  return (
    <FormControl sx={{ m: 1, width: 100 }} size="small">
      <InputLabel id="demo-multiple-checkbox-label">Place</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={DriverPlace}
        onChange={handleChange}
        input={<OutlinedInput label="Place" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {/* <MenuItem
          value='All'
          classes={{
            root: isAllSelected ? classes.selectedAll : '',
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                DriverPlace.length > 0 &&
                DriverPlace.length < driverPlaceTypes.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary='Select All'
          />
        </MenuItem> */}
        {driverPlaceTypes.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={DriverPlace.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const StatusDrowpDown = ({ setFilterChange, filterChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [DriverStatus, setDriverStatus] = React.useState([]);
  const filterStatus = useSelector((state) => state.driverStausFilter);
  const { statusFilter } = filterStatus;
  let { refresh } = useSelector((state) => state.refresh);
  useEffect(() => {
    setDriverStatus([]);
  }, [refresh]);
  useEffect(() => {
    if (!statusFilter) setDriverStatus([]);
  }, [statusFilter]);
  const handleChange = async (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === "All") {
      setDriverStatus(
        DriverStatus.length === statusTypes.length ? [] : statusTypes
      );
      DriverStatus.length === statusTypes.length
        ? dispatch(FilterDriverStatusAction([]))
        : dispatch(FilterDriverStatusAction(statusTypes));

      return;
    }
    setDriverStatus(typeof value === "string" ? value.split(",") : value);
    dispatch(FilterDriverStatusAction(value));
    setFilterChange(filterChange + 1);
  };

  const isAllSelected =
    statusTypes.length > 0 && DriverStatus.length === statusTypes.length;

  return (
    <FormControl sx={{ m: 1, width: 150 }} size="small">
      <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={DriverStatus}
        onChange={handleChange}
        input={<OutlinedInput label="Status" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {/* <MenuItem
          value="All"
          classes={{
            root: isAllSelected ? classes.selectedAll : "",
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                DriverStatus.length > 0 &&
                DriverStatus.length < statusTypes.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem> */}
        {statusTypes.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={DriverStatus.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const VehicleTypeDrowpDown = ({ setFilterChange, filterChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [Types, setTypes] = React.useState([]);
  const { VehcleTypeFilter } = useSelector(
    (state) => state.driverVehcleTypeFilter
  );
  let { refresh } = useSelector((state) => state.refresh);
  useEffect(() => {
    setTypes([]);
  }, [refresh]);
  useEffect(() => {
    if (!VehcleTypeFilter) setTypes([]);
  }, [VehcleTypeFilter]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === "All") {
      setTypes(Types.length === vehiclesTypes.length ? [] : vehiclesTypes);
      Types.length === vehiclesTypes.length
        ? dispatch(FilterVehicleTypeAction([]))
        : dispatch(FilterVehicleTypeAction(vehiclesTypes));

      return;
    }
    setTypes(typeof value === "string" ? value.split(",") : value);
    dispatch(FilterVehicleTypeAction(value));
    setFilterChange(filterChange + 1);
  };

  const isAllSelected =
    vehiclesTypes.length > 0 && Types.length === vehiclesTypes.length;

  return (
    <FormControl sx={{ m: 1, width: 150 }} size="small">
      <InputLabel id="demo-multiple-checkbox-label">Vehicle Type</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={Types}
        onChange={handleChange}
        input={<OutlinedInput label="Vehicle Type" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {/* <MenuItem
          value="All"
          classes={{
            root: isAllSelected ? classes.selectedAll : "",
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                Types.length > 0 && Types.length < vehiclesTypes.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem> */}
        {vehiclesTypes.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={Types.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const VehicleSubTypeDrowpDown = ({ setFilterChange, filterChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [subTypes, setSubTypes] = React.useState([]);
  const { subTypeFilter } = useSelector(
    (state) => state.driverVehicleSubTypeFilter
  );
  let { refresh } = useSelector((state) => state.refresh);
  useEffect(() => {
    setSubTypes([]);
  }, [refresh]);
  useEffect(() => {
    if (!subTypeFilter) setSubTypes([]);
  }, [subTypeFilter]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === "All") {
      setSubTypes(
        subTypes.length === vehiclesSubTypes.length ? [] : vehiclesSubTypes
      );
      subTypes.length === vehiclesSubTypes.length
        ? dispatch(FilterVehicleSubTypeAction([]))
        : dispatch(FilterVehicleSubTypeAction(vehiclesSubTypes));

      return;
    }
    setSubTypes(typeof value === "string" ? value.split(",") : value);
    dispatch(FilterVehicleSubTypeAction(value));
    setFilterChange(filterChange + 1);
  };

  const isAllSelected =
    statusTypes.length > 0 && subTypes.length === statusTypes.length;
  return (
    <FormControl sx={{ m: 1, width: 180 }} size="small">
      <InputLabel id="demo-multiple-checkbox-label">Vehicle subtype</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={subTypes}
        onChange={handleChange}
        input={<OutlinedInput label="Vehicle subtype" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {/* <MenuItem
          value="All"
          classes={{
            root: isAllSelected ? classes.selectedAll : "",
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                subTypes.length > 0 && subTypes.length < statusTypes.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem> */}
        {vehiclesSubTypes.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={subTypes.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const FromToDateFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const [from, setFrom] = React.useState("06/15/2022");
  const [to, setTo] = React.useState(new Date());
  let { statusDateFilter } = useSelector((state) => state.filterDate);
  let { refresh } = useSelector((state) => state.refresh);

  useEffect(() => {
    if (!statusDateFilter) {
      var t = new Date();
      t.setDate(t.getDate() + 1)
       setFrom("06/15/2022");
       setTo(t);
    }
  }, [refresh]);

  let submit = () => {
    
    var d = new Date(from);
    // d.setDate(d.getDate() - 1)
    let from2 = moment(d).format('L');
    var t = new Date(to);
     t.setDate(t.getDate() + 1)
     let to2 = moment(t).format('L');
    

    if (from2 && to2) {
      
      dispatch(FilterWithDate({ from: from2, to: to2 }));
    } else {
      alert("please fill both fields");
    }
  };

  return (
    <>
      <span>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="From"
            value={from}
            onChange={(newValue) => {
              setFrom(moment(newValue).format("L"));
            }}
            renderInput={(params) => (
              <TextField
                style={{ marginLeft: "10px", width: "150px" }}
                size="small"
                {...params}
              />
            )}
          />
          <DesktopDatePicker
            label="To"
            value={to}
            onChange={(newValue) => {
              let date = moment(newValue).format("L");
              setTo(date);
            }}
            renderInput={(params) => (
              <TextField
                style={{ marginLeft: "5px", width: "150px" }}
                size="small"
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </span>

      <Button
        sx={{ marginLeft: 1, marginBottom: 2 }}
        onClick={submit}
        variant="outlined"
      >
        submit Dates
      </Button>
    </>
  );
};

const SearchFilter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  const [content, setContent] = useState();
  // useEffect(() => {
  //   dispatch(searchAction(content));
  // }, [content]);
  const submit = (content) => {
    if (content) {
      dispatch(searchAction(content));
    } else {
      alert("Please Enter Somthing...");
    }
  };
  let { refresh } = useSelector((state) => state.refresh);
  useEffect(() => {
    setContent("");
  }, [refresh]);
  return (
    <>
      <Paper
        variant="outlined"
        square
        sx={{
          display: "inline-block",
          width: 250,
          height: 40,
          marginTop: 1,
          borderRadius: 1,
        }} // width: 400
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          inputProps={{ "aria-label": "search google maps" }}
          value={content}
          onChange={(e) => {
            setContent(e.target.value.trimStart());
          }}
        />
        <IconButton
          onClick={() => {
            submit(content);
            //  setContent("")
          }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
    </>
  );
};

const StatusDrowpDownForMap = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [DriverStatus, setDriverStatus] = React.useState([]);
  const { statusFilter } = useSelector((state) => state.driverStausFilter);
  let { refresh } = useSelector((state) => state.refresh);
  useEffect(() => {
    setDriverStatus([]);
  }, [refresh]);
  //  useEffect(()=>{
  //     if(!statusFilter) setDriverStatus([])
  //  },[statusFilter])
  const handleChange = async (event) => {
    const {
      target: { value },
    } = event;

    if (value[value.length - 1] === "All") {
      setDriverStatus(
        DriverStatus.length === statusTypesForMap.length
          ? []
          : statusTypesForMap
      );
      DriverStatus.length === statusTypesForMap.length
        ? dispatch(FilterDriverStatusAction([]))
        : dispatch(FilterDriverStatusAction(statusTypesForMap));

      return;
    }
    setDriverStatus(typeof value === "string" ? value.split(",") : value);
    console.log(value, "//////////////???????");
    dispatch(FilterDriverStatusAction(value));
  };

  const isAllSelected =
    statusTypesForMap.length > 0 &&
    DriverStatus.length === statusTypesForMap.length;

  return (
    <FormControl sx={{ m: 1, width: 150 }} size="small">
      <InputLabel id="demo-multiple-checkbox-label">Status</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={DriverStatus}
        onChange={handleChange}
        input={<OutlinedInput label="Status" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        <MenuItem
          value="All"
          classes={{
            root: isAllSelected ? classes.selectedAll : "",
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                DriverStatus.length > 0 &&
                DriverStatus.length < statusTypesForMap.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem>
        {statusTypesForMap.map((name) => (
          <MenuItem key={name} value={name}>
            <Checkbox checked={DriverStatus.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export {
  StatusDrowpDown,
  VehicleTypeDrowpDown,
  VehicleSubTypeDrowpDown,
  FromToDateFilter,
  PlaceDrowpDown,
  SearchFilter,
  StatusDrowpDownForMap,
};
