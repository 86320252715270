import TableCell from '@mui/material/TableCell';
import { Box } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
const headCells = [
  {
    id:"No",
    label:"No"
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'bookingId',
    label: 'Booking ID',
  },
  {
    id: 'place',
    label: 'Place',
  },
  {
    id: 'customer',
    label: 'Customer',
  },
  {
    id: 'vehicleType',
    label: 'Vehicle Type',
  },
  {
    id: 'subType',
    label: 'Sub Type',
  },
  {
    id: 'pickup&drop',
    label: 'Pickup & Drop Point',
  },
  {
    id: 'paymentDetails',
    label: 'Payment Details',
  },
  {
    id: 'driver',
    label: 'Driver',
  },
  {
    id: 'bookingAt',
    label: 'Booking At',
  },
  {
    id: 'unAssign&cancel',
    label: 'UnAssign / Cancel Order',
  },
  {
    id: 'change_status',
    label: 'Status Change',
  },
  {
    id: 'csnote',
    label: 'Cs Notes',
  },
];

function BookingTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export { BookingTableHead };
