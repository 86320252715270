import {
  BOOKING_DATE_FILTER,
  BOOKING_SEARCH,
  BOOKING_STATUS_FILTER,
  BOOKING_PLACES_FILTER,
  BOOKING_VEHICLETYPE_FILTER,
  BOOKING_VEHICLE_SUBTYPE_FILTER,
  WITHDRAWAL_DATE_FILTER,
  WITHDRAWAL_SEARCH_FILTER,
  WITHDRAWAL_STATUS_FILTER,
  DRIVER_PLACES_FILTER
} from '../Constants/FilterConstants';
import moment from "moment";
import Moment from "react-moment";
import "moment-timezone";
export const filterBookingPlaceReducer = (
  state = { placeFilter: false, filters: [], flt1: false },
  action
) => {
  if (action.type === BOOKING_PLACES_FILTER) {
    
    if (action.payload.length === 0) {
      return {
        placeFilter: false,
        flt1: true,
        filters: [...action.payload],
      };
    } else {
      return {
        placeFilter: true,
        flt1: true,
        filters: [...action.payload],
      };
    }
  } else {
    return state;
  }
};

export const filterDriverPlaceReducer = (
  state = { placeFilter: false, filters: [], flt1: false },
  action
) => {
  if (action.type === DRIVER_PLACES_FILTER) {
    
    if (action.payload.length === 0) {
      return {
        placeFilter: false,
        flt1: true,
        filters: [...action.payload],
      };
    } else {
      return {
        placeFilter: true,
        flt1: true,
        filters: [...action.payload],
      };
    }
  } else {
    return state;
  }
};


export const filterBookingStatusReducer = (
  state = { statusFilter: false, filters: [], flt1: false },
  action
) => {
  if (action.type === BOOKING_STATUS_FILTER) {
    if (action.payload.length === 0) {
      return {
        statusFilter: false,
        flt1: true,
        filters: [...action.payload],
      };
    } else {
      return {
        statusFilter: true,
        flt1: true,
        filters: [...action.payload],
      };
    }
  } else {
    return state;
  }
};

export const filterBookingVehicleTypeReducer = (
  state = { vehicleTypeStatus: false, vehicleTypefilters: [], flt1: false },
  action
) => {
  if (action.type === BOOKING_VEHICLETYPE_FILTER) {
    if (action.payload.length === 0) {
      return {
        vehicleTypeStatus: false,
        flt1: true,
        vehicleTypefilters: [...action.payload],
      };
    } else {
      return {
        vehicleTypeStatus: true,
        flt1: true,
        vehicleTypefilters: [...action.payload],
      };
    }
  } else {
    return state;
  }
};

export const filterBookingVehicleSubTypeReducer = (
  state = { subTypeStatus: false, subTypefilters: [], flt1: false },
  action
) => {
  if (action.type === BOOKING_VEHICLE_SUBTYPE_FILTER) {
    if (action.payload.length === 0) {
      return {
        subTypeStatus: false,
        flt1: true,
        subTypefilters: [...action.payload],
      };
    } else {
      return {
        subTypeStatus: true,
        flt1: true,
        subTypefilters: [...action.payload],
      };
    }
  } else {
    return state;
  }
};

export const searchBookingsReducer = (state = { content: null }, action) => {
  if (action.type === BOOKING_SEARCH) {
    return {
      content: action.payload,
      searchChange: Math.random(),
    };
  } else {
    return state;
  }
};

export const bookingDateFilterReducer = (state = {  }, action) => {
  



  if (action.type === BOOKING_DATE_FILTER) {
    
    if (action.payload.from && action.payload.to) {
      return {
        from: action.payload.from,
        to: action.payload.to,
        statusDateFilter: true,
      };
    } else {

      var t = new Date();
      t.setDate(t.getDate() + 1)
      let to2 = moment(t).format('L');
      return {
        
        from: "06/15/2022",
        to:to2,
        statusDateFilter: false,
      };
    }
  } else {
    return state;
  }
};


export const filterWithdrawalStatusReducer = (
  state = { statusFilter: false, filters: [], flt1: false },
  action
) => {
  if (action.type === WITHDRAWAL_STATUS_FILTER) {
    if (action.payload.length === 0) {
      return {
        statusFilter: false,
        flt1: true,
        filters: [...action.payload],
      };
    } else {
      return {
        statusFilter: true,
        flt1: true,
        filters: [...action.payload],
      };
    }
  } else {
    return state;
  }
};


export const withdrawDateFilterReducer = (state = { content: null }, action) => {
  if (action.type === WITHDRAWAL_DATE_FILTER) {
    if (action.payload.from && action.payload.to) {
      return {
        from: action.payload.from,
        to: action.payload.to,
        statusDateFilter: true,
      };
    } else {
      return {
        statusDateFilter: false,
      };
    }
  } else {
    return state;
  }
};


export const searchWithdrawRequestsReducer = (state = { content: null }, action) => {
  if (action.type === WITHDRAWAL_SEARCH_FILTER) {
    return {
      content: action.payload,
      searchChange: Math.random(),
    };
  } else {
    return state;
  }
};

