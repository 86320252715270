import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { LoadingButton } from "@mui/lab";

export default function MediaCard({ color, spin, title, count, cardWidth }) {
  return (
    <Card
      sx={{
        minWidth: cardWidth,
        display: "inline-block",
        m: 1,
        background: color,
        color: "#fff",
      }}
      raised={true}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
            // fontSize:"18x"
          }}
        >
          <span>{title}</span>
          {!spin ? (
            title === "Total Orders Value" ? (
              `₹${count}`
            ) : (
              count
            )
          ) : (
            <LoadingButton
              loading={true}
              variant="outlined"
              color="primary"
              disabled
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
}
