import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  getSinglePrcingTypeAction,
  updatePrcingTypeAction,
  updateRentalPrcingTypeAction,
} from "../../../Actions/AdminActions";
import Loader from "../../Loader";

const PricingUpdateForm = (props) => {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [subType, setSubType] = useState("");
  const [baseKM, setBaseKM] = useState(0);
  const [baseFare, setBaseFare] = useState(0);
  const [minKM1, setMinKM1] = useState(0);
  const [maxKM1, setMaxKM1] = useState(0);
  const [fareBetweenRange1, setFareBetweenRange1] = useState(0);
  const [minKM2, setMinKM2] = useState(0);
  const [maxKM2, setMaxKM2] = useState(0);
  const [fareBetweenRange2, setFareBetweenRange2] = useState(0);
  const [fareAfterRange2, setFareAfterRange2] = useState(0);
  const [extraCharge, setExtraCharge] = useState(0);
  const [extraChargeReason, setExtraChargeReason] = useState("");
  const [nightSurgeCharge, setNightSurgeCharge] = useState(0);
  const [nightSurgeTimeFrom, setNightSurgeTimeFrom] = useState("");
  const [nightSurgeTimeTo, setNightSurgeTimeTo] = useState("");
  const [greaterThanKm, setGreaterThanKm] = useState("");
  const [timeChecker, setTimeChecker] = useState("");
  const [timeChecker1, setTimeChecker1] = useState("");
  const [ok, setOk] = useState(false);
  const [ok1, setOk1] = useState(false);
  const [formError, setFormError] = useState("");
  const [rentalAmount, setRentalAmount] = useState("");
  const [rental, setRenta] = useState("");
  const [description, setDescription] = useState("");
  const singlePricingType = useSelector((state) => state.singlePricingType);
  const { pricingtype, loading } = singlePricingType;
  useEffect(() => {
    if (props.id) {
      dispatch(getSinglePrcingTypeAction(props.id));
    }
    if (pricingtype) {
      setVehicleType(pricingtype.vehicleType);
    }
  }, [dispatch, props.id, props.restart]);

  useEffect(() => {
    if (pricingtype) {
      setVehicleType(pricingtype.vehicleType);
      setSubType(pricingtype.subType);
      setRentalAmount(pricingtype.rentalAmount);
      setId(pricingtype._id);
      setDescription(pricingtype.description);
    }
  }, [pricingtype]);

  // calculate range

  const handleRange1 = (value) => {
    if (value) {
      let minRange = parseInt(value) + 1;
      setMinKM1(minRange);
    }
  };

  const handleRange2 = (value) => {
    if (value) {
      let minRange = parseInt(value) + 1;
      setMinKM2(minRange);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    

    if (vehicleType && subType && description && rentalAmount&& id) {
      setOk(false);
      setOk1(false);
      setFormError("");
      dispatch(
        updateRentalPrcingTypeAction(
          vehicleType,
          subType,
          description,
          id,
          rentalAmount
        )
      );
      props.setShowUpdateForm(false);
    } else {
      setFormError("Something Went Wrong!... Please Check.");
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="mt-5"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Form onSubmit={handleSubmit}>
            {formError && <p className="text-danger">{formError}</p>}
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Vehicle Type : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    value={vehicleType}
                    // onChange={(e) => setVehicleType(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Sub Type : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    value={subType}
                    // onChange={(e) => setSubType(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Description : </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            
            <Form.Group className="mt-3">
              <Row>
                <Col>
                  <Form.Label>Rental Amount : </Form.Label>
                </Col>
                <Col>
                
                  <Form.Control
                    value={rentalAmount}
                    type="number"
                    onChange={(e) => setRentalAmount(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                className="m-3 w-25"
                style={{
                  backgroundColor: "rgb(231, 92, 5)",
                  fontWeight: "bold",
                }}
              >
                Update
              </Button>
              <Button onClick={props.onHide} className="m-3 w-25">
                Close
              </Button>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PricingUpdateForm;
