import React, { useEffect ,useState} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/SubAdmin/sidebar/SideBar";
import InfoIcon from "@mui/icons-material/Info";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  StatusDrowpDown,
  PlaceDrowpDown,
  VehicleTypeDrowpDown,
  VehicleSubTypeDrowpDown,
  FromToDateFilter,
  SearchFilter,
} from "../../Components/Admin/driverHelper/Filter";

import { EnhancedTableHead } from "../../Components/SubAdmin/TableHeaders/DriverTable/DriverTableHead";
import Button from "@mui/material/Button";

import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import io from "socket.io-client";
import { LOADRUNNR_SOCKET_API } from "../../socketConst/BaseApi";
import { ButtonGroup, Paper } from "@mui/material";
import {
  driverListAction,
  
} from "../../Actions/SubAdminAction";
import {
 
  FilterDriverStatusAction,
  FilterVehicleTypeAction,
  FilterVehicleSubTypeAction,
  searchAction,
  FilterWithDate,
  refreshAction,
  ListAllDriverAction,
} from "../../Actions/AdminActions";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddWalletFrom from "../../Components/Admin/Modals/AddWalletFrom";
import WalletLogs from "../../Components/WalletLogs";
//import AddCommentForm from "../../Components/Admin/AddCommentForm";
import SubAddCommentForm from "../../Components/SubAdmin/SubAddCommentForm";
import exportFromJSON from "export-from-json";

import moment from "moment";
import Moment from "react-moment";
import "moment-timezone";
import BlockingForm from "../../Components/Admin/Modals/DriverBlockForm";
import UnBlockingForm from "../../Components/Admin/Modals/DriverUnblock";
import Swal from "sweetalert2";
import { filterDriverPlaceAction } from "../../Actions/FilterAction";




EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ManageDriver = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // end menu
  // wallt modal
  const [isOpen, setisOpen] = React.useState(false);
  const [isOpenComment, setisCommentOpen] = React.useState(false);



  const handleClose = () => {
    setisOpen(false);
  };
  const handleCommentClose = () => {
    setisCommentOpen(false);
  };

  // end wallet

  // Pagination
  const [filterChange, setFilterChange] = useState(1);

  const [dense, setDense] = React.useState(false);
 
  const [age, setAge] = React.useState("");
  const [online, setOnline] = React.useState(true);
  const [costomId, setCostomId] = React.useState("");
  const [driverId, setDriverId] = React.useState("");
  const [walletLogs, setWalletLogs] = React.useState(false);
  const updateDriverWallet = useSelector((state) => state.updatedDriver);
  const { updateSuccess } = updateDriverWallet;
  const [csnotes, setCsnotes] = React.useState([]);
 
  const [unblockModalShow, setUnblockModalShow] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);

  const filterPlaces = useSelector((state) => state.driverPlaceFilter);

  // useEffect(() => {
  //   const socket = io(LOADRUNNR_SOCKET_API, { transports: ["websocket"] });
  //   socket.on("online", (data) => {
  //     dispatch(driverListAction());
  //     setOnline(!online);
  //   });
  // }, [online]);
  

  
  const [subAdminName, setSubAdminName] = React.useState("");
  // All drivers
  const subAdminLogin = useSelector((state) => state.subAdminLogin);
  const { subAdminInfo } = subAdminLogin;
 

  let {  from, to,statusDateFilter} = useSelector((state) => state.filterDate);

  const { AllDriversList, loading } = useSelector(
    (state) => state.alldrivers
  );
  const filterLoding = useSelector(
    (state) => state.subFilteredDriverList.loading
  );

  const filterStatus = useSelector((state) => state.driverStausFilter);
  const filterSubType = useSelector(
    (state) => state.driverVehicleSubTypeFilter
  );
  const filterVehicleType = useSelector(
    (state) => state.driverVehcleTypeFilter
  );
  const { content } = useSelector((state) => state.searchFilter);

  useEffect(() => {
    if (!subAdminInfo) {
      navigate("/sub-admin/login");
    }
    dispatch(
      ListAllDriverAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.filters,
        vehicleType: filterVehicleType.filters,
        subType: filterSubType.filters,
        places: filterPlaces.filters,from, to
      }))
  }, [subAdminInfo, dispatch, navigate,content, from, to,statusDateFilter]);

  const handleAddComment = async (sbName) => {
    setCsnotes([]);
    if (AllDriversList) {
      setSubAdminName(sbName);
      setisCommentOpen(true);
    }
  };


  const handleDropdown = (action, id, complete, status) => {
    if (action === "walletLogs") {
      complete
        ? setWalletLogs(true)
        : Swal.fire(
            "Warning!",
            "This driver registration is not complete.",
            "error"
          );
    } else if (action === "viewProfile") {
      navigate("/sub-admin/driver-profile/" + id);
    }
  };

  let commentShow = (cmnt) => {
    let comment = "";
    let status = "";

    if (cmnt && cmnt.substring(0, 5) === "Block" && cmnt.length > 5) {
      status = "Block";
      comment = cmnt.slice(9);
    } else if (cmnt && cmnt.substring(0, 7) === "Unblock" && cmnt.length > 7) {
      status = "Unblock";
      comment = cmnt.slice(9);
    } else if (cmnt) {
      comment = cmnt;
    }

    switch (status) {
      case "Block":
        return (
          <>
            {" "}
            <span style={{ backgroundColor: "red", color: "white" }}>
              {status}
            </span>{" "}
            : <span> {comment} </span>{" "}
          </>
        );
        break;
      case "Unblock":
        return (
          <>
            {" "}
            <span style={{ backgroundColor: "green", color: "white" }}>
              {status}
            </span>{" "}
            <span> {comment} </span>{" "}
          </>
        );
        break;
      default:
        return <span>{comment}</span>;
        break;
    }
  };
  const [rows, setRows] = React.useState(10);
  const [skip, setSkip] = React.useState(0);
  const handleRowsCount = (event) => {
    setRows(event.target.value);
  };

  const handleNext = () => {
    if (skip < 1) {
      setSkip(rows);
      dispatch(
        ListAllDriverAction({
          rows,
          skip,
          content,
          status_filter: filterStatus.filters,
          vehicleType: filterVehicleType.filters,
          subType: filterSubType.filters,
          places: filterPlaces.filters,from, to
        })
      );
    } else {
      setSkip(skip + rows);
      dispatch(
        ListAllDriverAction({
          rows,
          skip,
          content,
          status_filter: filterStatus.filters,
          vehicleType: filterVehicleType.filters,
          subType: filterSubType.filters,
          places: filterPlaces.filters,from, to
        })
      );
    }
  };

  const handlePrev = () => {
    if (skip - rows < 0) {
      setSkip(0);
      dispatch(
        ListAllDriverAction({
          rows,
          skip,
          content,
          status_filter: filterStatus.filters,
          vehicleType: filterVehicleType.filters,
          subType: filterSubType.filters,
          places: filterPlaces.filters,from, to
        })
      );
    } else {
      setSkip(skip - rows);
      dispatch(
        ListAllDriverAction({
          rows,
          skip,
          content,
          status_filter: filterStatus.filters,
          vehicleType: filterVehicleType.filters,
          subType: filterSubType.filters,
          places: filterPlaces.filters,from, to
        })
      );
    }
  };
  const refresh = async () => {
    dispatch(ListAllDriverAction({ rows: 10, skip: 0, content: "" ,from:"06/15/2022", to: moment(new Date()).format("L")}));
    dispatch(FilterDriverStatusAction([]));
    dispatch(FilterVehicleTypeAction([]));
    dispatch(FilterVehicleSubTypeAction([]));
    dispatch(filterDriverPlaceAction([]));
    dispatch(FilterWithDate({ from: "", to: "" }));
    setFilterChange(filterChange + 1);
    setSkip(0);
    dispatch(searchAction(""));
  };
  let driverRecord = (row, i) => {
    return (
      <>
        <TableRow hover key={row.personalDetails.firstName}>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <span>{i + 1}</span>
          </TableCell>
          <TableCell>
            {row.isOnline ? (
              <span
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Online
              </span>
            ) : (
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Offline
              </span>
            )}
          </TableCell>
          <TableCell>
            {row.status === "PendingVehicleDetails" ? (
              <span
                style={{
                  color: "yellowgreen",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {row.status}
              </span>
            ) : (
              ""
            )}
            {row.status === "PendingBankDetails" ? (
              <span
                style={{
                  color: "blue",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {row.status}
              </span>
            ) : (
              ""
            )}
            {row.status === "Pending" ? (
              <span
                style={{
                  color: "orange",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {row.status}
              </span>
            ) : (
              ""
            )}
            {row.status === "Active" ? (
              <span
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {row.status}
              </span>
            ) : (
              ""
            )}
            {row.status === "Blocked" ? (
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                {row.status}
              </span>
            ) : (
              ""
            )}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <span>{row.personalDetails.addCity}</span>
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <span>{row.driverId}</span>
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.personalDetails.firstName}
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.personalDetails.defaultPhoneNumber}
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.vehicleDetails && row.vehicleDetails.vehicleType}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.vehicleDetails && row.vehicleDetails.subType}
          </TableCell>

          <TableCell sx={{ color: "green" }}>
            <div>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small">Action</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={age}
                  label="Action"
                  component="button"
                  onChange={(e) => {
                    setCostomId(row.driverId);
                    setDriverId(row._id);

                    handleDropdown(
                      e.target.value,
                      row._id,
                      row.regCompleted,
                      row.status
                    );
                  }}
                >
                  <MenuItem value="walletLogs">Wallet Logs</MenuItem>
                  <MenuItem value="viewProfile">View Profile</MenuItem>
                </Select>
              </FormControl>
            </div>
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.wallet.toFixed(0)}
          </TableCell>
          <TableCell
            sx={{ fontWeight: "bold", fontSize: "16px" }}
            onClick={() => {
              navigate(`/sub-admin/driver/bookinglist/${row._id}`);
            }}
          >
            <Button
              sx={{ width: 100 }}
              variant="outlined"
              endIcon={<InfoIcon />}
            >
              {row.attemptedRides ? row.attemptedRides : 0}
            </Button>
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <Button
              sx={{ width: 100 }}
              variant="outlined"
              endIcon={<InfoIcon />}
              onClick={() => navigate(`/sub-admin/${row._id}/driver-reviews`)}
            >
              {row.rating}/5
            </Button>
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.personalDetails.refferalNumber
              ? row.personalDetails.refferalNumber
              : "Nill"}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.dateAndTime}
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span>
                {row.csnotes[row.csnotes.length - 1]
                  ? commentShow(row.csnotes[row.csnotes.length - 1].comment)
                  : ""}
              </span>
            </div>
            <Button
              sx={{
                backgroundColor: "#e75c05",
                width: "200px",
              }}
              variant="contained"
              onClick={() => {
                setCostomId(row.driverId);
                setDriverId(row._id);
                let sbName = `${subAdminInfo.firstName} ${subAdminInfo.lastName} `;
                handleAddComment(sbName);
              }}
            >
              Add comment
            </Button>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const submitFilter = () => {
    setSkip(0);
    dispatch(
      ListAllDriverAction({
        rows,
        skip: 0,
        content,
        status_filter: filterStatus.filters,
        vehicleType: filterVehicleType.filters,
        subType: filterSubType.filters,
        places: filterPlaces.filters,from, to
      })
    );
  };

  return  (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="Drivers"
          id={subAdminInfo && subAdminInfo._id}
          email={subAdminInfo && subAdminInfo.email}
          name={
            subAdminInfo && `${subAdminInfo.firstName} ${subAdminInfo.lastName}`
          }
          token={subAdminInfo && subAdminInfo.token}
        />

        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          {!walletLogs ? (
            <>
              <Toolbar />

              <div
                style={{
                  width: "64%",
                }}
              >
                <h5 style={{ display: "block", color: "green" }}>
                  Manage Driver
                </h5>
              </div>

              <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                <PlaceDrowpDown style={{ marginTop: "30px" }} />
                <StatusDrowpDown style={{ marginTop: "30px" }} />
                <VehicleTypeDrowpDown style={{ marginTop: "30px" }} />
                <VehicleSubTypeDrowpDown style={{ marginTop: "30px" }} />
                <Button onClick={submitFilter} variant="outlined">
                  submit filter
                </Button>
                <span style={{ position: "relative", top: 9 }}>
                  <FromToDateFilter />
                </span>

                <span style={{ marginLeft: "20px" }}>
                  <SearchFilter />
                </span>

                <Button
                  variant="contained"
                  sx={{ bgcolor: "green", height: "39px", marginLeft: "10px" }}
                  onClick={refresh}
                >
                  <RefreshIcon />
                </Button>
             
              </div>

              {/* table */}
              <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2 }}>
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                    >
                      <EnhancedTableHead />
                      <TableBody>
                        {AllDriversList&&AllDriversList.map((row, i) => {
                          return driverRecord(row, i);
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <ButtonGroup
                  disableElevation
                  variant="outlined"
                  aria-label="Disabled elevation buttons"
                  sx={{ mt: 2 }}
                >
                  <Button onClick={handlePrev}>
                    <ArrowBackIosIcon />
                  </Button>
                  <Button onClick={handleNext}>
                    <ArrowForwardIosIcon />
                  </Button>
                </ButtonGroup>
                <FormControl sx={{ minWidth: 80, ml: 2, mt: 2 }} size="small">
                  <InputLabel id="demo-select-small">Rows </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={rows}
                    label="Rows"
                    onChange={handleRowsCount}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </>
          ) : (
            <WalletLogs
              onHide={() => setWalletLogs(false)}
              driverId={driverId}
              lrnr={costomId}
              logsFor="driver"
            />
          )}
        </Box>
      </Box>
      <SubAddCommentForm
        title="Driver"
        isOpen={isOpenComment}
        subAdminName={subAdminName}
        handleClose={handleCommentClose}
        costomId={costomId}
        driverId={driverId}
        commentFor="driver"
      />
      <AddWalletFrom
        title="Driver"
        isOpen={isOpen}
        handleClose={handleClose}
        costomId={costomId}
        driverId={driverId}
        walletFor="driver"
      />
      <BlockingForm
        show={modalShow}
        onHide={() => setModalShow(false)}
        lrnr={costomId}
        driverId={driverId}
        setModalShow={setModalShow}
      />
      <UnBlockingForm
        show={unblockModalShow}
        onHide={() => setUnblockModalShow(false)}
        lrnr={costomId}
        driverId={driverId}
        setUnblockModalShow={setUnblockModalShow}
      />
    </>
  ) 
};

export default ManageDriver;
