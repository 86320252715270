import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import InfoIcon from "@mui/icons-material/Info";
import Swal from "sweetalert2";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  SearchFilter,
  StatusDrowpDown,
} from "../../Components/Admin/customerHelper/FilterCustomer";
import { UsersTableHead } from "../../Components/Admin/customerHelper/CustomerTableHead";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Loader from "../../Components/Loader";
import { ButtonGroup, Paper } from "@mui/material";
import {
  ListAllCustomersAction,
  FilterCustomerStatusAction,
  SearchCustomersAction,
  refreshUserAction,
} from "../../Actions/AdminActions";
import AddWalletFrom from "../../Components/Admin/Modals/AddWalletFrom";
import WalletLogs from "../../Components/WalletLogs";
import AddCommentForm from "../../Components/Admin/AddCommentForm";
import BlockingForm from "../../Components/Admin/Modals/BlockingForm";
import UnBlockingForm from "../../Components/Admin/Modals/UnBlockingForm";
import exportFromJSON from "export-from-json";
import RefreshIcon from "@mui/icons-material/Refresh";
import CustomerBookings from "../../Components/Admin/CustomerBookings";
import { useState } from "react";
import axios from "axios";
import { API } from "../../socketConst/BaseApi";


var data = [];


UsersTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const AdminManageCustomer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { adminInfo } = adminLogin;
  // menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // end menu
  // wallt modal
  const [isOpen, setisOpen] = React.useState(false);
  const [isOpenComment, setisCommentOpen] = React.useState(false);
  const [adminName, setAdminName] = React.useState("");
  const handleOpen = () => {
    setisOpen(true);
  };
  const handleClose = () => {
    setisOpen(false);
  };
  const handleCommentClose = () => {
    setisCommentOpen(false);
  };

  
  
  const [walletLogs, setWalletLogs] = React.useState(false);
  const updateCustomer = useSelector((state) => state.updateCustomer);
  const { success } = updateCustomer;
  const [csnotes, setCsnotes] = React.useState([]);
  const [lrnr, setLrnr] = React.useState("");
  const [customerId, setCustomerId] = React.useState("");
  
  const [modalShow, setModalShow] = React.useState(false);
 
  const [unblockModalShow, setUnblockModalShow] = React.useState(false);

  const [customerBookings, setCustomerBookings] = React.useState(false);
  const [rows, setRows] = React.useState(10);
  const [skip, setSkip] = React.useState(0);
  const { customerLists, loading } = useSelector((state) => state.customerList);
  const filterStatus = useSelector((state) => state.customerStatusFilter);
  const { statusCustomerFilter } = useSelector(
    (state) => state.customerStatusFilter
  );
  const { content } = useSelector((state) => state.customerSearch);

  const [update, setUpdate] = React.useState(false);
  const { loading1 } = useSelector((state) => state.customerComment);

  const [coinsLogs, setCoinsLogs] = React.useState(false);

  const handlePrev = () => {
    if (skip - rows < 0) {
      setSkip(0);
    } else {
      setSkip(skip - rows);
    }
  };
  const handleNext = () => {
    if (skip < 1) {
      setSkip(rows);
    } else {
      setSkip(skip + rows);
    }
  };
  const handleRowsCount = (event) => {
    setRows(event.target.value);
  };
  useEffect(() => {
    dispatch(
      ListAllCustomersAction({
        rows,
        skip,content,
        status_filter: filterStatus.customerfilters,
      })
    );
  }, [rows, skip]);
 
  
  const [isStartedDownload, setIsStartedDownload] = useState(false);
  const ExportToExcel = async () => {
    setIsStartedDownload(true);
    var { data } = await axios.get(`${API}/api/admin/download-all-users`); 
    if (data) {
      setIsStartedDownload(false);
    
    if (data.length > 0) {
      const list = await data.map((customer) => {
        let customerArray = {
          ID: customer.cutomerID,
          "First Name": customer.firstName,
          "Last Name": customer.lastName,
          email: customer.email,
          "Phone Number": customer.phoneNumber,
          status: customer.isBlock ? "Blocked" : "Active",
          "GST Number": customer.gstNo,
          wallet: customer.wallet,
          orders: customer.orders,
          reviews: customer.reviews,
          "Register At": customer.dateAndTime,
        };
        return customerArray;
      });
       data = list;

      const fileName = "LOADRUNNR_ALL_USERS";
      const exportType = "xls";
      exportFromJSON({ data, fileName, exportType });
    } else {
      Swal.fire("Warning!", "Tata is not available for download.", "error");
    }
  }
  };

  const refresh = async () => {
    dispatch(refreshUserAction());
    dispatch(SearchCustomersAction([]));
    dispatch(FilterCustomerStatusAction([]));
    dispatch(ListAllCustomersAction({ rows: 10,content, skip: 0, status_filter: "" }));
  };

  // All drivers
  useEffect(() => {
    if (!adminInfo) {
      navigate("/login");
    }

    dispatch(
      ListAllCustomersAction({
        rows,content,
        skip,
        status_filter: filterStatus.customerfilters,
      })
    );
  }, [adminInfo, dispatch, navigate, loading1, success]);

  const handleWalletLogs = (customerId, lrnrID) => {
    setLrnr(lrnrID);
    setCustomerId(customerId);
    setWalletLogs(true);
  };

  const handleAddComment = async (adminName) => {
    setCsnotes([]);
    if (customerLists) {
      setAdminName(adminName);
      setisCommentOpen(true);
    }
  };

  const [log, setLog] = useState("");

  

  customerLists &&
  customerLists.map((customer) => {
      if (customerLists.length === data.length) {
        return data;
      } else {
        let status;
        if (customer.isBlock) {
          status = "Blocked";
        } else {
          status = "Active";
        }
        data.push({
          ID: customer.cutomerID,
          "First Name": customer.firstName,
          "Last Name": customer.lastName,
          email: customer.email,
          "Phone Number": customer.phoneNumber,
          status: status,
          "GST Number": customer.gstNo,
          wallet: customer.wallet,
          orders: customer.orders,
          reviews: customer.reviews,
          "Register At": customer.dateAndTime,
        });
      }
    });

  filterStatus.statusCustomerFilter && (data = []);
  content && (data = []);

  let commentShow = (cmnt) => {
    let comment = "";
    let status = "";

    if (cmnt && cmnt.substring(0, 5) === "Block" && cmnt.length > 5) {
      status = "Block";
      comment = cmnt.slice(9);
    } else if (cmnt && cmnt.substring(0, 7) === "Unblock" && cmnt.length > 7) {
      status = "Unblock";
      comment = cmnt.slice(9);
    } else if (cmnt) {
      comment = cmnt;
    }

    switch (status) {
      case "Block":
        return (
          <>
            {" "}
            <span style={{ backgroundColor: "red", color: "white" }}>
              {status}
            </span>
            {" :"}
            <span> {comment} </span>{" "}
          </>
        );
        break;
      case "Unblock":
        return (
          <>
            {" "}
            <span style={{ backgroundColor: "green", color: "white" }}>
              {status}
            </span>{" "}
            <span> {comment} </span>{" "}
          </>
        );
        break;
      default:
        return <span>{comment}</span>;
        break;
    }
  };

  const { customerOrder } = useSelector((state) => state.customerOrders);
  const [coinsHistory, setCoinsHistory] = useState([]);
  const [details, setDetails] = useState();
  const handleCustomerOrders = (id, cstmId) => {
    navigate(`/customer/bookinglist/${id}`);
  };

  let userRecord = (row, i) => {
    return (
      <>
        <TableRow hover key={row._id}>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <span>{i + 1}</span>
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.cutomerID}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.isBlock ? "Blocked" : "Active"}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.firstName}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.lastName}
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.phoneNumber}
          </TableCell>

          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.email}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.gstNo}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span>₹{row.wallet}</span>
            </div>
            <Button
              sx={{
                backgroundColor: "#e75c05",
                width: "20px",
              }}
              variant="contained"
              onClick={() => {
                setLrnr(row.cutomerID);
                setCustomerId(row._id);
                let adminName = `${adminInfo.firstName} ${adminInfo.lastName}`;
                handleOpen(adminName);
              }}
            >
              Add
            </Button>
          </TableCell>

          <TableCell
            sx={{ fontWeight: "bold", fontSize: "16px" }}
            onClick={() => handleCustomerOrders(row._id, row.cutomerID)}
          >
            <Button
              sx={{ width: 100 }}
              variant="outlined"
              endIcon={<InfoIcon />}
            >
              {row.placedOrders}
            </Button>
          </TableCell>
          <TableCell
            sx={{ fontWeight: "bold", fontSize: "16px" }}
            onClick={() => {
              setLog("coin");
              setCoinsHistory(row.coinHistory);
              handleWalletLogs(row._id, row.cutomerID);
              setDetails(row);
            }}
          >
            <Button
              sx={{ width: 100 }}
              variant="outlined"
              endIcon={<InfoIcon />}
            >
              {row.coins}
            </Button>
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <div>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small">Action</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  label="Action"
                  component="button"
                >
                  <MenuItem
                    value="walletLogs"
                    onClick={() => {
                      setLog("wallet");
                      handleWalletLogs(row._id, row.cutomerID);
                    }}
                  >
                    Wallet Logs
                  </MenuItem>
                  {row.isBlock ? (
                    <MenuItem
                      value="UnBlock"
                      onClick={() => {
                        setLrnr(row.cutomerID);
                        setCustomerId(row._id);
                        setUnblockModalShow(true);
                      }}
                    >
                      UnBlock
                    </MenuItem>
                  ) : (
                    <MenuItem
                      value="Block"
                      onClick={() => {
                        setLrnr(row.cutomerID);
                        setCustomerId(row._id);
                        setModalShow(true);
                      }}
                    >
                      Block
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.reviews}
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span>
                {row.csnote[row.csnote.length - 1]
                  ? commentShow(row.csnote[row.csnote.length - 1].comment)
                  : ""}
              </span>
            </div>
            <Button
              sx={{
                backgroundColor: "#e75c05",
                width: "150px",
              }}
              variant="contained"
              onClick={() => {
                setLrnr(row.cutomerID);
                setCustomerId(row._id);

                let adName = `${adminInfo.firstName} ${adminInfo.lastName} `;
                handleAddComment(adName);
              }}
            >
              Add Comment
            </Button>
          </TableCell>
          <TableCell sx={{ fontWeight: "bold", fontSize: "16px" }}>
            {row.dateAndTime}
          </TableCell>
        </TableRow>
      </>
    );
  };
  const submitFilter = () => {
    setSkip(0);
    dispatch(
      ListAllCustomersAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.customerfilters,
      })
    );
  };
  let submitSearch = ()=>{
    if (skip > 10) {
      setSkip(0);
    }
    if (content) {
      setSkip(0);
    }
    dispatch(
      ListAllCustomersAction({
        rows,
        skip,
        content,
        status_filter: filterStatus.customerfilters,
      })
    );
  }
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidebar
          ActiveTab="Customer"
          id={adminInfo && adminInfo._id}
          email={adminInfo && adminInfo.email}
          name={adminInfo && `${adminInfo.firstName} ${adminInfo.lastName}`}
          place={adminInfo && adminInfo.place}
          token={adminInfo && adminInfo.token}
        />

        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          {!walletLogs ? (
            <>
              <Toolbar />

              <h5 style={{ display: "inline-block", color: "green" }}>
                Manage users
              </h5>

              <div style={{ paddingTop: 1 }}>
                <StatusDrowpDown style={{ marginTop: "25px" }} />
                <Button sx={{mr:2}} onClick={submitFilter} variant="outlined">
                  submit filter
                </Button>
                <div
                  style={{
                    display: "inline-flex",
                  }}
                >
                  <span>
                    <SearchFilter />
                  </span>
                 
                  <Button sx={{ml:2,mt:1,height:"40px"}} onClick={submitSearch} variant="outlined">
                 
                  submit search
                </Button>
               
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "green",
                      height: "39px",
                      marginLeft: "10px",
                      marginTop: "9px",
                    }}
                    onClick={refresh}
                  >
                    <RefreshIcon />
                  </Button>

                  <Button
                    onClick={ExportToExcel}
                    variant="contained"
                    sx={{
                      height: "39px",
                      marginLeft: "9px",
                      marginTop: "10px",
                    }}
                  >
                    <DownloadIcon />
                  </Button>
                </div>
              </div>

              {/* table */}

              {!loading ? (
                <Box sx={{ width: "100%" }}>
                  <Paper sx={{ width: "100%", mb: 2 }}>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        // size={dense ? "small" : "medium"}
                        id="table-to-xls"
                      >
                        <UsersTableHead />

                        <TableBody>
                          {customerLists&&customerLists.map((row, i) => {
                            return userRecord(row, i);
                          })}
                        </TableBody>
                      </Table>
                      <Loader loading={loading} />
                    </TableContainer>
                  </Paper>

                  <ButtonGroup
                    disableElevation
                    variant="outlined"
                    aria-label="Disabled elevation buttons"
                    sx={{ mt: 2 }}
                  >
                    <Button onClick={handlePrev}>
                      <ArrowBackIosIcon />
                    </Button>
                    <Button onClick={handleNext}>
                      <ArrowForwardIosIcon />
                    </Button>
                  </ButtonGroup>
                  <FormControl sx={{ minWidth: 80, ml: 2, mt: 2 }} size="small">
                    <InputLabel id="demo-select-small">Rows </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={rows}
                      label="Rows"
                      onChange={handleRowsCount}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              ) : (
                <Loader loading={loading} />
              )}
            </>
          ) : (
            <WalletLogs
              onHide={() => setWalletLogs(false)}
              customerId={customerId}
              lrnr={lrnr}
              log={log}
              coinsHistory={coinsHistory}
              logsFor="customer"
              details={details}
            />
          )}
        </Box>
      </Box>
      <CustomerBookings
        show={customerBookings}
        onHide={() => setCustomerBookings(false)}
        customerId={customerId}
        setCustomerBookings={setCustomerBookings}
        customerOrder={customerOrder}
        lrnr={lrnr}
      />

      <UnBlockingForm
        show={unblockModalShow}
        onHide={() => setUnblockModalShow(false)}
        lrnr={lrnr}
        customerId={customerId}
        setUnblockModalShow={setUnblockModalShow}
      />
      <BlockingForm
        show={modalShow}
        onHide={() => setModalShow(false)}
        lrnr={lrnr}
        customerId={customerId}
        setModalShow={setModalShow}
      />
      <AddCommentForm
        title="Customer"
        isOpen={isOpenComment}
        adminName={adminName}
        handleClose={handleCommentClose}
        costomId={lrnr}
        customerId={customerId}
        // csnotes={csnotes}
        commentFor="customer"
      />
      <AddWalletFrom
        title="Customer"
        isOpen={isOpen}
        handleClose={handleClose}
        costomId={lrnr}
        customerId={customerId}
        walletFor="customer"
      />
       {isStartedDownload ? <Loader download={true} loading={isStartedDownload} /> : ""}
    </>
  );
};

export default AdminManageCustomer;
