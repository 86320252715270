import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AppBar from "@mui/material/AppBar";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { makeStyles } from "@material-ui/core";
import {
  createPromocodeAction,
  genratePromocodeAction,
  getSinglePromocodeAction,
  updatePromocodeAction,
} from "../../Actions/AdminActions";
import moment from "moment";
import { Spinner } from "react-bootstrap";

const helperText = makeStyles(() => ({
  root: {
    "& .MuiTextField-root": {
      margin: 1,
      width: 200,
    },
    "& .MuiFormHelperText-root": {
      color: "red !important",
    },
  },
  bg: {
    backgroundColor: "#7986cb",
  },
  textfield: {
    backgroundColor: "#fff",
  },
}));
const AddPromocode = ({ handleClose, handleClose1, open, id }) => {
  const helperTxtClass = helperText();
  const [category, setCategory] = useState("General");
  const [users, setUsers] = useState("");
  const [code, setCode] = useState("");
  const [codeStatus,setCodeStatus] = useState(false)
  const [couponValue, setCouponValue] = useState("");
  const [couponValueStatus,setCouponValueStatus]= useState(false)
  const [couponType, setCouponType] = useState("precentage");
  const [expireDate, setExpireDate] = useState(new Date());
  const [minPrice, setMinPrice] = useState();
  const [minPriceStatus,setMinPriceStatus] = useState(false)
  const [maxDiscount, setMaxDiscount] = useState();
  const [maxDiscountStatus,setMaxDiscountStatus]=useState(false)
  const [maxRides, setMaxRides] = useState();
  const [maxRidesStatus,setMaxRidesStatus] =useState(false)
  useEffect(() => {
    if (id) {
      dispatch(getSinglePromocodeAction(id));
    }
  }, [id]);
  const singlePromocode = useSelector((state) => state.singlePromocode);
  const { codeDetails } = singlePromocode;
  useEffect(() => {
    if (id && codeDetails) {
      setCategory(codeDetails.category);
      setUsers(codeDetails.users);
      setCode(codeDetails.promocode);
      setCouponValue(codeDetails.couponValue);
      setCouponType(codeDetails.couponType);
      setExpireDate(codeDetails.date);
      setMinPrice(codeDetails.minPrice);
      setMaxRides(codeDetails.maxRides);
      setMaxDiscount(codeDetails.maxDiscount);
    }
  }, [codeDetails]);
  const dispatch = useDispatch();

  const handleChange = (newValue) => {
    setExpireDate(newValue);
  };
  const genpromocode = useSelector((state) => state.genpromocode);
  const { loading, genPromocode } = genpromocode;
  useEffect(() => {
    if (genPromocode) {
      setCode(genPromocode);
    }
  }, [genPromocode]);

  const handleGen = () => {
    dispatch(genratePromocodeAction());
  };

  const handleSubmit = () => {
    
    if (
      category &&
      code &&
      couponValue &&
      couponType &&
      expireDate &&
      minPrice &&
      maxDiscount &&
      maxRides 
      
    ) {
      dispatch(
        createPromocodeAction(
          category,
          code,
          couponValue,
          couponType,
          expireDate,
          minPrice,
          maxDiscount,
          maxRides,
          users
        )
      );
      handleClose();
    }else{
      if(!code)setCodeStatus(true)
      else setCodeStatus(false)
      if(!couponValue)setCouponValueStatus(true)
      else setCouponValueStatus(false)
      if(!minPrice)setMinPriceStatus(true)
      else setMinPriceStatus(false)
      if(!maxDiscount)setMaxDiscountStatus(true)
      else setMaxDiscountStatus(false)
      if(!maxRides)setMaxRidesStatus(true)
      else setMaxRidesStatus(false)
    }
  };
  const handleUpdate = () => {
    dispatch(
      updatePromocodeAction(
        id,
        category,
        code,
        couponValue,
        couponType,
        expireDate,
        minPrice,
        maxDiscount,
        maxRides,
        users
      )
    );
    handleClose1();
  };
  let close= ()=>{
    setCategory("General")
    handleClose()
  }
  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={handleClose}>
        <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
          <DialogTitle sx={{ color: "white" }}>
            <AddIcon />
            <span style={{ marginLeft: "20px" }}>
              {id ? "Edit Promo Code" : "Create Promo Code"}
            </span>
          </DialogTitle>
        </AppBar>
        <DialogContent>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Category
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue="General"
            >
              <FormControlLabel
                value={category === "General" ? category : "General"}
                control={<Radio defaultChecked />}
                onChange={(e) => setCategory("General")}
                label="General"
              />
              <FormControlLabel
                value={category === "Specific" ? category : "Specific"}
                control={<Radio />}
                onChange={(e) => {
                  setCategory("Specific");
                }}
                label="Specific"
              />
            </RadioGroup>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            <TextField
              className={helperTxtClass.root}
              helperText={ !code && codeStatus ?  "Please enter coupon code" :""}
              label="Coupon Code"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                setCodeStatus(true)
                
              }}
              sx={{
                width: "950px",
              }}
            />
            <Button
              style={{
                backgroundColor: "blue",
                color: "white",
              }}
              sx={{
                width: "44px",
              }}
              onClick={() => {
                handleGen();
              }}
            >
              {loading ? <Spinner animation="border" /> : "gen"}
            </Button>
            <FormControl fullWidth>
              <InputLabel
                value={couponType}
                id="demo-simple-select-helper-label"
              >
                Coupon Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={couponType}
                onChange={(e) => setCouponType(e.target.value)}
                label="Coupon Type"
              >
                <MenuItem value="percentage">Percentage</MenuItem>
              </Select>
               {couponType ? "" : <FormHelperText sx={{ color: "red" }}>
                Select Coupon Type
              </FormHelperText> }
             
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            <TextField
              className={helperTxtClass.root}
              helperText={!couponValue && couponValueStatus ? "Please enter coupon value" : ""}
              label="Coupon Value"
              fullWidth
              value={couponValue}
              sx={{
                width: "544px",
              }}
              type="number"
              onChange={(e) => {
                setCouponValue(e.target.value);
                setCouponValueStatus(true)
              }}
            />

            <LocalizationProvider
              sx={{
                width: "544px",
              }}
              dateAdapter={AdapterDateFns}
            >
              <DateTimePicker
                disablePast
                label="Date&Time picker"
                minDate={new Date()}
                value={expireDate}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            <TextField
              className={helperTxtClass.root}
              helperText={!minPrice && minPriceStatus ? "Please enter min price" : ""}
              label="Min. Price"
              fullWidth
              type="number"
              value={minPrice}
              onChange={(e) => {
                setMinPrice(e.target.value);
                setMinPriceStatus(true)
              }}
            />
            <TextField
              className={helperTxtClass.root}
              helperText={!maxDiscount && maxDiscountStatus ? "Please enter max discount" :""}
              label="Max. Discount"
              fullWidth
              type="number"
              value={maxDiscount}
              onChange={(e) => {
                setMaxDiscount(e.target.value);
                setMaxDiscountStatus(true)
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            <TextField
              className={helperTxtClass.root}
              helperText={maxRidesStatus&&!maxRides? "Maximum Rides" :""}
              label="Maximum Rides"
              maxWidth="400px"
              type="number"
              value={maxRides}
              onChange={(e) => {
                setMaxRides(e.target.value);
                setMaxRidesStatus(true)
              }}
              sx={{
                width: "544px",
              }}
            />
           
            {category === "Specific" && (
              <>
                <FormControl
                  sx={{
                    width: "544px",
                  }}
                >
                  <TextField
                    className={helperTxtClass.root}
                    helperText="select one user"
                    label="select user"
                    value={users}
                    onChange={(e) => {
                      setUsers(e.target.value);
                    }}
                    sx={{
                      width: "544px",
                    }}
                  />
                </FormControl>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={id ? handleUpdate : handleSubmit}>Submit</Button>
          <Button onClick={id ? handleClose1 : close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddPromocode;
