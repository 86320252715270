import * as React from 'react';
import Box from '@mui/material/Box';
import { Drawer, Button } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import MyLocationSharpIcon from "@mui/icons-material/MyLocationSharp";
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import LogoutIcon from '@mui/icons-material/Logout';
import { height } from '@mui/system';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PricingScreen from '../../../Screens/AdminScreens/PricingScreen';
import NavBar from '../navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
const drawerWidth = 240;

export default function Sidebar(props) {
 
  const navigate = useNavigate();

  const handleSideBar = (element) => {
    if (element === 'Dashboard') {
      navigate('/sub-admin');
    } else if (element === 'Customers') {
      navigate('/sub-admin/manage-customer');
    } else if(element === "Drivers") {
      navigate('/sub-admin/manage-drivers')
    }else if(element === "Bookings"){
      navigate('/sub-admin/bookings')
    }else if(element === "Loadrunnr View"){
      navigate('/sub-admin/loadrunnr-view')
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar subAdminName={props.name} />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant='permanent'
        anchor='left'
      >
        <Toolbar>
        <img height={40} src="https://loadrunnr.in/images/614abccd6d626logoorange.png" alt="logo" />
          <Typography sx={{fontWeight:"bold",marginLeft:"5px",color:"#e75c05"}}>Sub Admin</Typography>
          
        </Toolbar>
        <Divider />
        <List>
          {['Dashboard', 'Customers', 'Drivers', 'Bookings', "Loadrunnr View"].map((text, index) => (
            <ListItem
            style={ text===props.ActiveTab ? { backgroundColor: '#e75c05' ,color:"#fff"}:{}}
              button
              onClick={() => {
                handleSideBar(text);
              }}
              key={text}
            >
              <ListItemIcon>
                {index === 0 ? <DashboardIcon sx={ text===props.ActiveTab ? {color:"#fff"} :{}} /> : ''}
                {index === 1 ? <AccountCircleIcon sx={ text===props.ActiveTab ? {color:"#fff"} :{}} /> : ''}
                {index === 2 ? <AirlineSeatReclineNormalIcon sx={ text === props.ActiveTab ? {color : "#fff"} : {}} /> : ""} 
                {index === 3 ? <BookmarkBorderIcon sx={ text === props.ActiveTab ? {color : "#fff"} : {}} /> : ""}
                {index === 4 ? <MyLocationSharpIcon sx={ text === props.ActiveTab ? { color : '#fff'} : {}} /> : ""}
               
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
