import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RegistredDriver from "../../Components/Admin/NotificationForm/RegistredDriver";
import Sidebar from "../../Components/Admin/sidebar/SideBar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useState } from "react";
import UnRegistredDriver from "../../Components/Admin/NotificationForm/UnregisteredDrivers";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function DriverNotification() {
  const theme = useTheme();

  const naviagate = useNavigate();
  const [value, setValue] = React.useState(0);
  const { adminInfo } = useSelector((state) => state.adminLogin);
  
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!adminInfo) {
      naviagate("/login");
    }
  }, [adminInfo, naviagate]);

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar
        ActiveTab="Driver Notification"
        id={adminInfo && adminInfo._id}
        email={adminInfo && adminInfo.email}
        name={`${adminInfo.firstName} ${adminInfo.lastName}`}
        place={adminInfo && adminInfo.place}
        token={adminInfo && adminInfo.token}
      />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 2 }}
      >
        <Box sx={{ bgcolor: "background.paper", marginY: 7 }}>
          {/* <Toolbar /> */}
          <AppBar position="static" sx={{ bgcolor: "green" }}>
            {/* f5b01b */}
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Registered drivers" {...a11yProps(0)} />
              <Tab label="Unregistered drivers" {...a11yProps(1)} />
              {/* <Tab label="both drivers" {...a11yProps(2)} /> */}
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <RegistredDriver /> 
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <UnRegistredDriver />
            </TabPanel>
            {/* <TabPanel value={value} index={2} dir={theme.direction}>
              both drivers
            </TabPanel> */}
          </SwipeableViews>
        </Box>
      </Box>
      {/* {value === 0 && (
        <div>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open1}
            severity="success"
            onClose={handleClose}
          >
            <Alert severity="success">Notification sent successfully!</Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open2}
            severity="error"
            onClose={handleClose}
          >
            <Alert severity="error">Notification Failed!</Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open3}
            severity="warning"
            onClose={handleClose}
          >
            <Alert severity="warning">
              Drivers Not Found Please Try Again!
            </Alert>
          </Snackbar>

          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open4}
            onClose={handleClose}
          >
            <Alert severity="info">
              All Drivers Can see This Notification!
            </Alert>
          </Snackbar>
        </div>
      )} */}
    </Box>
  );
}
