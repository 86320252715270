import React, { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Loader from "../Loader";
import {
  addCommentAction,
  addDriverCommentAction,
  driverCommentAction,
  customerCommentAction,
} from "../../Actions/AdminActions";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  DialogActions,
  AppBar,
  TextField,
  TextareaAutosize,
  Box,
} from "@mui/material";
import { useState } from "react";

const SubAddCommentForm = ({
  subAdminName,
  isOpen,
  driverId,
  commentFor,
  customerId,
  handleClose,
}) => {
  
  const dispatch = useDispatch();
  const [customerComment, setCustomerComment] = useState();
  const [driverComment, setDriverComment] = useState();
  const [customerCommentErr, setCustomerCommentErr] = useState(false);
  const [driverCommentErr, setDriverCommentErr] = useState(false);
  const customerComments = useSelector((state) => state.customerComment);
  const driverComments = useSelector((state) => state.drivrComment);
  useEffect(() => {
    switch (commentFor) {
      case "customer":
        if (customerId) dispatch(customerCommentAction(customerId));
        break;
      case "driver":
        if (driverId) dispatch(driverCommentAction(driverId));

        break;
      default:
        break;
    }
  }, [commentFor, isOpen, customerId, driverId, dispatch]);
 

  let commentShow = (cmnt) => {
    let comment = "";
    let status = "";

    if (cmnt && cmnt.substring(0, 5) === "Block" && cmnt.length > 5) {
      status = "Block";
      comment = cmnt.slice(9);
    } else if (cmnt && cmnt.substring(0, 7) === "Unblock" && cmnt.length > 7) {
      status = "Unblock";
      comment = cmnt.slice(9);
    } else if (cmnt) {
      comment = cmnt;
    }

    switch (status) {
      case "Block":
        return (
          <>
            {" "}
            <span style={{ backgroundColor: "red", color: "white" }}>
              {status}
            </span>
            {" :"}
            <span> {comment} </span>{" "}
          </>
        );
        break;
      case "Unblock":
        return (
          <>
            {" "}
            <span style={{ backgroundColor: "green", color: "white" }}>
              {status}
            </span>{" "}
            <span> {comment} </span>{" "}
          </>
        );
        break;
      default:
        return <span>{comment}</span>;
        break;
    }
  };

  const submit = () => {
    if (commentFor === "customer") {
      if ((customerComment && subAdminName && customerId)) {
        dispatch(addCommentAction(customerId, customerComment, subAdminName));
        setCustomerCommentErr(false);
        setCustomerComment("");
        handleClose();
      } else {
        setCustomerCommentErr(true);
      }
    } else {
      if (driverComment,subAdminName,driverId) {
        dispatch(addDriverCommentAction(driverId, driverComment,subAdminName));
        setDriverCommentErr(false);
        setDriverComment("");
        handleClose();
      } else {
        setDriverCommentErr(true);
      }
    }
  };

  const handleInput = (e) => {
    if (e.target.name === "customer") {
      setCustomerComment(e.target.value);
    } else {
      setDriverComment(e.target.value);
    }
  };

  switch (commentFor) {
    case "customer":
      return customerComments.loading1 ? (
        <Loader loading={customerComments.loading1} />
      ) : (
        <React.Fragment>
          <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={isOpen}
            onClose={handleClose}
          >
            <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
              <DialogTitle sx={{ color: "white" }}>
                <span style={{ marginLeft: "20px" }}>Add comment</span>{" "}
              </DialogTitle>
            </AppBar>
            <DialogContent>
              {customerComments &&
                customerComments.customerComments &&
                customerComments.customerComments.csNotes.map((note) => {
                  return (
                    <div style={{ border: "solid 1px gray", margin: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px",
                          minHeight: "40px",

                          color: "#fff",
                          backgroundColor: "#e75c05",
                        }}
                      >
                        <span>{note.admin}</span>
                        <span>{note.dateAndTime} </span>{" "}
                      </div>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          padding: "10px",
                          margin: "10px",
                          minHeight: "30px",
                        }}
                      >
                        {commentShow(note.comment)}
                      </div>
                    </div>
                  );
                })}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": { m: 2 },
                }}
              >
                <TextareaAutosize
                  onChange={handleInput}
                  name="customer"
                  value={customerComment}
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Add Your Remarks"
                  style={
                    customerCommentErr
                      ? { width: 1200, borderColor: "red" }
                      : { width: 1200 }
                  }
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                style={{ backgroundColor: "#e75c05", color: "#fff" }}
                onClick={submit}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "red", color: "#fff" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    case "driver":
      return driverComments.loading ? (
        <Loader loading={driverComments.loading} />
      ) : (
        <React.Fragment>
          <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={isOpen}
            onClose={handleClose}
          >
            <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
              <DialogTitle sx={{ color: "white" }}>
                <span style={{ marginLeft: "20px" }}>Add comment</span>{" "}
              </DialogTitle>
            </AppBar>
            <DialogContent>
              {driverComments &&
                driverComments.driverCommets &&
                driverComments.driverCommets.csNotes.map((note) => {
                  return (
                    <div style={{ border: "solid 1px gray", margin: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px",
                          minHeight: "40px",

                          color: "#fff",
                          backgroundColor: "#e75c05",
                        }}
                      >
                        <span>{note.admin}</span>{" "}
                        <span>{note.dateAndTime} </span>{" "}
                      </div>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          padding: "10px",
                          margin: "10px",
                          minHeight: "30px",
                        }}
                      >
                        {commentShow(note.comment)}
                      </div>
                    </div>
                  );
                })}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": { m: 2 },
                }}
              >
                <TextareaAutosize
                  onChange={handleInput}
                  name="driver"
                  value={driverComment}
                  aria-label="minimum height"
                  minRows={4}
                  placeholder="Add Your Remarks"
                  style={
                    driverCommentErr
                      ? { width: 1200, borderColor: "red" }
                      : { width: 1200 }
                  }
                />
              </Box>
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                style={{ backgroundColor: "#e75c05", color: "#fff" }}
                onClick={submit}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "red", color: "#fff" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
  }
};

export default SubAddCommentForm;
