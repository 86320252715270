import React, { useState } from "react";
import { MDBDataTable } from "mdbreact";
import PricingUpdateForm from "./Admin/Modals/PricingUpdateForm";
import Loader from "./Loader";
const DataTable = (props) => {
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [restart, setRestart] = useState(false);
  const [id, setId] = useState();
  var rowData = [];
  const handleShowForm = (typeId) => {
    setId(typeId);
    setRestart(true);
    setShowUpdateForm(true);
  };
  props.pricingListInfo &&
    props.pricingListInfo.map((list) => {
      console.log(list, "///////////// list p");
      rowData.unshift({
        vehicleType: list.vehicleType,
        subType: list.subType,
        baseKM: list.baseKM,
        baseFare: "₹" + list.baseFare,
        range1: list.range1,
        fareBetweenRange1: "₹" + list.fareBetweenRange1,
        range2: list.range2,
        fareBetweenRange2: "₹" + list.fareBetweenRange2,
        greaterThanKm: list.greaterThanKm + " " + "-",
        fareAfterRange2: "₹" + list.fareAfterRange2,
        extraCharge: "₹" + list.extraCharge,
        extraChargeReason: list.extraChargeReason,
        nightSurgeCharge: "₹" + list.nightSurgeCharge,
        nightSurgeTime: list.nightSurgeTime,
        farePerMin: list.farePerMin,
        update: (
          <button
            className="btn btn-danger"
            onClick={() => {
              handleShowForm(list._id);
            }}
          >
            update
          </button>
        ),
      });
    });
  const data = {
    columns: [
      {
        label: "Vehicle Type",
        field: "vehicleType",
        sort: "asc",
        width: 200,
      },
      {
        label: "Sub Type",
        field: "subType",
        sort: "asc",
        width: 270,
      },
      {
        label: "Base Km",
        field: "baseKM",
        sort: "asc",
        width: 200,
      },
      {
        label: "Base Fare",
        field: "baseFare",
        sort: "asc",
        width: 100,
      },
      {
        label: "KM _ KM",
        field: "range1",
        sort: "asc",
        width: 100,
      },
      {
        label: "fare (per KM)",
        field: "fareBetweenRange1",
        sort: "asc",
        width: 100,
      },
      {
        label: "KM _ KM",
        field: "range2",
        sort: "asc",
        width: 100,
      },
      {
        label: "fare (per KM)",
        field: "fareBetweenRange2",
        sort: "asc",
        width: 100,
      },
      {
        label: "fare after Range2 (per KM)",
        field: "fareAfterRange2",
        sort: "asc",
        width: 150,
      },
      {
        label: "extra charge",
        field: "extraCharge",
        sort: "asc",
        width: 150,
      },
      {
        label: "extra charge Reason",
        field: "extraChargeReason",
        sort: "asc",
        width: 150,
      },
      {
        label: "night surge charge",
        field: "nightSurgeCharge",
        sort: "asc",
        width: 150,
      },
      {
        label: "night surge time",
        field: "nightSurgeTime",
        sort: "asc",
        width: 150,
      },
      {
        label: "Fare Per Min",
        field: "farePerMin",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "update",
        sort: "asc",
        width: 150,
      },
    ],
    rows: rowData,
  };

  return data.rows[0] ? (
    <div>
      <MDBDataTable striped bordered small responsive btn data={data} />

      <PricingUpdateForm
        show={showUpdateForm}
        id={id}
        onHide={() => setShowUpdateForm(false)}
        setShowUpdateForm={setShowUpdateForm}
        restart={restart}
      />
    </div>
  ) : (
    <Loader loading={true} />
  );
};

export default DataTable;
