import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AppBar from "@mui/material/AppBar";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import UploadIcon from "@mui/icons-material/Upload";
import { API, LOADRUNNR_SOCKET_API } from "../../../socketConst/BaseApi";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Api } from "@mui/icons-material";

const helperText = makeStyles(() => ({
  root: {
    "& .MuiTextField-root": {
      margin: 1,
      width: 200,
    },
    "& .MuiFormHelperText-root": {
      color: "red !important",
    },
  },
  bg: {
    backgroundColor: "#7986cb",
  },
  textfield: {
    backgroundColor: "#fff",
  },
}));
const AddRules = ({ close, open, status, setStatus }) => {
  const helperTxtClass = helperText();
  const [coinType, setCoinType] = React.useState("");
  const [orderNumber, setOrderNumber] = React.useState();
  const [coin, setCoin] = React.useState();
  const [coin2, setCoin2] = React.useState();
  const [amount, setAmount] = React.useState();
  const [amount2, setAmount2] = React.useState();
  const [coin2Err, setCoin2Err] = React.useState(false);
  const [coinErr, setCoinErr] = React.useState(false);
  const handleChange = (event) => {
    let v = event.target.value;
    let n = event.target.name;

    if (n === "orderNo") {
        setOrderNumber(v);
    }
   
    if (n === "range1") {
      setCoin(v);
      setCoinErr(true);
    }
    if (n === "range2") {
      setCoin2(v);
      setCoin2Err(true);
    }
    if (n === "amount") setAmount(v);
    if (n === "amount2") setAmount2(v);
    if (n === "coinType") setCoinType(v);
  };

  const handleSubmit = async () => {
  
    if (coinType && orderNumber && coin && amount) {
      
      const { data } = await axios.post(
        `${API}/api/admin/create-coin-pricing`,
        {
          type: coinType,
          orderNumber: parseInt(orderNumber),
          range: orderNumber == 1 ? parseInt(coin2) : parseInt(coin),
          range1: orderNumber == 1 ? parseInt(coin) : 0,
          coins: orderNumber == 1 ? parseInt(amount2) : parseInt(amount),
          coins1: orderNumber == 1 ? parseInt(amount) : 0,
        }
        //, coins, range, range1, coins1,
      );
      if (data) {
        setStatus(!status);
        close();
      } else {
        alert("something went wrong");
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog fullWidth={true} maxWidth={"md"} open={open} onClose={close}>
        <AppBar position="static" sx={{ backgroundColor: "#e75c05" }}>
          <DialogTitle sx={{ color: "white" }}>
            <AddIcon /> Add A New Rule
          </DialogTitle>
        </AppBar>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            <FormControl sx={{ m: 1, width: "100%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                Coin Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={coinType}
                label="Coin Type"
                name="coinType"
                onChange={handleChange}
              >
                <MenuItem value="Fixed">Fixed</MenuItem>
                <MenuItem value="Per">Per</MenuItem>
              </Select>
              {/* <FormHelperText>With label + helper text</FormHelperText> */}
            </FormControl>
            <TextField
              className={helperTxtClass.root}
              //   helperText={!title && titleErr ? "Field is required" : ""}
              label="Order Number"
              name="orderNo"
              fullWidth
              value={orderNumber}
              type="number"
              onChange={handleChange}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            <TextField
              className={helperTxtClass.root}
                helperText={!coin && coinErr ? "Field is required" : ""}
              label={orderNumber == 1 ? "Range 1" : "Range"}
              name="range1"
              value={coin}
              fullWidth
              type="number"
              onChange={handleChange}
            />
            <TextField
              className={helperTxtClass.root}
              label={
                coin2 && coin
                  ? `Coins Between ₹${coin} & ₹${coin2}`
                  : coin
                  ? `Coins Above ₹${coin}`
                  : "Coins"
              }
              name="amount"
              //   value={coin}
              fullWidth
              type="number"
              onChange={handleChange}
            />
          </Box>
          {/* {orderNumber == 1 ? ( */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              "& > :not(style)": { m: 2 },
            }}
          >
            {orderNumber == 1 ? (
              <TextField
                className={helperTxtClass.root}
                helperText={!coin2 && coin2Err ? "Field is required" : ""}
                label="Range 2"
                name="range2"
                value={coin2}
                fullWidth
                type="number"
                onChange={handleChange}
              />
            ) : (
              ""
            )}
            {orderNumber == 1 ? (
              <TextField
                className={helperTxtClass.root}
                //   helperText={!coin && coinErr ? "Field is required" : ""}
                label={
                  coin2
                    ? `Coins Above ₹${coin2}`
                    : coin2
                    ? `Coins Above ₹${coin2}`
                    : "Coins"
                }
                name="amount2"
                //   value={coin}
                fullWidth
                type="number"
                onChange={handleChange}
              />
            ) : (
              ""
            )}
          </Box>
          {/* ) : (
            ""
          )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={close}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddRules;
