import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
//import { bookingAddComment, refreshBookingAction } from '../../../Actions/AdminActions';
import { bookingAddComment } from '../../../Actions/SubAdminAction';
const BookingAddCommentForm = (props) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [comment, setComment] = useState('');
  const [error, setError] = useState('');

  const submit = (e) => {
    e.preventDefault();
    if (comment) {
      setError('');
      dispatch(bookingAddComment(props.bookingId, comment,props.subAdminName));
      setComment('');
      props.setBookingId('');
      navigate('/sub-admin/bookings');
      props.setShowAddCommentForm(false);
      // dispatch(refreshBookingAction())
    } else {
      setError('comment is required..');
    }
  };
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='mt-5'
    >
      <Modal.Header closeButton className='mainColor'>
        <Modal.Title id='contained-modal-title-vcenter'>
          {'Booking ID : ' + props.lrnrbid}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.csNotes &&
          props.csNotes.map((note) => (
            <>
              <div className='row mainColor p-2' key={note._id}>
                <div className='col-md-6 text-light font-weight-bold'>
                  {note.admin}
                </div>
                <div className='col-md-6 d-flex justify-content-end text-light'>
                  {note.dateAndTime}
                </div>
              </div>
              <div className="p-4">
                <p>
                  {
                    note.comment.split(')')[0] === 'unAssign :' && <span style={{ fontWeight : "bold", background : "yellowgreen"}}>{note.comment.split(')')[0]}</span>
                  }
                  {
                    note.comment.split(')')[0] === 'cancel :' && <span style={{ fontWeight : "bold", background : "red", color : "white"}}>{note.comment.split(')')[0]}</span>
                  }
                  {note.comment.split(')')[1] ? note.comment.split(')')[1] : note.comment}
                </p>
              </div>
              
            </>
          ))}
        <div className='mt-4'>
          <Form onSubmit={submit}>
            <Form.Control
              className='mt-3'
              as='textarea'
              rows='3'
              cols='7'
              placeholder='Add Your comment'
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
            {error && (
              <p className='text-danger font-weight-bold mt-2 mb-2'>{error}</p>
            )}
            <div className='d-flex justify-content-end'>
              <Button
                type='submit'
                className='m-3 w-25'
                style={{
                  backgroundColor: 'rgb(231, 92, 5)',
                  fontWeight: 'bold',
                }}
              >
                Add
              </Button>
              <Button onClick={props.onHide} className='m-3 w-25'>
                Close
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BookingAddCommentForm;
