import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminLogin from "./Screens/AdminScreens/AdminLogin";
import SubAdminLogin from "./Screens/SubAdminScreen/SubAdminLogin";
import AdminDashboard from "./Screens/AdminScreens/AdminDashboard";
import PricingScreen from "./Screens/AdminScreens/PricingScreen";
import SubAdminDashboard from "./Screens/SubAdminScreen/SubAdminDashboard";
import SubAdminManageCostomer from "./Screens/SubAdminScreen/SubAdminManageCostomer";
import DriverBanner from "./Screens/AdminScreens/DriverBanner";
import UserBanner from "./Screens/AdminScreens/UserBanner";
import AdminManageDriver from "./Screens/AdminScreens/AdminDriverList";
import VerifyDriver from "./Screens/AdminScreens/VerifyDriver";
import PromoCode from "./Screens/AdminScreens/PromoCode";
import AdminCustomerList from "./Screens/AdminScreens/AdminCustomerList";
import Bookings from "./Screens/AdminScreens/Bookings";
import DriverProfile from "./Screens/AdminScreens/DriverProfile";
import AdminLoadrunnrView from "./Screens/AdminScreens/LoadrunnrView";
import WithdrawalRequest from "./Screens/AdminScreens/WithdrawalRequest";
import UserNotification from "./Screens/AdminScreens/UserNotification";
import DriverNotification from "./Screens/AdminScreens/DriverNotification";
import ManageDrivers from "./Screens/SubAdminScreen/ManageDrivers";
import ManageBookings from "./Screens/SubAdminScreen/ManageBookings";
import SubAdminLoadrunnrView from "./Screens/SubAdminScreen/SubAdminLoadrunnrView";
import PageNotFound from "./Components/Admin/Notfound";
import RentalPricingScreen from "./Screens/AdminScreens/RentalPricing";
import SubDriverProfile from "./Screens/SubAdminScreen/DriverProfile";
import AdminSubAdmin from "./Screens/AdminScreens/SubadminManagement";
import UserBookings from "./Components/UserBookings";
import BookingList from "./Components/UserBookingsList/BookingList";
import DriverReviews from "./Components/DriverReviews/DriverReviews";
import Edit from "./TEST/Edit"
import DriverReview from "./Components/SubAdmin/DriverReview";
import BookingLists from "./Screens/SubAdminScreen/BookingLists";
import ManageCoin from "./Screens/AdminScreens/ManageCoin";
import CoinOfferRequests from "./Screens/AdminScreens/CoinOfferRequests";
import DriverTransactions from "./Screens/AdminScreens/DriverTransactions";
import UserTransactions from "./Screens/AdminScreens/UserTransactions";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AdminDashboard />} />
        <Route path="/login" element={<AdminLogin />} />
        <Route path="/manage-customer" element={<AdminCustomerList />} />
        <Route path="/admin/driver-profile/:id" element={<DriverProfile />} />
        <Route path="/pricing" element={<PricingScreen />} />
        <Route path="/rental-pricing" element={<RentalPricingScreen />} />
        <Route path="/bookings" element={<Bookings />} />
        <Route path="/driver-banner" element={<DriverBanner />} />
        <Route path="/user-banner" element={<UserBanner />} />
        <Route path="/manage-driver" element={<AdminManageDriver />} />
        <Route path="/admin/verifydriver/:id" element={<VerifyDriver />} />
        <Route path="/create-promo" element={<PromoCode />} />
        <Route path="/loadrunnr-view" element={<AdminLoadrunnrView />} />

        <Route path="/coin-management" element={<ManageCoin />} />
        <Route path="/coin-offer-requests" element={<CoinOfferRequests />} />
       
        <Route
          path="/:id/:user/bookings/:customid"
          element={<UserBookings />}
        />
        <Route
          path="/:id/driver-reviews"
          element={<DriverReviews user="admin" />}
        />

        <Route
          path="/sub-admin/:id/driver-reviews"
          element={<DriverReview />}
        />
        <Route path="/:user/bookinglist/:id" element={<BookingList />} />
        <Route
          path="/sub-admin/:user/bookinglist/:id"
          element={<BookingLists />}
        />
        <Route path="/sub-admin-management" element={<AdminSubAdmin />} />
        <Route path="/withdrawal-requests" element={<WithdrawalRequest />} />
        <Route path="/driver-Notification" element={<DriverNotification />} />
        <Route path="/user-Notification" element={<UserNotification />} />
        <Route path="/sub-admin/login" element={<SubAdminLogin />} />
        <Route
          path="/sub-admin/driver-profile/:id"
          element={<SubDriverProfile />}
        />

        <Route path="/sub-admin" element={<SubAdminDashboard />} />
        <Route
          path="/sub-admin/manage-customer"
          element={<SubAdminManageCostomer />}
        />
        <Route path="/sub-admin/manage-drivers" element={<ManageDrivers />} />
        <Route path="/sub-admin/bookings" element={<ManageBookings />} />
        <Route
          path="/sub-admin/loadrunnr-view"
          element={<SubAdminLoadrunnrView />}
        />
        {/* <Route path='*' element={<PageNotFound/>} /> */}
        <Route path='/test-olakka' element={<Edit/>} /> 
        <Route path="/driver-transactions" element={<DriverTransactions/>} />
        <Route path="/user-transactions" element={<UserTransactions/>} />
      </Routes>
    </Router>
  );
}

export default App;
