import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import BookingMap from "./BookingMap";
import { bookingCurrentDriverLocationAction } from "../../../Actions/AdminActions";
import userEvent from "@testing-library/user-event";

export default function BookingsMap(props) {
  const dispatch = useDispatch();
  const { currentLocation } = useSelector(
    (state) => state.driverCurrentLocation
  );
  useEffect(() => {
    if (props.driverBookingId && props.bookingDriverId) {
      dispatch(bookingCurrentDriverLocationAction(props.driverBookingId));
      props.setDriverBookingId("");
    }
  }, [props.driverBookingId]);
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  useEffect(() => {
    if (props.status && props.status === "Cancel") {
      setLat("");
      setLng("");
    } else if (props.status && props.status === "Assigning") {
      setLat("");
      setLng("");
    } else if (props.status && props.status === "Completed") {
      setLat("");
      setLng("");
    } else {
      if (
        currentLocation &&
        currentLocation.status &&
        currentLocation.currentLocation &&
        currentLocation.currentLocation.lat &&
        currentLocation.currentLocation.lng
      ) {
        setLat(currentLocation.currentLocation.lat);
        setLng(currentLocation.currentLocation.lng);
      }
      
    }
  }, [props.driverBookingId, props.status]);

  // lat: currentLocation && currentLocation.status && currentLocation.currentLocation && currentLocation.currentLocation.lat ?  currentLocation.currentLocation.lat :props.location.dropLat ,
  // lng: currentLocation && currentLocation.status && currentLocation.currentLocation && currentLocation.currentLocation.lng ?  currentLocation.currentLocation.lng :props.location.dropLog,
  const link = [
    {
      coords: {
        lat: lat,
        lng: lng,
      },

      title: "something",
      img: "https://res.cloudinary.com/db1tmyw64/image/upload/v1653392364/PRICING_TYPE_IMAGE/edfxbvaryl5aeu2hl5hr.png",
    },
  ];
  const polyline = [];
  function addMarkers(map, links) {
    links.forEach((link, index) => {
      const marker = new window.google.maps.Marker({
        map,
        position: link.coords,
        // label: `${index + 1}`,
        title: link.title,
        icon: "https://res.cloudinary.com/db1tmyw64/image/upload/v1656401189/markers_Loadrunnr/lorrygreen_tousbm.png",
      });
    });
  }
 
  let mapProps = {
    options: {
      center: { lat: 12.971599, lng: 77.594566 },
      zoom: 13,
      zoomControl: true,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: true,
    },
    onMount: addMarkers,
    onMountProps: link, // linksComingFromSomewhere,
    polyline: polyline,
  };
  // const MemoMap = useCallback(<BookingMap {...mapProps} />, []);
  if (
    props.location &&
    props.location.pickupLocation &&
    props.location.dropLocation &&
    !props.location.address2Location &&
    !props.location.address3Location
  ) {
    polyline[0] = {
      lat: props.location.pickuplat,
      lng: props.location.pickuplog,
    };
    polyline[1] = {
      lat: props.location.dropLat,
      lng: props.location.dropLog,
    };
  } else if (
    props.location &&
    props.location.pickupLocation &&
    props.location.dropLocation &&
    props.location.address2Location &&
    !props.location.address3Location
  ) {
    polyline[0] = {
      lat: props.location.pickuplat,
      lng: props.location.pickuplog,
    };
    polyline[1] = {
      lat: props.location.address2lat,
      lng: props.location.address2log,
    };
    polyline[2] = {
      lat: props.location.dropLat,
      lng: props.location.dropLog,
    };
  } else if (
    props.location &&
    props.location.pickupLocation &&
    props.location.dropLocation &&
    props.location.address2Location &&
    props.location.address3Location
  ) {
    polyline[0] = {
      lat: props.location.pickuplat,
      lng: props.location.pickuplog,
    };
    polyline[1] = {
      lat: props.location.address2lat,
      lng: props.location.address2log,
    };
    polyline[2] = {
      lat: props.location.address3lat,
      lng: props.location.address3log,
    };
    polyline[3] = {
      lat: props.location.dropLat,
      lng: props.location.dropLog,
    };
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="mt-5"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Route</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "400px" }}>
        {/* <pre>{JSON.stringify(currentLocation, null, 4)}</pre> */}
        <div style={{ width: "40px" }}>
          {/* {MemoMap} */}
          <BookingMap {...mapProps} />
        </div>
        <div
          className="mt-4"
          style={{ border: "1px solid black", borderRadius: "5px" }}
        >
          <div className="m-4">
            <p>{props.location.pickupLocation}</p>
            {props.location.pickupName && <p>{props.location.pickupName}</p>}
            {props.location.pickupPhoneNumber && (
              <p>{props.location.pickupPhoneNumber}</p>
            )}
          </div>
        </div>
        {props.location.address2Location && (
          <div
            className="mt-4"
            style={{ border: "1px solid black", borderRadius: "5px" }}
          >
            <div className="m-4">
              <p>{props.location.address2Location}</p>
              {props.location.address2Name && (
                <p>{props.location.address2Name}</p>
              )}
              {props.location.address2PhoneNumber && (
                <p>{props.location.address2PhoneNumber}</p>
              )}
            </div>
          </div>
        )}
        {props.location.address3Location && (
          <div
            className="mt-4"
            style={{ border: "1px solid black", borderRadius: "5px" }}
          >
            <div className="m-4">
              <p>{props.location.address3Location}</p>
              {props.location.address3Name && (
                <p>{props.location.address3Name}</p>
              )}
              {props.location.address3PhoneNumber && (
                <p>{props.location.address3PhoneNumber}</p>
              )}
            </div>
          </div>
        )}
        <div
          className="mt-4"
          style={{ border: "1px solid black", borderRadius: "5px" }}
        >
          <div className="m-4">
            <p>{props.location.dropLocation}</p>
            <p>{props.location.dropName && props.location.dropName}</p>
            <p>
              {props.location.dropPhoneNumber && props.location.dropPhoneNumber}
            </p>
          </div>
        </div>
        {/* <pre>{JSON.stringify(props.location, null, 4)}</pre>
        <pre>{JSON.stringify(link, null, 4)}</pre>
        <pre>{JSON.stringify(polyline, null, 4)}</pre> */}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
