import {
  SUB_ADMIN_LOGIN_REQUEST,
  SUB_ADMIN_LOGIN_SUCCESS,
  SUB_ADMIN_LOGIN_FAIL,
  SUB_ADMIN_CUSTOMER_REQUEST,
  SUB_ADMIN_COSTOMER_SUCCESS,
  SUB_ADMIN_COSTOMER_FAIL,
  SUB_ADMIN_UPDATE_CUSTOMER_REQUEST,
  SUB_ADMIN_UPDATE_CUSTOMER_SUCCESS,
  SUB_ADMIN_UPDATE_CUSTOMER_FAIL,
  SUB_ADMIN_LOGOUT,
  SUB_ADMIN_DRIVER_LIST_REQUEST,
  SUB_ADMIN_DRIVER_LIST_SUCCESS,
  SUB_ADMIN_DRIVER_LIST_FAIL,
  SUB_ADMIN_DRIVER_ADD_COMMENT_REQUEST,
  SUB_ADMIN_DRIVER_ADD_COMMENT_SUCCESS,
  SUB_ADMIN_DRIVER_ADD_COMMENT_FAIL,
  SUB_ADMIN_BOOKING_LIST_REQUEST,
  SUB_ADMIN_BOOKING_LIST_SUCCESS,
  SUB_ADMIN_BOOKING_LIST_FAIL,
  SUB_ADMIN_BOOKING_ADDCOMMENT_REQUEST,
  SUB_ADMIN_BOOKING_ADDCOMMENT_SUCCESS,
  SUB_ADMIN_BOOKING_ADDCOMMENT_FAIL,
  SUB_ADMIN_FILTERED_DRIVER_LIST_REQUEST,
  SUB_ADMIN_FILTERED_DRIVER_LIST_SUCCESS,
  SUB_ADMIN_FILTERED_DRIVER_LIST_FAIL,
  SUB_ADMIN_DRIVER_FILTER_STATUS,
  SUB_ADMIN_DRIVER_FILTER_VEHICLE_TYPE
} from '../Constants/SubAdminConstants';

export const subAdminBookingsAddCommentReducer = (state = {commentSuccess:false}, action) => {
  switch (action.type) {
    case SUB_ADMIN_BOOKING_ADDCOMMENT_REQUEST:
      return { loading: true,commentSuccess:false };
    case SUB_ADMIN_BOOKING_ADDCOMMENT_SUCCESS:
      return { loading: false, commentSuccess: true };
    case SUB_ADMIN_BOOKING_ADDCOMMENT_FAIL:
      return { loading: false, error: action.payload,commentSuccess:false };
    default:
      return state;
  }
};
export const subAdminLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case SUB_ADMIN_LOGIN_REQUEST:
      return { loading: true };
    case SUB_ADMIN_LOGIN_SUCCESS:
      return { loading: false, subAdminInfo: action.payload };
    case SUB_ADMIN_LOGIN_FAIL:
      return { error: action.payload };
      case SUB_ADMIN_LOGOUT:
        return {};
    default:
      return state;
  }
};


export const sub_admin_listAllCustomersReducer = (state = {}, action) => {
  switch (action.type) {
    case SUB_ADMIN_CUSTOMER_REQUEST:
      return { loading: true };
    case SUB_ADMIN_COSTOMER_SUCCESS:
      return { loading: false, customerLists: action.payload };
    case SUB_ADMIN_COSTOMER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subAdminupdateCustomerReducer = (state = {}, action) => {
  switch (action.type) {
    case SUB_ADMIN_UPDATE_CUSTOMER_REQUEST:
      return {};
    case SUB_ADMIN_UPDATE_CUSTOMER_SUCCESS:
      return { success: true };
    case SUB_ADMIN_UPDATE_CUSTOMER_FAIL:
      return { error: action.payload };
    default:
      return state;
  }
};

export const subAdminDriverListReducer = (state = {}, action) => {
  switch (action.type) {
    case SUB_ADMIN_DRIVER_LIST_REQUEST:
      return {};
    case SUB_ADMIN_DRIVER_LIST_SUCCESS:
      return { loading: false, AllDriversList : action.payload };
    case SUB_ADMIN_DRIVER_LIST_FAIL:
      return { error: action.payload };
    default:
      return state;
  }
};
export const subFilterDriverVehicleTypeReducer = (
  state = { VehcleTypeFilter: false, filters: [], typeChange: 1 },
  action
) => {
  if (action.type === SUB_ADMIN_DRIVER_FILTER_VEHICLE_TYPE) {
    if (action.payload.length === 0) {
      return {
        VehcleTypeFilter: false,
        filters: [],
        typeChange: 2,
      };
    } else {
      return {
        VehcleTypeFilter: true,
        filters: [...action.payload],
        typeChange: state.typeChange + 1,
      };
    }
  } else {
    return state;
  }
};

export const subFilterDriverStatusReducer = (
  state = { statusFilter: false, filters: [], flt1: false },
  action
) => {
  if (action.type === SUB_ADMIN_DRIVER_FILTER_STATUS) {
    if (action.payload.length === 0) {
      return {
        statusFilter: false,
        filters: [],
      };
    } else {
      return {
        statusFilter: true,

        filters: [...action.payload],
      };
    }
  } else {
    return state;
  }
};
export const subFilteredDriverReducer = (state = { change: 1 }, action) => {
  switch (action.type) {
    case SUB_ADMIN_FILTERED_DRIVER_LIST_REQUEST:
      return { loading: true, change: state.change + 1 };
    case SUB_ADMIN_FILTERED_DRIVER_LIST_SUCCESS:
      return {
        loading: false,
        DriverList: action.payload,
        success: true,
        change: state.change + 1,
      };
    case SUB_ADMIN_FILTERED_DRIVER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subAdmin_driverAddCommentReducer = (state = {}, action) => {
  switch (action.type) {
    case SUB_ADMIN_DRIVER_ADD_COMMENT_REQUEST:
      return {};
    case SUB_ADMIN_DRIVER_ADD_COMMENT_SUCCESS:
      return { loading: false, AllDriversList : action.payload };
    case SUB_ADMIN_DRIVER_ADD_COMMENT_FAIL:
      return { error: action.payload };
    default:
      return state;
  }
};


export const subAdmin_booking_listReducer = (state = {}, action) => {
  switch(action.type){
    case SUB_ADMIN_BOOKING_LIST_REQUEST :
      return {};
    case SUB_ADMIN_BOOKING_LIST_SUCCESS :
      return {loading : false, bookingsInfo : action.payload}
    case SUB_ADMIN_BOOKING_LIST_FAIL :
      return { error : action.payload};
    default : 
       return state;
  }
}